// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function makeThemeColor(theme) {
  return {
          TAG: "Main",
          _0: theme === "Dark" ? "White" : "Black"
        };
}

function toMainColor(color) {
  if (color === "Text" || color === "Grey" || color === "Arte" || color === "Background" || color === "CorporateMenuText" || color === "Error" || color === "Success" || color === "Secondary" || color === "CorporateMenuIcon" || color === "HoverGrey" || color === "Actualite" || color === "LightGrey" || color === "White" || color === "TextAlternative" || color === "Primary" || color === "Black") {
    return {
            TAG: "Main",
            _0: color
          };
  } else {
    return {
            TAG: "Main",
            _0: "White"
          };
  }
}

var bold = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Bold");

var light = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Light");

var semiBold = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "SemiBold");

var medium = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Medium");

function makeStyle(asset, border, color, displayOpt, weight, sizeOpt, lineHeightOpt, decoration, style, hover, alignment, truncateLine, whiteSpace) {
  var display = displayOpt !== undefined ? Caml_option.valFromOption(displayOpt) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inline");
  var size = sizeOpt !== undefined ? Caml_option.valFromOption(sizeOpt) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");
  var lineHeight = lineHeightOpt !== undefined ? lineHeightOpt : "S";
  return {
          asset: asset,
          border: border,
          color: color,
          display: display,
          lineHeight: lineHeight,
          size: size,
          weight: weight,
          decoration: decoration,
          style: style,
          hover: hover,
          alignment: alignment,
          truncateLine: truncateLine,
          whiteSpace: whiteSpace
        };
}

var $$default = makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var cta = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Secondary"
    }, undefined, Caml_option.some(semiBold), undefined, "XXS", undefined, undefined, {
      TAG: "Main",
      _0: "Secondary"
    }, undefined, undefined, undefined);

function makeCtaWithAsset(asset) {
  return {
          asset: asset,
          border: cta.border,
          color: cta.color,
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: cta.hover,
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

function makeCtaWithAssetAndColor(asset, color) {
  return {
          asset: asset,
          border: cta.border,
          color: color,
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: color,
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

function makeCtaWithColor(color) {
  return {
          asset: cta.asset,
          border: cta.border,
          color: color,
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: color,
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

function makeWhiteCta(asset) {
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "Black"
            }, undefined, Caml_option.some(semiBold), undefined, "XXS", undefined, undefined, {
              TAG: "Main",
              _0: "Black"
            }, undefined, undefined, undefined);
}

var textfield = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var searchHeader = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

function makeIcon(position) {
  return {
          TAG: "Icon",
          _0: {
            position: position,
            icon: Atom_Icon_Ds.play,
            size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
          }
        };
}

var left = makeStyle(makeIcon({
          TAG: "Left",
          _0: Particle_Screen_Constants.xsInnerSpace
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var right = makeStyle(makeIcon({
          TAG: "Right",
          _0: Particle_Screen_Constants.xsInnerSpace
        }), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var alone = makeStyle(makeIcon("Alone"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Icon = {
  makeIcon: makeIcon,
  left: left,
  right: right,
  alone: alone
};

function makeLogo(position) {
  return {
          TAG: "Logo",
          _0: {
            position: position,
            logo: "ArteHorizontalLogo"
          }
        };
}

var left$1 = makeStyle({
      TAG: "Logo",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xsInnerSpace
        },
        logo: "ArteHorizontalLogo"
      }
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var right$1 = makeStyle({
      TAG: "Logo",
      _0: {
        position: {
          TAG: "Right",
          _0: Particle_Screen_Constants.xsInnerSpace
        },
        logo: "ArteHorizontalLogo"
      }
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var alone$1 = makeStyle({
      TAG: "Logo",
      _0: {
        position: "Alone",
        logo: "ArteHorizontalLogo"
      }
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Logo = {
  makeLogo: makeLogo,
  left: left$1,
  right: right$1,
  alone: alone$1
};

var WithAsset = {
  Icon: Icon,
  Logo: Logo
};

var Stories = {
  WithAsset: WithAsset
};

var listItem = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "listItem")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listItemUnderline = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "listItem")), undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

function makeExternal(s) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
                },
                icon: Atom_Icon_Ds.Arrow.arrow45,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
              }
            }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeDownloadWithText(color, param) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                },
                icon: Atom_Icon_Ds.download,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, color, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
}

function makeDownload(color, param) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: Atom_Icon_Ds.download,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
              }
            }, undefined, color, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
}

function make(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", "L", undefined, "S")), "S", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "DescNoClamp") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "XL", undefined, "L")), "S", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else {
    return cta;
  }
}

function button(ctaStatus) {
  return {
          asset: ctaStatus === "Disabled" ? undefined : ({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Right",
                    _0: Particle_Screen_Constants.xxsInnerSpace
                  },
                  icon: Atom_Icon_Ds.Arrow.right,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
                }
              }),
          border: cta.border,
          color: ctaStatus === "Disabled" ? ({
                TAG: "Main",
                _0: "LightGrey"
              }) : ({
                TAG: "Main",
                _0: "Black"
              }),
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: ctaStatus === "Disabled" ? undefined : ({
                TAG: "Main",
                _0: "Black"
              }),
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

function actionButton(status) {
  var tmp;
  tmp = status === "On" ? ({
        TAG: "Icon",
        _0: {
          position: {
            TAG: "Left",
            _0: Particle_Screen_Constants.xxsInnerSpace
          },
          icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.checked, "Black"),
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
        }
      }) : undefined;
  return {
          asset: tmp,
          border: cta.border,
          color: {
            TAG: "Main",
            _0: "Black"
          },
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: {
            TAG: "Main",
            _0: "Black"
          },
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

var EventZone = {
  make: make,
  button: button,
  actionButton: actionButton
};

function makeContentCollection(s) {
  if (typeof s === "object") {
    if (s.NAME === "Cta") {
      return makeStyle({
                  TAG: "Icon",
                  _0: {
                    position: {
                      TAG: "Left",
                      _0: Particle_Screen_Constants.xxsInnerSpace
                    },
                    icon: Atom_Icon_Ds.Arrow.left,
                    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                  }
                }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }
    
  } else {
    if (s === "Subtitle") {
      return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    if (s === "Title") {
      return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "XL")), "XS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    if (s === "Desc") {
      return $$default;
    }
    
  }
  return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeTopBanner(s) {
  if (typeof s === "object") {
    if (s.NAME === "Cta") {
      return makeStyle({
                  TAG: "Icon",
                  _0: {
                    position: {
                      TAG: "Left",
                      _0: Particle_Screen_Constants.xxsInnerSpace
                    },
                    icon: Atom_Icon_Ds.Arrow.left,
                    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                  }
                }, undefined, {
                  TAG: "Main",
                  _0: "White"
                }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Shadow", undefined, undefined, undefined, undefined, undefined);
    }
    
  } else {
    if (s === "Subtitle") {
      return makeStyle(undefined, undefined, {
                  TAG: "Main",
                  _0: "White"
                }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "M")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    if (s === "Title") {
      return makeStyle(undefined, undefined, {
                  TAG: "Main",
                  _0: "White"
                }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, 3, undefined, 2)), undefined);
    }
    
  }
  return makeStyle(undefined, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeTopTeaserPresse(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else {
    return cta;
  }
}

function makeTopTeaser(asset, s) {
  var makeCtaStyle = function (asset, color) {
    return makeStyle(asset, undefined, color, undefined, Caml_option.some(semiBold), undefined, "XS", undefined, undefined, undefined, undefined, undefined, undefined);
  };
  if (typeof s === "object") {
    if (s.NAME === "Cta") {
      var match = s.VAL;
      if (match === "Disabled") {
        return makeCtaStyle(asset, {
                    TAG: "Main",
                    _0: "LightGrey"
                  });
      } else if (match === "Default") {
        return makeStyle(asset, undefined, undefined, undefined, Caml_option.some(semiBold), undefined, "XS", undefined, undefined, undefined, undefined, undefined, undefined);
      } else {
        return makeCtaStyle(undefined, {
                    TAG: "Main",
                    _0: "Black"
                  });
      }
    }
    
  } else {
    if (s === "Subtitle") {
      return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    if (s === "Title") {
      return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    if (s === "Desc") {
      return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
    }
    
  }
  return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);
}

var secondaryCta = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), undefined, "XS", undefined, undefined, undefined, undefined, undefined, "NoWrap");

function makeSecondaryCtaWithAsset(asset) {
  return {
          asset: asset,
          border: secondaryCta.border,
          color: secondaryCta.color,
          display: secondaryCta.display,
          lineHeight: secondaryCta.lineHeight,
          size: secondaryCta.size,
          weight: secondaryCta.weight,
          decoration: secondaryCta.decoration,
          style: secondaryCta.style,
          hover: secondaryCta.hover,
          alignment: secondaryCta.alignment,
          truncateLine: secondaryCta.truncateLine,
          whiteSpace: secondaryCta.whiteSpace
        };
}

var ctaWithSmallResponsiveSize = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

function makeSecondaryCtaWithSmallResponsiveSizeAndAsset(color, asset) {
  return makeStyle(asset, undefined, color, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeFavorite(fill) {
  return makeSecondaryCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.updateColor(fill === "Filled" ? Atom_Icon_Ds.heartFilled : Atom_Icon_Ds.heart, "Text"),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            });
}

function makeFavoriteTeaserHover(fill) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.updateColor(fill === "Filled" ? Atom_Icon_Ds.heartFilled : Atom_Icon_Ds.heart, "White"),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeAlert(strike) {
  return makeSecondaryCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.updateColor(strike === "Default" ? Atom_Icon_Ds.notification : Atom_Icon_Ds.noNotification, "Text"),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            });
}

function makeAlertTeaserHover(strike) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.updateColor(strike === "Default" ? Atom_Icon_Ds.notification : Atom_Icon_Ds.noNotification, "White"),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var Favorite = {
  makeFavorite: makeFavorite,
  makeAlert: makeAlert,
  makeFavoriteTeaserHover: makeFavoriteTeaserHover,
  makeAlertTeaserHover: makeAlertTeaserHover
};

function makeIcon$1(icon) {
  return {
          TAG: "Icon",
          _0: {
            position: "Alone",
            icon: icon,
            size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
          }
        };
}

var pause = makeStyle(makeIcon$1(Atom_Icon_Ds.pause), undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var play = makeStyle(makeIcon$1(Atom_Icon_Ds.play), undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function makeArrow(direction) {
  var icon = Atom_Icon_Ds.Arrow.left;
  return makeCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: icon,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            });
}

var bullet = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Carousel = {
  pause: pause,
  play: play,
  makeArrow: makeArrow,
  bullet: bullet
};

var title = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function makeNoResults(icon) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: Core__Option.getOr(icon, Atom_Icon_Ds.rocket),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXL")
              }
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var Search = {
  title: title,
  makeNoResults: makeNoResults
};

function make$1(s) {
  if (typeof s === "object") {
    if (s.NAME === "Cta") {
      return makeStyle(undefined, undefined, {
                  TAG: "Main",
                  _0: "White"
                }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, {
                  TAG: "Main",
                  _0: "LightGrey"
                }, undefined, undefined, undefined);
    } else {
      return $$default;
    }
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "LightGrey"
              }, undefined, Caml_option.some(light), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), "M", undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return $$default;
  }
}

function makeTitle(status) {
  var asset = status !== undefined ? ({
        TAG: "Icon",
        _0: {
          position: {
            TAG: "Left",
            _0: Particle_Screen_Constants.xxsInnerSpace
          },
          icon: Atom_Icon_Ds.Arrow.fromToggleStatus(status),
          size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
        }
      }) : undefined;
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var title$1 = makeTitle(undefined);

function makePartner(logo) {
  return makeStyle({
              TAG: "Logo",
              _0: {
                position: "Alone",
                logo: logo
              }
            }, undefined, {
              TAG: "Main",
              _0: "LightGrey"
            }, undefined, Caml_option.some(light), undefined, "M", undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeSocial(icon) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: icon,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, {
              TAG: "Main",
              _0: "LightGrey"
            }, undefined, undefined, undefined);
}

var newsletter = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var textfield$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var european = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Footer = {
  make: make$1,
  makeTitle: makeTitle,
  title: title$1,
  makePartner: makePartner,
  makeSocial: makeSocial,
  newsletter: newsletter,
  textfield: textfield$1,
  european: european
};

function makeTos(s) {
  if (s === "Desc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, "Underline", undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, undefined);
  }
}

function makeFeedback(s) {
  if (s === "Error") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Error"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Success"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

var label = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Primary"
    }, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var current = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Primary"
    }, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);

var selectItem = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Primary"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listItem$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Primary"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var greyLabel = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "LightGrey"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Dropdown = {
  current: current,
  selectItem: selectItem,
  listItem: listItem$1,
  label: label,
  greyLabel: greyLabel
};

function makeSocial$1(value, kind) {
  var tmp;
  switch (value) {
    case "Facebook" :
        tmp = Atom_Icon_Ds.SocialMedia.facebook;
        break;
    case "X" :
        tmp = Atom_Icon_Ds.SocialMedia.x;
        break;
    case "Whatsapp" :
        tmp = Atom_Icon_Ds.SocialMedia.whatsapp;
        break;
    
  }
  var tmp$1;
  tmp$1 = kind === "EventZone" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS") : Particle_Screen_Ds.make(undefined, undefined, undefined, "XL", undefined, "S");
  return {
          asset: {
            TAG: "Icon",
            _0: {
              position: "Alone",
              icon: tmp,
              size: tmp$1
            }
          },
          border: cta.border,
          color: {
            TAG: "Main",
            _0: "White"
          },
          display: cta.display,
          lineHeight: cta.lineHeight,
          size: cta.size,
          weight: cta.weight,
          decoration: cta.decoration,
          style: cta.style,
          hover: undefined,
          alignment: cta.alignment,
          truncateLine: cta.truncateLine,
          whiteSpace: cta.whiteSpace
        };
}

var Sharebar = {
  makeSocial: makeSocial$1
};

var cta$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function ctaWithAsset(icon) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: icon,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function ctaWithAssetNewIcon(icon) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: icon,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var link = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var warningCta = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var warningSecondaryCta = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var warningLink = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var ctaWarningMessageHighlighted = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "XS")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var $$default$1 = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "L", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var title$2 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", "XXL", undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var subtitle = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", "L", undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var date = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "LightGrey"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "L", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var hoverChapter = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "M", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var hoverNextChapter = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var programTitle = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", "XXL", undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var message = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "L", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Player = {
  cta: cta$1,
  ctaWithAsset: ctaWithAsset,
  ctaWithAssetNewIcon: ctaWithAssetNewIcon,
  link: link,
  warningCta: warningCta,
  warningSecondaryCta: warningSecondaryCta,
  warningLink: warningLink,
  ctaWarningMessageHighlighted: ctaWarningMessageHighlighted,
  $$default: $$default$1,
  title: title$2,
  subtitle: subtitle,
  date: date,
  hoverChapter: hoverChapter,
  hoverNextChapter: hoverNextChapter,
  programTitle: programTitle,
  message: message
};

var emoji = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")), "XXS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var title$3 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "XL")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var itemTitle = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")), "XXS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var itemDesc = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var itemMessage = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var messageLink = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var ctaActive = makeStyle(undefined, undefined, toMainColor("Secondary"), undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Setting = {
  emoji: emoji,
  title: title$3,
  itemTitle: itemTitle,
  itemDesc: itemDesc,
  itemMessage: itemMessage,
  messageLink: messageLink,
  ctaActive: ctaActive
};

function make$2(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "inline", undefined, undefined, "none")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return cta;
  }
}

function makePlayNext(s) {
  if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return cta;
  }
}

function makeAfp(s) {
  if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "inline", undefined, undefined, "none")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

function makeInfo(s) {
  if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")), "XXS", undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "inline", undefined, undefined, "none")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

function makeWhite(s) {
  if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "inline", undefined, undefined, "none")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

var downloadAll = makeCtaWithAsset({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.download,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    });

var seeMore = makeCtaWithAsset({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Right",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.Arrow.right,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    });

var seeMorePlayNext = makeWhiteCta({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Right",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.Arrow.right,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    });

function makeNav(direction) {
  return makeCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.Arrow.fromDirection(direction), "Black"),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            });
}

var Slider = {
  make: make$2,
  makePlayNext: makePlayNext,
  makeAfp: makeAfp,
  makeInfo: makeInfo,
  makeWhite: makeWhite,
  downloadAll: downloadAll,
  seeMore: seeMore,
  seeMorePlayNext: seeMorePlayNext,
  makeNav: makeNav
};

function disable(t) {
  return {
          asset: t.asset,
          border: t.border,
          color: {
            TAG: "Main",
            _0: "LightGrey"
          },
          display: t.display,
          lineHeight: t.lineHeight,
          size: t.size,
          weight: t.weight,
          decoration: t.decoration,
          style: t.style,
          hover: t.hover,
          alignment: t.alignment,
          truncateLine: t.truncateLine,
          whiteSpace: t.whiteSpace
        };
}

function make$3(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "MobileHoverButton") {
    return makeStyle({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Left",
                    _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                  },
                  icon: Atom_Icon_Ds.info,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                }
              }, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(light), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")), "XXS", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else {
    return cta;
  }
}

function makeCollection(level) {
  if (level === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);
  }
}

function toParent(t) {
  return t;
}

function makeAfp$1(s) {
  var init = make$3(s);
  return {
          asset: init.asset,
          border: init.border,
          color: {
            TAG: "Main",
            _0: "White"
          },
          display: init.display,
          lineHeight: init.lineHeight,
          size: init.size,
          weight: init.weight,
          decoration: init.decoration,
          style: init.style,
          hover: init.hover,
          alignment: init.alignment,
          truncateLine: init.truncateLine,
          whiteSpace: init.whiteSpace
        };
}

function makePlayNext$1(s) {
  var init = make$3(s);
  return {
          asset: init.asset,
          border: init.border,
          color: {
            TAG: "Main",
            _0: "White"
          },
          display: init.display,
          lineHeight: init.lineHeight,
          size: init.size,
          weight: init.weight,
          decoration: init.decoration,
          style: init.style,
          hover: init.hover,
          alignment: init.alignment,
          truncateLine: init.truncateLine,
          whiteSpace: init.whiteSpace
        };
}

function makeInfo$1(s) {
  if (s === "MobileHoverButton") {
    return makeStyle({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Left",
                    _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                  },
                  icon: Atom_Icon_Ds.info,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                }
              }, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(light), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")), "XXS", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  }
}

function makeWhite$1(s) {
  if (s === "MobileHoverButton") {
    return makeStyle({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Left",
                    _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                  },
                  icon: Atom_Icon_Ds.info,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                }
              }, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(light), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")), "XXS", undefined, undefined, undefined, undefined, undefined, undefined);
  }
  var init = make$3(s);
  return {
          asset: init.asset,
          border: init.border,
          color: {
            TAG: "Main",
            _0: "Black"
          },
          display: init.display,
          lineHeight: init.lineHeight,
          size: init.size,
          weight: init.weight,
          decoration: init.decoration,
          style: init.style,
          hover: init.hover,
          alignment: init.alignment,
          truncateLine: init.truncateLine,
          whiteSpace: init.whiteSpace
        };
}

var makeGenre = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

function makeGuide(s) {
  if (typeof s === "object") {
    if (s.NAME === "Cta" && s.VAL === "Default") {
      return makeStyle(undefined, undefined, {
                  TAG: "Main",
                  _0: "LightGrey"
                }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, "S", "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
    } else {
      return $$default;
    }
  } else if (s === "Subtitle") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, "S", "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, "M", "S")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else {
    return $$default;
  }
}

var date$1 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, "M", "S")), undefined, "Capitalize", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), "NoWrap");

function make$4(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 3)), undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "White"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  }
}

function cta$2(asset, param) {
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);
}

var trailerCta = cta$2({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.play,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    }, undefined);

var portraitDesc = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 4)), undefined);

var genreLink = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "LightGrey"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

function toParent$1(t) {
  return t;
}

var Hover = {
  make: make$4,
  cta: cta$2,
  trailerCta: trailerCta,
  portraitDesc: portraitDesc,
  genreLink: genreLink,
  toParent: toParent$1
};

var geoblocking = makeStyle({
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.geoblocking,
        size: Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "XL")
      }
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Teaser = {
  make: make$3,
  makePlayNext: makePlayNext$1,
  makeAfp: makeAfp$1,
  makeInfo: makeInfo$1,
  makeWhite: makeWhite$1,
  makeGenre: makeGenre,
  date: date$1,
  toParent: toParent,
  disable: disable,
  makeCollection: makeCollection,
  makeGuide: makeGuide,
  geoblocking: geoblocking,
  Hover: Hover
};

var title$4 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var TeaserCollection = {
  title: title$4
};

function title$5(variant) {
  var tmp;
  tmp = variant === "Guide" ? Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S") : Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS");
  return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(tmp), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);
}

var subtitle$1 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "Bold", undefined, "Regular")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var description = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "initial", undefined, "none")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var category = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var firstBroadcast = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var date$2 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, "M", "S")), undefined, "Capitalize", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

function toParent$2(t) {
  return t;
}

var Broadcast = {
  title: title$5,
  subtitle: subtitle$1,
  description: description,
  category: category,
  firstBroadcast: firstBroadcast,
  date: date$2,
  toParent: toParent$2
};

var desc = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "inline", undefined, undefined, "none")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, 1, undefined, undefined, undefined)), undefined);

function makeCat(cat, weight) {
  var weight$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, weight);
  if (cat === "Default") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(weight$1), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, {
                TAG: "Category",
                _0: cat
              }, undefined, Caml_option.some(weight$1), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, {
                TAG: "Category",
                _0: cat
              }, undefined, undefined, undefined);
  }
}

function $$default$2(weight) {
  return makeCat("Default", weight);
}

function actualite(weight) {
  return makeCat("Actualite", weight);
}

function cinema(weight) {
  return makeCat("Cinema", weight);
}

function serie(weight) {
  return makeCat("Serie", weight);
}

function culture(weight) {
  return makeCat("Culture", weight);
}

function concert(weight) {
  return makeCat("Concert", weight);
}

function science(weight) {
  return makeCat("Science", weight);
}

function voyage(weight) {
  return makeCat("Voyage", weight);
}

function histoire(weight) {
  return makeCat("Histoire", weight);
}

var Breadcrumb = {
  desc: desc,
  makeCat: makeCat,
  $$default: $$default$2,
  actualite: actualite,
  cinema: cinema,
  serie: serie,
  culture: culture,
  concert: concert,
  science: science,
  voyage: voyage,
  histoire: histoire
};

var time = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var base = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var direct = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")), undefined, "Uppercase", undefined, undefined, undefined, undefined, undefined);

var live = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var highlight = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var tag = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var firstBroadcastDate = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.comingSoon,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
      }
    }, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var time$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXS", undefined, "XS", "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var base$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXS", undefined, "XS", "XS")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 1)), undefined);

var direct$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXS", undefined, "XS", "XS")), undefined, "Uppercase", undefined, undefined, undefined, undefined, undefined);

var TeaserGuide = {
  time: time$1,
  base: base$1,
  direct: direct$1
};

var Sticker = {
  time: time,
  base: base,
  direct: direct,
  live: live,
  highlight: highlight,
  tag: tag,
  firstBroadcastDate: firstBroadcastDate,
  TeaserGuide: TeaserGuide
};

function make$5(s, asset, hover) {
  if (typeof s === "object" && s.NAME === "Cta" && s.VAL === "Active") {
    return makeStyle(asset, undefined, {
                TAG: "Main",
                _0: "Black"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
  }
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, hover, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
}

function makeUserPanel(asset) {
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
}

var userPanelTitle = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);

var userPanelDescription = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);

var userPanelRegistration = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);

function makeLanguageSwitcher(s) {
  if (typeof s === "object" && s.NAME === "Cta" && s.VAL === "Active") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), Caml_option.some(semiBold), undefined, "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
  }
  return makeStyle(undefined, undefined, {
              TAG: "Main",
              _0: "White"
            }, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex")), Caml_option.some(semiBold), undefined, "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
}

function makeMenuNavigation(asset, s) {
  if (typeof s === "object" && s.NAME === "Cta" && s.VAL === "Active") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M2")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
  }
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M2")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
}

var concert$1 = makeStyle(undefined, undefined, {
      TAG: "Category",
      _0: "Concert"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, "NoWrap");

var selectGenre = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, "M", undefined, undefined, undefined, undefined, undefined, "NoWrap");

var concertMenuNavigation = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M2")), "M", undefined, undefined, undefined, undefined, undefined, "NoWrap");

var concertLogo = makeCtaWithAsset({
      TAG: "Logo",
      _0: {
        position: "Alone",
        logo: "ConcertLogo"
      }
    });

var redirectionAnchor = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var installMobileApp = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), "XXS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var link$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var Header = {
  make: make$5,
  makeUserPanel: makeUserPanel,
  userPanelTitle: userPanelTitle,
  userPanelDescription: userPanelDescription,
  userPanelRegistration: userPanelRegistration,
  makeLanguageSwitcher: makeLanguageSwitcher,
  makeMenuNavigation: makeMenuNavigation,
  concert: concert$1,
  selectGenre: selectGenre,
  concertMenuNavigation: concertMenuNavigation,
  concertLogo: concertLogo,
  redirectionAnchor: redirectionAnchor,
  installMobileApp: installMobileApp,
  link: link$1
};

function makeStorybook(s) {
  if (typeof s === "object") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "LightGrey"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Letter") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Black"
              }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Subdesc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "LightGrey"
              }, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "Icon") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Text"
              }, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

function makeMessage(s) {
  if (typeof s === "object") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (s === "SubHeaderError") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Secondary"
              }, undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
  } else if (s === "SubHeader") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Secondary"
              }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "PreWrap");
  } else {
    return makeStyle({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Right",
                    _0: Particle_Screen_Constants.xxsInnerSpace
                  },
                  icon: Atom_Icon_Ds.Arrow.right,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                }
              }, undefined, {
                TAG: "Main",
                _0: "Secondary"
              }, undefined, Caml_option.some(bold), undefined, undefined, "Underline", undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, "PreWrap");
  }
}

function makeExpanderButton(direction) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.Arrow.fromDirection(direction),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
}

function make$6(s) {
  if (typeof s === "object" && s.NAME === "Cta" && s.VAL === "Default") {
    return makeStyle({
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Left",
                    _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
                  },
                  icon: Atom_Icon_Ds.Arrow.arrow45,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
                }
              }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
  return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var ProgramExternal = {
  make: make$6
};

function make$7(s) {
  if (s === "Subtitle") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), "XS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Title") {
    return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);
  } else if (s === "Desc") {
    return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return cta;
  }
}

var descBold = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var buyProgramLink = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", "Underline", undefined, undefined, undefined, undefined, undefined);

var label$1 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var value = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var valueGenre = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")), "L", "Underline", undefined, undefined, undefined, undefined, undefined);

var chapter = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", "Underline", undefined, undefined, undefined, undefined, undefined);

var makeLink = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), "L", "Underline", undefined, undefined, undefined, undefined, undefined);

var ProgramContent = {
  make: make$7,
  descBold: descBold,
  buyProgramLink: buyProgramLink,
  label: label$1,
  value: value,
  valueGenre: valueGenre,
  chapter: chapter,
  makeLink: makeLink
};

var label$2 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), "M", undefined, undefined, undefined, undefined, undefined, "PreWrap");

var title$6 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var PlayNextSimple = {
  label: label$2,
  title: title$6
};

var switchLabel = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var paginatedGrid = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var pageList = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var coloredBox = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

function make$8(s) {
  if (s === "Desc") {
    return makeStyle(undefined, undefined, {
                TAG: "Main",
                _0: "Arte"
              }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, "Italic", undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);
  } else {
    return makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);
  }
}

var Quote = {
  make: make$8
};

function title$7(kind) {
  var alignment;
  alignment = kind === "MyAccount" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left") : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center");
  return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "XL")), "XS", undefined, undefined, undefined, Caml_option.some(alignment), undefined, undefined);
}

function subtitle$2(kind) {
  var alignment;
  alignment = kind === "MyAccount" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left") : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center");
  return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, Caml_option.some(alignment), undefined, undefined);
}

function cardTitle(surname) {
  var tmp;
  tmp = surname.TAG === "Email" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L") : Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L");
  return makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(tmp), "XS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);
}

var cardCta = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var label$3 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var info = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "LightGrey"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var $$default$3 = makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), undefined, undefined);

var cta$3 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var action = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function text(kind) {
  var alignment;
  alignment = kind === "MyAccount" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left") : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center");
  return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, Caml_option.some(alignment), undefined, undefined);
}

function textSmall(kind) {
  var alignment;
  alignment = kind === "MyAccount" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left") : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center");
  return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, Caml_option.some(alignment), undefined, undefined);
}

var link$2 = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var accountLink = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var textBold = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var footer = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var footerLink = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var footerAccordionLink = makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var dropdown = makeStyle({
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.settings,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var checkbox = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var checkboxLink = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

function makeTextfield(writableState) {
  var tmp;
  tmp = writableState === "Writable" ? ({
        TAG: "Main",
        _0: "White"
      }) : ({
        TAG: "Main",
        _0: "LightGrey"
      });
  return makeStyle(undefined, undefined, tmp, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);
}

var tvCellField = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var tvCellFieldNewDesign = makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.tvLogin)), undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var eventLink = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var profileNavigationItemList = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Right")), undefined, undefined);

function makeHeaderNavigationItemList(state) {
  var tmp;
  tmp = state === "Current" ? ({
        TAG: "Main",
        _0: "Arte"
      }) : ({
        TAG: "Main",
        _0: "White"
      });
  return makeStyle(undefined, undefined, tmp, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var hiddenLabel = makeStyle(undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "none")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var backlink = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.Arrow.left,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
      }
    }, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, "Underline", undefined, undefined, undefined, undefined, undefined);

var success = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
        },
        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.circleCheck, "Success"),
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var User = {
  title: title$7,
  subtitle: subtitle$2,
  cardTitle: cardTitle,
  cardCta: cardCta,
  label: label$3,
  info: info,
  $$default: $$default$3,
  cta: cta$3,
  action: action,
  text: text,
  textSmall: textSmall,
  link: link$2,
  accountLink: accountLink,
  textBold: textBold,
  footer: footer,
  footerLink: footerLink,
  footerAccordionLink: footerAccordionLink,
  dropdown: dropdown,
  checkbox: checkbox,
  checkboxLink: checkboxLink,
  makeTextfield: makeTextfield,
  tvCellField: tvCellField,
  tvCellFieldNewDesign: tvCellFieldNewDesign,
  eventLink: eventLink,
  profileNavigationItemList: profileNavigationItemList,
  makeHeaderNavigationItemList: makeHeaderNavigationItemList,
  hiddenLabel: hiddenLabel,
  backlink: backlink,
  success: success
};

function make$9(state) {
  var commonStyle = function (color) {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "M")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);
  };
  if (state === "Selected") {
    return commonStyle("Secondary");
  } else {
    return commonStyle("Primary");
  }
}

var SelectGenre = {
  make: make$9
};

function makeToggleButton(status) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
                },
                icon: status === "active" ? Atom_Icon_Ds.Arrow.down : Atom_Icon_Ds.Arrow.right,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
              }
            }, undefined, {
              TAG: "Main",
              _0: "Black"
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeMenuItem(asset, highlight) {
  var color = highlight ? "White" : "CorporateMenuText";
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: color
            }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, "NoWrap");
}

var menuItem = makeMenuItem(undefined, false);

var menuItemCurrent = makeMenuItem(undefined, true);

function makeMenuItemWithChilds(highlight, status) {
  var background = highlight ? "White" : "Background";
  var asset = {
    TAG: "Icon",
    _0: {
      position: {
        TAG: "Right",
        _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
      },
      icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.Arrow.fromToggleStatus(status), background),
      size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
    }
  };
  return makeMenuItem(asset, highlight);
}

function makeMenuItemChild(highlight) {
  return makeMenuItem(undefined, highlight);
}

var asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.search, "CorporateMenuIcon"),
    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
  }
};

var searchButton = makeMenuItem(asset, false);

var Corporate = {
  makeToggleButton: makeToggleButton,
  menuItem: menuItem,
  makeMenuItemWithChilds: makeMenuItemWithChilds,
  menuItemCurrent: menuItemCurrent,
  makeMenuItemChild: makeMenuItemChild,
  searchButton: searchButton
};

var menuBrandTitle = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "S")), undefined, "Uppercase", undefined, undefined, undefined, undefined, undefined);

function makeMenuItem$1(asset) {
  return makeStyle(asset, undefined, {
              TAG: "Main",
              _0: "White"
            }, undefined, undefined, undefined, "L", undefined, undefined, undefined, undefined, undefined, "NoWrap");
}

var menuItem$1 = makeMenuItem$1(undefined);

var menuItemCurrent$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Grey"
    }, undefined, undefined, undefined, "L", undefined, undefined, undefined, undefined, undefined, "NoWrap");

function clearFilter(disabled) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: {
                  TAG: "Left",
                  _0: Particle_Screen_Constants.xxsInnerSpace
                },
                icon: Atom_Icon_Ds.close,
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
              }
            }, undefined, disabled ? ({
                  TAG: "Main",
                  _0: "LightGrey"
                }) : ({
                  TAG: "Main",
                  _0: "Text"
                }), undefined, undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
}

function makeMenuItemWithChilds$1(status) {
  var asset = {
    TAG: "Icon",
    _0: {
      position: {
        TAG: "Right",
        _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
      },
      icon: Atom_Icon_Ds.Arrow.fromToggleStatus(status),
      size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
    }
  };
  return makeMenuItem$1(asset);
}

var checkbox$1 = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var asset$1 = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.search, "CorporateMenuIcon"),
    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
  }
};

var searchButton$1 = makeMenuItem$1(asset$1);

var title1 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", "XXL", undefined, "L2")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var title2 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L2", "XL", undefined, "L")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var title3 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", "L2", undefined, "M2")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var title4 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M2", "L", undefined, "M")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var title5 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", "M2", undefined, "S")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var title6 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", "M", undefined, "XS")), "XS", undefined, undefined, undefined, undefined, undefined, undefined);

var info$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "LightGrey"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var label$4 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Secondary"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "NoWrap");

var dateRangeLabel = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(light), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var searchSubmit = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Secondary"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var dropdown$1 = makeStyle({
      TAG: "Icon",
      _0: {
        position: "Alone",
        icon: Atom_Icon_Ds.settings,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var textfield$2 = makeStyle(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Left")), undefined, undefined);

function makeWeekSlider(status) {
  var tmp;
  tmp = status === "Current" ? ({
        TAG: "Main",
        _0: "Arte"
      }) : ({
        TAG: "Main",
        _0: "Text"
      });
  return makeStyle(undefined, undefined, tmp, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var weekSliderTooltip = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var pagination = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Text"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var paginationActive = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function paginationArrow(direction) {
  return makeStyle({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: Atom_Icon_Ds.Arrow.fromDirection(direction),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var guideDownload_asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.download, "Text"),
    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
  }
};

var guideDownload_border = ctaWithSmallResponsiveSize.border;

var guideDownload_color = {
  TAG: "Main",
  _0: "Text"
};

var guideDownload_display = ctaWithSmallResponsiveSize.display;

var guideDownload_lineHeight = ctaWithSmallResponsiveSize.lineHeight;

var guideDownload_size = ctaWithSmallResponsiveSize.size;

var guideDownload_weight = ctaWithSmallResponsiveSize.weight;

var guideDownload_decoration = ctaWithSmallResponsiveSize.decoration;

var guideDownload_style = ctaWithSmallResponsiveSize.style;

var guideDownload_hover = ctaWithSmallResponsiveSize.hover;

var guideDownload_alignment = ctaWithSmallResponsiveSize.alignment;

var guideDownload_truncateLine = ctaWithSmallResponsiveSize.truncateLine;

var guideDownload_whiteSpace = ctaWithSmallResponsiveSize.whiteSpace;

var guideDownload = {
  asset: guideDownload_asset,
  border: guideDownload_border,
  color: guideDownload_color,
  display: guideDownload_display,
  lineHeight: guideDownload_lineHeight,
  size: guideDownload_size,
  weight: guideDownload_weight,
  decoration: guideDownload_decoration,
  style: guideDownload_style,
  hover: guideDownload_hover,
  alignment: guideDownload_alignment,
  truncateLine: guideDownload_truncateLine,
  whiteSpace: guideDownload_whiteSpace
};

function makeNav$1(direction) {
  return makeCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: Atom_Icon_Ds.Arrow.fromDirection(direction),
                size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
              }
            });
}

function getColorByStatus(status) {
  if (status === "Current") {
    return {
            TAG: "Main",
            _0: "Background"
          };
  } else {
    return {
            TAG: "Main",
            _0: "Text"
          };
  }
}

function makeDay(status) {
  return makeStyle(undefined, undefined, getColorByStatus(status), undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "M")), undefined, "Capitalize", undefined, undefined, undefined, undefined, undefined);
}

function makeDate(status) {
  return makeStyle(undefined, undefined, getColorByStatus(status), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var downloadLabel = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "S")), undefined, "Capitalize", undefined, undefined, undefined, undefined, undefined);

var downloadFileName = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listGroupTitle = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listDate = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listTitle = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "M", undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function makeText(lines) {
  return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, lines)), "PreLine");
}

var listText = makeText(3);

var listInfos = makeText(2);

var articleButton = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Secondary"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var accordionIconIconOn_position = {
  TAG: "Left",
  _0: Particle_Screen_Constants.xxsInnerSpace
};

var accordionIconIconOn_icon = Atom_Icon_Ds.Arrow.up;

var accordionIconIconOn_size = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var accordionIconIconOn = {
  position: accordionIconIconOn_position,
  icon: accordionIconIconOn_icon,
  size: accordionIconIconOn_size
};

var accordionTitleOn = makeStyle({
      TAG: "Icon",
      _0: accordionIconIconOn
    }, undefined, {
      TAG: "Main",
      _0: "Primary"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var accordionTitleOff_asset = {
  TAG: "Icon",
  _0: {
    position: accordionIconIconOn_position,
    icon: Atom_Icon_Ds.Arrow.down,
    size: accordionIconIconOn_size
  }
};

var accordionTitleOff_border = accordionTitleOn.border;

var accordionTitleOff_color = accordionTitleOn.color;

var accordionTitleOff_display = accordionTitleOn.display;

var accordionTitleOff_lineHeight = accordionTitleOn.lineHeight;

var accordionTitleOff_size = accordionTitleOn.size;

var accordionTitleOff_weight = accordionTitleOn.weight;

var accordionTitleOff_decoration = accordionTitleOn.decoration;

var accordionTitleOff_style = accordionTitleOn.style;

var accordionTitleOff_hover = accordionTitleOn.hover;

var accordionTitleOff_alignment = accordionTitleOn.alignment;

var accordionTitleOff_truncateLine = accordionTitleOn.truncateLine;

var accordionTitleOff_whiteSpace = accordionTitleOn.whiteSpace;

var accordionTitleOff = {
  asset: accordionTitleOff_asset,
  border: accordionTitleOff_border,
  color: accordionTitleOff_color,
  display: accordionTitleOff_display,
  lineHeight: accordionTitleOff_lineHeight,
  size: accordionTitleOff_size,
  weight: accordionTitleOff_weight,
  decoration: accordionTitleOff_decoration,
  style: accordionTitleOff_style,
  hover: accordionTitleOff_hover,
  alignment: accordionTitleOff_alignment,
  truncateLine: accordionTitleOff_truncateLine,
  whiteSpace: accordionTitleOff_whiteSpace
};

function firstBroadcastDate$1(improveComWithUser) {
  if (improveComWithUser === "On") {
    return $$default;
  } else {
    return makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  }
}

var Presse = {
  menuBrandTitle: menuBrandTitle,
  menuItem: menuItem$1,
  makeMenuItemWithChilds: makeMenuItemWithChilds$1,
  searchSubmit: searchSubmit,
  menuItemCurrent: menuItemCurrent$1,
  searchButton: searchButton$1,
  title1: title1,
  title2: title2,
  title3: title3,
  title4: title4,
  title5: title5,
  title6: title6,
  info: info$1,
  $$default: $$default,
  label: label$4,
  checkbox: checkbox$1,
  hightlightFilter: $$default,
  dropdown: dropdown$1,
  dateRangeLabel: dateRangeLabel,
  makeWeekSlider: makeWeekSlider,
  textfield: textfield$2,
  guideDownload: guideDownload,
  weekSliderTooltip: weekSliderTooltip,
  makeNav: makeNav$1,
  makeDay: makeDay,
  makeDate: makeDate,
  downloadLabel: downloadLabel,
  downloadFileName: downloadFileName,
  listGroupTitle: listGroupTitle,
  listDate: listDate,
  listTitle: listTitle,
  listText: listText,
  listInfos: listInfos,
  articleButton: articleButton,
  accordionTitleOn: accordionTitleOn,
  accordionTitleOff: accordionTitleOff,
  firstBroadcastDate: firstBroadcastDate$1,
  pagination: pagination,
  paginationActive: paginationActive,
  paginationArrow: paginationArrow,
  clearFilter: clearFilter
};

var title$8 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var desc$1 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, "M", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var $$default$4 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var deviceDesktop_asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.desktop,
    size: Particle_Screen_Ds.make(undefined, undefined, {
          NAME: "Height",
          VAL: "XXL"
        }, undefined, undefined, {
          NAME: "Height",
          VAL: "XL"
        })
  }
};

var deviceDesktop_border = $$default$4.border;

var deviceDesktop_color = $$default$4.color;

var deviceDesktop_display = $$default$4.display;

var deviceDesktop_lineHeight = $$default$4.lineHeight;

var deviceDesktop_size = $$default$4.size;

var deviceDesktop_weight = $$default$4.weight;

var deviceDesktop_decoration = $$default$4.decoration;

var deviceDesktop_style = $$default$4.style;

var deviceDesktop_hover = $$default$4.hover;

var deviceDesktop_alignment = $$default$4.alignment;

var deviceDesktop_truncateLine = $$default$4.truncateLine;

var deviceDesktop_whiteSpace = $$default$4.whiteSpace;

var deviceDesktop = {
  asset: deviceDesktop_asset,
  border: deviceDesktop_border,
  color: deviceDesktop_color,
  display: deviceDesktop_display,
  lineHeight: deviceDesktop_lineHeight,
  size: deviceDesktop_size,
  weight: deviceDesktop_weight,
  decoration: deviceDesktop_decoration,
  style: deviceDesktop_style,
  hover: deviceDesktop_hover,
  alignment: deviceDesktop_alignment,
  truncateLine: deviceDesktop_truncateLine,
  whiteSpace: deviceDesktop_whiteSpace
};

var deviceSmartPhone_asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.smartPhone,
    size: Particle_Screen_Ds.make(undefined, undefined, {
          NAME: "Height",
          VAL: "XXL"
        }, undefined, undefined, {
          NAME: "Height",
          VAL: "XL"
        })
  }
};

var deviceSmartPhone_border = $$default$4.border;

var deviceSmartPhone_color = $$default$4.color;

var deviceSmartPhone_display = $$default$4.display;

var deviceSmartPhone_lineHeight = $$default$4.lineHeight;

var deviceSmartPhone_size = $$default$4.size;

var deviceSmartPhone_weight = $$default$4.weight;

var deviceSmartPhone_decoration = $$default$4.decoration;

var deviceSmartPhone_style = $$default$4.style;

var deviceSmartPhone_hover = $$default$4.hover;

var deviceSmartPhone_alignment = $$default$4.alignment;

var deviceSmartPhone_truncateLine = $$default$4.truncateLine;

var deviceSmartPhone_whiteSpace = $$default$4.whiteSpace;

var deviceSmartPhone = {
  asset: deviceSmartPhone_asset,
  border: deviceSmartPhone_border,
  color: deviceSmartPhone_color,
  display: deviceSmartPhone_display,
  lineHeight: deviceSmartPhone_lineHeight,
  size: deviceSmartPhone_size,
  weight: deviceSmartPhone_weight,
  decoration: deviceSmartPhone_decoration,
  style: deviceSmartPhone_style,
  hover: deviceSmartPhone_hover,
  alignment: deviceSmartPhone_alignment,
  truncateLine: deviceSmartPhone_truncateLine,
  whiteSpace: deviceSmartPhone_whiteSpace
};

var deviceTv_asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.tv,
    size: Particle_Screen_Ds.make(undefined, undefined, {
          NAME: "Height",
          VAL: "XXL"
        }, undefined, undefined, {
          NAME: "Height",
          VAL: "XL"
        })
  }
};

var deviceTv_border = $$default$4.border;

var deviceTv_color = $$default$4.color;

var deviceTv_display = $$default$4.display;

var deviceTv_lineHeight = $$default$4.lineHeight;

var deviceTv_size = $$default$4.size;

var deviceTv_weight = $$default$4.weight;

var deviceTv_decoration = $$default$4.decoration;

var deviceTv_style = $$default$4.style;

var deviceTv_hover = $$default$4.hover;

var deviceTv_alignment = $$default$4.alignment;

var deviceTv_truncateLine = $$default$4.truncateLine;

var deviceTv_whiteSpace = $$default$4.whiteSpace;

var deviceTv = {
  asset: deviceTv_asset,
  border: deviceTv_border,
  color: deviceTv_color,
  display: deviceTv_display,
  lineHeight: deviceTv_lineHeight,
  size: deviceTv_size,
  weight: deviceTv_weight,
  decoration: deviceTv_decoration,
  style: deviceTv_style,
  hover: deviceTv_hover,
  alignment: deviceTv_alignment,
  truncateLine: deviceTv_truncateLine,
  whiteSpace: deviceTv_whiteSpace
};

var deviceTablet_asset = {
  TAG: "Icon",
  _0: {
    position: "Alone",
    icon: Atom_Icon_Ds.tablet,
    size: Particle_Screen_Ds.make(undefined, undefined, {
          NAME: "Height",
          VAL: "XXL"
        }, undefined, undefined, {
          NAME: "Height",
          VAL: "XL"
        })
  }
};

var deviceTablet_border = $$default$4.border;

var deviceTablet_color = $$default$4.color;

var deviceTablet_display = $$default$4.display;

var deviceTablet_lineHeight = $$default$4.lineHeight;

var deviceTablet_size = $$default$4.size;

var deviceTablet_weight = $$default$4.weight;

var deviceTablet_decoration = $$default$4.decoration;

var deviceTablet_style = $$default$4.style;

var deviceTablet_hover = $$default$4.hover;

var deviceTablet_alignment = $$default$4.alignment;

var deviceTablet_truncateLine = $$default$4.truncateLine;

var deviceTablet_whiteSpace = $$default$4.whiteSpace;

var deviceTablet = {
  asset: deviceTablet_asset,
  border: deviceTablet_border,
  color: deviceTablet_color,
  display: deviceTablet_display,
  lineHeight: deviceTablet_lineHeight,
  size: deviceTablet_size,
  weight: deviceTablet_weight,
  decoration: deviceTablet_decoration,
  style: deviceTablet_style,
  hover: deviceTablet_hover,
  alignment: deviceTablet_alignment,
  truncateLine: deviceTablet_truncateLine,
  whiteSpace: deviceTablet_whiteSpace
};

var OverFooter = {
  title: title$8,
  desc: desc$1,
  deviceDesktop: deviceDesktop,
  deviceSmartPhone: deviceSmartPhone,
  deviceTv: deviceTv,
  deviceTablet: deviceTablet
};

var title$9 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "XL", undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var cta$4 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Black"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var textfield$3 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var NewsletterZone = {
  title: title$9,
  cta: cta$4,
  textfield: textfield$3
};

var title$10 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var Modal = {
  title: title$10
};

var _500Title = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var _500Subtitle = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var _500cta = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "Arte"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var _404Title = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "L")), "L", undefined, undefined, undefined, undefined, undefined, undefined);

var _404Subtitle = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, "L", undefined, undefined, undefined, undefined, undefined, undefined);

var _404cta = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.home,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var _404HaikuTitle = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", "XXL", undefined, "L")), "XXS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var _404HaikuSubtitle = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", "XL", undefined, "M")), "XXS", undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), undefined, undefined);

var _404Haiku = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", "XL", undefined, "M")), "XXS", undefined, "Italic", undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "Left", undefined, undefined, "Center")), undefined, undefined);

var _500FrontendTitle_asset = _404HaikuTitle.asset;

var _500FrontendTitle_border = _404HaikuTitle.border;

var _500FrontendTitle_color = _404HaikuTitle.color;

var _500FrontendTitle_display = _404HaikuTitle.display;

var _500FrontendTitle_lineHeight = _404HaikuTitle.lineHeight;

var _500FrontendTitle_size = _404HaikuTitle.size;

var _500FrontendTitle_weight = _404HaikuTitle.weight;

var _500FrontendTitle_decoration = _404HaikuTitle.decoration;

var _500FrontendTitle_style = _404HaikuTitle.style;

var _500FrontendTitle_hover = _404HaikuTitle.hover;

var _500FrontendTitle_alignment = _404HaikuTitle.alignment;

var _500FrontendTitle_whiteSpace = _404HaikuTitle.whiteSpace;

var _500FrontendTitle = {
  asset: _500FrontendTitle_asset,
  border: _500FrontendTitle_border,
  color: _500FrontendTitle_color,
  display: _500FrontendTitle_display,
  lineHeight: _500FrontendTitle_lineHeight,
  size: _500FrontendTitle_size,
  weight: _500FrontendTitle_weight,
  decoration: _500FrontendTitle_decoration,
  style: _500FrontendTitle_style,
  hover: _500FrontendTitle_hover,
  alignment: _500FrontendTitle_alignment,
  truncateLine: undefined,
  whiteSpace: _500FrontendTitle_whiteSpace
};

var Oops = {
  _500Title: _500Title,
  _500Subtitle: _500Subtitle,
  _500cta: _500cta,
  _404Title: _404Title,
  _404Subtitle: _404Subtitle,
  _404cta: _404cta,
  _404HaikuTitle: _404HaikuTitle,
  _404HaikuSubtitle: _404HaikuSubtitle,
  _404Haiku: _404Haiku,
  _500FrontendTitle: _500FrontendTitle
};

var title$11 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var date$3 = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "TextAlternative"
    }, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var backlink$1 = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.Arrow.left,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS")
      }
    }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Papa = {
  title: title$11,
  date: date$3,
  $$default: $$default,
  backlink: backlink$1
};

var copyrightInside = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var copyrightOutside = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "TextAlternative"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function makeCopyright(position) {
  if (position === "Inside") {
    return copyrightInside;
  } else {
    return copyrightOutside;
  }
}

var title$12 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function toParent$3(t) {
  return t;
}

var ImageCaption = {
  makeCopyright: makeCopyright,
  title: title$12,
  toParent: toParent$3
};

var securityCenter = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS")), "XS", undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined);

var userPrefMessage = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "XS")), "M", undefined, undefined, undefined, undefined, undefined, undefined);

var UserPref = {
  securityCenter: securityCenter,
  userPrefMessage: userPrefMessage
};

var title$13 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Guide = {
  title: title$13
};

function getColorByStatus$1(status) {
  if (status === "Current") {
    return {
            TAG: "Main",
            _0: "Background"
          };
  } else {
    return {
            TAG: "Main",
            _0: "Text"
          };
  }
}

function makeDay$1(status) {
  return makeStyle(undefined, undefined, getColorByStatus$1(status), undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, "Capitalize", undefined, undefined, undefined, undefined, undefined);
}

function makeDate$1(status) {
  return makeStyle(undefined, undefined, getColorByStatus$1(status), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

var GuideTvCalendar = {
  getColorByStatus: getColorByStatus$1,
  makeDay: makeDay$1,
  makeDate: makeDate$1
};

function makeDefault(color) {
  return makeStyle(undefined, undefined, toMainColor(color), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

function makeAndroidReleases(tag, color) {
  if (tag === "a") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(semiBold), undefined, undefined, "Underline", undefined, {
                TAG: "Main",
                _0: "Arte"
              }, undefined, undefined, undefined);
  } else if (tag === "p") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "em") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, undefined, undefined, undefined, undefined, "Italic", undefined, undefined, undefined, undefined);
  } else if (tag === "h1") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "h2") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "h3") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "li") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "listItem")), undefined, undefined, "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "strong") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeDefault(color);
  }
}

function makePapa(tag, color) {
  if (tag === "a") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, undefined, undefined, undefined, "Underline", undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "p") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "em") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, undefined, undefined, undefined, undefined, "Italic", undefined, undefined, undefined, undefined);
  } else if (tag === "h1") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), "M", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "h2") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "h3") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "block")), Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), "M", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "li") {
    return makeStyle(undefined, undefined, toMainColor(color), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "listItem")), undefined, undefined, "L", undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "strong") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(bold), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeDefault(color);
  }
}

function makeTable(tag, color) {
  if (tag === "td") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "th") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else if (tag === "strong") {
    return makeStyle(undefined, undefined, toMainColor(color), undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "M", undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  } else {
    return makeDefault(color);
  }
}

var DangerousHtml = {
  makeDefault: makeDefault,
  makeAndroidReleases: makeAndroidReleases,
  makePapa: makePapa,
  makeTable: makeTable
};

var heroTitle = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), "XS", undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var heroSubTitle = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(medium), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, 2)), undefined);

var heroTutorial = makeStyle({
      TAG: "Icon",
      _0: {
        position: {
          TAG: "Left",
          _0: Particle_Screen_Constants.xxsInnerSpace
        },
        icon: Atom_Icon_Ds.info,
        size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
      }
    }, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var heroTutorialButton = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var pageTitle = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var listTitle$1 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

function releaseTitle(theme) {
  return makeStyle(undefined, undefined, makeThemeColor(theme), undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), "M", undefined, undefined, undefined, undefined, undefined, undefined);
}

var ctaTutorial = makeStyle(undefined, undefined, {
      TAG: "Main",
      _0: "White"
    }, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var ctaDownload_asset = {
  TAG: "Icon",
  _0: {
    position: {
      TAG: "Left",
      _0: Particle_Screen_Constants.xxsInnerSpace
    },
    icon: Atom_Icon_Ds.download,
    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
  }
};

var ctaDownload_border = ctaWithSmallResponsiveSize.border;

var ctaDownload_color = {
  TAG: "Main",
  _0: "White"
};

var ctaDownload_display = ctaWithSmallResponsiveSize.display;

var ctaDownload_lineHeight = ctaWithSmallResponsiveSize.lineHeight;

var ctaDownload_size = ctaWithSmallResponsiveSize.size;

var ctaDownload_weight = ctaWithSmallResponsiveSize.weight;

var ctaDownload_decoration = ctaWithSmallResponsiveSize.decoration;

var ctaDownload_style = ctaWithSmallResponsiveSize.style;

var ctaDownload_hover = ctaWithSmallResponsiveSize.hover;

var ctaDownload_alignment = ctaWithSmallResponsiveSize.alignment;

var ctaDownload_truncateLine = ctaWithSmallResponsiveSize.truncateLine;

var ctaDownload_whiteSpace = ctaWithSmallResponsiveSize.whiteSpace;

var ctaDownload = {
  asset: ctaDownload_asset,
  border: ctaDownload_border,
  color: ctaDownload_color,
  display: ctaDownload_display,
  lineHeight: ctaDownload_lineHeight,
  size: ctaDownload_size,
  weight: ctaDownload_weight,
  decoration: ctaDownload_decoration,
  style: ctaDownload_style,
  hover: ctaDownload_hover,
  alignment: ctaDownload_alignment,
  truncateLine: ctaDownload_truncateLine,
  whiteSpace: ctaDownload_whiteSpace
};

var AndroidReleases = {
  heroTitle: heroTitle,
  heroSubTitle: heroSubTitle,
  heroTutorial: heroTutorial,
  heroTutorialButton: heroTutorialButton,
  pageTitle: pageTitle,
  listTitle: listTitle$1,
  releaseTitle: releaseTitle,
  ctaTutorial: ctaTutorial,
  ctaDownload: ctaDownload
};

var program = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Partners = {
  program: program,
  rc: $$default
};

function close(color) {
  return makeCtaWithAsset({
              TAG: "Icon",
              _0: {
                position: "Alone",
                icon: color !== undefined ? Atom_Icon_Ds.updateColor(Atom_Icon_Ds.close, color) : Atom_Icon_Ds.close,
                size: Particle_Screen_Ds.make(undefined, undefined, "XS", undefined, undefined, "XXS")
              }
            });
}

var Message = {
  close: close
};

var title$14 = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var link$3 = makeStyle(undefined, undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var SiteMap = {
  title: title$14,
  link: link$3
};

var icon = {
  TAG: "Icon",
  _0: {
    position: {
      TAG: "Left",
      _0: Particle_Screen_Constants.xxsInnerSpace
    },
    icon: Atom_Icon_Ds.play,
    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")
  }
};

var programEnabled = makeSecondaryCtaWithSmallResponsiveSizeAndAsset(undefined, icon);

var programDisabled = makeSecondaryCtaWithSmallResponsiveSizeAndAsset({
      TAG: "Main",
      _0: "Grey"
    }, icon);

var eventZone = makeSecondaryCtaWithSmallResponsiveSizeAndAsset({
      TAG: "Main",
      _0: "White"
    }, icon);

var Trailer = {
  icon: icon,
  programEnabled: programEnabled,
  programDisabled: programDisabled,
  eventZone: eventZone
};

var title$15 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "XXL", undefined, undefined, "L")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var desc$2 = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(bold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")), undefined, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Center")), undefined, undefined);

var header = makeStyle(undefined, undefined, undefined, undefined, Caml_option.some(semiBold), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var Shorts = {
  title: title$15,
  desc: desc$2,
  header: header
};

function view(param) {
  var truncateLine = param.truncateLine;
  var display = param.display;
  var color = param.color;
  var asset = param.asset;
  var getDisplay = function (display, truncateLine) {
    if (display === "none") {
      return "none";
    } else if (truncateLine !== undefined) {
      return "webkitbox";
    } else {
      return display;
    }
  };
  var display$1 = asset !== undefined ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex") : (
      truncateLine !== undefined ? Particle_Screen_Ds.zipWith(getDisplay, display, Caml_option.valFromOption(truncateLine)) : display
    );
  return {
          asset: asset,
          border: param.border,
          color: color !== undefined ? color._0 : undefined,
          display: display$1,
          family: "Barna",
          lineHeight: param.lineHeight,
          size: param.size,
          weight: param.weight,
          decoration: param.decoration,
          style: param.style,
          hover: Core__Option.map(param.hover, (function (hover) {
                  return hover._0;
                })),
          alignment: param.alignment,
          truncateLine: truncateLine,
          whiteSpace: param.whiteSpace
        };
}

var assetIconInnerSpace = Particle_Screen_Constants.xxsInnerSpace;

export {
  makeThemeColor ,
  toMainColor ,
  bold ,
  light ,
  semiBold ,
  medium ,
  makeStyle ,
  assetIconInnerSpace ,
  $$default as default,
  cta ,
  makeCtaWithAsset ,
  makeCtaWithAssetAndColor ,
  makeCtaWithColor ,
  makeWhiteCta ,
  textfield ,
  searchHeader ,
  Stories ,
  listItem ,
  listItemUnderline ,
  makeExternal ,
  makeDownloadWithText ,
  makeDownload ,
  EventZone ,
  makeContentCollection ,
  makeTopBanner ,
  makeTopTeaserPresse ,
  makeTopTeaser ,
  secondaryCta ,
  makeSecondaryCtaWithAsset ,
  ctaWithSmallResponsiveSize ,
  makeSecondaryCtaWithSmallResponsiveSizeAndAsset ,
  Favorite ,
  Carousel ,
  Search ,
  Footer ,
  makeTos ,
  makeFeedback ,
  Dropdown ,
  Sharebar ,
  Player ,
  Setting ,
  Slider ,
  Teaser ,
  TeaserCollection ,
  Broadcast ,
  Breadcrumb ,
  Sticker ,
  Header ,
  makeStorybook ,
  makeMessage ,
  makeExpanderButton ,
  ProgramExternal ,
  ProgramContent ,
  PlayNextSimple ,
  switchLabel ,
  paginatedGrid ,
  pageList ,
  coloredBox ,
  Quote ,
  User ,
  SelectGenre ,
  Corporate ,
  Presse ,
  OverFooter ,
  NewsletterZone ,
  Modal ,
  Oops ,
  Papa ,
  ImageCaption ,
  UserPref ,
  Guide ,
  GuideTvCalendar ,
  DangerousHtml ,
  AndroidReleases ,
  Partners ,
  Message ,
  SiteMap ,
  Trailer ,
  Shorts ,
  view ,
}
/* bold Not a pure module */
