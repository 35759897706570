// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Particle_Color from "./Particle_Color.res.mjs";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.res.mjs";

function pxToRem(px) {
  var remValue = px / 16;
  return CssJs.rem(remValue);
}

var baseFontSize = CssJs.fontSize(CssJs.px(16));

function spacerSizeInsideValue(x) {
  if (x === "M") {
    return 25;
  } else if (x === "S") {
    return 15;
  } else if (x === "XL") {
    return 60;
  } else if (x === "XS") {
    return 10;
  } else if (x === "XXS") {
    return 5;
  } else if (x === "Zero" || x === "None") {
    return 0;
  } else if (x === "XXXS") {
    return 2;
  } else {
    return 30;
  }
}

function spacerSizeInside(size) {
  return CssJs.px(spacerSizeInsideValue(size));
}

function spacerSizeOutside(size) {
  if (size === "Auto") {
    return CssJs.auto;
  } else {
    return spacerSizeInside(size);
  }
}

function spacerSizeToString(size) {
  if (size === "Auto") {
    return Css_AtomicTypes.Margin.toString("auto");
  } else {
    return Css_AtomicTypes.Length.toString(spacerSizeInside(size));
  }
}

function breakpoint(x) {
  if (x === "M") {
    return 768;
  } else if (x === "S") {
    return 480;
  } else if (x === "XL") {
    return 1440;
  } else if (x === "XS") {
    return 0;
  } else {
    return 1200;
  }
}

function screenResolutionBreakpoint(x) {
  var variant = x.NAME;
  if (variant === "M") {
    return breakpoint("M");
  } else if (variant === "S") {
    return breakpoint("S");
  } else if (variant === "XL") {
    return breakpoint("XL");
  } else if (variant === "XS") {
    return breakpoint("XS");
  } else {
    return breakpoint("L");
  }
}

function screenResolutionToPx(screenSize) {
  return CssJs.px(screenResolutionBreakpoint(screenSize));
}

function minWidthMediaQuery(screenSize) {
  if (typeof screenSize === "object" && screenSize.NAME === "XS") {
    return "";
  }
  return "(min-width: " + (Css_AtomicTypes.Length.toString(CssJs.px(screenResolutionBreakpoint(screenSize))) + ")");
}

function maxWidthMediaQuery(screenSize) {
  if (typeof screenSize === "object" && screenSize.NAME === "XS") {
    return "";
  }
  var screenResolutionValue = screenResolutionBreakpoint(screenSize) - 1 | 0;
  return "(max-width: " + (Css_AtomicTypes.Length.toString(CssJs.px(screenResolutionValue)) + ")");
}

function minMaxWidthMediaQuery(screenSizeMin, screenSizeMax) {
  var maxQuery = maxWidthMediaQuery(screenSizeMax);
  if (typeof screenSizeMin === "object" && screenSizeMin.NAME === "XS") {
    return maxQuery;
  }
  return minWidthMediaQuery(screenSizeMin) + (" and " + maxQuery);
}

function font_family(x) {
  return CssJs.fontFamilies([
              {
                NAME: "custom",
                VAL: "barna"
              },
              {
                NAME: "custom",
                VAL: "barna-fallback"
              },
              "sansSerif"
            ]);
}

function font_weight(x) {
  if (x === "Light") {
    return CssJs.fontWeight("light");
  } else if (x === "SemiBold") {
    return CssJs.fontWeight("semiBold");
  } else if (x === "Bold") {
    return CssJs.fontWeight("bold");
  } else if (x === "Medium") {
    return CssJs.fontWeight("medium");
  } else {
    return CssJs.fontWeight("normal");
  }
}

function font_size_int(x) {
  if (x === "L") {
    return 25;
  } else if (x === "M") {
    return 20;
  } else if (x === "S") {
    return 17;
  } else if (x === "L2") {
    return 30;
  } else if (x === "M2") {
    return 22;
  } else if (x === "XL") {
    return 40;
  } else if (x === "XS") {
    return 14;
  } else if (x === "XXL") {
    return 55;
  } else if (x === "XXS") {
    return 10;
  } else {
    return 17;
  }
}

function font_size_rem(x) {
  return pxToRem(font_size_int(x));
}

function font_size(size) {
  return CssJs.fontSize(font_size_rem(size));
}

function line_height_float(x) {
  if (x === "L") {
    return 1.6;
  } else if (x === "M") {
    return 1.4;
  } else if (x === "S") {
    return 1.2;
  } else if (x === "XS") {
    return 1.1;
  } else if (x === "XXS") {
    return 1;
  } else {
    return 1.4;
  }
}

function line_height_abs(x) {
  return {
          NAME: "abs",
          VAL: line_height_float(x)
        };
}

function line_height(x) {
  return CssJs.lineHeight(line_height_abs(x));
}

function font_display(display) {
  if (display === "webkitbox") {
    return CssJs.unsafe("display", "-webkit-box");
  } else {
    return CssJs.display(display);
  }
}

function text_decoration(x) {
  if (x === "Capitalize") {
    return CssJs.textTransform(CssJs.capitalize);
  } else if (x === "Uppercase") {
    return CssJs.textTransform(CssJs.uppercase);
  } else if (x === "Underline") {
    return CssJs.textDecoration(CssJs.underline);
  } else {
    return CssJs.textShadow(Css_Js_Core.Shadow.text(Css.px(1), Css.px(1), Css.px(2), CssJs.black));
  }
}

function text_style(x) {
  if (x === "Italic") {
    return CssJs.fontStyle(CssJs.italic);
  } else {
    return CssJs.fontStyle(CssJs.normal);
  }
}

function text_hover(x) {
  if (x !== undefined) {
    return [CssJs.color(Particle_Color.Css.color(x))];
  } else {
    return [];
  }
}

function imagePresentation(x) {
  if (x === "Cover") {
    return Caml_option.some(CssJs.objectFit("cover"));
  }
  
}

function shape(x) {
  var defaultRules = [
    CssJs.alignItems(CssJs.center),
    CssJs.justifyContent(CssJs.center)
  ];
  switch (x.TAG) {
    case "Rectangle" :
        var height = x._0;
        if (typeof height !== "object") {
          return [CssJs.width("auto")];
        } else if (height.TAG === "Height") {
          return [CssJs.height(pxToRem(height._0))].concat(defaultRules);
        } else {
          return [
                    CssJs.width(pxToRem(height._0)),
                    CssJs.height(pxToRem(height._1))
                  ].concat(defaultRules);
        }
    case "Circle" :
        var size = x._0;
        return [
                  CssJs.borderRadius(CssJs.pct(100)),
                  CssJs.width(pxToRem(size)),
                  CssJs.height(pxToRem(size))
                ].concat(defaultRules);
    case "Square" :
        var size$1 = x._0;
        return [
                  CssJs.width(pxToRem(size$1)),
                  CssJs.height(pxToRem(size$1))
                ].concat(defaultRules);
    
  }
}

function getDeviceMediaQuery(x) {
  return CssJs.media("(hover: hover) and (pointer: fine)", x._0);
}

function border_width(x) {
  if (x === "Regular") {
    return CssJs.px(1);
  } else if (x === "SemiBold") {
    return CssJs.px(4);
  } else if (x === "Medium") {
    return CssJs.px(2);
  } else {
    return CssJs.px(0);
  }
}

function text_align(alignment) {
  if (alignment === "Left") {
    return CssJs.textAlign("left");
  } else if (alignment === "Center") {
    return CssJs.textAlign("center");
  } else {
    return CssJs.textAlign("right");
  }
}

function scrollSnapTypeValue(scrollSnapTypeValue$1) {
  if (scrollSnapTypeValue$1 === "Proximity") {
    return "proximity";
  } else {
    return "mandatory";
  }
}

function scrollSnapTypeDirection(scrollSnapTypeDirection$1) {
  if (scrollSnapTypeDirection$1 === "Y") {
    return "y";
  } else {
    return "x";
  }
}

function scrollSnapType(scrollSnapType$1) {
  return CssJs.unsafe("scroll-snap-type", scrollSnapTypeDirection(scrollSnapType$1[0]) + " " + scrollSnapTypeValue(scrollSnapType$1[1]));
}

function scrollSnapAlign(scrollSnapAlign$1) {
  var __x = scrollSnapAlign$1 === "Start" ? "start" : (
      scrollSnapAlign$1 === "Center" ? "center" : "end"
    );
  return CssJs.unsafe("scrollSnapAlign", __x);
}

var basePx = 16;

export {
  basePx ,
  pxToRem ,
  baseFontSize ,
  spacerSizeInsideValue ,
  spacerSizeInside ,
  spacerSizeOutside ,
  spacerSizeToString ,
  breakpoint ,
  screenResolutionBreakpoint ,
  screenResolutionToPx ,
  minWidthMediaQuery ,
  maxWidthMediaQuery ,
  minMaxWidthMediaQuery ,
  font_family ,
  font_weight ,
  font_size_int ,
  font_size_rem ,
  font_size ,
  line_height_float ,
  line_height_abs ,
  line_height ,
  font_display ,
  text_decoration ,
  text_style ,
  text_hover ,
  imagePresentation ,
  shape ,
  getDeviceMediaQuery ,
  border_width ,
  text_align ,
  scrollSnapTypeValue ,
  scrollSnapTypeDirection ,
  scrollSnapType ,
  scrollSnapAlign ,
}
/* baseFontSize Not a pure module */
