import { useEffect } from 'react';
import { useTokenStatus, useUserTrackingInfo } from '@artegeie/design-system/replay';
import { getCookie, setCookie } from 'cookies-next';
import { userStore } from './userStore';
import { isValidAge } from '@replay/utils/ageVerification';
import { addWeeks } from '@artegeie/date';
import { LoginStatus } from '@replay/types/Tracking';

type TrackingInfo = {
    id: string;
    ageVerification: undefined | string;
};

export const setUserInfos = ({
    userStatus,
    userTrackingInfo,
    setLoginStatus,
    setUserInfo,
}: {
    userStatus: LoginStatus;
    userTrackingInfo: TrackingInfo | undefined;
    setUserInfo: (userInfo: { userId: string; ageVerification: string | undefined }) => void;
    setLoginStatus: (loginStatus: LoginStatus) => void;
}) => {
    setLoginStatus(userStatus);
    if (userTrackingInfo || userStatus === 'Anonymous') {
        setUserInfo({
            userId: userTrackingInfo?.id || 'anonymous',
            ageVerification: userTrackingInfo?.ageVerification,
        });
        const ageCookie = getCookie('validated-age');
        if (
            !ageCookie ||
            !isValidAge(ageCookie.toString()) ||
            ageCookie.toString() !== userTrackingInfo?.ageVerification
        ) {
            setCookie('validated-age', userTrackingInfo?.ageVerification || '0', {
                expires: addWeeks(new Date(), 52),
            });
        }
    }
};

const useSetUserInfos = () => {
    const { setLoginStatus, setUserInfo } = userStore.getState();
    const userStatus = useTokenStatus();
    const userTrackingInfo = useUserTrackingInfo();
    useEffect(() => {
        setUserInfos({ userStatus, userTrackingInfo, setLoginStatus, setUserInfo });
    }, [userStatus, setLoginStatus, userTrackingInfo, setUserInfo]);
};

export default useSetUserInfos;
