import { v4 } from 'uuid';
import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { isAfter } from '@artegeie/date';
import { type ReleaseInfos } from '@replay/types/App';
import { type Geoblocking } from '@artegeie/design-system/type';
import { type Locale } from '@replay/types/Locale';
import { getStats, type ServerSideProps } from '@replay/types/MyGetServerSidePropsResult';
import { type ServerSideTracking } from '@replay/types/Stats';
import type { ConsentCategory, Data, RequiredData, UtmData, LoginStatus } from '@replay/types/Tracking';

// 30 days in ms
export const trackingIdMaxAge = 30 * 24 * 60 * 60 * 1000;

type TrackingData = {
    status: 'initializing' | 'ready';
    data: Data;
};

const initialStore: TrackingData = {
    status: 'initializing',
    data: {
        trackingId: undefined,
        trackingIdExp: undefined,
        loginStatus: 'NotReady',
        audienceMeasurementConsent: undefined,
        technicalMeasurementConsent: undefined,
        releaseInfos: undefined,
        abv: undefined,
        locale: 'FR',
        userId: undefined,
        ageVerification: undefined,
        utm: undefined,
        geoblocking: undefined,
        pageStats: undefined,
    },
};

interface UserStore extends TrackingData {
    setLoginStatus: (loginStatus: LoginStatus) => void;
    setAudienceMeasurementConsent: (audienceMeasurementConsent: ConsentCategory) => void;
    setTechnicalMeasurementConsent: (technicalMeasurementConsent: ConsentCategory) => void;
    setReleaseInfos: (releaseInfos: ReleaseInfos) => void;
    setLocale: (locale: Locale) => void;
    setTrackingId: () => void;
    setUserInfo: (userInfoTracking: { userId: string; ageVerification?: string }) => void;
    setUtm: (utm: UtmData) => void;
    setAbv: (abv: string) => void;
    setGeoBlocking: (geoblocking: Geoblocking) => void;
    setPageStats: (pageStats: ServerSideTracking) => void;
    getReadyData: () => RequiredData | undefined;
    getPageStats: () => ServerSideTracking | undefined;
}

const isInitialized = (data: Data): data is RequiredData => {
    if (data.loginStatus === 'NotReady') {
        return false;
    }
    if (data.loginStatus === 'Connected') {
        if (data.userId === undefined) return false;
    }
    const { audienceMeasurementConsent, technicalMeasurementConsent } = data;
    return audienceMeasurementConsent !== undefined && technicalMeasurementConsent !== undefined;
};

const updateStore = (data: Data): TrackingData => {
    if (isInitialized(data)) {
        return { status: 'ready', data: data };
    }
    return { status: 'initializing', data: data };
};

const getReadyData = (data: TrackingData): RequiredData | undefined => {
    if (data.status === 'ready') {
        return data.data as RequiredData;
    }
};

export const getServerSideTracking = (page: ServerSideProps): ServerSideTracking | undefined => {
    return getStats(page).serverSideTracking;
};

const userStoreName = 't_datas_replay';
export const userStore = create<UserStore>()(
    persist(
        subscribeWithSelector(
            devtools(
                (set, get) => ({
                    ...initialStore,
                    setUserInfo: (userInfo) => {
                        set((state) => updateStore({ ...state.data, ...userInfo }), false, {
                            type: 'setUserInfo',
                            userInfo,
                        });
                    },

                    setLoginStatus: (loginStatus: LoginStatus) => {
                        set((state) => updateStore({ ...state.data, loginStatus }), false, {
                            type: 'setLoginStatus',
                            loginStatus,
                        });
                    },
                    setAudienceMeasurementConsent: (audienceMeasurementConsent: ConsentCategory) =>
                        set(
                            (state) => updateStore({ ...state.data, audienceMeasurementConsent }),

                            false,
                            { type: 'setAudienceMeasurementConsent', audienceMeasurementConsent },
                        ),
                    setTechnicalMeasurementConsent: (technicalMeasurementConsent: ConsentCategory) =>
                        set((state) => updateStore({ ...state.data, technicalMeasurementConsent }), false, {
                            type: 'setTechnicalMeasurementConsent',
                            technicalMeasurementConsent,
                        }),
                    setReleaseInfos: (releaseInfos: ReleaseInfos) => {
                        set((state) => updateStore({ ...state.data, releaseInfos }), false, {
                            type: 'setReleaseInfos',
                            releaseInfos,
                        });
                    },
                    setLocale: (locale: Locale) => {
                        const formattedLocale = locale.toUpperCase() as 'FR' | 'DE' | 'EN' | 'ES' | 'PL' | 'IT';
                        set((state) => updateStore({ ...state.data, locale: formattedLocale }), false, {
                            type: 'setLocale',
                            locale,
                        });
                    },
                    setTrackingId: () => {
                        const currentState = get();
                        if (
                            currentState.data.trackingId &&
                            currentState.data.trackingIdExp &&
                            isAfter(new Date(currentState.data.trackingIdExp), new Date())
                        ) {
                            return;
                        }

                        set(
                            (state) =>
                                updateStore({
                                    ...state.data,
                                    trackingId: v4(),
                                    trackingIdExp: new Date(Date.now() + trackingIdMaxAge).toISOString(),
                                }),
                            false,
                            { type: 'trackingId' },
                        );
                    },
                    setUtm: (utm) => {
                        set((state) => updateStore({ ...state.data, utm }), false, { type: 'utm', utm });
                    },
                    setAbv: (abv) => {
                        set((state) => updateStore({ ...state.data, abv }), false, { type: 'abv', abv });
                    },
                    setGeoBlocking: (geoblocking) => {
                        set((state) => updateStore({ ...state.data, geoblocking }), false, {
                            type: 'geoblocking',
                            geoblocking,
                        });
                    },
                    setPageStats: (pageStats) => {
                        set((state) => updateStore({ ...state.data, pageStats }), false, {
                            type: 'pageStats',
                            pageStats,
                        });
                    },
                    getReadyData: () => {
                        return getReadyData(get());
                    },
                    getPageStats: () => {
                        return get().data.pageStats;
                    },
                }),

                { name: userStoreName },
            ),
        ),
        {
            name: userStoreName,
            version: 1, //  if the version in the storage does not match the version in the code, the stored value won't be used
            partialize: (state) => {
                // Only persist the store data in local storage
                const newState = { ...state.data };
                // We do not control the ABV groups, it comes from the cookie
                delete newState.abv;
                return newState;
            },
            merge: (persistedState, currentState: UserStore) => {
                // since we remove some keys in the partialize function, we need to merge the persisted state with the current state
                // otherwise the non persisted keys will be lost
                const persisted = persistedState as Data;
                return {
                    ...currentState,
                    data: {
                        ...currentState.data,
                        ...persisted,
                    },
                };
            },
        },
    ),
);
