// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Gradient_Ds from "../particle/Particle_Gradient_Ds.res.mjs";
import * as Particle_Gradient_Css from "../particle/Particle_Gradient_Css.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var alignItems = Particle_Screen_Ds.make(undefined, undefined, "flexStart", undefined, undefined, "center");

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, "flexStart", undefined, undefined, "center");

var justifyContent$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart");

var Container = {
  justifyContent: justifyContent$1
};

var justifyContent$2 = Particle_Screen_Ds.make(undefined, undefined, "flexEnd", undefined, undefined, "normal");

var width = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(70), undefined, undefined, CssJs.pct(100));

var rulesAspectRatio = Particle_Screen_Ds.make(undefined, undefined, Util_Css.preserveAspectRatioRules("R16_9"), undefined, undefined, Util_Css.preserveAspectRatioRules("R1_1"));

var rules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.marginBottom("zero")], undefined, undefined, [CssJs.marginBottom({
            NAME: "percent",
            VAL: -18
          })]);

var Container$1 = {
  rules: rules
};

var $$Image = {
  justifyContent: justifyContent$2,
  width: width,
  rulesAspectRatio: rulesAspectRatio,
  Container: Container$1
};

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeHorizontal("XL")), undefined, undefined, Particle_Spacer_Constants.Zone.firstMobile);

var justifyContent$3 = Particle_Screen_Ds.make(undefined, undefined, "flexStart", undefined, undefined, "center");

var rules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss({
            TAG: "Exact",
            _0: "TopTeaser"
          })]);

var maxWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(50), CssJs.pct(45), undefined, CssJs.pct(100));

var subtitleSpacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XXS"));

var Content = {
  blockSpace: blockSpace,
  justifyContent: justifyContent$3,
  rules: rules$1,
  maxWidth: maxWidth,
  subtitleSpacer: subtitleSpacer
};

var blockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

var liveBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

var display = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none");

var Sticker = {
  blockSpace: blockSpace$1,
  liveBlockSpace: liveBlockSpace,
  display: display
};

var display$1 = Util_Css.Display.fromL;

var Description = {
  display: display$1
};

var blockSpace$2 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"));

var DescriptionButtons = {
  blockSpace: blockSpace$2
};

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var ButtonsChildrenRow = {
  innerSpace: Particle_Screen_Constants.sInnerSpace,
  gap: gap
};

var baseRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.zIndex(0)]);

var gradientRules = Particle_Gradient_Css.toCss(Particle_Gradient_Ds.view({
          TAG: "BottomAndLeft",
          _0: "Header"
        }));

var aspectRatioRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Css.preserveAspectRatioRules("R16_9"));

var rules$2 = Particle_Screen_Ds.$$Array.concat(baseRules, aspectRatioRules);

var width$1 = Particle_Screen_Ds.make(undefined, undefined, Css.pct(70), undefined, undefined, CssJs.pct(100));

var Clip = {
  baseRules: baseRules,
  gradientRules: gradientRules,
  aspectRatioRules: aspectRatioRules,
  rules: rules$2,
  widthBreakpointM: 70,
  width: width$1
};

export {
  alignItems ,
  justifyContent ,
  Container ,
  $$Image ,
  Content ,
  Sticker ,
  Description ,
  DescriptionButtons ,
  ButtonsChildrenRow ,
  Clip ,
}
/* alignItems Not a pure module */
