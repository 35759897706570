import Head from 'next/head';
import path from 'ramda/src/path';
import { type ReactElement } from 'react';
import { env } from '@replay/env-generator';

import { type Stats, type TagsCommanderVars, type Xiti } from '@replay/types/Stats';
import { stripNullValues } from './stripNullValues';
import { sendAtCampaignEvent } from './atInternet';
import { ConsentCategory, type ConsentStatus } from '@replay/types/Tracking';

const tcUrl = env.NEXT_PUBLIC_TAG_COMMANDER_BASE_URL as string;

export const mapXitiStatsToTagCommanderVars = (xitiStats: Xiti): TagsCommanderVars => {
    return stripNullValues({
        env_work: env.NODE_ENV === 'development' ? 'preprod' : xitiStats.env_work,
        env_n2: xitiStats.s2,
        env_language: xitiStats.x1,
        env_template: xitiStats.x2,
        page_name: xitiStats.page_name,
        page_cat1_name: xitiStats.chapter1,
        page_cat2_name: xitiStats.chapter2,
        page_cat3_name: xitiStats.chapter3,
        user_abv: xitiStats.x4,
    });
};

export const TagCommanderBodyScriptUrlsFromCDN = (): ReactElement => (
    <div id="analytics">
        <script async type="text/javascript" src={`${tcUrl}/tc_Arte_1.js`}></script>
        <script async type="text/javascript" src={`${tcUrl}/tc_Arte_2.js`}></script>
        <script async type="text/javascript" src={`${tcUrl}/tc_Arte_4.js`}></script>
    </div>
);

type Props = { stats: Stats | null | undefined };

export const TagCommanderScriptTag = ({ stats }: Props): ReactElement | null => {
    if (!stats || !stats.xiti) return null;

    const tcVars = mapXitiStatsToTagCommanderVars(stats.xiti);
    return (
        <Head>
            <script
                type="text/javascript"
                id="tagcommander"
                dangerouslySetInnerHTML={{
                    __html: `
                    //<![CDATA[
                    var tc_vars = ${JSON.stringify(tcVars)};
                    //]]>
                `,
                }}
            />
        </Head>
    );
};

type CommandersActValue = {
    consent: Consent;
};

type Categories = { [key: string]: Category };
type Consent = {
    status: string;
    categories: Categories;
};

type Category = {
    status: ConsentCategory;
};

type CommandersActParamFunction = (value: CommandersActValue) => void;
type CommandersActParamObject = {
    categories: Categories;
};
type CommandersActParam = CommandersActParamFunction | CommandersActParamObject;
type CommandersAct = (arg0: string, arg1: CommandersActParam) => void;

type Callback = (commandersAct: CommandersAct) => void;
const callCommandersAct = (callback: Callback) => {
    if (typeof window === 'undefined')
        return console.error('CommandersAct not initialized. We are in a server context');
    const commandersAct = path<CommandersAct>(['cact'], window);
    if (commandersAct && typeof commandersAct === 'function') {
        callback(commandersAct);
    } else {
        // If CommandersAct is not loaded, we create a stub API
        // https://community.commandersact.com/trustcommander/onsite-api/getting-started#api-stub
        Object.defineProperty(window, 'caReady', {
            value: [],
            writable: true,
        });

        Object.defineProperty(window, 'cact', {
            value: function (...args: Parameters<CommandersAct>) {
                const caReady = path<Parameters<CommandersAct>[]>(['caReady'], window);
                caReady?.push(args);
            },
            writable: true,
        });

        // Finally retry
        callCommandersAct(callback);
    }
};

// This id come from Commanders Act (trustCommander/TagCommander) and it will stay the same
// It correspond to a specific consent in the Commanders Act system
const CONSENT_AUDIENCE_MEASUREMENT = 1;
const CONSENT_TECHNICAL_MEASUREMENT = 4;

const getQueryParam = (name: string): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};

export const addConsentUpdateCallback = ({
    onReady,
}: {
    onReady?: (
        consentStatus: ConsentStatus,
        audienceMeasurementConsent: ConsentCategory,
        technicalMeasurementConsent: ConsentCategory,
    ) => void;
}) => {
    const callback = (commandersAct: CommandersAct) => {
        commandersAct('consent.onUpdate', (value: CommandersActValue) => {
            const audienceMeasurementConsent = value.consent.categories[CONSENT_AUDIENCE_MEASUREMENT];
            if (
                audienceMeasurementConsent?.status === ConsentCategory.ON &&
                env.NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE
            ) {
                const atMediumQueryParam = getQueryParam('at_medium');
                const atCampaignQueryParam = getQueryParam('at_campaign');
                if (atMediumQueryParam && atCampaignQueryParam) {
                    sendAtCampaignEvent('click.action', {
                        click: 'clic optin',
                        src_medium: atMediumQueryParam,
                        src_campaign: atCampaignQueryParam,
                        'b:src_force': true,
                    });
                }
            }
        });
        commandersAct('consent.onReady', (value: CommandersActValue) => {
            const audienceMeasurementConsent = value.consent.categories[CONSENT_AUDIENCE_MEASUREMENT];
            const technicalMeasurementConsent = value.consent.categories[CONSENT_TECHNICAL_MEASUREMENT];
            if (onReady)
                onReady(
                    value.consent.status as ConsentStatus,
                    audienceMeasurementConsent?.status,
                    technicalMeasurementConsent?.status,
                );
        });
    };

    callCommandersAct(callback);
};

export const showPrivacyCenter = (): false => {
    const showPrivacyCenter = path(['tC', 'privacyCenter', 'showPrivacyCenter'], window);
    if (showPrivacyCenter && typeof showPrivacyCenter === 'function') {
        showPrivacyCenter();
    }
    return false;
};
