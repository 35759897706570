// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../../../type/Type_Sticker.res.mjs";
import * as Molecule_Sticker from "../../molecule/Molecule_Sticker.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function stickerToIdentifier(sticker) {
  if (typeof sticker === "object" && sticker.NAME === "Livestream") {
    return Identifiers.Sticker.livestream;
  } else {
    return Identifiers.__unsafe_stringToIdentifier("");
  }
}

function getId(id, label) {
  return id + ("-" + label);
}

function getIdBySticker(id, sticker) {
  if (typeof sticker !== "object") {
    return getId(id, "sticker");
  }
  var variant = sticker.NAME;
  if (variant === "Direct") {
    return getId(id, "direct");
  } else if (variant === "Time") {
    return getId(id, "duration");
  } else {
    return getId(id, "sticker");
  }
}

function getStickerTextAndStyle(sticker, stickersDefinition, teaserStatus, teaserId, dotColor) {
  var identifier = stickerToIdentifier(sticker);
  var stickerId = getIdBySticker(teaserId, sticker);
  var text;
  var style;
  if (typeof sticker !== "object") {
    return ;
  }
  var variant = sticker.NAME;
  if (variant === "Soon" || variant === "LastDay" || variant === "Livestream" || variant === "AvailableOn") {
    var style$1 = stickersDefinition.text;
    if (style$1 === undefined) {
      return ;
    }
    text = sticker.VAL;
    style = Caml_option.valFromOption(style$1);
  } else {
    if (variant === "Direct") {
      return {
              text: Type_String.NotEmpty.toString(sticker.VAL),
              style: Molecule_Sticker.Molecule_Sticker_Ds.direct(dotColor),
              id: stickerId,
              identifier: identifier
            };
    }
    if (variant === "Genre") {
      var style$2 = stickersDefinition.genre;
      if (style$2 === undefined) {
        return ;
      }
      text = sticker.VAL;
      style = Caml_option.valFromOption(style$2);
    } else {
      if (variant === "Live") {
        return {
                text: Type_String.NotEmpty.toString(sticker.VAL),
                style: Molecule_Sticker.Molecule_Sticker_Ds.live(dotColor),
                id: stickerId,
                identifier: identifier
              };
      }
      if (variant !== "Time") {
        return ;
      }
      var style$3 = stickersDefinition.time;
      var time = sticker.VAL;
      var exit = 0;
      var style$4 = stickersDefinition.disabledTime;
      if (style$4 !== undefined) {
        if (teaserStatus === "Disabled") {
          return {
                  text: time,
                  style: Caml_option.valFromOption(style$4),
                  id: stickerId,
                  identifier: identifier
                };
        }
        exit = 2;
      } else {
        exit = 2;
      }
      if (exit === 2) {
        if (style$3 !== undefined && (teaserStatus === "PlayedNext" || teaserStatus === "Standard")) {
          return {
                  text: time,
                  style: Caml_option.valFromOption(style$3),
                  id: stickerId,
                  identifier: identifier
                };
        } else {
          return ;
        }
      }
      
    }
  }
  return {
          text: Type_String.NotEmpty.toString(text),
          style: style,
          id: stickerId,
          identifier: identifier
        };
}

function Organism_Teaser_Stickers(props) {
  var id = props.id;
  var color = props.color;
  var status = props.status;
  var stickersDefinition = props.stickersDefinition;
  var stickers = props.stickers;
  var match = Context.I18n.getState();
  var match$1 = match.labels.program;
  var topLeft = Core__Option.mapOr(Core__Option.flatMap(Type_Sticker.getTopLeft(stickers), (function (__x) {
              return getStickerTextAndStyle(__x, stickersDefinition, status, id, color);
            })), null, (function (param) {
          var id = param.id;
          return JsxRuntime.jsx(Molecule_Sticker.make, {
                      text: param.text,
                      style: Caml_option.some(param.style),
                      position: "TopLeft",
                      id: id,
                      identifier: Caml_option.some(param.identifier)
                    }, id);
        }));
  var bottomRight = Core__Option.mapOr(Core__Option.flatMap(Type_Sticker.getBottomRight(stickers), (function (__x) {
              return getStickerTextAndStyle(__x, stickersDefinition, status, id, color);
            })), null, (function (param) {
          var id = param.id;
          return JsxRuntime.jsx(Molecule_Sticker.make, {
                      text: param.text,
                      style: Caml_option.some(param.style),
                      position: "BottomRight",
                      id: id
                    }, id);
        }));
  var bottomLeft;
  if (status === "PlayedNext") {
    var style = Molecule_Sticker.Molecule_Sticker_Ds.followNext;
    var id$1 = "tobecontinued";
    bottomLeft = JsxRuntime.jsx(Molecule_Sticker.make, {
          text: match$1.to_be_continued,
          style: Caml_option.some(style),
          position: "BottomLeft",
          id: id$1
        }, id$1);
  } else {
    bottomLeft = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                topLeft,
                bottomLeft,
                bottomRight
              ]
            });
}

var make = Organism_Teaser_Stickers;

export {
  stickerToIdentifier ,
  getId ,
  getIdBySticker ,
  getStickerTextAndStyle ,
  make ,
}
/* Context Not a pure module */
