'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as MyNullable from "../MyNullable.res.mjs";
import * as Type_Image from "../type/Type_Image.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Atom_Cta_Ds from "../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Identifiers from "../selector/Identifiers.res.mjs";
import * as Type_Common from "../type/Type_Common.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Type_Teaser from "../type/Type_Teaser.res.mjs";
import * as Binding_Date from "../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_GuideTv from "../type/Type_GuideTv.res.mjs";
import * as Type_Sticker from "../type/Type_Sticker.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Type_Progression from "../type/Type_Progression.res.mjs";

var _map = {"CONTACT_NAME":"CONTACT_NAME","CONTACT_MAIL":"CONTACT_MAIL","CONTACT_PHONE":"CONTACT_PHONE","RELEASE_URL":"RELEASE_URL","RELEASE_LABEL":"RELEASE_LABEL"};

function contactCodeToJs(param) {
  return param;
}

function contactCodeFromJs(param) {
  return _map[param];
}

function broadcastOnline_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ]]));
}

function broadcastOnline_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
  if (label.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              label: label._0
            }
          };
  }
  var e = label._0;
  return {
          TAG: "Error",
          _0: {
            path: ".label" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function broadcast_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "subtitle",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.subtitle)
                  ],
                  [
                    "shortDescription",
                    false,
                    Spice.stringToJson(v.shortDescription)
                  ],
                  [
                    "image",
                    false,
                    Spice.stringToJson(v.image)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "schedule",
                    false,
                    Spice.stringToJson(v.schedule)
                  ],
                  [
                    "presseCategory",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.presseCategory)
                  ],
                  [
                    "highlight",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.highlight)
                  ],
                  [
                    "online",
                    false,
                    MyNullable.t_encode(broadcastOnline_encode)(v.online)
                  ],
                  [
                    "downloadUrl",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.downloadUrl)
                  ]
                ]));
}

function broadcast_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
    if (title.TAG === "Ok") {
      var subtitle = (function (extra) {
            return Spice.optionFromJson(Spice.stringFromJson, extra);
          })(Belt_Option.getWithDefault(Js_dict.get(v, "subtitle"), null));
      if (subtitle.TAG === "Ok") {
        var shortDescription = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "shortDescription"), null));
        if (shortDescription.TAG === "Ok") {
          var image = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "image"), null));
          if (image.TAG === "Ok") {
            var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
            if (url.TAG === "Ok") {
              var schedule = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "schedule"), null));
              if (schedule.TAG === "Ok") {
                var presseCategory = (function (extra) {
                      return Spice.optionFromJson(Spice.stringFromJson, extra);
                    })(Belt_Option.getWithDefault(Js_dict.get(v, "presseCategory"), null));
                if (presseCategory.TAG === "Ok") {
                  var highlight = (function (extra) {
                        return Spice.optionFromJson(Spice.stringFromJson, extra);
                      })(Belt_Option.getWithDefault(Js_dict.get(v, "highlight"), null));
                  if (highlight.TAG === "Ok") {
                    var online = MyNullable.t_decode(broadcastOnline_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "online"), null));
                    if (online.TAG === "Ok") {
                      var downloadUrl = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "downloadUrl"), null));
                      if (downloadUrl.TAG === "Ok") {
                        return {
                                TAG: "Ok",
                                _0: {
                                  id: id._0,
                                  title: title._0,
                                  subtitle: subtitle._0,
                                  shortDescription: shortDescription._0,
                                  image: image._0,
                                  url: url._0,
                                  schedule: schedule._0,
                                  presseCategory: presseCategory._0,
                                  highlight: highlight._0,
                                  online: online._0,
                                  downloadUrl: downloadUrl._0
                                }
                              };
                      }
                      var e = downloadUrl._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".downloadUrl" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = online._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".online" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = highlight._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".highlight" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = presseCategory._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".presseCategory" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = schedule._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".schedule" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = url._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".url" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = image._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".image" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = shortDescription._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".shortDescription" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = subtitle._0;
      return {
              TAG: "Error",
              _0: {
                path: ".subtitle" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = title._0;
    return {
            TAG: "Error",
            _0: {
              path: ".title" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function formatBroadcastDates(timeReference, nextBroadcastDateFormater, broadcastDates) {
  return Core__Option.map(broadcastDates, (function (broadcastDates) {
                return Core__Array.reduce(broadcastDates, [], (function (accumulator, broadcastDate) {
                              var date = new Date(broadcastDate);
                              var isFuture = $$Date.isAfter(date, timeReference);
                              if (!isFuture) {
                                return accumulator;
                              }
                              var dateFormat = Type_String.Teaser.BroadcastDate.make(nextBroadcastDateFormater(date));
                              return accumulator.concat([dateFormat]);
                            }));
              }));
}

function getAvailabilitySticker(timeReference, dateFormater, availability) {
  return Core__Option.map((function (__x) {
                  return Core__Option.flatMap(__x, (function (param) {
                                var start = new Date(param.start);
                                if ($$Date.isAfter(start, timeReference)) {
                                  return {
                                          type: param.type,
                                          start: start
                                        };
                                }
                                
                              }));
                })((availability == null) ? undefined : Caml_option.some(availability)), dateFormater);
}

function getAvailabilityLabel(timeReference, betweenDatesFormater, start, end_) {
  var start$1 = new Date(start);
  var end_$1 = new Date(end_);
  var match = Binding_Date.compareDatesByDay(timeReference, end_$1);
  var args;
  switch (match) {
    case "Same" :
    case "After" :
        args = {
          start: start$1,
          end: end_$1
        };
        break;
    case "Before" :
        args = undefined;
        break;
    
  }
  return Core__Option.map(args, betweenDatesFormater);
}

function addToStickers(availabilitySticker, stickers, availability) {
  var type_ = availability !== undefined ? availability.type : undefined;
  return Core__Option.getOr(Core__Option.map(availabilitySticker, (function (text) {
                    var match = Type_String.NotEmpty.make(text);
                    if (match === undefined) {
                      return stickers;
                    }
                    var text$1 = Caml_option.valFromOption(match);
                    if (type_ !== undefined && (type_ === "LIVESTREAM_WEB" || type_ === "LIVESTREAM_TV")) {
                      return Type_Sticker.updateTopLeft(stickers, {
                                  NAME: "Livestream",
                                  VAL: text$1
                                });
                    } else {
                      return Type_Sticker.updateTopLeft(stickers, {
                                  NAME: "AvailableOn",
                                  VAL: text$1
                                });
                    }
                  })), stickers);
}

function addAvailabilitySticker(timeReference, teaserKind, dateFormater, availability, stickers) {
  var match = Type_Teaser.Kind.view(teaserKind);
  var match$1 = stickers.topLeft;
  if (match === "Standard" && match$1 === undefined) {
    return addToStickers(getAvailabilitySticker(timeReference, dateFormater, availability), stickers, (availability == null) ? undefined : Caml_option.some(availability));
  } else {
    return stickers;
  }
}

function parseCodeLabels(codeLabels) {
  return Core__Option.getOr(Core__Option.map((codeLabels == null) ? undefined : Caml_option.some(codeLabels), (function (audioVersions) {
                    return Util_Array.catOption(audioVersions.map(function (audioVersion) {
                                    return Core__Option.map(Type_Common.audioVersionCodeFromJs(audioVersion.code), (function (code) {
                                                  return {
                                                          code: code,
                                                          label: audioVersion.label
                                                        };
                                                }));
                                  }));
                  })), []);
}

function toDsBroadcast(broadcast) {
  var makeBroadcastOnline = function (online) {
    return {
            label: online.label
          };
  };
  var highlight = broadcast.highlight;
  var downloadUrl = broadcast.downloadUrl;
  var tmp;
  tmp = (downloadUrl == null) || downloadUrl === "" ? undefined : downloadUrl;
  return {
          id: broadcast.id,
          title: Type_String.Teaser.Title.make(broadcast.title),
          subtitle: Core__Option.mapOr(broadcast.subtitle, Type_String.Teaser.Subtitle.make(""), Type_String.Teaser.Subtitle.make),
          shortDescription: broadcast.shortDescription,
          image: Type_Image.Src.make(broadcast.image),
          url: broadcast.url,
          schedule: broadcast.schedule,
          presseCategory: broadcast.presseCategory,
          sticker: highlight !== undefined ? ({
                TAG: "Highlight",
                _0: highlight
              }) : undefined,
          online: Core__Option.map(Caml_option.nullable_to_opt(broadcast.online), makeBroadcastOnline),
          downloadUrl: tmp
        };
}

function toDsTeaser(teaser, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, firstBroadcastLabel, page) {
  var firstBroadcast = teaser.firstBroadcast;
  var durationLabel = Type_String.Teaser.DurationLabel.makeFromNullable(teaser.durationLabel);
  var durationStickerLabel = !(firstBroadcast == null) && firstBroadcast ? Type_String.Teaser.DurationLabel.make(firstBroadcastLabel) : durationLabel;
  var stickers = Core__Option.getOr(Core__Option.map(Caml_option.nullable_to_opt(teaser.stickers), (function (__x) {
              return Type_Sticker.makeFromCommonStickers(undefined, __x);
            })), Type_Sticker.empty);
  var teaserKind = Type_Teaser.Kind.fromStickers(stickers);
  var stickers$1 = addAvailabilitySticker(timeReference, teaserKind, dateFormater, teaser.availability, Type_Teaser.Helper.addDurationSticker(durationStickerLabel, teaserKind, stickers));
  var dsId = Type_String.Teaser.DsId.make(teaser.id);
  var audioVersions = parseCodeLabels(teaser.audioVersions);
  var technicalInformations = parseCodeLabels(teaser.displayTechnicalInformations);
  var tmp;
  tmp = page === "Program" ? Belt_Array.concat(audioVersions, technicalInformations) : undefined;
  var url = teaser.url;
  return {
          audioVersions: tmp,
          availability: Core__Option.map(Caml_option.nullable_to_opt(teaser.availability), (function (param) {
                  var end_ = param.end;
                  var start = param.start;
                  return {
                          type: param.type,
                          label: getAvailabilityLabel(timeReference, betweenDatesFormater, start, Core__Option.getOr((end_ == null) ? undefined : Caml_option.some(end_), "")),
                          start: start,
                          upcomingDate: param.upcomingDate,
                          end: (end_ == null) ? undefined : Caml_option.some(end_)
                        };
                })),
          broadcastDates: formatBroadcastDates(timeReference, nextBroadcastDateFormater, Caml_option.nullable_to_opt(teaser.broadcastDates)),
          callToAction: undefined,
          parentPage: undefined,
          credits: Caml_option.nullable_to_opt(teaser.credits),
          description: Type_String.Teaser.Description.makeFromNullable(teaser.teaserText),
          dsId: dsId,
          duration: Type_Common.jsMinDurationToDuration(teaser.duration),
          durationLabel: durationLabel,
          favoriteStatus: "NotAvailable",
          href: (url == null) ? Type_String.Href.empty : Type_String.Href.make(url),
          geoblocking: undefined,
          id: Type_String.Teaser.Id.make(teaser.id),
          imageSrc: Type_Image.Src.make(teaser.image),
          key: Type_Teaser.Helper.makeKey(undefined, dsId),
          kind: Type_Teaser.Kind.standard,
          partners: undefined,
          program: {
            clip: undefined,
            description: Caml_option.nullable_to_opt(teaser.fullDescription),
            firstBroadcastDate: !(firstBroadcast == null) && firstBroadcast ? Belt_Option.map(Caml_option.nullable_to_opt(teaser.broadcastDates), (function (broadcastDates) {
                      return Type_String.Program.FirstBroadcastDate.make(Core__Option.getOr(Util_Array.getFirstElement(broadcastDates), ""));
                    })) : undefined,
            genre: Core__Option.map(Caml_option.nullable_to_opt(teaser.genre), (function (param) {
                    return {
                            label: Type_String.Program.Genre.Label.make(param.label),
                            url: Type_String.Program.Genre.Url.makeFromNullable(param.url)
                          };
                  })),
            id: Type_String.Program.Id.make(teaser.id),
            kind: "PRESSE",
            shortDescription: Caml_option.nullable_to_opt(teaser.shortDescription),
            trailer: undefined,
            chapters: undefined
          },
          progression: Core__Option.getOr(undefined, Type_Progression.noProgression),
          shopUrl: undefined,
          stickers: stickers$1,
          subtitle: Core__Option.map(Caml_option.nullable_to_opt(teaser.subtitle), (function (subtitle) {
                  return {
                          TAG: "Displayed",
                          label: Type_String.Teaser.Subtitle.make(subtitle)
                        };
                })),
          title: Core__Option.map(Caml_option.nullable_to_opt(teaser.title), (function (title) {
                  return {
                          TAG: "Displayed",
                          _0: Type_String.Teaser.Title.make(title)
                        };
                })),
          trackingPixel: undefined,
          hoverBehavior: "Normal",
          ageRestriction: undefined
        };
}

function makePresseMetas(contactLabel, program) {
  var contacts = program.press;
  if (contacts == null) {
    return [];
  } else {
    return [{
              label: contactLabel,
              value: {
                TAG: "MultiString",
                _0: Util_Array.catOption(Core__Array.filterMap(contacts, (function (contact) {
                            return Core__Option.map(contactCodeFromJs(contact.code), (function (code) {
                                          if (code === "RELEASE_LABEL" || code === "RELEASE_URL") {
                                            return ;
                                          } else {
                                            return contact.label;
                                          }
                                        }));
                          })))
              },
              raw: "Other"
            }];
  }
}

function recommandationsToDsZone(recommendations, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, firstBroadcastLabel, page) {
  var link_url = Caml_option.nullable_to_opt(recommendations.rcUrl);
  var link_title = recommendations.rcTitle;
  var link = {
    url: link_url,
    title: link_title,
    page: undefined
  };
  return {
          id: recommendations.rcId,
          link: link,
          title: {
            TAG: "Displayed",
            _0: recommendations.rcTitle
          },
          itemTitle: "Normal",
          itemTemplate: "PlayNext",
          theme: {
            NAME: "PlayNext",
            VAL: "WithoutSwitch"
          },
          data: recommendations.teasers.map(function (teaser) {
                return toDsTeaser(teaser, dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, firstBroadcastLabel, page);
              }),
          genreStickerBehavior: "HideGenreSticker"
        };
}

function fileToDownloadElement(file) {
  return {
          title: Type_String.Teaser.Title.make(file.title),
          subtitle: Type_String.Teaser.Subtitle.make(file.description),
          href: file.download,
          imageSrc: [{
              w: 1920,
              h: 1080,
              url: file.url
            }],
          id: file.id
        };
}

function filesToDownloadElements(__x) {
  return __x.map(fileToDownloadElement);
}

function programToCtas(program) {
  var getFromCode = function (target) {
    return Core__Option.flatMap(Caml_option.nullable_to_opt(program.press), (function (contacts) {
                  return Core__Array.reduce(contacts, undefined, (function (acc, contact) {
                                var match = contactCodeFromJs(contact.code);
                                if (acc !== undefined || !(match !== undefined && match === target)) {
                                  return acc;
                                } else {
                                  return contact;
                                }
                              }));
                }));
  };
  var releaseLabel = getFromCode("RELEASE_LABEL");
  var releaseUrl = getFromCode("RELEASE_URL");
  var downloadButton = releaseLabel !== undefined && releaseUrl !== undefined ? ({
        label: releaseLabel.label,
        kind: {
          TAG: "Href",
          href: Type_String.Href.make(releaseUrl.label),
          style: Atom_Cta_Ds.Slider.downloadAll,
          target: Core__Option.getOr(Caml_option.nullable_to_opt(releaseUrl.target), "_self")
        },
        identifier: Identifiers.Cta.presseDownloadLink
      }) : undefined;
  return Util_Array.NonEmpty.makeFromArray(Util_Array.catOption([downloadButton]));
}

function labels_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "download_all",
                    false,
                    Spice.stringToJson(v.download_all)
                  ],
                  [
                    "images",
                    false,
                    Spice.stringToJson(v.images)
                  ],
                  [
                    "highlight",
                    false,
                    Spice.stringToJson(v.highlight)
                  ]
                ]));
}

function labels_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var download_all = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "download_all"), null));
  if (download_all.TAG === "Ok") {
    var images = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "images"), null));
    if (images.TAG === "Ok") {
      var highlight = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "highlight"), null));
      if (highlight.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  download_all: download_all._0,
                  images: images._0,
                  highlight: highlight._0
                }
              };
      }
      var e = highlight._0;
      return {
              TAG: "Error",
              _0: {
                path: ".highlight" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = images._0;
    return {
            TAG: "Error",
            _0: {
              path: ".images" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = download_all._0;
  return {
          TAG: "Error",
          _0: {
            path: ".download_all" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function code_encode(v) {
  switch (v) {
    case "Zip" :
        return "zip";
    case "Xml" :
        return "xml";
    case "Doc" :
        return "doc";
    
  }
}

function code_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("zip" === v) {
          return {
                  TAG: "Ok",
                  _0: "Zip"
                };
        } else if ("xml" === v) {
          return {
                  TAG: "Ok",
                  _0: "Xml"
                };
        } else if ("doc" === v) {
          return {
                  TAG: "Ok",
                  _0: "Doc"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function types_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ],
                  [
                    "ariaLabel",
                    false,
                    Spice.stringToJson(v.ariaLabel)
                  ]
                ]));
}

function types_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      var ariaLabel = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ariaLabel"), null));
      if (ariaLabel.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  code: code._0,
                  label: label._0,
                  ariaLabel: ariaLabel._0
                }
              };
      }
      var e = ariaLabel._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ariaLabel" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function weekDownload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pattern",
                    false,
                    Spice.stringToJson(v.pattern)
                  ],
                  [
                    "types",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(types_encode, extra);
                        })(v.types)
                  ]
                ]));
}

function weekDownload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pattern = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pattern"), null));
  if (pattern.TAG === "Ok") {
    var types = (function (extra) {
          return Spice.arrayFromJson(types_decode, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "types"), null));
    if (types.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pattern: pattern._0,
                types: types._0
              }
            };
    }
    var e = types._0;
    return {
            TAG: "Error",
            _0: {
              path: ".types" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pattern._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pattern" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "day",
                    false,
                    Spice.stringToJson(v.day)
                  ],
                  [
                    "labels",
                    false,
                    labels_encode(v.labels)
                  ],
                  [
                    "weekDownload",
                    false,
                    weekDownload_encode(v.weekDownload)
                  ],
                  [
                    "programming",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(broadcast_encode, extra);
                        })(v.programming)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var day = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "day"), null));
  if (day.TAG === "Ok") {
    var labels = labels_decode(Belt_Option.getWithDefault(Js_dict.get(v, "labels"), null));
    if (labels.TAG === "Ok") {
      var weekDownload = weekDownload_decode(Belt_Option.getWithDefault(Js_dict.get(v, "weekDownload"), null));
      if (weekDownload.TAG === "Ok") {
        var programming = (function (extra) {
              return Spice.arrayFromJson(broadcast_decode, extra);
            })(Belt_Option.getWithDefault(Js_dict.get(v, "programming"), null));
        if (programming.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    day: day._0,
                    labels: labels._0,
                    weekDownload: weekDownload._0,
                    programming: programming._0
                  }
                };
        }
        var e = programming._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".programming" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = weekDownload._0;
      return {
              TAG: "Error",
              _0: {
                path: ".weekDownload" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = labels._0;
    return {
            TAG: "Error",
            _0: {
              path: ".labels" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = day._0;
  return {
          TAG: "Error",
          _0: {
            path: ".day" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function getByCode(types, code) {
  return types.find(function (_type) {
              var result = code_decode(_type.code);
              if (result.TAG === "Ok") {
                return result._0 === code;
              } else {
                return false;
              }
            });
}

function makeDownloadUrl(pattern, codeType, weekNumber) {
  return pattern.replace("%WEEKNUMBER%", weekNumber.toString()).replace("%TYPE%", codeType);
}

var weekRangeOnFriday = Belt_Array.range(1, 8);

var defaultWeekRange = Belt_Array.range(1, 7);

function makeDisplayArrows(index) {
  var match = index === 0;
  var match$1 = index === 7;
  if (match) {
    if (match$1) {
      return {
              next: "Hidden",
              previous: "Hidden"
            };
    } else {
      return {
              next: "Visible",
              previous: "Hidden"
            };
    }
  } else if (match$1) {
    return {
            next: "Hidden",
            previous: "Visible"
          };
  } else {
    return {
            next: "Visible",
            previous: "Visible"
          };
  }
}

function makeDaysRange(startOfWeek, selectedDate) {
  var startOfWeek$1 = Type_GuideTv.makeDateWithoutTime(startOfWeek);
  var selectedDate$1 = Type_GuideTv.makeDateWithoutTime(selectedDate);
  var daysRange = Binding_Date.range(startOfWeek$1, 7);
  return daysRange.map(function (date) {
              var status = $$Date.isSameDay(date, selectedDate$1) ? "Current" : "Default";
              return {
                      date: date,
                      status: status
                    };
            });
}

function getWeekRange(date) {
  var match = Binding_Date.getDay(date);
  if (match === 5) {
    return weekRangeOnFriday;
  } else {
    return defaultWeekRange;
  }
}

function makeWeeks(currentDate, selectedDate) {
  var startOfWeek = Binding_Date.startOfWeek(currentDate, "De", 6);
  var firstWeekNumber = Binding_Date.getWeek(startOfWeek, "De", 6);
  var currentWeekNumber = Binding_Date.getWeek(selectedDate, "De", 6);
  var firstWeek_displayArrows = makeDisplayArrows(0);
  var firstWeek_rangeSelectDay = makeDaysRange(startOfWeek, selectedDate);
  var firstWeek_status = firstWeekNumber === currentWeekNumber ? "Current" : "Default";
  var firstWeek = {
    displayArrows: firstWeek_displayArrows,
    rangeSelectDay: firstWeek_rangeSelectDay,
    weekNumber: firstWeekNumber,
    status: firstWeek_status,
    startOfWeek: startOfWeek
  };
  var otherWeeks = getWeekRange(currentDate).map(function (number) {
        var startOfWeek$1 = $$Date.addWeeks(startOfWeek, number);
        var weekNumber = Binding_Date.getWeek(startOfWeek$1, "De", 6);
        return {
                displayArrows: makeDisplayArrows(number),
                rangeSelectDay: makeDaysRange(startOfWeek$1, selectedDate),
                weekNumber: weekNumber,
                status: weekNumber === currentWeekNumber ? "Current" : "Default",
                startOfWeek: startOfWeek$1
              };
      });
  return Util_Array.NonEmpty.make(otherWeeks, firstWeek);
}

function updateStatusIntoDayRange(rangeSelectDay, date) {
  return rangeSelectDay.map(function (day) {
              var status = $$Date.isSameDay(day.date, date) ? "Current" : "Default";
              return {
                      date: day.date,
                      status: status
                    };
            });
}

function updateWeekRange(weekRange, selectWeekNumber) {
  return Util_Array.NonEmpty.map(weekRange, (function (week) {
                var status = week.weekNumber === selectWeekNumber ? "Current" : "Default";
                return {
                        displayArrows: week.displayArrows,
                        rangeSelectDay: week.rangeSelectDay,
                        weekNumber: week.weekNumber,
                        status: status,
                        startOfWeek: week.startOfWeek
                      };
              }));
}

function getSelectWeekRange(weekRange, weekNumber, defaultWeek) {
  return Core__Option.mapOr(Util_Array.NonEmpty.find(weekRange, (function (week) {
                    return week.weekNumber === weekNumber;
                  })), defaultWeek, (function (week) {
                return {
                        displayArrows: week.displayArrows,
                        rangeSelectDay: week.rangeSelectDay,
                        weekNumber: week.weekNumber,
                        status: "Current",
                        startOfWeek: week.startOfWeek
                      };
              }));
}

function getHoursFromSchedule(schedule) {
  return new Date(schedule).getHours();
}

function getMinutesFromSchedule(schedule) {
  return new Date(schedule).getMinutes();
}

function filterBroadcastsByInterval(broadcasts, interval, date) {
  return broadcasts.filter(function (broadcast) {
              var hours = new Date(broadcast.schedule).getHours();
              var minutes = new Date(broadcast.schedule).getMinutes();
              var dateWithTime = Binding_Date.updateTime(date, hours, minutes);
              var dateInterval = Type_GuideTv.makeDateFromTimeInterval(dateWithTime, interval);
              return $$Date.isWithinInterval(dateWithTime, dateInterval);
            });
}

function intervalToString(interval) {
  if (interval === "AllDay") {
    return "allDay";
  } else {
    return "evening";
  }
}

function toInterval(filter) {
  if (filter === "evening") {
    return "Evening";
  } else {
    return "AllDay";
  }
}

function setInitialeState(selectedDate, weekItems) {
  var selectWeek = Binding_Date.getWeek(selectedDate, "De", 6);
  var selectedWeekIntoWeekItems = Util_Array.NonEmpty.find(weekItems, (function (item) {
          return item.weekNumber === selectWeek;
        }));
  return Core__Option.getOr(selectedWeekIntoWeekItems, Util_Array.NonEmpty.head(weekItems));
}

var Guide_eveningInterval = {
  start: {
    hour: 19,
    minute: 0
  },
  end: {
    hour: 23,
    minute: 59
  }
};

var Guide = {
  eveningInterval: Guide_eveningInterval,
  labels_encode: labels_encode,
  labels_decode: labels_decode,
  code_encode: code_encode,
  code_decode: code_decode,
  types_encode: types_encode,
  types_decode: types_decode,
  weekDownload_encode: weekDownload_encode,
  weekDownload_decode: weekDownload_decode,
  t_encode: t_encode,
  t_decode: t_decode,
  getByCode: getByCode,
  makeDownloadUrl: makeDownloadUrl,
  daysToDisplay: 7,
  defaultWeeksToDisplay: 7,
  weeksToDisplayOnFriday: 8,
  weekRangeOnFriday: weekRangeOnFriday,
  defaultWeekRange: defaultWeekRange,
  makeDisplayArrows: makeDisplayArrows,
  makeDaysRange: makeDaysRange,
  getWeekRange: getWeekRange,
  makeWeeks: makeWeeks,
  updateStatusIntoDayRange: updateStatusIntoDayRange,
  updateWeekRange: updateWeekRange,
  getSelectWeekRange: getSelectWeekRange,
  getHoursFromSchedule: getHoursFromSchedule,
  getMinutesFromSchedule: getMinutesFromSchedule,
  filterBroadcastsByInterval: filterBroadcastsByInterval,
  intervalToString: intervalToString,
  toInterval: toInterval,
  setInitialeState: setInitialeState
};

function itemToDs(item) {
  return {
          category: item.category,
          title: item.title,
          image: Type_Image.Src.make(item.mainImageUrl),
          date: item.publishedAt,
          infos: item.infos,
          subtitle: item.subtitle,
          url: item.url,
          stickerText: item.stickerText
        };
}

function toDsPage(page) {
  return {
          title: Caml_option.nullable_to_opt(page.title),
          subtitle: Caml_option.nullable_to_opt(page.subtitle),
          groups: page.groups.map(function (group) {
                return {
                        title: group.title,
                        items: group.items.map(itemToDs)
                      };
              }),
          backlink: Caml_option.nullable_to_opt(page.backlink)
        };
}

var List = {
  itemToDs: itemToDs,
  toDsPage: toDsPage
};

export {
  contactCodeToJs ,
  contactCodeFromJs ,
  broadcastOnline_encode ,
  broadcastOnline_decode ,
  broadcast_encode ,
  broadcast_decode ,
  formatBroadcastDates ,
  getAvailabilitySticker ,
  getAvailabilityLabel ,
  addToStickers ,
  addAvailabilitySticker ,
  parseCodeLabels ,
  toDsBroadcast ,
  toDsTeaser ,
  makePresseMetas ,
  recommandationsToDsZone ,
  fileToDownloadElement ,
  filesToDownloadElements ,
  programToCtas ,
  Guide ,
  List ,
}
/* weekRangeOnFriday Not a pure module */
