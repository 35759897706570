// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Box from "../../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Logo from "../../../atom/Atom_Logo.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../../../type/Type_Common.res.mjs";
import * as Type_Header from "../../../../type/Type_Header.res.mjs";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as Particle_Css from "../../../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Header_Css from "../Organism_Header_Css.res.mjs";
import * as Particle_Background_Ds from "../../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var position = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.m),
        right: CssJs.inherit_
      }
    }, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.default),
        right: CssJs.px(0),
        left: CssJs.px(0)
      }
    });

function Organism_MenuNavigation(props) {
  var menuNavigation = props.menuNavigation;
  var categories = menuNavigation.categories;
  var mobileLinks = menuNavigation.mobileLinks;
  var country = props.country;
  var category = props.category;
  var ffComingSoonEuropeEntry = Context.Config.getFeatureFlippingStatus("ComingSoonEuropeEntry");
  var mobileLinks$1;
  mobileLinks$1 = ffComingSoonEuropeEntry === "On" || country !== "Europe" ? mobileLinks : mobileLinks.filter(function (param) {
          return param.id !== "upcoming";
        });
  var match = Context.I18n.getState();
  var match$1 = match.labels.accessibility;
  var newWindowLabel = match$1.newWindow;
  var activeLink = category !== undefined ? categories.find(function (category$1) {
          return category === category$1.code;
        }) : undefined;
  if (props.state === "On") {
    return JsxRuntime.jsxs(Atom_Row.make, {
                background: Caml_option.some(Particle_Background_Ds.dropdownMenu),
                blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"))),
                children: [
                  JsxRuntime.jsx(Atom_Column.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeLeft("S")), undefined, undefined, Particle_Spacer_Ds.makeNone())),
                        children: JsxRuntime.jsx(Atom_Logo.make, {
                              logo: {
                                NAME: "ArteVerticalLogo",
                                VAL: "MenuNavigation"
                              }
                            }),
                        justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center"))
                      }),
                  JsxRuntime.jsxs(Atom_Box.make, {
                        children: [
                          JsxRuntime.jsx(Atom_Column.make, {
                                children: mobileLinks$1.map(function (param) {
                                      return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                  href: param.href,
                                                  label: param.label,
                                                  style: Atom_Cta_Ds.Header.makeMenuNavigation("Inactive")
                                                }, param.id);
                                    }),
                                display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "inlineFlex"))
                              }),
                          JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.menuNavigation,
                                display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "inlineFlex"))
                              }),
                          JsxRuntime.jsx(Atom_Column.make, {
                                blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("M"))),
                                children: categories.map(function (param) {
                                      var href = param.href;
                                      var buttonStyle = activeLink !== undefined && activeLink.code === param.code ? Atom_Cta_Ds.Header.makeMenuNavigation("Active") : Atom_Cta_Ds.Header.makeMenuNavigation("Inactive");
                                      return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                  href: href,
                                                  label: param.label,
                                                  style: buttonStyle
                                                }, href);
                                    })
                              }),
                          JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.menuNavigation,
                                display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "inlineFlex"))
                              }),
                          JsxRuntime.jsx(Atom_Column.make, {
                                children: menuNavigation.otherLinks.map(function (param) {
                                      var href = param.href;
                                      var label = param.label;
                                      var match = Type_Common.linkKindFromJs(param.kind);
                                      if (match === "external_") {
                                        return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                    href: href,
                                                    label: label,
                                                    screenReadersLabel: "(" + newWindowLabel + ")",
                                                    style: Atom_Cta_Ds.Header.makeMenuNavigation("External"),
                                                    target: "_blank"
                                                  }, href);
                                      }
                                      return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                  href: href,
                                                  label: label,
                                                  style: Atom_Cta_Ds.Header.makeMenuNavigation("Inactive")
                                                }, href);
                                    })
                              })
                        ],
                        direction: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column")),
                        spacer: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("S"))),
                        width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                      })
                ],
                position: Caml_option.some(position),
                rules: Caml_option.some(Organism_Header_Css.MenuNavigation.rules),
                wrap: "OnOneLine"
              });
  } else {
    return null;
  }
}

var make = Organism_MenuNavigation;

export {
  position ,
  make ,
}
/* position Not a pure module */
