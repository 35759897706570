// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as UseForm from "../../hook/UseForm.res.mjs";
import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as User_Type from "../User_Type.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Type_Progression from "../../type/Type_Progression.res.mjs";

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var favorites = (function (extra) {
        return Spice.optionFromJson((function (extra) {
                      return Spice.arrayFromJson(Spice.stringFromJson, extra);
                    }), extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "favorites"), null));
  if (favorites.TAG === "Ok") {
    var subscriptions = (function (extra) {
          return Spice.optionFromJson((function (extra) {
                        return Spice.arrayFromJson(Spice.stringFromJson, extra);
                      }), extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "subscriptions"), null));
    if (subscriptions.TAG === "Ok") {
      var lastvieweds = (function (extra) {
            return Spice.optionFromJson((function (extra) {
                          return Spice.arrayFromJson(Type_Progression.clubProgression_decode, extra);
                        }), extra);
          })(Belt_Option.getWithDefault(Js_dict.get(v, "lastvieweds"), null));
      if (lastvieweds.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  favorites: favorites._0,
                  subscriptions: subscriptions._0,
                  lastvieweds: lastvieweds._0
                }
              };
      }
      var e = lastvieweds._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastvieweds" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = subscriptions._0;
    return {
            TAG: "Error",
            _0: {
              path: ".subscriptions" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = favorites._0;
  return {
          TAG: "Error",
          _0: {
            path: ".favorites" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function endpoint_1(args) {
  return "me/data/" + Util_I18n.localeToString(args.language);
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  userRestriction: "Anonymous"
};

var DataConfig = {
  t_decode: t_decode,
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: t_decode
};

var Data = Api.User.MakeRequest({
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: t_decode,
      options: options
    });

var endpoint$1 = {
  NAME: "Static",
  VAL: "me"
};

var options$1 = {
  userRestriction: "Connected"
};

function decodeApiResponse(resp) {
  return Core__Result.map(Type_Club.User.t_decode(resp), (function (response) {
                return Core__Option.getOr(Type_Club.$$Response.getData(response)[0], Type_Club.User.defaultValue);
              }));
}

var GetConfig = {
  domain: "Sso",
  endpoint: endpoint$1,
  options: options$1,
  decodeApiResponse: decodeApiResponse
};

var Get = Api.User.MakeRequest({
      domain: "Sso",
      endpoint: endpoint$1,
      decodeApiResponse: decodeApiResponse,
      options: options$1
    });

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Type_String.User.Email.t_encode(v.email)
                  ],
                  [
                    "gender",
                    false,
                    Type_Club.User.gender_encode(v.gender)
                  ],
                  [
                    "lastName",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.lastName)
                  ],
                  [
                    "firstName",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.firstName)
                  ],
                  [
                    "birthdate",
                    false,
                    Spice.stringToJson(v.birthday)
                  ],
                  [
                    "street",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.street)
                  ],
                  [
                    "zipCode",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.postCode)
                  ],
                  [
                    "city",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.city)
                  ],
                  [
                    "country",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.country)
                  ]
                ]));
}

var endpoint$2 = {
  NAME: "Static",
  VAL: "me"
};

var options$2 = {
  userRestriction: "Connected"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

var decodeApiResponse$1 = Type_Club.User.t_decode;

var PutConfig = {
  args_encode: args_encode,
  domain: "Sso",
  endpoint: endpoint$2,
  method: "Put",
  options: options$2,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse$1
};

var defaultItemEmptyValue = "";

function get(values, field) {
  switch (field) {
    case "Email" :
        return values.email;
    case "Gender" :
        return values.gender;
    case "LastName" :
        return values.lastName;
    case "FirstName" :
        return values.firstName;
    case "Birthday" :
        return values.birthday;
    case "Street" :
        return values.street;
    case "PostCode" :
        return values.postCode;
    case "City" :
        return values.city;
    case "Country" :
        return values.country;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case "Email" :
        return {
                email: value,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "Gender" :
        return {
                email: values.email,
                gender: value,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "LastName" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: value,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "FirstName" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: value,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "Birthday" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: value,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "Street" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: value,
                postCode: values.postCode,
                city: values.city,
                country: values.country
              };
    case "PostCode" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: value,
                city: values.city,
                country: values.country
              };
    case "City" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: value,
                country: values.country
              };
    case "Country" :
        return {
                email: values.email,
                gender: values.gender,
                lastName: values.lastName,
                firstName: values.firstName,
                birthday: values.birthday,
                street: values.street,
                postCode: values.postCode,
                city: values.city,
                country: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

var formInitialState = {
  email: "",
  gender: "Unknown",
  lastName: "",
  firstName: "",
  birthday: "",
  street: "",
  postCode: "",
  city: "",
  country: ""
};

var Form = UseForm.Make({
      set: set,
      get: get
    });

function toOption(value) {
  if (value === "") {
    return ;
  } else {
    return value;
  }
}

function stateFormToPutUser(state) {
  var match = state.values;
  return {
          email: match.email,
          gender: match.gender,
          lastName: toOption(match.lastName),
          firstName: toOption(match.firstName),
          birthday: match.birthday,
          street: toOption(match.street),
          postCode: toOption(match.postCode),
          city: toOption(match.city),
          country: toOption(match.country)
        };
}

function initFormStateFromUser(user) {
  return Core__Option.getOr(Core__Option.map(user, (function (user) {
                    return {
                            email: Core__Option.getOr(User_Type.Info.email(user), ""),
                            gender: Core__Option.getOr(User_Type.Info.gender(user), "Unknown"),
                            lastName: Core__Option.getOr(User_Type.Info.lastName(user), ""),
                            firstName: Core__Option.getOr(User_Type.Info.firstName(user), ""),
                            birthday: Core__Option.getOr(User_Type.Info.birthdate(user), ""),
                            street: Core__Option.getOr(User_Type.Info.street(user), ""),
                            postCode: Core__Option.getOr(User_Type.Info.zipCode(user), ""),
                            city: Core__Option.getOr(User_Type.Info.city(user), ""),
                            country: Core__Option.getOr(User_Type.Info.country(user), "")
                          };
                  })), formInitialState);
}

var schema = Form.Schema.Validation.schema([
      Form.Schema.Validation.string(undefined, undefined, 256, undefined, "LastName"),
      Form.Schema.Validation.string(undefined, undefined, 256, undefined, "FirstName"),
      Form.Schema.Validation.string(undefined, undefined, 128, undefined, "PostCode"),
      Form.Schema.Validation.string(undefined, undefined, 256, undefined, "City"),
      Form.Schema.Validation.string(undefined, undefined, 128, undefined, "Country"),
      Form.Schema.Validation.string(undefined, undefined, 512, undefined, "Street"),
      Form.Schema.Validation.date("Birthday")
    ]);

function genderItems(placeholder, madam, sir) {
  return Util_Array.NonEmpty.make([
              {
                value: Type_String.Dropdown.Item.make(JSON.stringify(Type_Club.User.gender_encode("Male"))),
                label: Type_String.Dropdown.Item.make(sir)
              },
              {
                value: Type_String.Dropdown.Item.make(JSON.stringify(Type_Club.User.gender_encode("Female"))),
                label: Type_String.Dropdown.Item.make(madam)
              }
            ], {
              value: Type_String.Dropdown.Item.make(JSON.stringify(Type_Club.User.gender_encode("Unknown"))),
              label: Type_String.Dropdown.Item.make(placeholder)
            });
}

function makeCalendarItems(maxInt, emptyLabel) {
  var __x = Belt_Array.rangeBy(1, maxInt, 1).map(function (i) {
        var value = Type_String.Dropdown.Item.makeFromInt(i);
        return {
                value: value,
                label: value
              };
      });
  return [{
              value: Type_String.Dropdown.Item.make(defaultItemEmptyValue),
              label: emptyLabel
            }].concat(__x);
}

function makeYearItems(emptyLabel) {
  var date = new Date();
  var endRange = date.getFullYear();
  var arr = Belt_Array.rangeBy(1913, endRange, 1);
  arr.reverse();
  var __x = arr.map(function (i) {
        var value = Type_String.Dropdown.Item.makeFromInt(i);
        return {
                value: value,
                label: value
              };
      });
  return [{
              value: Type_String.Dropdown.Item.make(defaultItemEmptyValue),
              label: emptyLabel
            }].concat(__x);
}

function formatStateDate(dateValue) {
  var year = dateValue.year;
  var month = dateValue.month;
  var day = dateValue.day;
  if (day === "" && month === "" && year === "") {
    return "";
  } else {
    return month + "/" + day + "/" + year;
  }
}

var Put = {
  nameMaxCharacters: 256,
  streetMaxCharacters: 512,
  zipCodeMaxCharacters: 128,
  cityMaxCharacters: 256,
  countryMaxCharacters: 128,
  startBirthdayYearRange: 1913,
  defaultItemEmptyValue: defaultItemEmptyValue,
  FormFields: FormFields,
  formInitialState: formInitialState,
  Form: Form,
  toOption: toOption,
  stateFormToPutUser: stateFormToPutUser,
  initFormStateFromUser: initFormStateFromUser,
  schema: schema,
  genderItems: genderItems,
  makeCalendarItems: makeCalendarItems,
  makeYearItems: makeYearItems,
  formatStateDate: formatStateDate
};

var PutCall = Api.User.MakeMutation({
      domain: "Sso",
      method: "Put",
      endpoint: endpoint$2,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse$1,
      options: options$2
    });

function args_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Type_String.User.Email.t_encode(v.email)
                  ]]));
}

var endpoint$3 = {
  NAME: "Static",
  VAL: "me/new-email"
};

var options$3 = {
  userRestriction: "Connected"
};

function makeBody$1(args) {
  return Api.Utils.makeJSONBody(args, args_encode$1);
}

function decodeApiResponse$2(param) {
  return {
          TAG: "Ok",
          _0: "EmailSent"
        };
}

var NewEmailConfig = {
  args_encode: args_encode$1,
  domain: "Sso",
  endpoint: endpoint$3,
  method: "Post",
  options: options$3,
  makeBody: makeBody$1,
  decodeApiResponse: decodeApiResponse$2
};

var NewEmail = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint$3,
      makeBody: makeBody$1,
      decodeApiResponse: decodeApiResponse$2,
      options: options$3
    });

function args_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "oldpassword",
                    false,
                    Spice.stringToJson(v.oldpassword)
                  ],
                  [
                    "newpassword",
                    false,
                    Spice.stringToJson(v.newpassword)
                  ]
                ]));
}

var endpoint$4 = {
  NAME: "Static",
  VAL: "me/password"
};

var options$4 = {
  userRestriction: "Connected"
};

function makeBody$2(args) {
  return Api.Utils.makeJSONBody(args, args_encode$2);
}

function decodeApiResponse$3(param) {
  return {
          TAG: "Ok",
          _0: "PasswordUpdated"
        };
}

var PutConfig$1 = {
  args_encode: args_encode$2,
  domain: "Sso",
  endpoint: endpoint$4,
  method: "Put",
  options: options$4,
  makeBody: makeBody$2,
  decodeApiResponse: decodeApiResponse$3
};

var Put$1 = Api.User.MakeMutation({
      domain: "Sso",
      method: "Put",
      endpoint: endpoint$4,
      makeBody: makeBody$2,
      decodeApiResponse: decodeApiResponse$3,
      options: options$4
    });

var Password_update = Put$1.call;

var Password = {
  PutConfig: PutConfig$1,
  Put: Put$1,
  update: Password_update
};

function args_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "avatarId",
                    false,
                    Spice.stringToJson(v.avatarId)
                  ]]));
}

var endpoint$5 = {
  NAME: "Static",
  VAL: "me/avatar"
};

var options$5 = {
  userRestriction: "Connected"
};

function makeBody$3(args) {
  return Api.Utils.makeJSONBody(args, args_encode$3);
}

function decodeApiResponse$4(param) {
  return {
          TAG: "Ok",
          _0: "AvatarUpdated"
        };
}

var PutConfig$2 = {
  args_encode: args_encode$3,
  domain: "Sso",
  endpoint: endpoint$5,
  method: "Put",
  options: options$5,
  makeBody: makeBody$3,
  decodeApiResponse: decodeApiResponse$4
};

var Put$2 = Api.User.MakeMutation({
      domain: "Sso",
      method: "Put",
      endpoint: endpoint$5,
      makeBody: makeBody$3,
      decodeApiResponse: decodeApiResponse$4,
      options: options$5
    });

var Avatar_update = Put$2.call;

var Avatar = {
  PutConfig: PutConfig$2,
  Put: Put$2,
  update: Avatar_update
};

var endpoint$6 = {
  NAME: "Static",
  VAL: "me"
};

var options$6 = {
  userRestriction: "Connected"
};

function makeBody$4(param) {
  
}

function decodeApiResponse$5(param) {
  return {
          TAG: "Ok",
          _0: "AccountDeleted"
        };
}

var DeleteConfig = {
  domain: "Sso",
  endpoint: endpoint$6,
  method: "Delete",
  options: options$6,
  makeBody: makeBody$4,
  decodeApiResponse: decodeApiResponse$5
};

var Delete = Api.User.MakeMutation({
      domain: "Sso",
      method: "Delete",
      endpoint: endpoint$6,
      makeBody: makeBody$4,
      decodeApiResponse: decodeApiResponse$5,
      options: options$6
    });

function args_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Type_String.User.Email.t_encode(v.email)
                  ],
                  [
                    "source",
                    false,
                    Spice.stringToJson(v.source)
                  ],
                  [
                    "nlarte",
                    false,
                    Spice.boolToJson(v.nlarte)
                  ],
                  [
                    "acceptCom",
                    false,
                    Spice.boolToJson(v.acceptCom)
                  ],
                  [
                    "language",
                    false,
                    Util_I18n.locale_encode(v.locale)
                  ]
                ]));
}

var endpoint$7 = {
  NAME: "Static",
  VAL: "me/newsletter/subscribe"
};

function makeBody$5(args) {
  return Api.Utils.makeJSONBody(args, args_encode$4);
}

function decodeApiResponse$6(param) {
  return {
          TAG: "Ok",
          _0: "Subscribed"
        };
}

var options$7 = {};

var SubscribeConfig = {
  args_encode: args_encode$4,
  domain: "Sso",
  endpoint: endpoint$7,
  method: "Post",
  makeBody: makeBody$5,
  decodeApiResponse: decodeApiResponse$6,
  options: options$7
};

var Subscribe = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint$7,
      makeBody: makeBody$5,
      decodeApiResponse: decodeApiResponse$6,
      options: options$7
    });

var Newsletter = {
  SubscribeConfig: SubscribeConfig,
  Subscribe: Subscribe
};

var getData = Data.call;

var get$1 = Get.call;

var put = PutCall.call;

var newEmail = NewEmail.call;

var $$delete = Delete.call;

export {
  DataConfig ,
  Data ,
  getData ,
  GetConfig ,
  Get ,
  get$1 as get,
  PutConfig ,
  Put ,
  PutCall ,
  put ,
  NewEmailConfig ,
  NewEmail ,
  newEmail ,
  Password ,
  Avatar ,
  DeleteConfig ,
  Delete ,
  $$delete ,
  Newsletter ,
}
/* Data Not a pure module */
