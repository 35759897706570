// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "@rescript/std/lib/es6/caml.js";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as Type_Url from "./Type_Url.res.mjs";
import * as Belt_Result from "@rescript/std/lib/es6/belt_Result.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Util_String from "../util/Util_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Core__Nullable from "@rescript/core/src/Core__Nullable.res.mjs";
import * as Belt_MutableMap from "@rescript/std/lib/es6/belt_MutableMap.js";

function t_encode(v) {
  return Spice.stringToJson(v);
}

function t_decode(v) {
  return Belt_Result.map(Spice.stringFromJson(v), (function (v) {
                return v;
              }));
}

var empty = "";

function makeFromNullable(str) {
  return Caml_option.nullable_to_opt(Core__Nullable.map(str, (function (str) {
                    return str;
                  })));
}

function makeFromNullableOrEmpty(str) {
  return Core__Option.getOr(makeFromNullable(str), "");
}

function String_toString(prim) {
  return prim;
}

function String_make(prim) {
  return prim;
}

var $$String = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: String_toString,
  empty: empty,
  make: String_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

function t_encode$1(v) {
  return Spice.stringToJson(v);
}

function t_decode$1(v) {
  return Spice.stringFromJson(v);
}

function make(str) {
  var trimedStr = str.trim();
  if (Util_String.isNotEmpty(trimedStr)) {
    return trimedStr;
  }
  
}

function toString(t) {
  return t;
}

var NotEmpty = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  toString: toString,
  make: make
};

function MakeMutable(T) {
  var cmp = Caml.string_compare;
  var Cmp = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var key_encode = function (v) {
    return T.Str.t_encode(v);
  };
  var key_decode = function (v) {
    return T.Str.t_decode(v);
  };
  var t_tuple_encode = function (v) {
    return [
            T.Str.t_encode(v[0]),
            T.t_encode(v[1])
          ];
  };
  var t_tuple_decode = function (v) {
    if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
      return Spice.error(undefined, "Not a tuple", v);
    }
    if (!Array.isArray(v)) {
      return Spice.error(undefined, "Not a tuple", v);
    }
    if (v.length !== 2) {
      return Spice.error(undefined, "Incorrect cardinality", v);
    }
    var v0 = v[0];
    var v1 = v[1];
    var match = T.Str.t_decode(v0);
    var match$1 = T.t_decode(v1);
    if (match.TAG === "Ok") {
      if (match$1.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: [
                  match._0,
                  match$1._0
                ]
              };
      }
      var e = match$1._0;
      return {
              TAG: "Error",
              _0: {
                path: "[1]" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = match._0;
    return {
            TAG: "Error",
            _0: {
              path: "[0]" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  };
  var t_array_encode = function (v) {
    return Spice.arrayToJson(t_tuple_encode, v);
  };
  var t_array_decode = function (v) {
    return Spice.arrayFromJson(t_tuple_decode, v);
  };
  var InternalType = {
    t_array_encode: t_array_encode,
    t_array_decode: t_array_decode
  };
  var t_encode = function (t) {
    return t_array_encode(Belt_MutableMap.toArray(t));
  };
  var t_decode = function (arr) {
    return Core__Result.map(t_array_decode(arr), (function (a) {
                  return Belt_MutableMap.fromArray(a, Cmp);
                }));
  };
  var merge = function (t1, t2, f) {
    Belt_MutableMap.forEach(t2, (function (key, value) {
            var newValue = f(key, Belt_MutableMap.get(t1, key), Caml_option.some(value));
            if (newValue !== undefined) {
              return Belt_MutableMap.set(t1, key, Caml_option.valFromOption(newValue));
            } else {
              return Belt_MutableMap.set(t1, key, value);
            }
          }));
  };
  var make = function () {
    return Belt_MutableMap.make(Cmp);
  };
  return {
          Cmp: Cmp,
          key_encode: key_encode,
          key_decode: key_decode,
          t_tuple_encode: t_tuple_encode,
          t_tuple_decode: t_tuple_decode,
          InternalType: InternalType,
          t_encode: t_encode,
          t_decode: t_decode,
          get: Belt_MutableMap.get,
          merge: merge,
          remove: Belt_MutableMap.remove,
          set: Belt_MutableMap.set,
          reduce: Belt_MutableMap.reduce,
          make: make,
          toArray: Belt_MutableMap.toArray
        };
}

var $$Map = {
  MakeMutable: MakeMutable
};

function t_encode$2(v) {
  return Spice.stringToJson(v);
}

function t_decode$2(v) {
  return Spice.stringFromJson(v);
}

function toString$1(t) {
  return t.toLowerCase();
}

var Email = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toString: toString$1
};

var User = {
  Email: Email
};

var Id_toString = String_toString;

var Id_make = String_make;

var Id = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Id_toString,
  empty: empty,
  make: Id_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Zone = {
  Id: Id
};

var Title_toString = String_toString;

var Title_make = String_make;

var Title = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Title_toString,
  empty: empty,
  make: Title_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Subtitle_toString = String_toString;

var Subtitle_make = String_make;

var Subtitle = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Subtitle_toString,
  empty: empty,
  make: Subtitle_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Description_toString = String_toString;

var Description_make = String_make;

var Description = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Description_toString,
  empty: empty,
  make: Description_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Id_toString$1 = String_toString;

var Id_make$1 = String_make;

var Id$1 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Id_toString$1,
  empty: empty,
  make: Id_make$1,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var DsId_toString = String_toString;

var DsId_make = String_make;

var DsId = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: DsId_toString,
  empty: empty,
  make: DsId_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var BroadcastDate_toString = String_toString;

var BroadcastDate_make = String_make;

var BroadcastDate = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: BroadcastDate_toString,
  empty: empty,
  make: BroadcastDate_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Key_toString = String_toString;

var Key_make = String_make;

var Key = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Key_toString,
  empty: empty,
  make: Key_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Label_toString = String_toString;

var Label_make = String_make;

var Label = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Label_toString,
  empty: empty,
  make: Label_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Geoblocking = {
  Label: Label
};

var DurationLabel_toString = String_toString;

var DurationLabel_make = String_make;

var DurationLabel = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: DurationLabel_toString,
  empty: empty,
  make: DurationLabel_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Teaser = {
  Title: Title,
  Subtitle: Subtitle,
  Description: Description,
  Id: Id$1,
  DsId: DsId,
  BroadcastDate: BroadcastDate,
  Key: Key,
  Geoblocking: Geoblocking,
  DurationLabel: DurationLabel
};

var Id_toString$2 = String_toString;

var Id_make$2 = String_make;

var Id$2 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Id_toString$2,
  empty: empty,
  make: Id_make$2,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Clip_toString = String_toString;

var Clip_make = String_make;

var Clip = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Clip_toString,
  empty: empty,
  make: Clip_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Trailer_toString = String_toString;

var Trailer_make = String_make;

var Trailer = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Trailer_toString,
  empty: empty,
  make: Trailer_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Label_toString$1 = String_toString;

var Label_make$1 = String_make;

var Label$1 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Label_toString$1,
  empty: empty,
  make: Label_make$1,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Url_toString = String_toString;

var Url_make = String_make;

var Url = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Url_toString,
  empty: empty,
  make: Url_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Genre = {
  Label: Label$1,
  Url: Url
};

var FirstBroadcastDate_toString = String_toString;

var FirstBroadcastDate_make = String_make;

var FirstBroadcastDate = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: FirstBroadcastDate_toString,
  empty: empty,
  make: FirstBroadcastDate_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

function makeFromTrailer(trailer) {
  return trailer;
}

function makeFromClip(clip) {
  return clip;
}

var Video_toString = String_toString;

var Video_make = String_make;

var Video = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Video_toString,
  empty: empty,
  make: Video_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty,
  makeFromTrailer: makeFromTrailer,
  makeFromClip: makeFromClip
};

var Program = {
  Id: Id$2,
  Clip: Clip,
  Trailer: Trailer,
  Genre: Genre,
  FirstBroadcastDate: FirstBroadcastDate,
  Video: Video
};

var CallToAction_toString = String_toString;

var CallToAction_make = String_make;

var CallToAction = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: CallToAction_toString,
  empty: empty,
  make: CallToAction_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var toString$2 = String_toString;

function toTypeUrl(t) {
  return Type_Url.TrackingPixel.make(toString$2(t));
}

var TrackingPixel_make = String_make;

var TrackingPixel = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString$2,
  empty: empty,
  make: TrackingPixel_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty,
  toTypeUrl: toTypeUrl
};

var Href_toString = String_toString;

var Href_make = String_make;

var Href = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Href_toString,
  empty: empty,
  make: Href_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Label_toString$2 = String_toString;

var Label_make$2 = String_make;

var Label$2 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Label_toString$2,
  empty: empty,
  make: Label_make$2,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Value_toString = String_toString;

var Value_make = String_make;

var Value = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Value_toString,
  empty: empty,
  make: Value_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Credit = {
  Label: Label$2,
  Value: Value
};

var Success_toString = String_toString;

var Success_make = String_make;

var Success = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Success_toString,
  empty: empty,
  make: Success_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Error_toString = String_toString;

var Error_make = String_make;

var $$Error = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Error_toString,
  empty: empty,
  make: Error_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var SubscribeToNewsletter = {
  Success: Success,
  $$Error: $$Error
};

function addPath(cdnUrl, path) {
  return cdnUrl + path;
}

var CdnUrl_toString = String_toString;

var CdnUrl_make = String_make;

var CdnUrl = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: CdnUrl_toString,
  empty: empty,
  make: CdnUrl_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty,
  addPath: addPath
};

var Id_toString$3 = String_toString;

var Id_make$3 = String_make;

var Id$3 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Id_toString$3,
  empty: empty,
  make: Id_make$3,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Source_toString = String_toString;

var Source_make = String_make;

var Source = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Source_toString,
  empty: empty,
  make: Source_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Video$1 = {
  Id: Id$3,
  Source: Source
};

var Title_toString$1 = String_toString;

var Title_make$1 = String_make;

var Title$1 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Title_toString$1,
  empty: empty,
  make: Title_make$1,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Label_toString$3 = String_toString;

var Label_make$3 = String_make;

var Label$3 = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Label_toString$3,
  empty: empty,
  make: Label_make$3,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Footer = {
  Title: Title$1,
  Label: Label$3
};

var PlayStoreUrl_toString = String_toString;

var PlayStoreUrl_make = String_make;

var PlayStoreUrl = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: PlayStoreUrl_toString,
  empty: empty,
  make: PlayStoreUrl_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var AppStoreUrl_toString = String_toString;

var AppStoreUrl_make = String_make;

var AppStoreUrl = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: AppStoreUrl_toString,
  empty: empty,
  make: AppStoreUrl_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

var Notification_toString = String_toString;

var Notification_make = String_make;

var $$Notification = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Notification_toString,
  empty: empty,
  make: Notification_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty
};

function makeFromInt(i) {
  if (i < 0) {
    return "00";
  } else if (i < 10) {
    return "0" + i.toString();
  } else {
    return i.toString();
  }
}

var Item_toString = String_toString;

var Item_make = String_make;

var Item = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: Item_toString,
  empty: empty,
  make: Item_make,
  makeFromNullable: makeFromNullable,
  makeFromNullableOrEmpty: makeFromNullableOrEmpty,
  makeFromInt: makeFromInt
};

var Dropdown = {
  Item: Item
};

export {
  $$String ,
  NotEmpty ,
  $$Map ,
  User ,
  Zone ,
  Teaser ,
  Program ,
  CallToAction ,
  TrackingPixel ,
  Href ,
  Credit ,
  SubscribeToNewsletter ,
  CdnUrl ,
  Video$1 as Video,
  Footer ,
  PlayStoreUrl ,
  AppStoreUrl ,
  $$Notification ,
  Dropdown ,
}
/* No side effect */
