// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Icon_X from "./icon/path/Atom_Icon_X.res.mjs";
import * as Atom_Icon_AD from "./icon/path/Atom_Icon_AD.res.mjs";
import * as Atom_Icon_BW from "./icon/path/Atom_Icon_BW.res.mjs";
import * as Atom_Icon_DE from "./icon/path/Atom_Icon_DE.res.mjs";
import * as Atom_Icon_HD from "./icon/path/Atom_Icon_HD.res.mjs";
import * as Atom_Icon_OV from "./icon/path/Atom_Icon_OV.res.mjs";
import * as Atom_Icon_ST from "./icon/path/Atom_Icon_ST.res.mjs";
import * as Atom_Icon_Tv from "./icon/path/Atom_Icon_Tv.res.mjs";
import * as Atom_Icon_UT from "./icon/path/Atom_Icon_UT.res.mjs";
import * as Atom_Icon_VF from "./icon/path/Atom_Icon_VF.res.mjs";
import * as Atom_Icon_VO from "./icon/path/Atom_Icon_VO.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Icon_DOL from "./icon/path/Atom_Icon_DOL.res.mjs";
import * as Atom_Icon_Off from "./icon/path/Atom_Icon_Off.res.mjs";
import * as Atom_Icon_OmU from "./icon/path/Atom_Icon_OmU.res.mjs";
import * as Atom_Icon_STE from "./icon/path/Atom_Icon_STE.res.mjs";
import * as Atom_Icon_STM from "./icon/path/Atom_Icon_STM.res.mjs";
import * as Atom_Icon_WID from "./icon/path/Atom_Icon_WID.res.mjs";
import * as Atom_Icon_WRN from "./icon/path/Atom_Icon_WRN.res.mjs";
import * as Atom_Icon_Back from "./icon/path/Atom_Icon_Back.res.mjs";
import * as Atom_Icon_Home from "./icon/path/Atom_Icon_Home.res.mjs";
import * as Atom_Icon_Info from "./icon/path/Atom_Icon_Info.res.mjs";
import * as Atom_Icon_Live from "./icon/path/Atom_Icon_Live.res.mjs";
import * as Atom_Icon_Menu from "./icon/path/Atom_Icon_Menu.res.mjs";
import * as Atom_Icon_Next from "./icon/path/Atom_Icon_Next.res.mjs";
import * as Atom_Icon_Play from "./icon/path/Atom_Icon_Play.res.mjs";
import * as Atom_Icon_Plus from "./icon/path/Atom_Icon_Plus.res.mjs";
import * as Atom_Icon_Star from "./icon/path/Atom_Icon_Star.res.mjs";
import * as Atom_Icon_User from "./icon/path/Atom_Icon_User.res.mjs";
import * as Atom_Icon_Angle from "./icon/path/Atom_Icon_Angle.res.mjs";
import * as Atom_Icon_Arrow from "./icon/path/Atom_Icon_Arrow.res.mjs";
import * as Atom_Icon_Close from "./icon/path/Atom_Icon_Close.res.mjs";
import * as Atom_Icon_Error from "./icon/path/Atom_Icon_Error.res.mjs";
import * as Atom_Icon_Heart from "./icon/path/Atom_Icon_Heart.res.mjs";
import * as Atom_Icon_Pause from "./icon/path/Atom_Icon_Pause.res.mjs";
import * as Atom_Icon_Share from "./icon/path/Atom_Icon_Share.res.mjs";
import * as Atom_Icon_Speed from "./icon/path/Atom_Icon_Speed.res.mjs";
import * as Atom_Icon_Trash from "./icon/path/Atom_Icon_Trash.res.mjs";
import * as Atom_Icon_Logout from "./icon/path/Atom_Icon_Logout.res.mjs";
import * as Atom_Icon_Rocket from "./icon/path/Atom_Icon_Rocket.res.mjs";
import * as Atom_Icon_Search from "./icon/path/Atom_Icon_Search.res.mjs";
import * as Atom_Icon_Shorts from "./icon/path/Atom_Icon_Shorts.res.mjs";
import * as Atom_Icon_Tablet from "./icon/path/Atom_Icon_Tablet.res.mjs";
import * as Atom_Icon_Tiktok from "./icon/path/Atom_Icon_Tiktok.res.mjs";
import * as Atom_Icon_Twitch from "./icon/path/Atom_Icon_Twitch.res.mjs";
import * as Atom_Icon_VodDvd from "./icon/path/Atom_Icon_VodDvd.res.mjs";
import * as Atom_Icon_Airplay from "./icon/path/Atom_Icon_Airplay.res.mjs";
import * as Atom_Icon_Browser from "./icon/path/Atom_Icon_Browser.res.mjs";
import * as Atom_Icon_Checked from "./icon/path/Atom_Icon_Checked.res.mjs";
import * as Atom_Icon_Desktop from "./icon/path/Atom_Icon_Desktop.res.mjs";
import * as Atom_Icon_Loading from "./icon/path/Atom_Icon_Loading.res.mjs";
import * as Atom_Icon_Version from "./icon/path/Atom_Icon_Version.res.mjs";
import * as Atom_Icon_Youtube from "./icon/path/Atom_Icon_Youtube.res.mjs";
import * as Atom_Icon_Calendar from "./icon/path/Atom_Icon_Calendar.res.mjs";
import * as Atom_Icon_Download from "./icon/path/Atom_Icon_Download.res.mjs";
import * as Atom_Icon_Envelope from "./icon/path/Atom_Icon_Envelope.res.mjs";
import * as Atom_Icon_Facebook from "./icon/path/Atom_Icon_Facebook.res.mjs";
import * as Atom_Icon_Previous from "./icon/path/Atom_Icon_Previous.res.mjs";
import * as Atom_Icon_Settings from "./icon/path/Atom_Icon_Settings.res.mjs";
import * as Atom_Icon_Sound_On from "./icon/path/Atom_Icon_Sound_On.res.mjs";
import * as Atom_Icon_Whatsapp from "./icon/path/Atom_Icon_Whatsapp.res.mjs";
import * as Atom_Icon_BackStart from "./icon/path/Atom_Icon_BackStart.res.mjs";
import * as Atom_Icon_Instagram from "./icon/path/Atom_Icon_Instagram.res.mjs";
import * as Atom_Icon_MapMarker from "./icon/path/Atom_Icon_MapMarker.res.mjs";
import * as Atom_Icon_MegaPhone from "./icon/path/Atom_Icon_MegaPhone.res.mjs";
import * as Atom_Icon_Sound_Off from "./icon/path/Atom_Icon_Sound_Off.res.mjs";
import * as Atom_Icon_Chromecast from "./icon/path/Atom_Icon_Chromecast.res.mjs";
import * as Atom_Icon_ComingSoon from "./icon/path/Atom_Icon_ComingSoon.res.mjs";
import * as Atom_Icon_Fullscreen from "./icon/path/Atom_Icon_Fullscreen.res.mjs";
import * as Atom_Icon_SmartPhone from "./icon/path/Atom_Icon_SmartPhone.res.mjs";
import * as Atom_Icon_StarLocked from "./icon/path/Atom_Icon_StarLocked.res.mjs";
import * as Atom_Icon_CircleCheck from "./icon/path/Atom_Icon_CircleCheck.res.mjs";
import * as Atom_Icon_Geoblocking from "./icon/path/Atom_Icon_Geoblocking.res.mjs";
import * as Atom_Icon_Seek_Rewind from "./icon/path/Atom_Icon_Seek_Rewind.res.mjs";
import * as Atom_Icon_Heart_Filled from "./icon/path/Atom_Icon_Heart_Filled.res.mjs";
import * as Atom_Icon_Notification from "./icon/path/Atom_Icon_Notification.res.mjs";
import * as Atom_Icon_Seek_Forward from "./icon/path/Atom_Icon_Seek_Forward.res.mjs";
import * as Atom_Icon_Restriction12 from "./icon/path/Atom_Icon_Restriction12.res.mjs";
import * as Atom_Icon_Restriction16 from "./icon/path/Atom_Icon_Restriction16.res.mjs";
import * as Atom_Icon_Restriction18 from "./icon/path/Atom_Icon_Restriction18.res.mjs";
import * as Atom_Icon_NoNotification from "./icon/path/Atom_Icon_NoNotification.res.mjs";
import * as Atom_Icon_PicturePicture from "./icon/path/Atom_Icon_PicturePicture.res.mjs";
import * as Atom_Icon_Playlist_Illico from "./icon/path/Atom_Icon_Playlist_Illico.res.mjs";
import * as Atom_Icon_Playlist_Normal from "./icon/path/Atom_Icon_Playlist_Normal.res.mjs";

function updateColor(t, color) {
  var newrecord = Caml_obj.obj_dup(t);
  newrecord.color = color;
  return newrecord;
}

var airplay = {
  component: Atom_Icon_Airplay
};

var backstart = {
  component: Atom_Icon_BackStart
};

var chromeCast = {
  component: Atom_Icon_Chromecast
};

var fullscreen = {
  component: Atom_Icon_Fullscreen
};

var live = {
  component: Atom_Icon_Live
};

var menu = {
  component: Atom_Icon_Menu
};

var next = {
  component: Atom_Icon_Next
};

var pictureInPicture = {
  component: Atom_Icon_PicturePicture
};

var playList = {
  component: Atom_Icon_Playlist_Normal
};

var playListIllico = {
  component: Atom_Icon_Playlist_Illico
};

var previous = {
  component: Atom_Icon_Previous
};

var seekForward = {
  component: Atom_Icon_Seek_Forward
};

var seekRewind = {
  component: Atom_Icon_Seek_Rewind
};

var soundOff = {
  component: Atom_Icon_Sound_Off
};

var soundOn = {
  component: Atom_Icon_Sound_On
};

var speed = {
  component: Atom_Icon_Speed
};

var version = {
  component: Atom_Icon_Version
};

var vodDvd = {
  component: Atom_Icon_VodDvd
};

var arrow45 = {
  component: Atom_Icon_Arrow
};

var down_angle = 0;

var down = {
  angle: down_angle,
  component: Atom_Icon_Angle
};

var left_angle = 90;

var left = {
  angle: left_angle,
  component: Atom_Icon_Angle
};

var right_angle = -90;

var right = {
  angle: right_angle,
  component: Atom_Icon_Angle
};

var up_angle = 180;

var up = {
  angle: up_angle,
  component: Atom_Icon_Angle
};

var back_viewBox = "0 0 46 41";

var back = {
  viewBox: back_viewBox,
  component: Atom_Icon_Back
};

function fromDirection(direction) {
  if (direction === "Top") {
    return up;
  } else if (direction === "Down") {
    return down;
  } else if (direction === "Left") {
    return left;
  } else {
    return right;
  }
}

function fromToggleStatus(status) {
  if (status === "On") {
    return up;
  } else {
    return down;
  }
}

var ad = {
  component: Atom_Icon_AD
};

var adu = {
  component: Atom_Icon_Restriction18
};

var bw = {
  component: Atom_Icon_BW
};

var de = {
  component: Atom_Icon_DE
};

var dol = {
  component: Atom_Icon_DOL
};

var hd = {
  component: Atom_Icon_HD
};

var omu = {
  component: Atom_Icon_OmU
};

var ov = {
  component: Atom_Icon_OV
};

var st = {
  component: Atom_Icon_ST
};

var ste = {
  component: Atom_Icon_STE
};

var stm = {
  component: Atom_Icon_STM
};

var ut = {
  component: Atom_Icon_UT
};

var vf = {
  component: Atom_Icon_VF
};

var vo = {
  component: Atom_Icon_VO
};

var wid = {
  component: Atom_Icon_WID
};

var wrn = {
  component: Atom_Icon_WRN
};

var AudioVersion = {
  ad: ad,
  adu: adu,
  bw: bw,
  de: de,
  dol: dol,
  hd: hd,
  omu: omu,
  ov: ov,
  st: st,
  ste: ste,
  stm: stm,
  ut: ut,
  vf: vf,
  vo: vo,
  wid: wid,
  wrn: wrn
};

var facebook = {
  component: Atom_Icon_Facebook
};

var instagram = {
  component: Atom_Icon_Instagram
};

var tiktok = {
  component: Atom_Icon_Tiktok
};

var twitch = {
  component: Atom_Icon_Twitch
};

var whatsapp = {
  component: Atom_Icon_Whatsapp
};

var x = {
  component: Atom_Icon_X
};

var youtube = {
  component: Atom_Icon_Youtube
};

var facebook_opacity = 0.6;

var facebook$1 = {
  opacity: facebook_opacity,
  component: Atom_Icon_Facebook
};

var instagram_opacity = 0.6;

var instagram$1 = {
  opacity: instagram_opacity,
  component: Atom_Icon_Instagram
};

var tiktok_opacity = 0.6;

var tiktok$1 = {
  opacity: tiktok_opacity,
  component: Atom_Icon_Tiktok
};

var twitch_opacity = 0.6;

var twitch$1 = {
  opacity: twitch_opacity,
  component: Atom_Icon_Twitch
};

var x_opacity = 0.6;

var x$1 = {
  opacity: x_opacity,
  component: Atom_Icon_X
};

var youtube_opacity = 0.6;

var youtube$1 = {
  opacity: youtube_opacity,
  component: Atom_Icon_Youtube
};

var calendar_color = "Arte";

var calendar = {
  color: calendar_color,
  component: Atom_Icon_Calendar
};

var checked = {
  component: Atom_Icon_Checked
};

var circleCheck = {
  component: Atom_Icon_CircleCheck
};

var close = {
  component: Atom_Icon_Close
};

var comingSoon = {
  component: Atom_Icon_ComingSoon
};

var desktop = {
  component: Atom_Icon_Desktop
};

var download = {
  component: Atom_Icon_Download
};

var envelope = {
  component: Atom_Icon_Envelope
};

var error_viewBox = "0 0 16 16";

var error = {
  viewBox: error_viewBox,
  component: Atom_Icon_Error
};

var geoblocking = {
  component: Atom_Icon_Geoblocking
};

var heart = {
  component: Atom_Icon_Heart
};

var heartFilled = {
  component: Atom_Icon_Heart_Filled
};

var home = {
  component: Atom_Icon_Home
};

var info = {
  component: Atom_Icon_Info
};

var loading = {
  component: Atom_Icon_Loading
};

var logout = {
  component: Atom_Icon_Logout
};

var mapMarker_color = "Arte";

var mapMarker = {
  color: mapMarker_color,
  component: Atom_Icon_MapMarker
};

var megaphone = {
  component: Atom_Icon_MegaPhone
};

var noNotification_viewBox = "1 1.5 19 20";

var noNotification = {
  viewBox: noNotification_viewBox,
  component: Atom_Icon_NoNotification
};

var notification_viewBox = "0 0 15 17";

var notification = {
  viewBox: notification_viewBox,
  component: Atom_Icon_Notification
};

var off = {
  component: Atom_Icon_Off
};

var pause = {
  component: Atom_Icon_Pause
};

var play = {
  component: Atom_Icon_Play
};

var plus = {
  component: Atom_Icon_Plus
};

var restrictions12 = {
  component: Atom_Icon_Restriction12
};

var restrictions16 = {
  component: Atom_Icon_Restriction16
};

var restrictions18 = {
  component: Atom_Icon_Restriction18
};

var rocket = {
  component: Atom_Icon_Rocket
};

var search = {
  component: Atom_Icon_Search
};

var settings = {
  component: Atom_Icon_Settings
};

var share = {
  component: Atom_Icon_Share
};

var smartPhone = {
  component: Atom_Icon_SmartPhone
};

var star = {
  component: Atom_Icon_Star
};

var starLocked = {
  component: Atom_Icon_StarLocked
};

var tablet = {
  component: Atom_Icon_Tablet
};

var trash = {
  component: Atom_Icon_Trash
};

var tv = {
  component: Atom_Icon_Tv
};

var user = {
  component: Atom_Icon_User
};

var browser = {
  component: Atom_Icon_Browser
};

var shorts_viewBox = "0 0 50 46";

var shorts = {
  viewBox: shorts_viewBox,
  component: Atom_Icon_Shorts
};

function getViewBox(param) {
  return Core__Option.getOr(param.viewBox, "0 0 32 32");
}

function getOpacity(param) {
  return Core__Option.getOr(param.opacity, 1);
}

function getAngle(param) {
  return Core__Option.getOr(param.angle, 0);
}

function view(t) {
  var opacity = getOpacity(t);
  var viewBox = getViewBox(t);
  var angle = getAngle(t);
  return {
          angle: angle,
          color: t.color,
          hover: t.hover,
          opacity: opacity,
          viewBox: viewBox,
          component: t.component
        };
}

var Player = {
  seekRewind: seekRewind,
  seekForward: seekForward,
  playList: playList,
  playListIllico: playListIllico,
  soundOn: soundOn,
  soundOff: soundOff,
  speed: speed,
  chromeCast: chromeCast,
  next: next,
  previous: previous,
  pictureInPicture: pictureInPicture,
  fullscreen: fullscreen,
  live: live,
  airplay: airplay,
  menu: menu,
  backstart: backstart,
  version: version,
  vodDvd: vodDvd
};

var Arrow = {
  arrow45: arrow45,
  down: down,
  left: left,
  right: right,
  up: up,
  fromDirection: fromDirection,
  fromToggleStatus: fromToggleStatus,
  back: back
};

var SocialMedia_Footer = {
  youtube: youtube$1,
  instagram: instagram$1,
  x: x$1,
  facebook: facebook$1,
  tiktok: tiktok$1,
  twitch: twitch$1
};

var SocialMedia = {
  facebook: facebook,
  instagram: instagram,
  tiktok: tiktok,
  twitch: twitch,
  whatsapp: whatsapp,
  x: x,
  youtube: youtube,
  Footer: SocialMedia_Footer
};

export {
  search ,
  Player ,
  Arrow ,
  AudioVersion ,
  SocialMedia ,
  calendar ,
  checked ,
  circleCheck ,
  close ,
  comingSoon ,
  desktop ,
  download ,
  envelope ,
  error ,
  geoblocking ,
  heart ,
  heartFilled ,
  home ,
  info ,
  loading ,
  logout ,
  mapMarker ,
  megaphone ,
  noNotification ,
  notification ,
  off ,
  pause ,
  play ,
  plus ,
  restrictions12 ,
  restrictions16 ,
  restrictions18 ,
  rocket ,
  settings ,
  share ,
  smartPhone ,
  star ,
  starLocked ,
  tablet ,
  trash ,
  tv ,
  user ,
  browser ,
  shorts ,
  view ,
  updateColor ,
}
/* Atom_Icon_X Not a pure module */
