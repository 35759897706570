// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as User_Type from "../User_Type.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var LogoutConfig_endpoint = {
  NAME: "Static",
  VAL: "logout"
};

var LogoutConfig_options = {
  withCredentials: "Include"
};

var LogoutConfig = {
  domain: "SsoLogin",
  endpoint: LogoutConfig_endpoint,
  decodeApiResponse: decodeApiResponse,
  options: LogoutConfig_options
};

var Logout = Api.User.MakeRequest(LogoutConfig);

function endpoint_1(param) {
  return "login?shouldValidateAnonymous=true";
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  tokenKind: "None",
  withCredentials: "Include"
};

function ssoResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var token = (function (extra) {
        return Spice.optionFromJson(Spice.stringFromJson, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
  if (token.TAG === "Ok") {
    var isauthenticated = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isauthenticated"), null));
    if (isauthenticated.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                token: token._0,
                isauthenticated: isauthenticated._0
              }
            };
    }
    var e = isauthenticated._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isauthenticated" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".token" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function decodeApiResponse$1(resp) {
  var __x = ssoResponse_decode(resp);
  return Core__Result.map(__x, (function (param) {
                var token = param.token;
                if (token !== undefined && param.isauthenticated) {
                  return User_Type.Token.make(token);
                } else {
                  return User_Type.Token.disconnected;
                }
              }));
}

var LoginConfig = {
  domain: "SsoLogin",
  endpoint: endpoint,
  options: options,
  ssoResponse_decode: ssoResponse_decode,
  decodeApiResponse: decodeApiResponse$1
};

var Login = Api.User.MakeRequest({
      domain: "SsoLogin",
      endpoint: endpoint,
      decodeApiResponse: decodeApiResponse$1,
      options: options
    });

function endpoint_1$1(args) {
  return "setCustomToken?shouldValidateAnonymous=true&token=" + User_Type.Token.toString(args.token);
}

var endpoint$1 = {
  NAME: "Dynamic",
  VAL: endpoint_1$1
};

var options$1 = {
  tokenKind: "None",
  withCredentials: "Include"
};

function ssoResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ok = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "ok"), null));
  if (ok.TAG === "Ok") {
    var access_token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "access_token"), null));
    if (access_token.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                ok: ok._0,
                access_token: access_token._0
              }
            };
    }
    var e = access_token._0;
    return {
            TAG: "Error",
            _0: {
              path: ".access_token" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = ok._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ok" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function decodeApiResponse$2(t) {
  var err = ssoResponse_decode$1(t);
  if (err.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: err._0
          };
  }
  var match = err._0;
  if (match.ok === true) {
    return {
            TAG: "Ok",
            _0: User_Type.Token.make(match.access_token)
          };
  } else {
    return Spice.error("setCustomToken", "Failed to set custom token", t);
  }
}

var SetCustomTokenConfig = {
  domain: "SsoLogin",
  endpoint: endpoint$1,
  options: options$1,
  ssoResponse_decode: ssoResponse_decode$1,
  decodeApiResponse: decodeApiResponse$2
};

var SetCustomToken = Api.User.MakeRequest({
      domain: "SsoLogin",
      endpoint: endpoint$1,
      decodeApiResponse: decodeApiResponse$2,
      options: options$1
    });

var deleteToken = Logout.call;

var getTokenFromCookie = Login.call;

var setToken = SetCustomToken.call;

export {
  LogoutConfig ,
  Logout ,
  deleteToken ,
  LoginConfig ,
  Login ,
  getTokenFromCookie ,
  SetCustomTokenConfig ,
  SetCustomToken ,
  setToken ,
}
/* Logout Not a pure module */
