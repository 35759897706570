// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Grid from "../atom/Atom_Grid.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../type/Type_Teaser.res.mjs";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Template_Label from "./template/Template_Label.res.mjs";
import * as Template_Player from "./template/Template_Player.res.mjs";
import * as Layout_Constants from "./Layout_Constants.res.mjs";
import * as Template_PlayNext from "./template/Template_PlayNext.res.mjs";
import * as Type_Page_Program from "../../type/Type_Page_Program.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_FavAlertButton from "../atom/Atom_FavAlertButton.res.mjs";
import * as Organism_Slider_Teaser from "../organism/slider/teaser/Organism_Slider_Teaser.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";
import * as Organism_Header_Subheaders from "../organism/header/Organism_Header_Subheaders.res.mjs";
import * as Organism_Video_Content_Presse from "../organism/slider/Organism_Video_Content_Presse.res.mjs";
import * as Template_ProgramContent_Metas from "./template/program/Template_ProgramContent_Metas.res.mjs";
import * as Molecule_ContentCollection_Css from "../molecule/Molecule_ContentCollection_Css.res.mjs";
import * as Template_ProgramContent_VodDvd from "./template/program/Template_ProgramContent_VodDvd.res.mjs";
import * as Template_ProgramContent_Heading from "./template/program/Template_ProgramContent_Heading.res.mjs";
import * as Template_ProgramContent_Chapters from "./template/program/Template_ProgramContent_Chapters.res.mjs";
import * as Template_ProgramContent_Description from "./template/program/Template_ProgramContent_Description.res.mjs";
import * as Template_ProgramContent_FirstBroadcast from "./template/program/Template_ProgramContent_FirstBroadcast.res.mjs";
import * as Template_ProgramContent_TechnicalInfos from "./template/program/Template_ProgramContent_TechnicalInfos.res.mjs";

function getBlockSpace(mobileOpt, desktopOpt, param) {
  var mobile = mobileOpt !== undefined ? Caml_option.valFromOption(mobileOpt) : Particle_Spacer_Constants.Zone.mobile;
  var desktop = desktopOpt !== undefined ? Caml_option.valFromOption(desktopOpt) : Particle_Spacer_Constants.Zone.desktop;
  return Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(desktop), undefined, undefined, mobile);
}

var defaultBlockSpace = getBlockSpace(undefined, undefined, undefined);

var blockSpace = getBlockSpace(Caml_option.some(Particle_Spacer_Constants.ProgramPage.PlayNext.mobile), Caml_option.some(Particle_Spacer_Constants.ProgramPage.PlayNext.desktop), undefined);

function Layout_Program$TemplatePlayNext(props) {
  var zone = props.zone;
  if (zone !== undefined) {
    return JsxRuntime.jsx(Template_PlayNext.make, {
                playedProgramId: props.playedProgramId,
                blockSpace: blockSpace,
                zone: zone,
                onPlayNextChange: props.onPlayNextChange,
                isPlayNextActivated: props.isPlayNextActivated
              });
  } else {
    return null;
  }
}

var TemplatePlayNext = {
  blockSpace: blockSpace,
  make: Layout_Program$TemplatePlayNext
};

var blockSpace$1 = getBlockSpace(Caml_option.some(Particle_Spacer_Constants.ProgramPage.Label.mobile), Caml_option.some(Particle_Spacer_Constants.ProgramPage.Label.desktop), undefined);

function Layout_Program$TemplateLabel(props) {
  var zone = props.zone;
  if (zone !== undefined) {
    return JsxRuntime.jsx(Template_Label.make, {
                blockSpace: blockSpace$1,
                zone: zone.VAL
              });
  } else {
    return null;
  }
}

var TemplateLabel = {
  blockSpace: blockSpace$1,
  make: Layout_Program$TemplateLabel
};

function Layout_Program$TemplatePartner(props) {
  var partnerZone = props.partnerZone;
  if (partnerZone !== undefined) {
    return JsxRuntime.jsx(Organism_Slider_Teaser.make, {
                blockSpace: defaultBlockSpace,
                zone: partnerZone.VAL
              });
  } else {
    return null;
  }
}

var TemplatePartner = {
  make: Layout_Program$TemplatePartner
};

var blockSpaceProgramContent = getBlockSpace(Caml_option.some(Particle_Spacer_Constants.ProgramPage.ProgramContent.mobile), Caml_option.some(Particle_Spacer_Constants.ProgramPage.ProgramContent.desktop), undefined);

function Layout_Program$TemplateProgramContentHeading(props) {
  var program = props.program;
  if (program === undefined) {
    return null;
  }
  var title = Type_Teaser.Helper.extractTitle(program);
  var subtitle = Type_Teaser.Helper.extractSubtitleString(program.subtitle);
  return JsxRuntime.jsx(Atom_Cell.make, {
              children: JsxRuntime.jsx(Template_ProgramContent_Heading.make, {
                    title: title,
                    subtitle: subtitle
                  })
            });
}

var TemplateProgramContentHeading = {
  make: Layout_Program$TemplateProgramContentHeading
};

function Layout_Program$TemplateProgramContentDescription(props) {
  var program = props.program;
  if (program !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_Description.make, {
                teaser: program,
                descriptionBehavior: props.descriptionBehavior
              });
  } else {
    return null;
  }
}

var TemplateProgramContentDescription = {
  make: Layout_Program$TemplateProgramContentDescription
};

function Layout_Program$TemplateProgramContentMetas(props) {
  var meta = props.meta;
  var program = props.program;
  if (meta !== undefined && program !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_Metas.make, {
                teaser: program,
                meta: meta,
                parent: props.parent,
                buttons: props.programContentButtons
              });
  } else {
    return null;
  }
}

var TemplateProgramContentMetas = {
  make: Layout_Program$TemplateProgramContentMetas
};

function Layout_Program$TemplateOtherSliders(props) {
  var zones = props.zones;
  if (zones !== undefined) {
    return Util_Array.NonEmpty.toReactElement(Util_Array.NonEmpty.map(Caml_option.valFromOption(zones), (function (slider) {
                      var zone = slider.VAL;
                      return JsxRuntime.jsx(Organism_Slider_Teaser.make, {
                                  blockSpace: defaultBlockSpace,
                                  zone: zone
                                }, zone.id);
                    })));
  } else {
    return null;
  }
}

var TemplateOtherSliders = {
  make: Layout_Program$TemplateOtherSliders
};

var blockSpace$2 = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeAll("XL")), undefined, Particle_Spacer_Ds.makeNone());

function Layout_Program$TemplateEvent(props) {
  var zone = props.zone;
  if (zone === undefined) {
    return null;
  }
  var match = zone.VAL;
  var match$1 = match.video;
  var textPosition = match.textPosition;
  var teaser = match.teaser;
  if (match$1 !== undefined) {
    if (match$1.TAG === "Clip") {
      return JsxRuntime.jsx(Zone_Layout.EventZone.make, {
                  blockSpace: blockSpace$2,
                  teaser: teaser,
                  textPosition: textPosition,
                  clip: {
                    TAG: "Clip",
                    _0: match$1._0,
                    _1: "Standard"
                  }
                });
    } else {
      return JsxRuntime.jsx(Zone_Layout.EventZone.make, {
                  blockSpace: blockSpace$2,
                  teaser: teaser,
                  textPosition: textPosition,
                  programId: match$1._0
                });
    }
  } else {
    return JsxRuntime.jsx(Zone_Layout.EventZone.make, {
                blockSpace: blockSpace$2,
                teaser: teaser,
                textPosition: textPosition
              });
  }
}

var TemplateEvent = {
  blockSpace: blockSpace$2,
  make: Layout_Program$TemplateEvent
};

function Layout_Program$TemplateChapters(props) {
  var program = props.program;
  if (program === undefined) {
    return null;
  }
  var chapters = Core__Option.map(program.program.chapters, (function (arr) {
          return Util_Array.NonEmpty.toArray(arr);
        }));
  if (chapters !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_Chapters.make, {
                chapters: chapters,
                baseUrl: program.href,
                parentPage: program.parentPage,
                onChapterClick: props.onChapterClick
              });
  } else {
    return null;
  }
}

var TemplateChapters = {
  make: Layout_Program$TemplateChapters
};

function Layout_Program$TemplateTechnicalInfos(props) {
  var metas = Core__Option.flatMap(props.metas, (function (__x) {
          return Util_Array.NonEmpty.makeFromArray(__x);
        }));
  if (metas !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_TechnicalInfos.make, {
                metas: Caml_option.valFromOption(metas)
              });
  } else {
    return null;
  }
}

var TemplateTechnicalInfos = {
  make: Layout_Program$TemplateTechnicalInfos
};

var gap = Molecule_ContentCollection_Css.ContentButtons.gap;

function Layout_Program$TemplateButtons(props) {
  var program = props.program;
  if (program === undefined) {
    return null;
  }
  var favoriteAvailability = Atom_FavAlertButton.availabilityFromTeaserFavorite(program.favoriteStatus);
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfStart),
              children: [
                props.buttons,
                JsxRuntime.jsx(Atom_FavAlertButton.make, {
                      teaser: program,
                      styleVariation: "Program",
                      availability: favoriteAvailability
                    })
              ],
              gap: Caml_option.some(gap)
            });
}

var TemplateButtons = {
  gap: gap,
  make: Layout_Program$TemplateButtons
};

var sectionBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeTop("L")), undefined, Particle_Spacer_Ds.makeTop("M"));

var blockSpace$3 = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("XL")), undefined, Particle_Spacer_Ds.makeBottom("M"));

function Layout_Program$TemplateTags(props) {
  var parent = props.parent;
  var genre = Core__Option.flatMap(props.program, (function (teaser) {
          return teaser.program.genre;
        }));
  if (parent !== undefined) {
    return JsxRuntime.jsx(Atom_Column.make, {
                blockSpace: Caml_option.some(blockSpace$3),
                children: JsxRuntime.jsx(Template_ProgramContent_Metas.Tags.Links.make, {
                      parent: parent,
                      genre: genre
                    })
              });
  } else {
    return null;
  }
}

var TemplateTags = {
  blockSpace: blockSpace$3,
  make: Layout_Program$TemplateTags
};

function Layout_Program$TemplateVodDvd(props) {
  var program = props.program;
  if (program !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_VodDvd.make, {
                shopUrl: program.shopUrl
              });
  } else {
    return null;
  }
}

var TemplateVodDvd = {
  make: Layout_Program$TemplateVodDvd
};

function Layout_Program$TemplateFirstBroadcast(props) {
  var program = props.program;
  if (program === undefined) {
    return null;
  }
  var match = program.program;
  return JsxRuntime.jsx(Template_ProgramContent_FirstBroadcast.make, {
              programKind: match.kind,
              value: match.firstBroadcastDate
            });
}

var TemplateFirstBroadcast = {
  make: Layout_Program$TemplateFirstBroadcast
};

var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.margin("auto")]);

var blockSpace$4 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("S"));

var boxDirection = Particle_Screen_Ds.make(undefined, undefined, undefined, "row", undefined, "column");

var gridRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [
      CssJs.display("grid"),
      CssJs.gridTemplateColumns([{
              NAME: "repeat",
              VAL: [
                {
                  NAME: "num",
                  VAL: 3
                },
                CssJs.fr(1)
              ]
            }]),
      CssJs.gridTemplateAreas({
            NAME: "areas",
            VAL: [
              "description description metas",
              "description description metas"
            ]
          })
    ], undefined, [
      CssJs.gridTemplateColumns([CssJs.fr(1)]),
      CssJs.gridAutoRows("auto"),
      CssJs.gridTemplateAreas({
            NAME: "areas",
            VAL: [
              "description",
              "metas"
            ]
          })
    ]);

var gridDescriptionRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.gridArea({
            NAME: "ident",
            VAL: "description"
          })]);

var descriptionBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeRight("XL")), undefined, Particle_Spacer_Ds.makeNone());

var gridMetaRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.gridArea({
            NAME: "ident",
            VAL: "metas"
          })]);

var metaBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeTop("XL")), Caml_option.some(Particle_Spacer_Ds.makeTop("Zero")), undefined, Particle_Spacer_Ds.makeTop("M"));

var technicalInfosRowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.gap2(Particle_Css.spacerSizeInside("S"), "normal")]);

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Layout_Program(props) {
  var __geoblockingMode = props.geoblockingMode;
  var layout = props.layout;
  var onChapterClick = props.onChapterClick;
  var geoblockingMode = __geoblockingMode !== undefined ? __geoblockingMode : "Available";
  var ffImproveComWithUser = Context.Config.getFeatureFlippingStatus("ImproveComWithUser");
  var match = Type_Page_Program.programContent(layout);
  var match$1;
  if (match !== undefined) {
    var match$2 = match.VAL;
    match$1 = [
      match$2[0],
      match$2[1],
      match$2[2]
    ];
  } else {
    match$1 = [
      undefined,
      undefined,
      "Auto"
    ];
  }
  var descriptionBehavior = match$1[2];
  var parent = match$1[1];
  var program = match$1[0];
  var ctas = Type_Page_Program.ctas(layout);
  var ctaButtons = ctas !== undefined ? Util_Array.NonEmpty.toArray(Caml_option.valFromOption(ctas)).map(function (param) {
          var identifier = param.identifier;
          var kind = param.kind;
          var label = param.label;
          if (kind.TAG === "Href") {
            return Caml_option.some(JsxRuntime.jsx(Atom_Cta.Link.make, {
                            href: Type_String.Href.toString(kind.href),
                            identifier: Caml_option.some(identifier),
                            label: label,
                            style: kind.style,
                            target: kind.target
                          }, Identifiers.toString(identifier)));
          }
          var onClick = kind._0;
          return Caml_option.some(JsxRuntime.jsx(Atom_Cta.Button.make, {
                          identifier: Caml_option.some(identifier),
                          label: label,
                          onClick: (function (param) {
                              onClick();
                            }),
                          style: kind._1
                        }, Identifiers.toString(identifier)));
        }) : [];
  var programContentButtons = Util_Array.catOption(ctaButtons);
  var download = Type_Page_Program.download(layout);
  var tmp;
  tmp = ffImproveComWithUser === "On" && geoblockingMode !== "Available" ? JsxRuntime.jsx(Organism_Header_Subheaders.Geoblocked.make, {}) : null;
  var tmp$1;
  tmp$1 = ffImproveComWithUser === "On" ? JsxRuntime.jsxs(Atom_Column.make, {
          children: [
            JsxRuntime.jsx(Layout_Program$TemplateProgramContentHeading, {
                  program: program
                }),
            JsxRuntime.jsx(Layout_Program$TemplateFirstBroadcast, {
                  program: program
                }),
            JsxRuntime.jsxs(Atom_Column.make, {
                  blockSpace: Caml_option.some(sectionBlockspace),
                  children: [
                    JsxRuntime.jsx(Layout_Program$TemplateButtons, {
                          buttons: programContentButtons,
                          program: program
                        }),
                    JsxRuntime.jsx(Layout_Program$TemplateTechnicalInfos, {
                          metas: Type_Page_Program.meta(layout)
                        })
                  ],
                  innerSpace: Caml_option.some(innerSpace),
                  rules: Caml_option.some(technicalInfosRowRules)
                }),
            JsxRuntime.jsx(Layout_Program$TemplateTags, {
                  parent: parent,
                  program: program
                }),
            JsxRuntime.jsx(Layout_Program$TemplateProgramContentDescription, {
                  program: program,
                  descriptionBehavior: descriptionBehavior
                }),
            JsxRuntime.jsx(Layout_Program$TemplateChapters, {
                  program: program,
                  onChapterClick: onChapterClick
                }),
            JsxRuntime.jsx(Layout_Program$TemplateVodDvd, {
                  program: program
                }),
            JsxRuntime.jsx(Layout_Program$TemplateLabel, {
                  zone: Type_Page_Program.label(layout)
                })
          ],
          width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
        }) : JsxRuntime.jsxs(Atom_Grid.make, {
          children: [
            JsxRuntime.jsxs(Atom_Column.make, {
                  blockSpace: Caml_option.some(descriptionBlockSpace),
                  children: [
                    JsxRuntime.jsx(Layout_Program$TemplateProgramContentDescription, {
                          program: program,
                          descriptionBehavior: descriptionBehavior
                        }),
                    JsxRuntime.jsx(Layout_Program$TemplateChapters, {
                          program: program,
                          onChapterClick: onChapterClick
                        }),
                    JsxRuntime.jsx(Layout_Program$TemplateLabel, {
                          zone: Type_Page_Program.label(layout)
                        })
                  ],
                  rules: Caml_option.some(gridDescriptionRules),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                }),
            JsxRuntime.jsx(Atom_Column.make, {
                  blockSpace: Caml_option.some(metaBlockSpace),
                  children: JsxRuntime.jsx(Layout_Program$TemplateProgramContentMetas, {
                        program: program,
                        meta: Type_Page_Program.meta(layout),
                        parent: parent,
                        programContentButtons: programContentButtons
                      }),
                  rules: Caml_option.some(gridMetaRules),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                })
          ],
          rules: Caml_option.some(gridRules)
        });
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(blockSpace$4),
              children: [
                tmp,
                JsxRuntime.jsx(Template_Player.make, {
                      player: props.player
                    }),
                JsxRuntime.jsx(Layout_Program$TemplateEvent, {
                      zone: Type_Page_Program.$$event(layout)
                    }),
                JsxRuntime.jsx(Layout_Program$TemplatePlayNext, {
                      zone: Type_Page_Program.playNext(layout),
                      playedProgramId: props.playedProgramId,
                      onPlayNextChange: props.onPlayNextChange,
                      isPlayNextActivated: props.isPlayNextActivated
                    }),
                JsxRuntime.jsx(Atom_Box.make, {
                      children: Caml_option.some(tmp$1),
                      direction: Caml_option.some(boxDirection),
                      spacer: Caml_option.some(blockSpaceProgramContent),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    }),
                JsxRuntime.jsx(Layout_Program$TemplatePartner, {
                      partnerZone: Type_Page_Program.partner(layout)
                    }),
                JsxRuntime.jsx(Layout_Program$TemplateOtherSliders, {
                      zones: Type_Page_Program.sliders(layout)
                    }),
                download !== undefined ? JsxRuntime.jsx(Atom_Box.make, {
                        children: Caml_option.some(JsxRuntime.jsx(Organism_Video_Content_Presse.make, {
                                  title: download.title,
                                  id: download.id,
                                  items: {
                                    TAG: "Download",
                                    _0: download.items
                                  },
                                  href: download.href
                                })),
                        direction: Caml_option.some(boxDirection),
                        spacer: Caml_option.some(blockSpaceProgramContent),
                        width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                      }) : null
              ],
              kind: "Main",
              maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
              rules: Caml_option.some(rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var make = Layout_Program;

export {
  getBlockSpace ,
  defaultBlockSpace ,
  TemplatePlayNext ,
  TemplateLabel ,
  TemplatePartner ,
  blockSpaceProgramContent ,
  TemplateProgramContentHeading ,
  TemplateProgramContentDescription ,
  TemplateProgramContentMetas ,
  TemplateOtherSliders ,
  TemplateEvent ,
  TemplateChapters ,
  TemplateTechnicalInfos ,
  TemplateButtons ,
  sectionBlockspace ,
  TemplateTags ,
  TemplateVodDvd ,
  TemplateFirstBroadcast ,
  rules ,
  blockSpace$4 as blockSpace,
  boxDirection ,
  gridRules ,
  gridDescriptionRules ,
  descriptionBlockSpace ,
  gridMetaRules ,
  metaBlockSpace ,
  technicalInfosRowRules ,
  innerSpace ,
  make ,
}
/* defaultBlockSpace Not a pure module */
