// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Image from "../../type/Type_Image.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Belt_MutableMapString from "@rescript/std/lib/es6/belt_MutableMapString.js";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";

function styleToString(style) {
  if (typeof style !== "object") {
    switch (style) {
      case "AndroidReleases" :
          return "android-releases";
      case "Banner" :
          return "banner";
      case "Broadcast" :
          return "broadcast";
      case "BigTeaser" :
          return "big-teaser";
      case "Contained" :
          return "contained";
      case "ContentCollection" :
          return "content-collection";
      case "EventZone" :
          return "event-zone";
      case "Newsletter" :
          return "newsletter";
      case "NotFoundPage" :
          return "not-found-page";
      case "PapaLandscape" :
          return "papa-landscape";
      case "Partner" :
          return "partner";
      case "PortraitTeaser" :
          return "portrait-teaser";
      case "PosterTeaser" :
          return "poster-teaser";
      case "PresseRcBanner" :
          return "presse-rc-banner";
      case "PresseListItem" :
          return "press-list-item";
      case "PresseTopTeaser" :
          return "press-top-teaser";
      case "PromotionalLogo" :
          return "promotional-logo";
      case "Square" :
          return "square";
      case "StandardTeaser" :
          return "standard-teaser";
      case "ThumbnailLandscape" :
          return "thumbnail-landscape";
      case "TopTeaser" :
          return "top-teaser";
      case "PaginatedTeaser" :
          return "paginated-teaser";
      
    }
  } else {
    switch (style.TAG) {
      case "Avatar" :
          var tmp;
          var tmp$1 = style._0;
          if (typeof tmp$1 !== "object") {
            switch (tmp$1) {
              case "Icon" :
                  tmp = "icon";
                  break;
              case "Card" :
                  tmp = "card";
                  break;
              case "UserPanel" :
                  tmp = "user-panel";
                  break;
              
            }
          } else {
            tmp = "list";
          }
          return "avatar-" + tmp;
      case "DangerousHtml" :
          return "dangerousHtml_android-releases";
      case "FromCdn" :
          var a = style._0;
          var tmp$2;
          if (typeof a !== "object") {
            switch (a) {
              case "AfterRightVOD" :
                  tmp$2 = "after-right-vod";
                  break;
              case "Background500" :
                  tmp$2 = "background-500";
                  break;
              case "Error500" :
                  tmp$2 = "Error500";
                  break;
              case "EuropeanFlag" :
                  tmp$2 = "european-flag";
                  break;
              case "NewsletterZone" :
                  tmp$2 = "newsletter-zone";
                  break;
              case "QrCode" :
                  tmp$2 = "qr-code";
                  break;
              
            }
          } else {
            switch (a.TAG) {
              case "Badge" :
                  tmp$2 = "badge";
                  break;
              case "BuyProgram" :
                  var tmp$3;
                  tmp$3 = a._0 === "Available" ? "available" : "not-available";
                  tmp$2 = "BuyProgram" + tmp$3;
                  break;
              case "Newsletter" :
                  tmp$2 = "newsletter";
                  break;
              
            }
          }
          return "from-cdn-" + tmp$2;
      case "TeaserGuide" :
          var tmp$4;
          tmp$4 = style._0 === "Standard" ? "standard" : "big";
          return "teaser-guide-" + tmp$4;
      case "TeaserHover" :
          var tmp$5;
          switch (style._0) {
            case "Portrait" :
                tmp$5 = "portrait";
                break;
            case "Standard" :
                tmp$5 = "strandard";
                break;
            case "Big" :
                tmp$5 = "big";
                break;
            
          }
          return "teaser-hover-" + tmp$5;
      case "WithCaption" :
          return ;
      
    }
  }
}

function getRatio(display) {
  if (typeof display !== "object") {
    return ;
  } else {
    return display._0.ratio;
  }
}

function makeMainStruct(imageKind) {
  var exit = 0;
  if (typeof imageKind !== "object") {
    switch (imageKind) {
      case "AndroidReleases" :
          return {
                  gradient: "LeftToRight",
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1600,
                          minHeight: 180,
                          ratio: "Unknow"
                        }
                      })
                };
      case "Banner" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 767,
                          minHeight: 120,
                          ratio: "R4_1"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1220,
                          minHeight: 192,
                          ratio: "R4_1"
                        }
                      }, undefined, undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 479,
                          minHeight: 80,
                          ratio: "R4_1"
                        }
                      })
                };
      case "Broadcast" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 170,
                          height: 96,
                          ratio: "R16_9"
                        }
                      }, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 114,
                          height: 64,
                          ratio: "R16_9"
                        }
                      })
                };
      case "BigTeaser" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 325,
                          height: 183,
                          ratio: "R16_9"
                        }
                      }, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 160,
                          height: 90,
                          ratio: "R16_9"
                        }
                      }),
                  loadMethod: "eager"
                };
      case "Contained" :
          return {
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "FullPlace",
                        _0: {
                          maxWidth: 1920,
                          ratio: "R16_9"
                        }
                      })
                };
      case "ContentCollection" :
          return {
                  gradient: {
                    TAG: "BottomAndLeft",
                    _0: undefined
                  },
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 767,
                          minHeight: 270,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 840,
                          minHeight: 303,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1220,
                          minHeight: 473,
                          ratio: "R16_9"
                        }
                      }, undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 479,
                          minHeight: 180,
                          ratio: "R16_9"
                        }
                      }),
                  loadMethod: "eager"
                };
      case "EventZone" :
          return {
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 768,
                          minHeight: 253,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 540,
                          minHeight: 180,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 660,
                          minHeight: 305,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 800,
                          minHeight: 372,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 480,
                          minHeight: 180,
                          ratio: "R16_9"
                        }
                      })
                };
      case "Newsletter" :
          return {
                  background: Caml_option.some(Particle_Background_Ds.transparent),
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 650,
                          ratio: "R16_9"
                        }
                      }, undefined, undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 495,
                          ratio: "R16_9"
                        }
                      })
                };
      case "NotFoundPage" :
          return {
                  gradient: {
                    TAG: "BottomAndLeft",
                    _0: undefined
                  },
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 768,
                          minHeight: 253,
                          ratio: "R1_1"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 804,
                          minHeight: 283,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 973,
                          minHeight: 452,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1121,
                          minHeight: 547,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 450,
                          minHeight: 320,
                          ratio: "R1_1"
                        }
                      })
                };
      case "Partner" :
          return {
                  color: "Background",
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 125,
                          height: 70,
                          ratio: "R16_9"
                        }
                      })
                };
      case "PortraitTeaser" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 235,
                          height: 352,
                          ratio: "R2_3"
                        }
                      }, {
                        TAG: "Fixed",
                        _0: {
                          width: 265,
                          height: 397,
                          ratio: "R2_3"
                        }
                      }, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 128,
                          height: 192,
                          ratio: "R2_3"
                        }
                      })
                };
      case "PosterTeaser" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 150,
                          height: 200,
                          ratio: "R3_4"
                        }
                      }, {
                        TAG: "Fixed",
                        _0: {
                          width: 189,
                          height: 252,
                          ratio: "R3_4"
                        }
                      }, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 132,
                          height: 176,
                          ratio: "R3_4"
                        }
                      })
                };
      case "PapaLandscape" :
      case "PresseRcBanner" :
          exit = 1;
          break;
      case "PresseListItem" :
          return {
                  display: Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 250,
                          height: 149,
                          ratio: "R16_9"
                        }
                      }, undefined, undefined, {
                        TAG: "FullPlace",
                        _0: {
                          maxWidth: 768,
                          minHeight: 20,
                          ratio: "R16_9"
                        }
                      })
                };
      case "PresseTopTeaser" :
          return {
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Auto")
                };
      case "PromotionalLogo" :
          return {
                  display: Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 42,
                          height: 42,
                          ratio: "R1_1"
                        }
                      }, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 26,
                          height: 26,
                          ratio: "R1_1"
                        }
                      })
                };
      case "Square" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 190,
                          height: 190,
                          ratio: "R1_1"
                        }
                      }, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 125,
                          height: 125,
                          ratio: "R1_1"
                        }
                      })
                };
      case "StandardTeaser" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 265,
                          height: 149,
                          ratio: "R16_9"
                        }
                      }, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 128,
                          height: 72,
                          ratio: "R16_9"
                        }
                      })
                };
      case "ThumbnailLandscape" :
          return {
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        TAG: "Fixed",
                        _0: {
                          width: 135,
                          height: 75,
                          ratio: "R16_9"
                        }
                      })
                };
      case "TopTeaser" :
          return {
                  gradient: {
                    TAG: "BottomAndLeft",
                    _0: undefined
                  },
                  presentation: "Cover",
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 768,
                          minHeight: 480,
                          ratio: "R1_1"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 840,
                          minHeight: 303,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1008,
                          minHeight: 473,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 1121,
                          minHeight: 568,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 480,
                          minHeight: 320,
                          ratio: "R1_1"
                        }
                      }),
                  loadMethod: "eager"
                };
      case "PaginatedTeaser" :
          return {
                  display: Particle_Screen_Ds.make(undefined, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 352,
                          minHeight: 117,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 343,
                          minHeight: 112,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 305,
                          minHeight: 141,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 276,
                          minHeight: 137,
                          ratio: "R16_9"
                        }
                      }, {
                        TAG: "Fluid",
                        _0: {
                          maxWidth: 208,
                          minHeight: 70,
                          ratio: "R16_9"
                        }
                      })
                };
      
    }
  } else {
    switch (imageKind.TAG) {
      case "Avatar" :
          var tmp = imageKind._0;
          if (typeof tmp === "object") {
            return {
                    display: Particle_Screen_Ds.make(undefined, undefined, {
                          TAG: "Fixed",
                          _0: {
                            width: 195,
                            height: 195,
                            ratio: "R1_1"
                          }
                        }, undefined, undefined, {
                          TAG: "Fixed",
                          _0: {
                            width: 100,
                            height: 100,
                            ratio: "R1_1"
                          }
                        }),
                    mask: "Circle"
                  };
          }
          switch (tmp) {
            case "Icon" :
                return {
                        display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 30,
                                height: 30,
                                ratio: "R1_1"
                              }
                            }),
                        mask: "Circle"
                      };
            case "Card" :
                return {
                        display: Particle_Screen_Ds.make(undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 300,
                                height: 300,
                                ratio: "R1_1"
                              }
                            }, undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 100,
                                height: 100,
                                ratio: "R1_1"
                              }
                            }),
                        mask: "Circle"
                      };
            case "UserPanel" :
                return {
                        display: Particle_Screen_Ds.make(undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 60,
                                height: 60,
                                ratio: "R1_1"
                              }
                            }, undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 60,
                                height: 60,
                                ratio: "R1_1"
                              }
                            }),
                        mask: "Circle"
                      };
            
          }
      case "DangerousHtml" :
          return {
                  display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Auto")
                };
      case "FromCdn" :
          var tmp$1 = imageKind._0;
          if (typeof tmp$1 !== "object") {
            switch (tmp$1) {
              case "AfterRightVOD" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fixed",
                                _0: {
                                  width: 126,
                                  height: 91,
                                  ratio: "Unknow"
                                }
                              })
                        };
              case "Background500" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fluid",
                                _0: {
                                  maxWidth: 1920,
                                  ratio: "R16_9"
                                }
                              })
                        };
              case "Error500" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Auto")
                        };
              case "EuropeanFlag" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fixed",
                                _0: {
                                  width: 166,
                                  height: 56,
                                  ratio: "Unknow"
                                }
                              })
                        };
              case "NewsletterZone" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fluid",
                                _0: {
                                  maxWidth: 650,
                                  ratio: "Unknow"
                                }
                              })
                        };
              case "QrCode" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fixed",
                                _0: {
                                  width: 126,
                                  height: 126,
                                  ratio: "R1_1"
                                }
                              })
                        };
              
            }
          } else {
            switch (tmp$1.TAG) {
              case "Badge" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fixed",
                                _0: {
                                  width: 130,
                                  height: 40,
                                  ratio: "Unknow"
                                }
                              })
                        };
              case "BuyProgram" :
                  return {
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fixed",
                                _0: {
                                  width: 159,
                                  height: 111,
                                  ratio: "Unknow"
                                }
                              })
                        };
              case "Newsletter" :
                  return {
                          presentation: "Cover",
                          display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                TAG: "Fluid",
                                _0: {
                                  maxWidth: 650,
                                  ratio: "Unknow"
                                }
                              })
                        };
              
            }
          }
      case "TeaserGuide" :
          if (imageKind._0 === "Standard") {
            return {
                    display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 170,
                            minHeight: 96,
                            ratio: "R16_9"
                          }
                        })
                  };
          } else {
            return {
                    display: Particle_Screen_Ds.make(undefined, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 672,
                            minHeight: 217,
                            ratio: "R16_9"
                          }
                        }, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 265,
                            minHeight: 149,
                            ratio: "R16_9"
                          }
                        }, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 380,
                            minHeight: 168,
                            ratio: "R16_9"
                          }
                        }, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 400,
                            minHeight: 214,
                            ratio: "R16_9"
                          }
                        }, {
                          TAG: "FullPlace",
                          _0: {
                            maxWidth: 384,
                            minHeight: 150,
                            ratio: "R16_9"
                          }
                        })
                  };
          }
      case "TeaserHover" :
          switch (imageKind._0) {
            case "Portrait" :
            case "Standard" :
                exit = 2;
                break;
            case "Big" :
                return {
                        display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                              TAG: "Fixed",
                              _0: {
                                width: 410,
                                height: 232,
                                ratio: "R16_9"
                              }
                            })
                      };
            
          }
          break;
      case "WithCaption" :
          return {
                  display: imageKind._0
                };
      
    }
  }
  switch (exit) {
    case 1 :
        return {
                display: Particle_Screen_Ds.make(undefined, {
                      TAG: "Fluid",
                      _0: {
                        maxWidth: 767,
                        minHeight: 120,
                        ratio: "R4_1"
                      }
                    }, {
                      TAG: "Fluid",
                      _0: {
                        maxWidth: 1220,
                        minHeight: 192,
                        ratio: "R4_1"
                      }
                    }, {
                      TAG: "Fluid",
                      _0: {
                        maxWidth: 1400,
                        minHeight: 300,
                        ratio: "R4_1"
                      }
                    }, {
                      TAG: "Fluid",
                      _0: {
                        maxWidth: 1600,
                        minHeight: 360,
                        ratio: "R4_1"
                      }
                    }, {
                      TAG: "Fluid",
                      _0: {
                        maxWidth: 479,
                        minHeight: 80,
                        ratio: "R4_1"
                      }
                    })
              };
    case 2 :
        return {
                display: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                      TAG: "Fixed",
                      _0: {
                        width: 344,
                        height: 195,
                        ratio: "R16_9"
                      }
                    })
              };
    
  }
}

function getWidth(display) {
  if (typeof display !== "object") {
    return ;
  }
  switch (display.TAG) {
    case "Fixed" :
        return display._0.width;
    case "Fluid" :
    case "FullPlace" :
        return display._0.maxWidth;
    
  }
}

function imageSizeVariationReducer(acc, display) {
  var match = getWidth(display);
  var previousWidth = acc.previousWidth;
  var match$1 = acc.imageSizeVariation;
  if (match !== undefined) {
    switch (match$1) {
      case "Increasing" :
          if (match >= previousWidth) {
            acc.previousWidth = match;
          } else {
            acc.imageSizeVariation = "Random";
          }
          break;
      case "Random" :
          break;
      case "Decreasing" :
          if (match <= previousWidth) {
            acc.previousWidth = match;
          } else {
            acc.imageSizeVariation = "Random";
          }
          break;
      case "Static" :
          if (match > previousWidth) {
            acc.imageSizeVariation = "Increasing";
            acc.previousWidth = match;
          } else if (match < previousWidth) {
            acc.imageSizeVariation = "Decreasing";
            acc.previousWidth = match;
          }
          break;
      
    }
  } else {
    acc.imageSizeVariation = "Random";
  }
  return acc;
}

function getSizeVariation(display) {
  var defaultWidth = getWidth(Particle_Screen_Ds.extractDefaultValue(display));
  if (defaultWidth === undefined) {
    return "Random";
  }
  var initial = {
    previousWidth: defaultWidth,
    imageSizeVariation: "Static"
  };
  return Particle_Screen_Ds.reduce(display, initial, imageSizeVariationReducer).imageSizeVariation;
}

function getGlobalRatio(display) {
  var defaultRatio = getRatio(Particle_Screen_Ds.extractDefaultValue(display));
  if (Particle_Screen_Ds.all(display, (function (d) {
            return getRatio(d) === defaultRatio;
          }))) {
    return "Uniform";
  } else {
    return "DifferentRatio";
  }
}

function computeMinWidth(display) {
  return Particle_Screen_Ds.map(display, (function (display) {
                if (typeof display !== "object") {
                  return ;
                }
                switch (display.TAG) {
                  case "Fixed" :
                      return ;
                  case "Fluid" :
                  case "FullPlace" :
                      break;
                  
                }
                var match = display._0;
                var minHeight = match.minHeight;
                if (minHeight === undefined) {
                  return ;
                }
                var coeff = Type_Image.Ratio.getCoeffFromRatio(match.ratio);
                if (coeff !== undefined) {
                  return minHeight * coeff | 0;
                }
                
              }));
}

function makeView(param) {
  var display = param.display;
  return {
          background: param.background,
          color: param.color,
          gradient: param.gradient,
          presentation: param.presentation,
          display: display,
          globalRatio: getGlobalRatio(display),
          sizeVariation: getSizeVariation(display),
          minWidth: computeMinWidth(display),
          mask: param.mask,
          loadMethod: Core__Option.getOr(param.loadMethod, "lazy")
        };
}

var imageMap = Belt_MutableMapString.make();

function make(imageKind) {
  var styleStr = styleToString(imageKind);
  if (styleStr === undefined) {
    return {
            TAG: "Dynamic",
            _0: makeMainStruct(imageKind)
          };
  }
  var match = Belt_MutableMapString.get(imageMap, styleStr);
  if (match !== undefined) {
    return {
            TAG: "Static",
            _0: styleStr
          };
  }
  var t = makeMainStruct(imageKind);
  Belt_MutableMapString.set(imageMap, styleStr, [
        t,
        makeView(t)
      ]);
  return {
          TAG: "Static",
          _0: styleStr
        };
}

var defaultMainStruct = makeMainStruct("StandardTeaser");

var defaultView = makeView(defaultMainStruct);

function getMapKey(t) {
  if (t.TAG === "Static") {
    return t._0;
  }
  
}

function view(t) {
  if (t.TAG !== "Static") {
    return makeView(t._0);
  }
  var match = Belt_MutableMapString.get(imageMap, t._0);
  if (match !== undefined) {
    return match[1];
  } else {
    return defaultView;
  }
}

export {
  make ,
  view ,
  getMapKey ,
  getSizeVariation ,
}
/* imageMap Not a pure module */
