'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../provider/Context.res.mjs";
import * as Type_Url from "../type/Type_Url.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";

function make(trackingPixel) {
  var match = Context.Config.getState();
  var trackPixel = match.trackPixel;
  return function (param) {
    if (!(trackPixel == null) && trackingPixel !== undefined) {
      return trackPixel(Type_Url.TrackingPixel.toEncodedString(Caml_option.valFromOption(trackingPixel)));
    }
    
  };
}

export {
  make ,
}
/* Context Not a pure module */
