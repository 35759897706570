import { create } from 'zustand';
import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';
import { env } from '@replay/env-generator';
import { type TrackingEvent } from '@artegeie/arte-vp';
import { type ServerSideTracking } from '@replay/types/Stats';
import { pageViewedEvent, ageVerificationEvent, teaserClickedEvent, playerEvent, userClickedEvent } from './event';
import type {
    AgeVerificationFrontendContext,
    AgeVerificationGroupControlTypes,
    Event,
    UserFrontContext,
} from '@replay/types/Tracking';

type TrackingEventData = {
    queue: Event[];
    forceSend: boolean;
};

const trackingInitialStore: TrackingEventData = {
    forceSend: false,
    queue: [],
};

interface TrackingEvents extends TrackingEventData {
    sendPageview: (stats: ServerSideTracking | undefined) => void;
    sendTeaserClick: (stats: ServerSideTracking | undefined, clickTrackingUrl: string) => void;
    setForceSend: (forceSend: boolean) => void;
    sendPlayerEvent: (
        stats: ServerSideTracking | undefined,
        { action, frontendContextPlayer, apiContextPlayer }: TrackingEvent,
    ) => void;
    sendAgeVerificationEvent: (stats: ServerSideTracking, action: AgeVerificationGroupControlTypes) => void;
    sendUserClickedEvent: (stats: ServerSideTracking | undefined, action: UserFrontContext) => void;
}

const eventsStoreName = 't_queue_replay';
export const eventStore = create<TrackingEvents>()(
    persist(
        subscribeWithSelector(
            devtools(
                (set) => ({
                    ...trackingInitialStore,
                    setForceSend: (forceSend: boolean) => {
                        set({ forceSend });
                    },
                    sendPageview: (stats) => {
                        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING) {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: [...state.queue, pageViewedEvent(stats)],
                                        };
                                    },
                                    false,
                                    'sendPageview',
                                );
                            }
                        }
                    },
                    sendAgeVerificationEvent: (stats, action) => {
                        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING) {
                            const context: AgeVerificationFrontendContext = {
                                action: 'ACTION',
                                eventName: action as Lowercase<AgeVerificationGroupControlTypes>,
                                eventGroup: 'age_verification',
                            };
                            set(
                                (state) => {
                                    return {
                                        queue: [...state.queue, ageVerificationEvent(stats, context)],
                                    };
                                },
                                false,
                                `sendAgeVerificationEvent/${action}`,
                            );
                        }
                    },
                    sendTeaserClick: (stats, clickTrackingUrl) => {
                        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING) {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: [...state.queue, teaserClickedEvent(stats, clickTrackingUrl)],
                                        };
                                    },
                                    false,
                                    'sendTeaserClick',
                                );
                            }
                        }
                    },
                    sendPlayerEvent: (stats, { action, frontendContextPlayer, apiContextPlayer }) => {
                        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING) {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: [
                                                ...state.queue,
                                                playerEvent(stats, action, {
                                                    playerContext: frontendContextPlayer,
                                                    playerApiContext: apiContextPlayer,
                                                }),
                                            ],
                                        };
                                    },
                                    false,
                                    'sendPlayerEvent',
                                );
                            }
                        }
                    },
                    sendUserClickedEvent: (stats, action) => {
                        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING) {
                            if (stats) {
                                set(
                                    (state) => {
                                        return {
                                            queue: [...state.queue, userClickedEvent(stats, action)],
                                        };
                                    },
                                    false,
                                    'sendUserClickedEvent',
                                );
                            }
                        }
                    },
                }),
                { name: eventsStoreName, enabled: global.window && env.NODE_ENV === 'development' },
            ),
        ),
        { name: eventsStoreName },
    ),
);
