// Generated by ReScript, PLEASE EDIT WITH CARE


function envToString(env) {
  if (env === "preprod") {
    return "-preprod";
  } else {
    return "";
  }
}

export {
  envToString ,
}
/* No side effect */
