// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Type_Image from "../../type/Type_Image.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../type/Type_Avatar.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_Avatar_Ds from "./Molecule_Avatar_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";

var imageRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.maxHeight({
            NAME: "percent",
            VAL: 100
          })]);

var iconRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.maxHeight({
            NAME: "percent",
            VAL: 100
          }),
      CssJs.padding({
            NAME: "px",
            VAL: 2
          })
    ]);

function make(param) {
  var style = param.style;
  var avatar = param.avatar;
  var selected = Core__Option.getOr(param.selected, "NotSelected");
  var match = Context.I18n.getState();
  var match$1 = Molecule_Avatar_Ds.make(style);
  var border = match$1.border;
  var src = Type_Image.Src.makeResolution(avatar.images);
  var alt = Type_Avatar.getAvatarTitleByLocale(match.locale, avatar, style);
  var accessibility;
  accessibility = selected === "Selected" ? Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "true", undefined, undefined, undefined, undefined) : undefined;
  var imageRules$1;
  if (typeof style !== "object") {
    switch (style) {
      case "Card" :
          imageRules$1 = imageRules;
          break;
      case "Icon" :
      case "UserPanel" :
          imageRules$1 = iconRules;
          break;
      
    }
  } else {
    imageRules$1 = imageRules;
  }
  var content;
  content = typeof style !== "object" ? JsxRuntime.jsx(Atom_Image.make, {
          alt: alt,
          border: border,
          rules: Caml_option.some(imageRules$1),
          src: src,
          style: {
            TAG: "Avatar",
            _0: style
          }
        }) : JsxRuntime.jsx(Atom_Cta.Button.Block.make, {
          accessibility: accessibility,
          children: JsxRuntime.jsx(Atom_Image.make, {
                alt: alt,
                border: border,
                rules: Caml_option.some(imageRules$1),
                src: src,
                style: {
                  TAG: "Avatar",
                  _0: style
                }
              }),
          onClick: param.onClick,
          style: Atom_Cta_Ds.avatar
        });
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: match$1.blockSpace,
              width: Caml_option.some(match$1.width),
              height: Caml_option.some(match$1.height),
              children: content
            });
}

export {
  imageRules ,
  iconRules ,
  make ,
}
/* imageRules Not a pure module */
