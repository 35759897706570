'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../user/User.res.mjs";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as User_Type from "../user/User_Type.res.mjs";
import * as User_Api__Sso__LastVieweds from "../user/User_Api/User_Api__Sso__LastVieweds.res.mjs";

function make(minInterval) {
  var controller = new AbortController();
  var signal = controller.signal;
  var match = Context.I18n.getState();
  var locale = match.locale;
  React.useEffect((function () {
          var intervalId = setInterval((function (extra) {
                  var localTeasers = User_Type.getLocalProgressions(User.get());
                  if (localTeasers.length !== 0) {
                    User_Api__Sso__LastVieweds.setBatch({
                            args: {
                              programs: localTeasers,
                              language: locale
                            },
                            signal: signal,
                            locale: locale
                          }).then(function (result) {
                          if (result.TAG === "Ok") {
                            return User.set(User_Type.setAllProgressionRemote(User.get()));
                          }
                          
                        });
                    return ;
                  }
                  
                }), Math.imul(Math.imul(minInterval, 60), 1000));
          return (function () {
                    clearInterval(intervalId);
                  });
        }), [
        minInterval,
        locale
      ]);
}

export {
  make ,
}
/* User Not a pure module */
