// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function make(param) {
  var get = function (values, field) {
    switch (field) {
      case "Password" :
          return values.password;
      case "PasswordConfirmation" :
          return values.passwordConfirmation;
      case "OldPassword" :
          return values.oldPassword;
      
    }
  };
  var set = function (values, field, value) {
    switch (field) {
      case "Password" :
          return {
                  password: value,
                  passwordConfirmation: values.passwordConfirmation,
                  oldPassword: values.oldPassword
                };
      case "PasswordConfirmation" :
          return {
                  password: values.password,
                  passwordConfirmation: value,
                  oldPassword: values.oldPassword
                };
      case "OldPassword" :
          return {
                  password: values.password,
                  passwordConfirmation: values.passwordConfirmation,
                  oldPassword: value
                };
      
    }
  };
  var Form = UseForm.Make({
        set: set,
        get: get
      });
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.common;
  var match$2 = labels.mona.editPassword;
  var userOldPasswordPlaceholder = match$2.oldPasswordPlaceholder;
  var userNewPasswordPlaceholderConfirmation = match$2.newPasswordPlaceholderConfirmation;
  var userNewPasswordPlaceholder = match$2.newPasswordPlaceholder;
  var match$3 = labels.mona.editEmail;
  var userErrorGeneric = match$3.errorGeneric;
  var match$4 = labels.formValidation;
  var match$5 = labels.mona.editPassword;
  var userEditPasswordSuccess = match$5.success;
  var match$6 = UseUserFetch.make();
  var setSuccess = match$6.setSuccess;
  var setError = match$6.setError;
  var setLoading = match$6.setLoading;
  var setResponse = function (t) {
    if (t.TAG === "Ok") {
      return setSuccess(userEditPasswordSuccess);
    }
    setError(userErrorGeneric);
  };
  var schema = Form.Schema.Validation.schema([
        Form.Schema.Validation.nonEmpty("Password"),
        Form.Schema.Validation.string(6, undefined, undefined, undefined, "Password"),
        Form.Schema.Validation.nonEmpty("PasswordConfirmation"),
        Form.Schema.Validation.string(6, undefined, undefined, undefined, "PasswordConfirmation"),
        Form.Schema.Validation.password("Password"),
        Form.Schema.Validation.password("PasswordConfirmation"),
        Form.Schema.Validation.confirmation(match$4.passwordConfirmation, "Password", "PasswordConfirmation"),
        Form.Schema.Validation.nonEmpty("OldPassword")
      ]);
  var form = Form.use({
        password: "",
        passwordConfirmation: "",
        oldPassword: ""
      }, schema, (function (__x) {
          var match = __x.state.values;
          var controller = new AbortController();
          var signal = controller.signal;
          setLoading();
          User_Api__Sso__Me.Password.update({
                  args: {
                    oldpassword: match.oldPassword,
                    newpassword: match.password
                  },
                  signal: signal,
                  locale: locale
                }).then(function (response) {
                return setResponse(response);
              });
        }), labels, match.formaters, undefined, undefined);
  var match$7 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsx(Layout_Account.WithAvatar.make, {
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    children: [
                      JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$2.title),
                            kind: "h1",
                            style: Atom_Text_Ds.User.title("MyAccount")
                          }),
                      JsxRuntime.jsx(Atom_Column.make, {
                            children: JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                  autoComplete: "current-password",
                                  type_: "password",
                                  value: match$7.oldPassword,
                                  label: userOldPasswordPlaceholder,
                                  onBlur: (function (__x) {
                                      form.handleChange("OldPassword", __x);
                                    }),
                                  required: true,
                                  id: "oldPassword",
                                  name: userOldPasswordPlaceholder,
                                  errorMessage: form.getFieldError({
                                        TAG: "Field",
                                        _0: "OldPassword"
                                      }),
                                  inputRef: form.getFieldRef({
                                        TAG: "Field",
                                        _0: "OldPassword"
                                      })
                                }),
                            innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                          }),
                      JsxRuntime.jsxs(Atom_Column.make, {
                            blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"))),
                            children: [
                              JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                    autoComplete: "new-password",
                                    type_: "password",
                                    value: match$7.password,
                                    label: userNewPasswordPlaceholder,
                                    onBlur: (function (__x) {
                                        form.handleChange("Password", __x);
                                      }),
                                    required: true,
                                    id: "password",
                                    name: userNewPasswordPlaceholder,
                                    errorMessage: form.getFieldError({
                                          TAG: "Field",
                                          _0: "Password"
                                        }),
                                    inputRef: form.getFieldRef({
                                          TAG: "Field",
                                          _0: "Password"
                                        })
                                  }),
                              JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                    autoComplete: "new-password",
                                    type_: "password",
                                    value: match$7.passwordConfirmation,
                                    label: userNewPasswordPlaceholderConfirmation,
                                    onBlur: (function (__x) {
                                        form.handleChange("PasswordConfirmation", __x);
                                      }),
                                    required: true,
                                    id: "passwordConfirmation",
                                    name: userNewPasswordPlaceholderConfirmation,
                                    errorMessage: form.getFieldError({
                                          TAG: "Field",
                                          _0: "PasswordConfirmation"
                                        }),
                                    inputRef: form.getFieldRef({
                                          TAG: "Field",
                                          _0: "PasswordConfirmation"
                                        })
                                  })
                            ],
                            innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                          }),
                      match$6.component,
                      JsxRuntime.jsx(Atom_Row.make, {
                            children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                  label: match$1.save,
                                  variant: "base",
                                  type_: "submit"
                                })
                          })
                    ],
                    innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
                    kind: {
                      TAG: "Form",
                      _0: onSubmit
                    }
                  })
            });
}

export {
  make ,
}
/* Context Not a pure module */
