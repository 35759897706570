// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Icon from "../../../atom/Atom_Icon.res.mjs";
import * as Atom_Logo from "../../../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../../selector/Identifiers.res.mjs";
import * as Atom_Icon_Ds from "../../../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Provider from "../../../../user/User_Provider.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Organism_Slider_Teaser_MetaInformation$Title(props) {
  var ariaLabelledby = props.ariaLabelledby;
  var theme = props.theme;
  var title = props.title;
  var link = props.link;
  var userStatus = User_Provider.Hook.useUserStatus();
  var icon;
  var tmp = props.method;
  if (typeof tmp !== "object" || tmp.TAG !== "Connected") {
    icon = null;
  } else {
    var exit = 0;
    switch (userStatus) {
      case "Anonymous" :
      case "Connected" :
          exit = 1;
          break;
      case "Disconnected" :
          icon = null;
          break;
      
    }
    if (exit === 1) {
      icon = JsxRuntime.jsx(Atom_Icon.make, {
            icon: Atom_Icon_Ds.user,
            size: iconSize
          });
    }
    
  }
  if (title === undefined) {
    return null;
  }
  if (title.TAG !== "Displayed") {
    return JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                children: title._0,
                kind: "h2"
              });
  }
  var title$1 = title._0;
  var renderTitle = function (style) {
    if (link !== undefined) {
      var href = link.url;
      if (href !== undefined) {
        return JsxRuntime.jsx(Atom_Cta.Link.make, {
                    href: href,
                    id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, ""),
                    identifier: Caml_option.some(Identifiers.Slider_Teaser_MetaInformation.title),
                    label: title$1,
                    labelKind: "h2",
                    style: Atom_Cta_Ds.Slider.makeTitle(style)
                  });
      }
      
    }
    return JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(title$1),
                id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, ""),
                identifier: Caml_option.some(Identifiers.Slider_Teaser_MetaInformation.title),
                kind: "h2",
                style: style
              });
  };
  if (typeof theme === "object") {
    return JsxRuntime.jsxs(Atom_Row.make, {
                alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
                children: [
                  icon,
                  renderTitle(Atom_Text_Ds.Slider.makePlayNext("Title"))
                ],
                innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"))
              });
  } else if (theme === "Afp") {
    return JsxRuntime.jsxs(Atom_Row.make, {
                alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
                children: [
                  renderTitle(Atom_Text_Ds.Slider.makeAfp("Title")),
                  JsxRuntime.jsx(Atom_Logo.make, {
                        logo: "Afp"
                      })
                ],
                innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"))
              });
  } else if (theme === "User" || theme === "Classic" || theme === "Search") {
    return JsxRuntime.jsxs(Atom_Row.make, {
                alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
                children: [
                  icon,
                  renderTitle(Atom_Text_Ds.Slider.make("Title"))
                ],
                innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"))
              });
  } else if (theme === "Info") {
    return JsxRuntime.jsxs(Atom_Row.make, {
                children: [
                  JsxRuntime.jsx(Atom_Column.make, {
                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"))),
                        children: JsxRuntime.jsx(Atom_Logo.make, {
                              logo: {
                                NAME: "ArteVerticalLogo",
                                VAL: "Slider"
                              }
                            })
                      }),
                  renderTitle(Atom_Text_Ds.Slider.makeInfo("Title"))
                ],
                wrap: "OnOneLine"
              });
  } else {
    return JsxRuntime.jsx(Atom_Row.make, {
                alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
                children: renderTitle(Atom_Text_Ds.Slider.makeWhite("Title")),
                innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"))
              });
  }
}

var Title = {
  iconSize: iconSize,
  make: Organism_Slider_Teaser_MetaInformation$Title
};

var descriptionBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

var width = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.pct(45));

function renderDescription(style, description) {
  return Core__Option.mapOr(description, null, (function (desc) {
                return JsxRuntime.jsx(Atom_Text.make, {
                            blockSpace: Caml_option.some(descriptionBlockSpace),
                            children: Caml_option.some(desc),
                            kind: "p",
                            style: style,
                            width: Caml_option.some(width)
                          });
              }));
}

function Organism_Slider_Teaser_MetaInformation$Description(props) {
  var description = props.description;
  var theme = props.theme;
  if (typeof theme === "object") {
    return null;
  }
  if (theme === "Afp") {
    var style = Atom_Text_Ds.Slider.makeAfp("Subtitle");
    return renderDescription(style, description);
  }
  if (theme === "User" || theme === "Classic" || theme === "Search") {
    var style$1 = Atom_Text_Ds.Slider.make("Subtitle");
    return renderDescription(style$1, description);
  }
  if (theme === "Info") {
    var style$2 = Atom_Text_Ds.Slider.makeInfo("Subtitle");
    return renderDescription(style$2, description);
  }
  var style$3 = Atom_Text_Ds.Slider.makeWhite("Subtitle");
  return renderDescription(style$3, description);
}

var Description = {
  descriptionBlockSpace: descriptionBlockSpace,
  width: width,
  renderDescription: renderDescription,
  make: Organism_Slider_Teaser_MetaInformation$Description
};

function Organism_Slider_Teaser_MetaInformation$SeeMore(props) {
  var ariaLabelledby = props.ariaLabelledby;
  var link = props.link;
  var match = Context.I18n.getState();
  var match$1 = match.labels.collection.teaser.more;
  if (link === undefined) {
    return null;
  }
  var href = link.url;
  if (href === undefined) {
    return null;
  }
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(ariaLabelledby), undefined, undefined, undefined, undefined, undefined, undefined);
  var seeMoreLabelId = Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "_seemorelabel");
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              accessibility: accessibility,
              href: href,
              id: seeMoreLabelId,
              identifier: Caml_option.some(Identifiers.Slider_Teaser_MetaInformation.seeMoreLabel),
              label: match$1.label,
              style: Atom_Cta_Ds.Slider.makeSeeMore(props.theme)
            });
}

var SeeMore = {
  make: Organism_Slider_Teaser_MetaInformation$SeeMore
};

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.spaceBetween);

var alignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.flexEnd);

function Organism_Slider_Teaser_MetaInformation(props) {
  var link = props.link;
  var theme = props.theme;
  var autoSize = typeof theme === "object" && theme.NAME === "PlayNext" ? undefined : Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable);
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(props.id);
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(alignItems),
              blockSpace: Caml_option.some(props.blockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      autoSize: autoSize,
                      children: [
                        JsxRuntime.jsx(Organism_Slider_Teaser_MetaInformation$Title, {
                              link: link,
                              title: props.title,
                              theme: theme,
                              method: props.method,
                              ariaLabelledby: ariaLabelledby
                            }),
                        JsxRuntime.jsx(Organism_Slider_Teaser_MetaInformation$Description, {
                              theme: theme,
                              description: props.description,
                              onPlayNextChange: props.onPlayNextChange,
                              isPlayNextActivated: props.isPlayNextActivated
                            })
                      ]
                    }),
                JsxRuntime.jsx(Organism_Slider_Teaser_MetaInformation$SeeMore, {
                      link: link,
                      theme: theme,
                      ariaLabelledby: ariaLabelledby
                    })
              ],
              justifyContent: Caml_option.some(justifyContent),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var width$1 = Particle_Screen_Constants.oneHundredPctWidth;

var make = Organism_Slider_Teaser_MetaInformation;

export {
  width$1 as width,
  Title ,
  Description ,
  SeeMore ,
  justifyContent ,
  alignItems ,
  make ,
}
/* iconSize Not a pure module */
