import { env } from '@replay/env-generator';
import { type ServerSideTracking } from '@replay/types/Stats';
import type {
    AgeVerification,
    AgeVerificationFrontendContext,
    AgeVerificationGroupControlTypes,
    Event,
    EventData,
    PageViewed,
    TeaserClicked,
    UserClicked,
    UserClickGroupControlType,
    UserFrontContext,
    Video,
} from '@replay/types/Tracking';

export const getActionFromEventType = (type: Event['type']): EventData['action'] => {
    switch (type) {
        case 'AGE_VERIFICATION_SUCCEDED':
        case 'AGE_VERIFICATION_FAILED':
        case 'VERIFY_AGE_BUTTON':
        case 'HOME':
        case 'UPCOMING':
        case 'TV_GUIDE':
        case 'LIVE':
        case 'BURGER_MENU':
        case 'ARTE_CONCERT':
        case 'WATCH_CONTENT':
        case 'WATCH_TRAILER':
        case 'ADD_TO_FAVORITES':
        case 'REMOVE_FROM_FAVORITES':
        case 'SEARCH':
        case 'ACT':
        case 'CIN':
        case 'SER':
        case 'DOR':
        case 'CPO':
        case 'ARS':
        case 'SCI':
        case 'DEC':
        case 'HIS':
        case 'CONCERT-GENRES':
        case 'GUIDE_CONCERT':
        case 'SIDACTION':
            return 'CONTROL_CLICKED';
        default:
            return type;
    }
};
export const toApiEvent = (event: Event): EventData => {
    const payload: {
        clickTrackingUrl: string | undefined;
        playerContext: Video['payload']['playerContext'] | undefined;
        playerApiContext: Video['payload']['playerApiContext'] | undefined;
    } = { clickTrackingUrl: undefined, playerContext: undefined, playerApiContext: undefined };
    if (event.type === 'TEASER_CLICKED') {
        payload.clickTrackingUrl = event.payload.clickTrackingUrl;
    }
    if (event.type === 'VIDEO_PAUSED' || event.type === 'VIDEO_PLAYED' || event.type === 'VIDEO_STOPPED') {
        payload.playerContext = event.payload.playerContext;
        payload.playerApiContext = event.payload.playerApiContext;
    }

    const action = getActionFromEventType(event.type);

    return {
        type: event.action,
        action,
        time: event.time,
        referrer: event.referrer || '',
        frontendContext: {
            control: event?.group
                ? { group: event.group.eventGroup, name: event.group.eventName.toLocaleLowerCase() }
                : undefined,
            player: payload.playerContext,
        },
        emacApiContext: {
            ...event.emacApiContext,
            page: {
                ...event.emacApiContext.page,
                // add back base url to page url
                url: `${env.NEXT_PUBLIC_REPLAY_BASE_URL}${event.emacApiContext.page.url}`.replace(/([^:]\/)\/+/g, '$1'),
            },
        },
        playerApiContext: payload.playerApiContext,
        clickTrackingUrl: payload.clickTrackingUrl,
    };
};

export const teaserClickedEvent = (emacApiContext: ServerSideTracking, clickTrackingUrl: string): TeaserClicked => ({
    action: 'ACTION',
    type: 'TEASER_CLICKED',
    time: new Date().toISOString(),
    emacApiContext,
    group: undefined,
    payload: { clickTrackingUrl },
    behavior: 'DELAYED',
});

export const playerEvent = (
    emacApiContext: ServerSideTracking,
    type: Video['type'],
    playerContext: Video['payload'],
): Video => ({
    type: type,
    action: 'PLAYBACK',
    emacApiContext,
    time: new Date().toISOString(),
    group: undefined,
    payload: playerContext,
    behavior: 'IMMEDIATE',
});

export const ageVerificationEvent = (
    emacApiContext: ServerSideTracking,
    ageVerificationType: AgeVerificationFrontendContext,
): AgeVerification => {
    const ageVerification = ageVerificationType.eventName.toUpperCase() as AgeVerificationGroupControlTypes;
    const behavior = ageVerification === 'VERIFY_AGE_BUTTON' ? 'IMMEDIATE' : 'DELAYED';
    return {
        type: ageVerification,
        emacApiContext,
        referrer: document ? document.URL : '',
        action: ageVerificationType.action,
        time: new Date().toISOString(),
        group: ageVerificationType,
        payload: undefined,
        behavior,
    };
};

export const pageViewedEvent = (emacApiContext: ServerSideTracking): PageViewed => ({
    type: 'PAGE_VIEWED',
    emacApiContext,
    action: 'NAVIGATION',
    referrer: document ? document.referrer : '',
    time: new Date().toISOString(),
    group: undefined,
    payload: undefined,
    behavior: 'IMMEDIATE',
});
export const userClickedEvent = (emacApiContext: ServerSideTracking, userclick: UserFrontContext): UserClicked => {
    const userClickEvent = userclick.eventName.toUpperCase();
    const setBehavior = () => {
        switch (userClickEvent) {
            case 'BURGER_MENU':
            case 'ADD_TO_FAVORITES':
            case 'REMOVE_FROM_FAVORITES':
                return 'IMMEDIATE';
            default:
                return 'DELAYED';
        }
    };
    const type = userClickEvent as UserClickGroupControlType;
    return {
        type: type,
        action: userclick.action,
        time: new Date().toISOString(),
        referrer: document ? document.URL : '',
        emacApiContext,
        group: userclick,
        payload: undefined,
        behavior: setBehavior(),
    };
};
