// Generated by ReScript, PLEASE EDIT WITH CARE


function view(t) {
  return t;
}

export {
  view ,
}
/* No side effect */
