'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "./Context.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Util_Config from "../util/Util_Config.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Context_Config from "./Context_Config.res.mjs";
import * as FeatureFlipping from "../FeatureFlipping.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function initialState(playStoreUrl, api, baseUrl, cdnUrl, createVideoElement, featureFlippings, appStoreUrl, trackPixel, trackUserClick, vodStoreHomepageUrl, serverTime, apiRestrictionTTL) {
  return Context.Config.make("Initialized", {
              api: api,
              baseUrl: baseUrl,
              cdnUrl: Type_String.CdnUrl.make(cdnUrl),
              createVideoElement: createVideoElement,
              featureFlippings: featureFlippings,
              trackPixel: trackPixel,
              trackUserClick: trackUserClick,
              vodStoreHomepageUrl: vodStoreHomepageUrl,
              playStoreUrl: playStoreUrl,
              appStoreUrl: appStoreUrl,
              serverTime: serverTime,
              apiRestrictionTTL: apiRestrictionTTL
            });
}

function Provider_Config(props) {
  var serverTime = Core__Option.mapOr(props.serverTime, new Date(), (function (prim) {
          return new Date(prim);
        }));
  var ensuredApi = (function (__x) {
        return Context_Config.ensureApi(__x);
      })(props.api);
  var ensuredVodStoreHomepageUrl = Context_Config.Helpers.ensureVodStoreHomepageUrl(props.vodStoreHomepageUrl);
  var ensuredFeatureFlipping = FeatureFlipping.make(props.featureFlippings);
  var ensuredPlayStoreUrl = Context_Config.Helpers.ensurePlayStoreUrl(props.playStoreUrl);
  var ensuredAppStoreUrl = Context_Config.Helpers.ensureAppStoreUrl(props.appStoreUrl);
  var apiRestrictionTTL = Core__Option.getOr(props.apiRestrictionTTL, 1);
  var state = initialState(ensuredPlayStoreUrl, ensuredApi, props.baseUrl, Core__Option.getOr(props.cdnUrl, Util_Config.latestCdnUrl), props.createVideoElement, ensuredFeatureFlipping, ensuredAppStoreUrl, props.trackPixel, props.trackUserClick, ensuredVodStoreHomepageUrl, serverTime, apiRestrictionTTL);
  return JsxRuntime.jsx(Context.Config.Context.Provider.make, {
              value: state,
              children: props.children
            });
}

var make = Provider_Config;

export {
  initialState ,
  make ,
}
/* Context Not a pure module */
