// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Atom_Backlink from "../atom/Atom_Backlink.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function myVideosKind_encode(v) {
  switch (v) {
    case "MyVideos" :
        return ["MyVideos"];
    case "Favorite" :
        return ["Favorite"];
    case "History" :
        return ["History"];
    
  }
}

function myVideosKind_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Favorite" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Favorite"
                  };
          }
      case "History" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "History"
                  };
          }
      case "MyVideos" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "MyVideos"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

var minHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "vh",
      VAL: 80
    });

var headerBlockspace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "L", "XL", "XL")), undefined, undefined, Particle_Spacer_Ds.make("S", "M", "M", "M"));

function make(param) {
  var match = Context.I18n.getState();
  var formaters = match.formaters;
  var myVideosTitle = match.labels.mona.navigation.myVideosTitle;
  var match$1 = Context.Config.getState();
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "User", param.domain, match$1.serverTime, param.page);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(headerBlockspace),
                      children: [
                        JsxRuntime.jsx(Atom_Backlink.User.make, {}),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(myVideosTitle),
                              kind: "h1",
                              style: Atom_Text_Ds.User.title("MyAccount")
                            })
                      ]
                    }),
                JsxRuntime.jsx(Zone_Layout.make, {
                      page: page,
                      pageKind: "User"
                    })
              ],
              kind: "Section",
              minHeight: Caml_option.some(minHeight)
            });
}

export {
  myVideosKind_encode ,
  myVideosKind_decode ,
  minHeight ,
  headerBlockspace ,
  make ,
}
/* minHeight Not a pure module */
