import { useConnectedToken } from '@artegeie/design-system/replay';
import { useRouter } from 'next/router';
import { isOk } from '@replay/types/Result';
import { fetchJson } from '@replay/fetch';
import { type ServerSideTracking } from '@replay/types/Stats';
import { eventStore } from '@replay/tracking/eventStore';

//TODO: stats shouldn't be optional once all reference provides it
export const useYoti = (stats?: ServerSideTracking, overrideToken?: string) => {
    const userToken = useConnectedToken();
    // const { sendEvent } = useTracking();
    const { sendAgeVerificationEvent } = eventStore();
    const router = useRouter();

    const token = overrideToken || userToken || '';
    const fetchYotiUrl = async (cbUrl: string) => {
        const headers = {
            Authorization: token,
            client: 'web',
            ['Cache-Control']: 'no-cache',
        };
        const searchParams: { cbUrl: string; locale?: string } = router.locale
            ? {
                  cbUrl,
                  locale: router.locale,
              }
            : { cbUrl };
        const res = await fetchJson<{ verificationUrl: string }>(
            `/api/rproxy/yoti?${new URLSearchParams(searchParams)}`,
            {
                requestInit: {
                    method: 'GET',
                    headers,
                },
            },
        );

        if (isOk(res)) {
            return res.value.verificationUrl;
        }
        return null;
    };

    const redirectToYoti = async () => {
        const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
        const redirectUrl = `${origin}/${router.locale}/profile/verification-pending/`;
        if (stats) sendAgeVerificationEvent(stats, 'VERIFY_AGE_BUTTON');
        const url = await fetchYotiUrl(redirectUrl);
        if (url) {
            router.push(url);
        }
    };

    return redirectToYoti;
};
