// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Core__Int from "@rescript/core/src/Core__Int.res.mjs";
import * as Type_Image from "./Type_Image.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Caml_js_exceptions from "@rescript/std/lib/es6/caml_js_exceptions.js";

function titles_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "fr",
                    false,
                    Spice.stringToJson(v.fr)
                  ],
                  [
                    "de",
                    false,
                    Spice.stringToJson(v.de)
                  ],
                  [
                    "en",
                    false,
                    Spice.stringToJson(v.en)
                  ],
                  [
                    "es",
                    false,
                    Spice.stringToJson(v.es)
                  ],
                  [
                    "it",
                    false,
                    Spice.stringToJson(v.it)
                  ],
                  [
                    "pl",
                    false,
                    Spice.stringToJson(v.pl)
                  ]
                ]));
}

function titles_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var fr = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "fr"), null));
  if (fr.TAG === "Ok") {
    var de = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "de"), null));
    if (de.TAG === "Ok") {
      var en = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "en"), null));
      if (en.TAG === "Ok") {
        var es = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "es"), null));
        if (es.TAG === "Ok") {
          var it = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "it"), null));
          if (it.TAG === "Ok") {
            var pl = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pl"), null));
            if (pl.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        fr: fr._0,
                        de: de._0,
                        en: en._0,
                        es: es._0,
                        it: it._0,
                        pl: pl._0
                      }
                    };
            }
            var e = pl._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".pl" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = it._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".it" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = es._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".es" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = en._0;
      return {
              TAG: "Error",
              _0: {
                path: ".en" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = de._0;
    return {
            TAG: "Error",
            _0: {
              path: ".de" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = fr._0;
  return {
          TAG: "Error",
          _0: {
            path: ".fr" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function resolutionSSO_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "w",
                    false,
                    Spice.stringToJson(v.w)
                  ],
                  [
                    "h",
                    false,
                    Spice.stringToJson(v.h)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ]
                ]));
}

function resolutionSSO_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var w = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "w"), null));
  if (w.TAG === "Ok") {
    var h = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "h"), null));
    if (h.TAG === "Ok") {
      var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
      if (url.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  w: w._0,
                  h: h._0,
                  url: url._0
                }
              };
      }
      var e = url._0;
      return {
              TAG: "Error",
              _0: {
                path: ".url" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = h._0;
    return {
            TAG: "Error",
            _0: {
              path: ".h" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = w._0;
  return {
          TAG: "Error",
          _0: {
            path: ".w" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function avatarSSO_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "avatarId",
                    false,
                    Spice.stringToJson(v.avatarId)
                  ],
                  [
                    "images",
                    false,
                    (function (extra) {
                          return Spice.arrayToJson(resolutionSSO_encode, extra);
                        })(v.images)
                  ],
                  [
                    "titles",
                    false,
                    titles_encode(v.titles)
                  ]
                ]));
}

function avatarSSO_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var avatarId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "avatarId"), null));
  if (avatarId.TAG === "Ok") {
    var images = (function (extra) {
          return Spice.arrayFromJson(resolutionSSO_decode, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "images"), null));
    if (images.TAG === "Ok") {
      var titles = titles_decode(Belt_Option.getWithDefault(Js_dict.get(v, "titles"), null));
      if (titles.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  avatarId: avatarId._0,
                  images: images._0,
                  titles: titles._0
                }
              };
      }
      var e = titles._0;
      return {
              TAG: "Error",
              _0: {
                path: ".titles" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = images._0;
    return {
            TAG: "Error",
            _0: {
              path: ".images" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = avatarId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".avatarId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "images",
                    false,
                    Type_Image.Resolution.t_encode(v.images)
                  ],
                  [
                    "titles",
                    false,
                    titles_encode(v.titles)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var images = Type_Image.Resolution.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "images"), null));
    if (images.TAG === "Ok") {
      var titles = titles_decode(Belt_Option.getWithDefault(Js_dict.get(v, "titles"), null));
      if (titles.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  images: images._0,
                  titles: titles._0
                }
              };
      }
      var e = titles._0;
      return {
              TAG: "Error",
              _0: {
                path: ".titles" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = images._0;
    return {
            TAG: "Error",
            _0: {
              path: ".images" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function imageSizeFromString(s) {
  try {
    return Core__Option.getExn(Core__Int.fromString(s, undefined), undefined);
  }
  catch (raw_exn){
    var exn = Caml_js_exceptions.internalToOCamlException(raw_exn);
    console.error("Error while parsing image size from SSO: " + s, exn);
    return 0;
  }
}

function toDs(avatarSSO) {
  return {
          id: avatarSSO.avatarId,
          images: avatarSSO.images.map(function (image) {
                return {
                        w: imageSizeFromString(image.w),
                        h: imageSizeFromString(image.h),
                        url: image.url
                      };
              }),
          titles: avatarSSO.titles
        };
}

function getAvatarTitleByLocale(locale, avatar, style) {
  if (typeof style !== "object") {
    switch (style) {
      case "Card" :
          break;
      case "Icon" :
      case "UserPanel" :
          return "";
      
    }
  }
  switch (locale) {
    case "Fr" :
        return avatar.titles.fr;
    case "De" :
        return avatar.titles.de;
    case "Pl" :
        return avatar.titles.pl;
    case "It" :
        return avatar.titles.it;
    case "En" :
        return avatar.titles.en;
    case "Es" :
        return avatar.titles.es;
    
  }
}

function getAvatarList(sso) {
  return sso.map(toDs);
}

var defaultAvatar_images = [
  {
    w: 40,
    h: 40,
    url: "https://static-cdn.arte.tv/static/design-system/myarte/next-profile.png"
  },
  {
    w: 200,
    h: 200,
    url: "https://static-cdn.arte.tv/static/design-system/myarte/next-profile.png"
  },
  {
    w: 300,
    h: 300,
    url: "https://static-cdn.arte.tv/static/design-system/myarte/next-profile.png"
  }
];

var defaultAvatar_titles = {
  fr: "Default",
  de: "Default",
  en: "Default",
  es: "Default",
  it: "Default",
  pl: "Default"
};

var defaultAvatar = {
  id: "default_avatar",
  images: defaultAvatar_images,
  titles: defaultAvatar_titles
};

export {
  titles_encode ,
  titles_decode ,
  resolutionSSO_encode ,
  resolutionSSO_decode ,
  avatarSSO_encode ,
  avatarSSO_decode ,
  t_encode ,
  t_decode ,
  imageSizeFromString ,
  toDs ,
  getAvatarTitleByLocale ,
  getAvatarList ,
  defaultAvatar ,
}
/* Type_Image Not a pure module */
