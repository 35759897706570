// This file is generated by tools/env/generateEnv.ts do not edit it manually
const fullEnvTypes = {
    BUNDLE_ANALYZE: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_LIVE_PAGE: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_IMAGE_PLACEHOLDER: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_MENU_TARGETED_COMMS: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_PROFILE_MENU: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_NEW_GUIDE_TV: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_NEWSLETTER_ZONE_WITH_TEASER_IMAGE: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_USE_EMAC_V4: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_DIRECT_PLAYER_AUTOPLAY: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_FSK: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_FSK_SUBHEADER_ALL_USERS: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_EVENTS: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS: 'string[]',
    NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_ANIMATED_STICKER: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_TEASER_MOBILE_HOVER: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_PLAYER_SERVER_SIDE_FETCHING: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY: 'boolean',
    NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_GENRE_LINKS: 'boolean',
    NEXT_PUBLIC_EMAC_VERSION: 'string',
    NEXT_PUBLIC_IS_USER_ACTIVATED: 'boolean',
    NEXT_PUBLIC_DS_CDN: 'string',
    MAMI_BASE_URL: 'string',
    NEXT_PUBLIC_TAG_COMMANDER_BASE_URL: 'string',
    NEXT_PUBLIC_SHOP_URL: 'string',
    NEXT_PUBLIC_PLAYER_BASE_URL: 'string',
    NEXT_PUBLIC_EBU_BOX_URL: 'string',
    NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL: 'string',
    NEXT_PUBLIC_PLAYER_AUTOPLAY: 'string',
    NEXT_PUBLIC_CDN_URL: 'string',
    EMAC_BASE_URL: 'string',
    API_PLAYER_BASE_URL: 'string',
    NEXT_PUBLIC_REPLAY_BASE_URL: 'string',
    EMAC_SEND_ROLE: 'boolean',
    EMAC_TIMEOUT: 'number',
    NEXT_PUBLIC_SSO_ENV: 'string',
    NEXT_PUBLIC_SSO_API_KEY: 'string',
    NEXT_PUBLIC_SSO_TOKEN_API_KEY: 'string',
    YOTI_URL: 'string',
    NEXT_PUBLIC_SERVER_NAME: 'string',
    NEXT_PUBLIC_TIMEZONE: 'string',
    NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL: 'string',
    NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES: 'string[]',
    VARY_HEADERS: 'string[]',
    FEEDBACK_FORM_USERNAME: 'string',
    NEXT_PUBLIC_USER_DATA_TTL: 'number',
    NEXT_PUBLIC_LASTVIEWED_TTL: 'number',
    NEXT_PUBLIC_SSO_EVENTS_URL: 'string',
    PORT: 'number',
    SERVER_NAME: 'string',
    HEADERS_CORS: 'string',
    GZIP_COMPRESSION: 'boolean',
    NEW_RELIC_ENABLED: 'boolean',
    NEW_RELIC_APP_NAME: 'string',
    NEW_RELIC_LABELS: 'string',
    NEW_RELIC_DISTRIBUTED_TRACING_ENABLED: 'boolean',
    LOG_LEVEL: 'string',
    LOG_INLINE: 'boolean',
    NEW_RELIC_LICENSE_KEY: 'string',
    EMAC_ACCESS_TOKEN: 'string',
    API_PLAYER_ACCESS_TOKEN: 'string',
    YOTI_SDK_KEY: 'string',
    YOTI_API_KEY: 'string',
    FEEDBACK_FORM_PASSWORD: 'string',
    OPA_ACCESS_TOKEN: 'string',
    TURBO_TOKEN: 'string',
    TURBO_TEAM: 'string',
};
module.exports.fullEnvTypes = fullEnvTypes;
const getEnvFromKey = (key) => {
    const isServer = typeof global.window === 'undefined';
    if (key === 'NODE_ENV') {
        return process.env.NODE_ENV;
    }
    if (key === 'TZ') {
        return process.env.TZ;
    }
    if (isServer || process.env.NODE_ENV === 'test') {
        const value = process.env[key];
        if (!value && value !== '') {
            throw new Error(`Missing environment variable: ${key}`);
        }
        if (fullEnvTypes[key] === 'string') return value;
        try {
            return JSON.parse(value);
        } catch (e) {
            throw new Error(`Invalid environment variable: ${key}`);
        }
    } else {
        const value = window.__ENV[key];
        if (value === undefined || value === null) {
            throw new Error(`Missing environment variable: ${key}`);
        }
        return value;
    }
};
module.exports.getEnvFromKey = getEnvFromKey;
if (typeof window === 'undefined' || process.env.NODE_ENV === 'test') {
    module.exports.client = {
        NEXT_PUBLIC_FEATURE_FLAGS_LIVE_PAGE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_LIVE_PAGE'),
        NEXT_PUBLIC_FEATURE_FLAGS_IMAGE_PLACEHOLDER: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_IMAGE_PLACEHOLDER'),
        NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION'),
        NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING'),
        NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_MENU_TARGETED_COMMS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_MENU_TARGETED_COMMS',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_PROFILE_MENU: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_PROFILE_MENU'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_GUIDE_TV: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_NEW_GUIDE_TV'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEWSLETTER_ZONE_WITH_TEASER_IMAGE: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEWSLETTER_ZONE_WITH_TEASER_IMAGE',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_USE_EMAC_V4: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_USE_EMAC_V4'),
        NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX'),
        NEXT_PUBLIC_FEATURE_FLAGS_DIRECT_PLAYER_AUTOPLAY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_DIRECT_PLAYER_AUTOPLAY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE'),
        NEXT_PUBLIC_FEATURE_FLAGS_FSK: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_FSK'),
        NEXT_PUBLIC_FEATURE_FLAGS_FSK_SUBHEADER_ALL_USERS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_FSK_SUBHEADER_ALL_USERS',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE'),
        NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_EVENTS: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_EVENTS'),
        NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_ANIMATED_STICKER: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_ANIMATED_STICKER',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_TEASER_MOBILE_HOVER: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_TEASER_MOBILE_HOVER'),
        NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING'),
        NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM'),
        NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_PLAYER_SERVER_SIDE_FETCHING: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_PLAYER_SERVER_SIDE_FETCHING',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON'),
        NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_GENRE_LINKS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_GENRE_LINKS',
        ),
        NEXT_PUBLIC_EMAC_VERSION: getEnvFromKey('NEXT_PUBLIC_EMAC_VERSION'),
        NEXT_PUBLIC_IS_USER_ACTIVATED: getEnvFromKey('NEXT_PUBLIC_IS_USER_ACTIVATED'),
        NEXT_PUBLIC_DS_CDN: getEnvFromKey('NEXT_PUBLIC_DS_CDN'),
        NEXT_PUBLIC_TAG_COMMANDER_BASE_URL: getEnvFromKey('NEXT_PUBLIC_TAG_COMMANDER_BASE_URL'),
        NEXT_PUBLIC_SHOP_URL: getEnvFromKey('NEXT_PUBLIC_SHOP_URL'),
        NEXT_PUBLIC_PLAYER_BASE_URL: getEnvFromKey('NEXT_PUBLIC_PLAYER_BASE_URL'),
        NEXT_PUBLIC_EBU_BOX_URL: getEnvFromKey('NEXT_PUBLIC_EBU_BOX_URL'),
        NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL: getEnvFromKey('NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL'),
        NEXT_PUBLIC_PLAYER_AUTOPLAY: getEnvFromKey('NEXT_PUBLIC_PLAYER_AUTOPLAY'),
        NEXT_PUBLIC_CDN_URL: getEnvFromKey('NEXT_PUBLIC_CDN_URL'),
        NEXT_PUBLIC_REPLAY_BASE_URL: getEnvFromKey('NEXT_PUBLIC_REPLAY_BASE_URL'),
        NEXT_PUBLIC_SSO_ENV: getEnvFromKey('NEXT_PUBLIC_SSO_ENV'),
        NEXT_PUBLIC_SSO_API_KEY: getEnvFromKey('NEXT_PUBLIC_SSO_API_KEY'),
        NEXT_PUBLIC_SSO_TOKEN_API_KEY: getEnvFromKey('NEXT_PUBLIC_SSO_TOKEN_API_KEY'),
        NEXT_PUBLIC_SERVER_NAME: getEnvFromKey('NEXT_PUBLIC_SERVER_NAME'),
        NEXT_PUBLIC_TIMEZONE: getEnvFromKey('NEXT_PUBLIC_TIMEZONE'),
        NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL: getEnvFromKey('NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL'),
        NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES: getEnvFromKey('NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES'),
        NEXT_PUBLIC_USER_DATA_TTL: getEnvFromKey('NEXT_PUBLIC_USER_DATA_TTL'),
        NEXT_PUBLIC_LASTVIEWED_TTL: getEnvFromKey('NEXT_PUBLIC_LASTVIEWED_TTL'),
        NEXT_PUBLIC_SSO_EVENTS_URL: getEnvFromKey('NEXT_PUBLIC_SSO_EVENTS_URL'),
    };

    module.exports.server = {
        BUNDLE_ANALYZE: getEnvFromKey('BUNDLE_ANALYZE'),
        MAMI_BASE_URL: getEnvFromKey('MAMI_BASE_URL'),
        EMAC_BASE_URL: getEnvFromKey('EMAC_BASE_URL'),
        API_PLAYER_BASE_URL: getEnvFromKey('API_PLAYER_BASE_URL'),
        EMAC_SEND_ROLE: getEnvFromKey('EMAC_SEND_ROLE'),
        EMAC_TIMEOUT: getEnvFromKey('EMAC_TIMEOUT'),
        YOTI_URL: getEnvFromKey('YOTI_URL'),
        VARY_HEADERS: getEnvFromKey('VARY_HEADERS'),
        FEEDBACK_FORM_USERNAME: getEnvFromKey('FEEDBACK_FORM_USERNAME'),
        PORT: getEnvFromKey('PORT'),
        SERVER_NAME: getEnvFromKey('SERVER_NAME'),
        HEADERS_CORS: getEnvFromKey('HEADERS_CORS'),
        GZIP_COMPRESSION: getEnvFromKey('GZIP_COMPRESSION'),
        NEW_RELIC_ENABLED: getEnvFromKey('NEW_RELIC_ENABLED'),
        NEW_RELIC_APP_NAME: getEnvFromKey('NEW_RELIC_APP_NAME'),
        NEW_RELIC_LABELS: getEnvFromKey('NEW_RELIC_LABELS'),
        NEW_RELIC_DISTRIBUTED_TRACING_ENABLED: getEnvFromKey('NEW_RELIC_DISTRIBUTED_TRACING_ENABLED'),
        LOG_LEVEL: getEnvFromKey('LOG_LEVEL'),
        LOG_INLINE: getEnvFromKey('LOG_INLINE'),
        NEW_RELIC_LICENSE_KEY: getEnvFromKey('NEW_RELIC_LICENSE_KEY'),
        EMAC_ACCESS_TOKEN: getEnvFromKey('EMAC_ACCESS_TOKEN'),
        API_PLAYER_ACCESS_TOKEN: getEnvFromKey('API_PLAYER_ACCESS_TOKEN'),
        YOTI_SDK_KEY: getEnvFromKey('YOTI_SDK_KEY'),
        YOTI_API_KEY: getEnvFromKey('YOTI_API_KEY'),
        FEEDBACK_FORM_PASSWORD: getEnvFromKey('FEEDBACK_FORM_PASSWORD'),
        OPA_ACCESS_TOKEN: getEnvFromKey('OPA_ACCESS_TOKEN'),
        TURBO_TOKEN: getEnvFromKey('TURBO_TOKEN'),
        TURBO_TEAM: getEnvFromKey('TURBO_TEAM'),
    };
} else {
    module.exports.client = {
        NEXT_PUBLIC_FEATURE_FLAGS_LIVE_PAGE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_LIVE_PAGE'),
        NEXT_PUBLIC_FEATURE_FLAGS_IMAGE_PLACEHOLDER: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_IMAGE_PLACEHOLDER'),
        NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_SIDACTION'),
        NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING'),
        NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_MENU_TARGETED_COMMS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_MENU_TARGETED_COMMS',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_PROFILE_MENU: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_PROFILE_MENU'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_GUIDE_TV: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_NEW_GUIDE_TV'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEWSLETTER_ZONE_WITH_TEASER_IMAGE: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEWSLETTER_ZONE_WITH_TEASER_IMAGE',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_USE_EMAC_V4: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_USE_EMAC_V4'),
        NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_EBU_BOX'),
        NEXT_PUBLIC_FEATURE_FLAGS_DIRECT_PLAYER_AUTOPLAY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_DIRECT_PLAYER_AUTOPLAY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_ATINTERNET_SRC_FORCE'),
        NEXT_PUBLIC_FEATURE_FLAGS_FSK: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_FSK'),
        NEXT_PUBLIC_FEATURE_FLAGS_FSK_SUBHEADER_ALL_USERS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_FSK_SUBHEADER_ALL_USERS',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE'),
        NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_EVENTS: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_EVENTS'),
        NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_DISPLAYED_SUBHEADERS'),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_ANIMATED_STICKER: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_ANIMATED_STICKER',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_TEASER_MOBILE_HOVER: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_TEASER_MOBILE_HOVER'),
        NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_SERVER_SIDE_TRACKING'),
        NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM'),
        NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_PLAYER_SERVER_SIDE_FETCHING: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_PLAYER_SERVER_SIDE_FETCHING',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON: getEnvFromKey('NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON'),
        NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY',
        ),
        NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_GENRE_LINKS: getEnvFromKey(
            'NEXT_PUBLIC_FEATURE_FLAGS_NEW_CONCERT_GENRE_LINKS',
        ),
        NEXT_PUBLIC_EMAC_VERSION: getEnvFromKey('NEXT_PUBLIC_EMAC_VERSION'),
        NEXT_PUBLIC_IS_USER_ACTIVATED: getEnvFromKey('NEXT_PUBLIC_IS_USER_ACTIVATED'),
        NEXT_PUBLIC_DS_CDN: getEnvFromKey('NEXT_PUBLIC_DS_CDN'),
        NEXT_PUBLIC_TAG_COMMANDER_BASE_URL: getEnvFromKey('NEXT_PUBLIC_TAG_COMMANDER_BASE_URL'),
        NEXT_PUBLIC_SHOP_URL: getEnvFromKey('NEXT_PUBLIC_SHOP_URL'),
        NEXT_PUBLIC_PLAYER_BASE_URL: getEnvFromKey('NEXT_PUBLIC_PLAYER_BASE_URL'),
        NEXT_PUBLIC_EBU_BOX_URL: getEnvFromKey('NEXT_PUBLIC_EBU_BOX_URL'),
        NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL: getEnvFromKey('NEXT_PUBLIC_SSO_NEWSLETTER_SUBSCRIBE_URL'),
        NEXT_PUBLIC_PLAYER_AUTOPLAY: getEnvFromKey('NEXT_PUBLIC_PLAYER_AUTOPLAY'),
        NEXT_PUBLIC_CDN_URL: getEnvFromKey('NEXT_PUBLIC_CDN_URL'),
        NEXT_PUBLIC_REPLAY_BASE_URL: getEnvFromKey('NEXT_PUBLIC_REPLAY_BASE_URL'),
        NEXT_PUBLIC_SSO_ENV: getEnvFromKey('NEXT_PUBLIC_SSO_ENV'),
        NEXT_PUBLIC_SSO_API_KEY: getEnvFromKey('NEXT_PUBLIC_SSO_API_KEY'),
        NEXT_PUBLIC_SSO_TOKEN_API_KEY: getEnvFromKey('NEXT_PUBLIC_SSO_TOKEN_API_KEY'),
        NEXT_PUBLIC_SERVER_NAME: getEnvFromKey('NEXT_PUBLIC_SERVER_NAME'),
        NEXT_PUBLIC_TIMEZONE: getEnvFromKey('NEXT_PUBLIC_TIMEZONE'),
        NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL: getEnvFromKey('NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL'),
        NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES: getEnvFromKey('NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES'),
        NEXT_PUBLIC_USER_DATA_TTL: getEnvFromKey('NEXT_PUBLIC_USER_DATA_TTL'),
        NEXT_PUBLIC_LASTVIEWED_TTL: getEnvFromKey('NEXT_PUBLIC_LASTVIEWED_TTL'),
        NEXT_PUBLIC_SSO_EVENTS_URL: getEnvFromKey('NEXT_PUBLIC_SSO_EVENTS_URL'),
    };
    module.exports.server = {};
    module.exports.getEnvFromKey = () => {
        return undefined;
    };
    module.exports.fullEnvTypes = {};
}
