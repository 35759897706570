// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Box from "../../../atom/Atom_Box.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as UseToggle from "../../../../hook/UseToggle.res.mjs";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../../../particle/Particle_Css.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Organism_Tooltip from "../../../organism/Organism_Tooltip.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";
import * as Template_ProgramContent_AudioVersions from "./Template_ProgramContent_AudioVersions.res.mjs";

var technicalInfosBoxDirection = Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column");

var technicalInfosBoxAlign = Particle_Screen_Ds.make(undefined, undefined, "center", undefined, undefined, "flexStart");

var technicalInfosBoxInnerSpace = Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XS");

var gapValue = Particle_Css.spacerSizeInsideValue("M");

var dividerWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.relative)]);

var metaRowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gap2(Particle_Css.spacerSizeInside("XXS"), CssJs.px(gapValue)),
      CssJs.overflow("hidden"),
      CssJs.flexWrap("inherit_")
    ]);

var dividerAlignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch");

var dividerRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.display("flex"),
      CssJs.height(CssJs.px(Particle_Css.font_size_int("M"))),
      CssJs.position(CssJs.absolute),
      CssJs.right(CssJs.pct(100)),
      CssJs.top(CssJs.pct(50)),
      CssJs.transform(CssJs.translateY(CssJs.pct(-50))),
      CssJs.marginRight(CssJs.px(gapValue / 2 | 0))
    ], undefined, undefined, [CssJs.display("none")]);

function content(value) {
  return JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(value),
              style: Atom_Text_Ds.makeContentCollection("Desc")
            });
}

var divider = JsxRuntime.jsx(Atom_Row.make, {
      alignItems: Caml_option.some(dividerAlignItems),
      children: JsxRuntime.jsx(Atom_Divider.make, {
            style: Atom_Divider.contentCollectionMeta
          }),
      rules: Caml_option.some(dividerRules)
    });

function Template_ProgramContent_TechnicalInfos$ItemContent(props) {
  return JsxRuntime.jsx(Atom_Text.make, {
              children: Caml_option.some(props.value),
              style: Atom_Text_Ds.makeContentCollection("Desc"),
              textRef: props.itemRef
            });
}

var ItemContent = {
  make: Template_ProgramContent_TechnicalInfos$ItemContent
};

function Template_ProgramContent_TechnicalInfos(props) {
  var metas = props.metas;
  var tooltipRef = React.useRef(null);
  var centerTooltipRef = React.useRef(null);
  var match = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var state = match.state;
  var toggleRaw = match.toggleRaw;
  var match$1 = Context.I18n.getState();
  var match$2 = Context.Config.getState();
  var dateFormater = match$1.formaters.untilDateFormated;
  var today = match$2.serverTime;
  var stateToTooltipStatus = function (state) {
    if (state === "On") {
      return "Show";
    } else {
      return "Hide";
    }
  };
  var onHoverTooltip = function (param) {
    toggleRaw();
  };
  var items = Util_Array.NonEmpty.catOption(Util_Array.NonEmpty.mapWithIndex(metas, (function (meta, index) {
              var value = meta.value;
              var label = meta.label;
              switch (value.TAG) {
                case "String" :
                    var match = meta.raw;
                    var value$1 = value._0;
                    if (typeof match !== "object") {
                      return ;
                    }
                    switch (match.TAG) {
                      case "AvailabilityFormatted" :
                          var date = match._1;
                          var baseItem = function (value) {
                            return JsxRuntime.jsx(Template_ProgramContent_TechnicalInfos$ItemContent, {
                                        value: value,
                                        itemRef: centerTooltipRef
                                      }, "meta" + String(index));
                          };
                          if (date === undefined) {
                            return Caml_option.some(baseItem(value$1));
                          }
                          var date$1 = Caml_option.valFromOption(date);
                          if ($$Date.isAfter(today, date$1)) {
                            return Caml_option.some(baseItem(value$1));
                          }
                          var tenDaysFromNow = $$Date.addDays(today, 10);
                          if ($$Date.isBetween(today, tenDaysFromNow, date$1)) {
                            return Caml_option.some(JsxRuntime.jsx(Organism_Tooltip.Program.make, {
                                            status: stateToTooltipStatus(state),
                                            centerTooltipRef: centerTooltipRef,
                                            tooltipRef: tooltipRef,
                                            children: baseItem(value$1),
                                            content: content(dateFormater(date$1)),
                                            clickOutsideBehavior: {
                                              TAG: "Close",
                                              _0: (function () {
                                                  
                                                })
                                            },
                                            onMouseEnter: onHoverTooltip,
                                            onMouseLeave: onHoverTooltip
                                          }));
                          } else {
                            return Caml_option.some(baseItem(value$1));
                          }
                      case "Duration" :
                          return Caml_option.some(JsxRuntime.jsx(Template_ProgramContent_TechnicalInfos$ItemContent, {
                                          value: value$1,
                                          itemRef: centerTooltipRef
                                        }, "meta" + String(index)));
                      case "Availability" :
                      case "NextDiffusion" :
                      case "FirstBroadcast" :
                          break;
                      default:
                        return ;
                    }
                    return Caml_option.some(JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(label + " " + value$1),
                                    style: Atom_Text_Ds.makeContentCollection("Desc")
                                  }, "meta" + String(index)));
                case "AudioVersions" :
                    return Caml_option.some(JsxRuntime.jsx(Template_ProgramContent_AudioVersions.make, {
                                    audioVersions: value._0
                                  }));
                case "Genre" :
                    return ;
                case "MultiString" :
                    var tmp = meta.raw;
                    if (typeof tmp !== "object" || tmp.TAG !== "AllDiffusions") {
                      return ;
                    } else {
                      return Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                                      children: [
                                        JsxRuntime.jsx(Atom_Text.make, {
                                              children: Caml_option.some(label),
                                              style: Atom_Text_Ds.makeContentCollection("Desc")
                                            }),
                                        JsxRuntime.jsx(Atom_Column.make, {
                                              children: value._0.map(function (value, index) {
                                                    return JsxRuntime.jsx(Atom_Text.make, {
                                                                children: Caml_option.some(value),
                                                                style: Atom_Text_Ds.makeContentCollection("Desc")
                                                              }, label + String(index));
                                                  }),
                                              display: Caml_option.some(Particle_Screen_Constants.displayInlineFlex),
                                              innerSpace: Caml_option.some(Particle_Screen_Constants.xxsInnerSpace),
                                              kind: "List"
                                            })
                                      ],
                                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart)
                                    }, "meta" + String(index)));
                    }
                
              }
            })));
  var hasMultiString = Util_Array.NonEmpty.find(metas, (function (item) {
          if (item.value.TAG === "MultiString") {
            return true;
          } else {
            return false;
          }
        }));
  if (hasMultiString !== undefined) {
    if (items !== undefined) {
      return JsxRuntime.jsx(Atom_Column.make, {
                  alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                  children: Util_Array.NonEmpty.toReactElement(Util_Array.NonEmpty.map(Caml_option.valFromOption(items), (function (item) {
                              return item;
                            }))),
                  innerSpace: Caml_option.some(technicalInfosBoxInnerSpace)
                });
    } else {
      return null;
    }
  }
  if (items === undefined) {
    return null;
  }
  var match$3 = Util_Array.NonEmpty.extractHead(Caml_option.valFromOption(items));
  return JsxRuntime.jsxs(Atom_Box.make, {
              alignItems: Caml_option.some(technicalInfosBoxAlign),
              children: [
                match$3[0],
                Core__Option.mapOr(match$3[1], null, (function (items) {
                        return Util_Array.NonEmpty.toReactElement(Util_Array.NonEmpty.mapWithIndex(items, (function (item, index) {
                                          return JsxRuntime.jsxs(Atom_Cell.make, {
                                                      children: [
                                                        divider,
                                                        item
                                                      ],
                                                      rules: Caml_option.some(dividerWrapperRules)
                                                    }, "programContentItems" + index.toString());
                                        })));
                      }))
              ],
              direction: Caml_option.some(technicalInfosBoxDirection),
              rules: Caml_option.some(metaRowRules)
            });
}

var make = Template_ProgramContent_TechnicalInfos;

export {
  technicalInfosBoxDirection ,
  technicalInfosBoxAlign ,
  technicalInfosBoxInnerSpace ,
  gapValue ,
  dividerWrapperRules ,
  metaRowRules ,
  dividerAlignItems ,
  dividerRules ,
  content ,
  divider ,
  ItemContent ,
  make ,
}
/* technicalInfosBoxDirection Not a pure module */
