'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../user/User.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../design-system/atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../../design-system/atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../../../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Icon_Ds from "../../../design-system/atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as Particle_Color from "../../../design-system/particle/Particle_Color.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

function make(baseState) {
  var state = Core__Option.getOr(baseState, "UserCheck");
  return {
          state: state
        };
}

function useRedirect(status, state, userStatus, callback) {
  switch (status) {
    case "NotInitialized" :
        return ;
    case "Initialized" :
    case "ForceReInit" :
        break;
    
  }
  if (state !== "UserCheck") {
    return ;
  }
  switch (userStatus) {
    case "Connected" :
        return ;
    case "Anonymous" :
    case "Disconnected" :
        return callback();
    
  }
}

var UseVerifyStates = {
  initialState: "UserCheck",
  make: make,
  useRedirect: useRedirect
};

var iconRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.alignSelf(CssJs.center),
      CssJs.color(Particle_Color.Css.color("Primary"))
    ]);

function Page_Tv_Age_Verification$UserCheckView(props) {
  var startAgeVerification = props.startAgeVerification;
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.mona.tvAgeVerification;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var email = Core__Option.flatMap(userInfos, (function (user) {
          return User_Type.Info.email(user);
        }));
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.userCheckDescription),
                              style: Atom_Text_Ds.User.text("Auth")
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.userCheckConnectedAccountDescription),
                              style: Atom_Text_Ds.User.text("Auth")
                            }),
                        JsxRuntime.jsx(Atom_Column.make, {
                              children: email !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                      children: [
                                        JsxRuntime.jsx(Atom_Cell.make, {
                                              children: JsxRuntime.jsx(Atom_Icon.make, {
                                                    icon: Atom_Icon_Ds.user,
                                                    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L")
                                                  }),
                                              rules: Caml_option.some(iconRules)
                                            }),
                                        JsxRuntime.jsx(Atom_Text.make, {
                                              children: Caml_option.some(email),
                                              style: Atom_Text_Ds.User.text("Auth")
                                            })
                                      ]
                                    }) : JsxRuntime.jsx(Atom_Cell.make, {
                                      children: JsxRuntime.jsx(Atom_Loader.make, {}),
                                      rules: Caml_option.some(iconRules)
                                    }),
                              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                      label: match$1.userCheckPrimaryButton,
                      onClick: (function (param) {
                          startAgeVerification();
                        }),
                      variant: "fullWidth"
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(labels.mona.register.verifyFooter),
                              style: Atom_Text_Ds.User.text("Auth")
                            }),
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$1.userCheckSecondaryButton,
                              onClick: User_Provider.disconnect(props.redirectToLogin, undefined, match.locale),
                              variant: "link"
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              rules: Caml_option.some(Layout_Auth.Form_Css.wrapperRules),
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var UserCheckView = {
  iconRules: iconRules,
  make: Page_Tv_Age_Verification$UserCheckView
};

var iconRules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.alignSelf(CssJs.center),
      CssJs.color(Particle_Color.Css.color("Primary"))
    ]);

var tvIcon = Atom_Icon_Ds.updateColor(Atom_Icon_Ds.tv, "Text");

function Page_Tv_Age_Verification$AgeVerifiedView(props) {
  var redirectToHome = props.redirectToHome;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.tvAgeVerification;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cell.make, {
                              blockSpace: Caml_option.some(Particle_Screen_Constants.mBottomBlockSpace),
                              children: JsxRuntime.jsx(Atom_Icon.make, {
                                    icon: tvIcon,
                                    size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXL")
                                  }),
                              rules: Caml_option.some(iconRules$1)
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.ageVerifiedDescription),
                              style: Atom_Text_Ds.User.text("Auth")
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Row.make, {
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: match$1.ageVerifiedPrimaryButton,
                            onClick: (function (param) {
                                redirectToHome();
                              }),
                            variant: "fullWidth"
                          }),
                      gap: Caml_option.some(Layout_Auth.Form_Css.formBottomGap),
                      justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center"))
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var AgeVerifiedView = {
  iconRules: iconRules$1,
  tvIcon: tvIcon,
  make: Page_Tv_Age_Verification$AgeVerifiedView
};

function make$1(param) {
  var redirectToLogin = param.redirectToLogin;
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var match$2 = make(param.baseState);
  var state = match$2.state;
  var status = User.Status.get();
  var user = User.get();
  useRedirect(status, state, User_Type.getUserStatus(user), redirectToLogin);
  var tmp;
  tmp = state === "UserCheck" ? labels.mona.tvAgeVerification.userCheckTitle : labels.mona.tvLogin.ageVerifiedTitle;
  var tmp$1;
  tmp$1 = state === "UserCheck" ? JsxRuntime.jsx(Page_Tv_Age_Verification$UserCheckView, {
          redirectToLogin: redirectToLogin,
          startAgeVerification: param.startAgeVerification
        }) : JsxRuntime.jsx(Page_Tv_Age_Verification$AgeVerifiedView, {
          redirectToHome: param.redirectToHome
        });
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: tmp,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: tmp$1
            });
}

export {
  UseVerifyStates ,
  UserCheckView ,
  AgeVerifiedView ,
  make$1 as make,
}
/* iconRules Not a pure module */
