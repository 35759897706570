// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";

var halfButtonNavSize = 20;

function getNavRules(containerHeight, verticalPadding, direction, arrowPosition) {
  if (containerHeight === undefined) {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.width(CssJs.px(1000))]);
  }
  var containerMiddle = containerHeight / 2 | 0;
  var paddingValue = Particle_Css.spacerSizeInsideValue(verticalPadding);
  var buttonVerticalPosition = containerMiddle + (paddingValue - halfButtonNavSize | 0) | 0;
  var buttonHorizontalPosition = direction === "Left" ? Particle_Screen_Ds.map(arrowPosition, (function (position) {
            return [CssJs.left(CssJs.px(position))];
          })) : Particle_Screen_Ds.map(arrowPosition, (function (position) {
            return [CssJs.right(CssJs.px(position))];
          }));
  var width = direction === "Left" ? 40 : 80;
  var preserveFromHoverPart = CssJs.before([
        CssJs.unsafe("content", ""),
        CssJs.top(CssJs.px(-(containerMiddle - paddingValue | 0) | 0)),
        CssJs.right(CssJs.px(0)),
        CssJs.position(CssJs.absolute),
        CssJs.height(CssJs.px(containerHeight)),
        CssJs.width(CssJs.px(width))
      ]);
  var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
        TAG: "Exact",
        _0: "SliderArrow"
      });
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  CssJs.position(CssJs.absolute),
                  CssJs.top(CssJs.px(buttonVerticalPosition)),
                  zIndexRule,
                  preserveFromHoverPart
                ]), buttonHorizontalPosition);
}

var buttonNavSize = 40;

var mobilePosition = 15;

export {
  buttonNavSize ,
  halfButtonNavSize ,
  mobilePosition ,
  getNavRules ,
}
/* CssJs Not a pure module */
