// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Util_Css from "../../../util/Util_Css.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";

function Organism_SettingsMenu$Button(props) {
  var display = Util_Css.Display.fromL;
  return JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: props.href,
              style: Atom_Cta_Ds.Header.makeSettingsMenu(display)
            });
}

var Button = {
  make: Organism_SettingsMenu$Button
};

Organism_SettingsMenu$Button.displayName = "Organism_SettingsMenu.Button";

var display = Util_Css.Display.untilL;

function Organism_SettingsMenu$Link(props) {
  var match = Context.I18n.getState();
  var match$1 = match.labels.header;
  var settings = match$1.settings;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.menuNavigation,
                      display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "inlineFlex"))
                    }),
                JsxRuntime.jsx(Atom_Cta.Link.make, {
                      href: settings.href,
                      label: settings.title,
                      style: Atom_Cta_Ds.Header.makeSettingsMenuLink(display)
                    })
              ]
            });
}

var Link = {
  display: display,
  make: Organism_SettingsMenu$Link
};

export {
  Button ,
  Link ,
}
/*  Not a pure module */
