import { type GetServerSidePropsResult } from 'next';
import { ReplayError as Error, ReplayError } from '../types/Type_Error';
import { type AlternativeLanguage, defaultAlternativeLanguage } from './AlternativeLanguage';
import { type Locale } from './Locale';
import { type Page } from './Page';
import { type Result, isOk } from './Result';
import { defaultStats, type Stats } from './Stats';

type PageKind = 'emac' | 'other';

export type Domain = 'concert' | 'replay';

export type ResultFetchPageData = Result<Page, ReplayError>;

export type CommonProps<P extends PageKind, T = Record<string, unknown>> = {
    locale: Locale;
    domain: Domain;
    referer?: string;
    type: P;
    props: T;
};

export type EmacPageResult<T = Record<string, unknown>> = CommonProps<
    'emac',
    T & {
        page: Result<Page, Error>;
    }
>;

export type Other<T = Record<string, unknown>> = CommonProps<
    'other',
    T & {
        alternativeLanguages: AlternativeLanguage[];
        stats: Stats;
        referer?: string;
    }
>;

export type ServerSideProps<T = Record<string, unknown>, Scope extends PageKind = 'emac'> = Scope extends 'emac'
    ? EmacPageResult<T>
    : Other<T>;

export type MyGetServerSidePropsResult<
    T = Record<string, unknown>,
    Scope extends PageKind = 'emac',
> = GetServerSidePropsResult<ServerSideProps<T, Scope>>;

export type OtherServerSideProps<T = Record<string, unknown>> = MyGetServerSidePropsResult<T, 'other'>;
export type EmacServerSideProps<T = Record<string, unknown>> = MyGetServerSidePropsResult<T, 'emac'>;

// beware the name is important if you change it take a look to the eslint's configuration because it's used to
// configure a rule
export type ServerSidePageProps = OtherServerSideProps | EmacServerSideProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InferPageProps<T extends (...args: any[]) => any> =
    Awaited<ReturnType<T>> extends GetServerSidePropsResult<infer Props> ? Props : never;

export const getStats = (pageProps: EmacPageResult | Other | undefined): Stats => {
    if (pageProps) {
        if (pageProps.type === 'emac' && pageProps.props.page.tag === 'Ok' && pageProps.props.page.value.stats) {
            return pageProps.props.page.value.stats;
        }
        if (pageProps.type === 'other' && pageProps.props.stats) {
            return pageProps.props.stats;
        }
        // we shouldn't be here when we have an emac Page
        return defaultStats('', pageProps.locale, undefined);
    }
    return defaultStats('', 'fr', undefined);
};

export const getPageInfos = (
    pageProps: EmacPageResult | Other | undefined,
):
    | {
          deeplink: string;
      }
    | undefined => {
    if (pageProps && pageProps.type === 'emac') {
        if (pageProps.props.page.tag === 'Ok') {
            return {
                deeplink: pageProps.props.page.value.deeplink,
            };
        }
    }
};

export const getHighestParentUrlFromEmacPageProps = (pageProps: EmacPageResult | Other | undefined) => {
    if (pageProps && pageProps.type === 'emac') {
        if (pageProps.props.page.tag === 'Ok') {
            return getHighestParentUrl(pageProps.props.page.value.parent);
        }
    }
};

export const getHighestParentUrl = (initialParent?: Page['parent'] | null) => {
    if (!initialParent) return '';
    let parent: Page['parent']['parent'] = initialParent;
    while (parent?.parent) {
        parent = parent.parent;
    }
    return parent?.url;
};

export const resolveDomainWithPage = (url: string, initialPage: ResultFetchPageData): Domain => {
    const pageResult = initialPage && isOk<Page, ReplayError>(initialPage) ? initialPage : null;
    const parentUrl = pageResult?.value?.parent?.url;
    if (url.includes('arte-concert') || parentUrl?.split('/')[2] === 'arte-concert') {
        return 'concert';
    }
    return 'replay';
};

export const getAlternativeLanguages = (pageProps: EmacPageResult | Other | undefined): AlternativeLanguage[] => {
    if (pageProps && pageProps.type === 'emac') {
        if (pageProps.props.page.tag === 'Ok' && pageProps.props.page.value.alternativeLanguages) {
            return pageProps.props.page.value.alternativeLanguages;
        }
    }
    if (pageProps?.type === 'other') {
        return pageProps.props.alternativeLanguages;
    }

    return defaultAlternativeLanguage;
};
