// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Toaster from "../../../toast/Toaster.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Type_Club from "../../../club/Type_Club.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Atom_Icon_Ds from "../../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Backlink from "../../atom/Atom_Backlink.res.mjs";
import * as Atom_Box_Type from "../../atom/Atom_Box_Type.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Molecule_Message from "../../molecule/Molecule_Message.res.mjs";
import * as Organism_Dropdown from "../../organism/dropdown/Organism_Dropdown.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_EventZone from "../../molecule/Molecule_EventZone.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as User_Api__Sso__Event from "../../../user/User_Api/User_Api__Sso__Event.res.mjs";
import * as Molecule_DangerousHtml from "../../molecule/Molecule_DangerousHtml.res.mjs";
import * as Molecule_DangerousHtml_Ds from "../../molecule/Molecule_DangerousHtml_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../../particle/Particle_Spacer_Constants.res.mjs";
import * as Organism_User_Navigation_Header from "../../organism/Organism_User_Navigation_Header.res.mjs";

function Page_MyAccount_Event$Toast(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.mona.events;
  return JsxRuntime.jsx(Molecule_Message.Toast.make, {
              variant: "subHeader",
              text: match$2.participatedToastMessage,
              closeLabel: match$1.closeSubheader,
              icon: Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.circleCheck, "Success")),
              clickOnCloseButton: props.onClose
            });
}

var Toast = {
  make: Page_MyAccount_Event$Toast
};

function get(onClose) {
  return JsxRuntime.jsx(Page_MyAccount_Event$Toast, {
              onClose: onClose
            }, "event-participated-toast");
}

function make(param) {
  return get(param.remove);
}

var Toast$1 = {
  make: make
};

var EventParticipatedToast = {
  get: get,
  Toast: Toast$1
};

var Toaster$1 = Toaster.Make({
      Toast: Toast$1
    });

var minHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "vh",
      VAL: 80
    });

var headerBlockspace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "M", "XL", "XL")), undefined, undefined, Particle_Spacer_Ds.make("S", "M", "M", "M"));

var linksGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var zoneBlockspace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("None", "L", Particle_Spacer_Constants.Zone.defaultSpacerHorizontalValues.m, Particle_Spacer_Constants.Zone.defaultSpacerHorizontalValues.m)), undefined, undefined, Particle_Spacer_Ds.make("None", "L", Particle_Spacer_Constants.Zone.defaultSpacerHorizontalValues.default, Particle_Spacer_Constants.Zone.defaultSpacerHorizontalValues.default));

var descriptionLinksBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("S", "L", "None", "None"));

var eventBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("L", "M", "None", "None"));

function make$1(param) {
  var $$event = param.event;
  var match = Context.I18n.getState();
  var formatDate = match.formaters.date;
  var match$1 = match.labels.mona.events;
  var teaser = Type_Club.Teaser.toDsTeaser("min", undefined, $$event);
  var teaserTitle = Core__Option.getOr(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.empty);
  var teaserSubtitle = Type_String.Teaser.Subtitle.makeFromNullableOrEmpty($$event.location);
  var formattedStart = Core__Option.map(Core__Option.map(Caml_option.nullable_to_opt($$event.startDate), (function (prim) {
              return new Date(prim);
            })), formatDate);
  var formattedEnd = Core__Option.map(Core__Option.map(Caml_option.nullable_to_opt($$event.endDate), (function (prim) {
              return new Date(prim);
            })), formatDate);
  var teaserText = Type_String.Teaser.Description.make(Core__Option.getOr(formattedStart, "") + " - " + Core__Option.getOr(formattedEnd, ""));
  var match$2;
  switch (param.participationStatus) {
    case "Participated" :
        match$2 = [
          "Disabled",
          Type_String.CallToAction.make(match$1.eventCtaParticipated)
        ];
        break;
    case "NotParticipated" :
        match$2 = [
          "Enabled",
          Type_String.CallToAction.make(match$1.eventCta)
        ];
        break;
    case "Ended" :
        match$2 = [
          "Disabled",
          Type_String.CallToAction.make(match$1.eventCtaEnded)
        ];
        break;
    
  }
  var eventUrl = $$event.url;
  var eventDislaimer = $$event.disclamerLink;
  var description = teaser.description;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(eventBlockSpace),
              children: [
                JsxRuntime.jsx(Molecule_EventZone.make, {
                      title: teaserTitle,
                      subtitle: teaserSubtitle,
                      teaserText: teaserText,
                      images: teaser.imageSrc,
                      href: teaser.href,
                      trackingPixel: teaser.trackingPixel,
                      id: teaser.id,
                      buttonLabel: match$2[1],
                      textPosition: "TextOnRightSide",
                      blockSpace: Caml_option.some(zoneBlockspace),
                      onCtaClick: param.onParticipateClick,
                      sharebar: "With",
                      ctaStatus: match$2[0],
                      eventShareUrl: Type_String.Href.makeFromNullableOrEmpty($$event.eventShareUrl)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(zoneBlockspace),
                      children: [
                        JsxRuntime.jsxs(Atom_Row.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                              children: [
                                JsxRuntime.jsx(Atom_Icon.make, {
                                      icon: Atom_Icon_Ds.mapMarker,
                                      size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(Type_String.Teaser.Subtitle.toString(teaserSubtitle)),
                                      style: Atom_Text_Ds.ProgramContent.value
                                    })
                              ],
                              gap: Caml_option.some(Particle_Screen_Constants.xxsGap)
                            }),
                        JsxRuntime.jsxs(Atom_Row.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                              children: [
                                JsxRuntime.jsx(Atom_Icon.make, {
                                      icon: Atom_Icon_Ds.calendar,
                                      size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(Type_String.Teaser.Description.toString(teaserText)),
                                      style: Atom_Text_Ds.ProgramContent.value
                                    })
                              ],
                              gap: Caml_option.some(Particle_Screen_Constants.xxsGap)
                            }),
                        JsxRuntime.jsxs(Atom_Row.make, {
                              blockSpace: Caml_option.some(descriptionLinksBlockspace),
                              children: [
                                (eventUrl == null) ? null : JsxRuntime.jsx(Atom_Cta.User.Link.make, {
                                        label: match$1.moreInfos,
                                        href: eventUrl,
                                        target: "_blank",
                                        variant: "outline"
                                      }),
                                (eventDislaimer == null) ? null : JsxRuntime.jsx(Atom_Cta.User.Link.make, {
                                        label: match$1.rules,
                                        href: eventDislaimer,
                                        target: "_blank",
                                        variant: "outline"
                                      })
                              ],
                              gap: Caml_option.some(linksGap)
                            }),
                        description !== undefined ? JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                dangerousHtml: Type_String.Teaser.Description.toString(description),
                                variant: Molecule_DangerousHtml_Ds.userDescription
                              }) : null
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xxsInnerSpace)
                    })
              ],
              kind: "Main"
            });
}

var EventView = {
  make: make$1
};

var rowRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.flexDirection("row"),
      CssJs.flexWrap("wrap")
    ], undefined, undefined, Belt_Array.concatMany([
          [
            CssJs.flexDirection("column"),
            CssJs.flexWrap("nowrap")
          ],
          Atom_Box_Type.getFlexGapRules("XS")
        ]));

var rulesGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

function make$2(param) {
  var onParticipated = param.onParticipated;
  var $$event = param.event;
  var eventDislaimer = $$event.disclamerLink;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.events;
  var errorParticipationEnded = match$1.errorParticipationEnded;
  var errorInvalidEvent = match$1.errorInvalidEvent;
  var errorAlreadyParticipated = match$1.errorAlreadyParticipated;
  var match$2 = Context.I18n.getState();
  var locale = match$2.locale;
  var labels = match$2.labels;
  var match$3 = labels.common;
  var match$4 = labels.mona.profile;
  var userEditStreetPlaceholderLabel = match$4.streetPlaceholder;
  var userEditPostCodePlaceholderLabel = match$4.postCodePlaceholder;
  var userEditLastNamePlaceholderLabel = match$4.lastNamePlaceholder;
  var userEditGenderPlaceHolder = match$4.genderPlaceHolder;
  var userEditFirstNamePlaceholderLabel = match$4.firstNamePlaceholder;
  var errorMessage = match$4.errorMessage;
  var userEmailPlaceholderLabel = match$4.emailPlaceholder;
  var countryLabel = match$4.countryLabel;
  var userEditCityPlaceholderLabel = match$4.cityPlaceholder;
  var match$5 = UseUserFetch.make();
  var setSuccess = match$5.setSuccess;
  var setError = match$5.setError;
  var setLoading = match$5.setLoading;
  var state = match$5.state;
  var handleSubmit = function (param) {
    var formState = param.state;
    var controller = new AbortController();
    var signal = controller.signal;
    var exec = async function (eventId) {
      var userClub = await User_Api__Sso__Me.put({
            args: User_Api__Sso__Me.Put.stateFormToPutUser(formState),
            signal: signal,
            locale: locale
          });
      if (userClub.TAG !== "Ok") {
        return setError(errorMessage);
      }
      User_Observable.UserInfo.updateUserInfos(User_Type.Info.userInfoFromUserClub(Type_Club.User.getFromT(userClub._0)));
      var response = await User_Api__Sso__Event.join({
            args: {
              locale: locale,
              eventId: eventId
            },
            signal: signal,
            locale: locale
          });
      if (response.TAG === "Ok") {
        setSuccess("");
        User_Observable.UserInfo.addEventParticipation(eventId);
        return onParticipated();
      }
      var match = response._0;
      if (typeof match !== "object") {
        return setError(errorMessage);
      }
      if (match.TAG !== "BadRequest") {
        return setError(errorMessage);
      }
      switch (match._0) {
        case "EventInvalid" :
            return setError(errorInvalidEvent);
        case "EventAlreadyParticipated" :
            return setError(errorAlreadyParticipated);
        case "EventParticipationEnded" :
            return setError(errorParticipationEnded);
        default:
          return setError(errorMessage);
      }
    };
    var match = $$event.id;
    if (typeof state !== "object" && state === "Idle" && !(match == null)) {
      setLoading();
      exec(match);
      return ;
    }
    
  };
  var form = User_Api__Sso__Me.Put.Form.use(User_Api__Sso__Me.Put.initFormStateFromUser(param.userInfos), User_Api__Sso__Me.Put.schema, handleSubmit, labels, match$2.formaters, undefined, undefined);
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  var match$6 = form.values;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(zoneBlockspace),
                      children: [
                        JsxRuntime.jsxs(Atom_Cell.make, {
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              children: [
                                JsxRuntime.jsx(Atom_Textfield.User.Readonly.make, {
                                      value: match$6.email,
                                      label: userEmailPlaceholderLabel,
                                      name: userEmailPlaceholderLabel,
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    }),
                                JsxRuntime.jsx(Organism_Dropdown.make, {
                                      kind: {
                                        TAG: "Select",
                                        _0: {
                                          name: userEditGenderPlaceHolder,
                                          title: userEditGenderPlaceHolder,
                                          autoComplete: "honorificPrefix",
                                          variant: "myProfileNewDesign"
                                        }
                                      },
                                      items: User_Api__Sso__Me.Put.genderItems("", match$4.genderMadamValue, match$4.genderSirValue),
                                      label: userEditGenderPlaceHolder,
                                      value: JSON.stringify(Type_Club.User.gender_encode(match$6.gender)),
                                      onChange: (function (item) {
                                          form.handleChange("Gender", Core__Result.getOr(Type_Club.User.gender_decode(JSON.parse(Type_String.Dropdown.Item.toString(item.value))), "Unknown"));
                                        }),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    })
                              ],
                              rules: Caml_option.some(rowRules)
                            }),
                        JsxRuntime.jsxs(Atom_Cell.make, {
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              children: [
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      autoComplete: "family-name",
                                      value: match$6.lastName,
                                      label: userEditLastNamePlaceholderLabel,
                                      onBlur: (function (__x) {
                                          form.handleChange("LastName", __x);
                                        }),
                                      id: "lastName",
                                      name: userEditLastNamePlaceholderLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "LastName"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "LastName"
                                          }),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    }),
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      autoComplete: "given-name",
                                      value: match$6.firstName,
                                      label: userEditFirstNamePlaceholderLabel,
                                      onBlur: (function (__x) {
                                          form.handleChange("FirstName", __x);
                                        }),
                                      id: "firstName",
                                      name: userEditFirstNamePlaceholderLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "FirstName"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "FirstName"
                                          }),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    })
                              ],
                              rules: Caml_option.some(rowRules)
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(zoneBlockspace),
                      children: [
                        JsxRuntime.jsx(Atom_Cell.make, {
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              children: JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                    autoComplete: "street-address",
                                    value: match$6.street,
                                    label: userEditStreetPlaceholderLabel,
                                    onBlur: (function (__x) {
                                        form.handleChange("Street", __x);
                                      }),
                                    id: "street",
                                    name: userEditStreetPlaceholderLabel,
                                    errorMessage: form.getFieldError({
                                          TAG: "Field",
                                          _0: "Street"
                                        }),
                                    inputRef: form.getFieldRef({
                                          TAG: "Field",
                                          _0: "Street"
                                        }),
                                    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                  }),
                              rules: Caml_option.some(rowRules)
                            }),
                        JsxRuntime.jsxs(Atom_Cell.make, {
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              children: [
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      autoComplete: "postal-code",
                                      value: match$6.postCode,
                                      label: userEditPostCodePlaceholderLabel,
                                      onBlur: (function (__x) {
                                          form.handleChange("PostCode", __x);
                                        }),
                                      id: "postCode",
                                      name: userEditPostCodePlaceholderLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "PostCode"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "PostCode"
                                          }),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    }),
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      autoComplete: "address-level2",
                                      value: match$6.city,
                                      label: userEditCityPlaceholderLabel,
                                      onBlur: (function (__x) {
                                          form.handleChange("City", __x);
                                        }),
                                      id: "city",
                                      name: userEditCityPlaceholderLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "City"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "City"
                                          }),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                    })
                              ],
                              rules: Caml_option.some(rowRules)
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              children: JsxRuntime.jsx(Organism_Dropdown.make, {
                                    kind: {
                                      TAG: "Select",
                                      _0: {
                                        name: countryLabel,
                                        title: countryLabel,
                                        autoComplete: "countryName",
                                        variant: "myProfileNewDesign"
                                      }
                                    },
                                    items: param.countries,
                                    label: countryLabel,
                                    value: match$6.country,
                                    onChange: (function (countryItem) {
                                        form.handleChange("Country", Type_String.Dropdown.Item.toString(countryItem.value));
                                      }),
                                    autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable)
                                  }),
                              rules: Caml_option.some(rowRules)
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(zoneBlockspace),
                      children: [
                        (eventDislaimer == null) ? null : JsxRuntime.jsxs(Atom_Row.make, {
                                children: [
                                  JsxRuntime.jsx(Atom_Text.make, {
                                        children: Caml_option.some(match$1.rulesLabel),
                                        kind: "span",
                                        style: Atom_Text_Ds.User.footer
                                      }),
                                  JsxRuntime.jsx(Atom_Cta.User.Link.make, {
                                        label: match$1.rules,
                                        href: eventDislaimer,
                                        variant: "footer"
                                      })
                                ],
                                gap: Caml_option.some(rulesGap)
                              }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              children: match$5.component
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                    label: match$3.confirm,
                                    variant: "base",
                                    type_: "submit"
                                  })
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    })
              ],
              kind: {
                TAG: "Form",
                _0: onSubmit
              }
            });
}

var FormView = {
  rowRules: rowRules,
  rulesGap: rulesGap,
  make: make$2
};

function make$3(param) {
  var redirectToLogin = param.redirectToLogin;
  var $$event = param.event;
  var match = Context.I18n.getState();
  var match$1 = React.useState(function () {
        return "Event";
      });
  var setViewMode = match$1[1];
  var viewMode = match$1[0];
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var match$2 = Toaster$1.Hook.use();
  var toastify = match$2.add;
  var userStatus = User_Observable.UserStatus.Hook.use();
  var eventId = Core__Option.getOr(Caml_option.nullable_to_opt($$event.id), "");
  var match$3 = User_Api__Sso__Event.useGetEventStatus(Core__Option.flatMap(userInfos, User_Type.Info.eventsParticipation), eventId, Caml_option.nullable_to_opt($$event.participationEndDate));
  var setParticipationStatus = match$3[1];
  var match$4 = match.labels.mona.events;
  var countries = (function (__x) {
        return __x.map(function (param) {
                    return {
                            value: Type_String.Dropdown.Item.make(param[0]),
                            label: Type_String.Dropdown.Item.make(param[1])
                          };
                  });
      })(Object.entries(param.countries));
  var emptyCountriesItem_value = Type_String.Dropdown.Item.make("-1");
  var emptyCountriesItem_label = Type_String.Dropdown.Item.empty;
  var emptyCountriesItem = {
    value: emptyCountriesItem_value,
    label: emptyCountriesItem_label
  };
  var countriesItems = Util_Array.NonEmpty.make(countries, emptyCountriesItem);
  var backlinkOverride;
  backlinkOverride = viewMode === "Event" ? undefined : (function () {
        setViewMode(function (param) {
              return "Event";
            });
      });
  var tmp;
  tmp = viewMode === "Event" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs(Atom_Column.make, {
                  blockSpace: Caml_option.some(headerBlockspace),
                  children: [
                    JsxRuntime.jsx(Atom_Backlink.User.make, {
                          onClick: backlinkOverride
                        }),
                    JsxRuntime.jsx(Atom_Text.make, {
                          children: Caml_option.some(match$4.listTitle),
                          kind: "h1",
                          style: Atom_Text_Ds.User.title("MyAccount")
                        })
                  ],
                  innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                }),
            JsxRuntime.jsx(Organism_User_Navigation_Header.make, {
                  currentKey: param.currentKey
                })
          ]
        }) : JsxRuntime.jsxs(Atom_Column.make, {
          blockSpace: Caml_option.some(headerBlockspace),
          children: [
            JsxRuntime.jsx(Atom_Backlink.User.make, {
                  onClick: backlinkOverride
                }),
            JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(match$4.formTitle),
                  kind: "h2",
                  style: Atom_Text_Ds.User.subtitle("MyAccount")
                }),
            JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(match$4.formDescription),
                  style: Atom_Text_Ds.User.text("MyAccount")
                })
          ],
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
        });
  var tmp$1;
  tmp$1 = viewMode === "Event" ? JsxRuntime.jsx(make$1, {
          event: $$event,
          onParticipateClick: (function () {
              switch (userStatus) {
                case "Connected" :
                    return setViewMode(function (param) {
                                return "Form";
                              });
                case "Anonymous" :
                case "Disconnected" :
                    return redirectToLogin();
                
              }
            }),
          participationStatus: match$3[0]
        }) : JsxRuntime.jsx(make$2, {
          event: $$event,
          countries: countriesItems,
          onParticipated: (function () {
              setViewMode(function (param) {
                    return "Event";
                  });
              setParticipationStatus(function (param) {
                    return "Participated";
                  });
              toastify(10000, {});
            }),
          userInfos: userInfos
        });
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Toaster$1.Container.make, {}),
                tmp,
                tmp$1
              ],
              kind: "Section",
              minHeight: Caml_option.some(minHeight)
            });
}

export {
  Toast ,
  EventParticipatedToast ,
  Toaster$1 as Toaster,
  minHeight ,
  headerBlockspace ,
  linksGap ,
  zoneBlockspace ,
  descriptionLinksBlockspace ,
  eventBlockSpace ,
  EventView ,
  FormView ,
  make$3 as make,
}
/* Toaster Not a pure module */
