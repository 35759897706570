// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as User_Provider from "../../user/User_Provider.res.mjs";
import * as Molecule_Avatar from "../molecule/Molecule_Avatar.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Type_User_Navigation from "../../type/Type_User_Navigation.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var rules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.alignSelf("auto"),
      CssJs.minWidth({
            NAME: "px",
            VAL: 454
          })
    ], undefined, undefined, [CssJs.alignSelf("center")]);

var wrapperBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeNone()), undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var titleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"));

var avatarBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XS"));

var innerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var deleteBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"));

function make(param) {
  var surname = param.surname;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = Context.Config.getState();
  var match$2 = labels.mona.navigation;
  var match$3 = labels.mona.deleteAccount;
  var title;
  if (surname.TAG === "Email") {
    var mail = surname._0;
    title = JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(mail),
          style: Atom_Text_Ds.User.cardTitle({
                TAG: "Email",
                _0: mail
              })
        });
  } else {
    var surname$1 = surname._0;
    title = JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(surname$1),
          style: Atom_Text_Ds.User.cardTitle({
                TAG: "FirstName",
                _0: surname$1
              })
        });
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(wrapperBlockSpace),
              children: [
                JsxRuntime.jsx(Molecule_Avatar.make, {
                      avatar: param.avatar,
                      style: "Card"
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(titleBlockSpace),
                      children: title
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(avatarBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: Type_User_Navigation.Profile.makeHref(locale, "avatar"),
                              label: match$2.changeAvatar,
                              style: Atom_Cta_Ds.User.accountLink
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: Type_User_Navigation.Profile.makeHref(locale, "tvlogin"),
                              label: match$2.connectMyTv,
                              style: Atom_Cta_Ds.User.accountLink
                            })
                      ],
                      innerSpace: Caml_option.some(innerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(deleteBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Button.make, {
                              label: match$2.disconnection,
                              onClick: User_Provider.disconnect(match$1.api.onLogoutCallback, undefined, locale),
                              style: Atom_Cta_Ds.User.card
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: Type_User_Navigation.Profile.makeHref(locale, "delete"),
                              label: match$3.title,
                              style: Atom_Cta_Ds.User.accountLink
                            })
                      ],
                      innerSpace: Caml_option.some(innerSpace)
                    })
              ],
              display: param.display,
              rules: Caml_option.some(rules)
            });
}

export {
  rules ,
  wrapperBlockSpace ,
  titleBlockSpace ,
  avatarBlockSpace ,
  innerSpace ,
  deleteBlockSpace ,
  make ,
}
/* rules Not a pure module */
