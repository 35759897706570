// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Emac_Teaser from "../../emac/Emac_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Page_Program from "./Page_Program.res.mjs";
import * as Layout_Program from "../layout/Layout_Program.res.mjs";
import * as Type_Page_Program from "../../type/Type_Page_Program.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var match = Context.I18n.getState();
  var formaters = match.formaters;
  var nextBroadcastDateFormater = formaters.nextBroadcastDate;
  var betweenDatesFormater = formaters.betweenDates;
  var availabilityDateDateFormater = formaters.availabilityDate;
  var match$1 = Context.Config.getState();
  var timeReference = match$1.serverTime;
  var page = Type_Page.fromEmac(availabilityDateDateFormater, betweenDatesFormater, nextBroadcastDateFormater, undefined, undefined, "Direct", param.domain, timeReference, param.page);
  var program = Core__Option.map(param.program, (function (__x) {
          return Emac_Teaser.toDsTeaser(availabilityDateDateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, undefined, "Normal", undefined, undefined, undefined, __x);
        }));
  var layout = Type_Page_Program.makeFromPage(match.labels, page, program, undefined, undefined, undefined, formaters.date, undefined, "Direct", undefined);
  return JsxRuntime.jsx(Layout_Program.make, {
              isPlayNextActivated: Page_Program.Autoplay.ensureIsPlayNextActivated(param.isPlayNextActivated),
              onPlayNextChange: Page_Program.Autoplay.ensureOnPlayNextChange(param.onPlayNextChange),
              onChapterClick: Page_Program.Autoplay.ensureOnChapterClick(param.onChapterClick),
              playedProgramId: param.playedProgramId,
              layout: layout,
              player: param.player
            });
}

export {
  make ,
}
/* Context Not a pure module */
