import i18next from 'i18next';
type Translate = typeof i18next.t;

export const translations = (translate: Translate) => ({
    accessibility: {
        close: translate('accessibility.close'),
        closeSubheader: translate('accessibility.closeSubheader'),
        newWindow: translate('accessibility.newWindow'),
        readLess: translate('accessibility.readLess'),
        readMore: translate('accessibility.readMore'),
        showVideosSortingOptions: translate('accessibility.showVideosSortingOptions'),
        sortVideoBy: translate('accessibility.sortVideoBy'),
    },
    collection: {
        teaser: {
            more: {
                label: translate('collection.teaser.more.label'),
            },
        },
    },
    common: {
        add: translate('common.add'),
        all_videos: translate('common.all_videos'),
        confirm: translate('common.confirm'),
        download: translate('common.download'),
        downloadAll: translate('common.downloadAll'),
        edit: translate('common.edit'),
        filter_by: translate('common.filter_by'),
        firstBroadcast: translate('common.firstBroadcast'),
        fskSubHeader: translate('common.fskSubHeader'),
        fskSubHeaderLink: translate('common.fskSubHeaderLink'),
        loading: translate('common.loading'),
        moreInfos: translate('common.moreInfos'),
        press: translate('common.press'),
        read_less: translate('common.read_less'),
        read_more: translate('common.read_more'),
        remove: translate('common.remove'),
        safariVersionDeprecated: translate('common.safariVersionDeprecated'),
        save: translate('common.save'),
        see_more: translate('common.see_more'),
        setting: translate('common.setting'),
        subHeader: translate('common.subHeader'),
    },
    error: {
        buttonLabel500: translate('error.buttonLabel500'),
        buttonUrl500: translate('error.buttonUrl500'),
        errorCode: translate('error.errorCode'),
        iaSticker: translate('error.iaSticker'),
        subtitle404: translate('error.subtitle404'),
        title500: translate('error.title500'),
        unsupportedBrowserDescription: translate('error.unsupportedBrowserDescription'),
        unsupportedBrowserList: translate('error.unsupportedBrowserList'),
        unsupportedBrowserTitle: translate('error.unsupportedBrowserTitle'),
    },
    feedback: {
        buttonLabel: translate('feedback.buttonLabel'),
        closeLabel: translate('feedback.closeLabel'),
        dataLabel: translate('feedback.dataLabel'),
        dataLink: translate('feedback.dataLink'),
        description: translate('feedback.description'),
        dislikedLabel: translate('feedback.dislikedLabel'),
        emailLabel: translate('feedback.emailLabel'),
        faqLabel: translate('feedback.faqLabel'),
        faqLink: translate('feedback.faqLink'),
        genericError: translate('feedback.genericError'),
        labelFive: translate('feedback.labelFive'),
        labelFour: translate('feedback.labelFour'),
        labelOne: translate('feedback.labelOne'),
        labelThree: translate('feedback.labelThree'),
        labelTwo: translate('feedback.labelTwo'),
        likedLabel: translate('feedback.likedLabel'),
        ratingLabel: translate('feedback.ratingLabel'),
        saveLabel: translate('feedback.saveLabel'),
        successButton: translate('feedback.successButton'),
        successDescription: translate('feedback.successDescription'),
        successTitle: translate('feedback.successTitle'),
        title: translate('feedback.title'),
    },
    formValidation: {
        date: translate('formValidation.date'),
        eitherRequired: translate('formValidation.eitherRequired'),
        email: translate('formValidation.email'),
        emailConfirmation: translate('formValidation.emailConfirmation'),
        password: translate('formValidation.password'),
        passwordConfirmation: translate('formValidation.passwordConfirmation'),
        required: translate('formValidation.required'),
    },
    guide: {
        concerts: {
            soonColumnTitle: translate('guide.concerts.soonColumnTitle'),
        },
        dayAbbreviation: translate('guide.dayAbbreviation'),
        hoursMinutes: translate('guide.hoursMinutes'),
        monthAbbreviation: translate('guide.monthAbbreviation'),
        tv: {
            daySlider: {
                dateFormat: translate('guide.tv.daySlider.dateFormat'),
                dayFormat: translate('guide.tv.daySlider.dayFormat'),
                redirectChooseDate: translate('guide.tv.daySlider.redirectChooseDate'),
                redirectProgram: translate('guide.tv.daySlider.redirectProgram'),
                today: translate('guide.tv.daySlider.today'),
                tomorrow: translate('guide.tv.daySlider.tomorrow'),
                yesterday: translate('guide.tv.daySlider.yesterday'),
            },
            daytime: translate('guide.tv.daytime'),
            evening: translate('guide.tv.evening'),
            morning: translate('guide.tv.morning'),
        },
    },
    header: {
        club: {
            login: translate('header.club.login'),
            pseudo: translate('header.club.pseudo'),
        },
        editProfile: {
            href: translate('header.editProfile.href'),
            title: translate('header.editProfile.title'),
        },
        help: {
            href: translate('header.help.href'),
            title: translate('header.help.title'),
        },
        installApp: translate('header.installApp'),
        myAdvantages: {
            href: translate('header.myAdvantages.href'),
            title: translate('header.myAdvantages.title'),
        },
        myVideosLink: {
            href: translate('header.myVideosLink.href'),
            label: translate('header.myVideosLink.label'),
        },
        redirectionAnchor: {
            redirectMainLabel: translate('header.redirectionAnchor.redirectMainLabel'),
            redirectPlayerLabel: translate('header.redirectionAnchor.redirectPlayerLabel'),
            redirectSearchLabel: translate('header.redirectionAnchor.redirectSearchLabel'),
        },
        registration: {
            href: translate('header.registration.href'),
            title: translate('header.registration.title'),
        },
        searchNavigation: {
            closeLabel: translate('header.searchNavigation.closeLabel'),
            displayLabel: translate('header.searchNavigation.displayLabel'),
            href: translate('header.searchNavigation.href'),
        },
        settings: {
            href: translate('header.settings.href'),
            title: translate('header.settings.title'),
        },
    },
    label: {
        live: translate('label.live'),
    },
    mona: {
        advantage: {
            subscribedLabel: translate('mona.advantage.subscribedLabel'),
        },
        deleteAccount: {
            description: translate('mona.deleteAccount.description'),
            title: translate('mona.deleteAccount.title'),
        },
        editEmail: {
            description: translate('mona.editEmail.description'),
            errorAlreadyRegistered: translate('mona.editEmail.errorAlreadyRegistered'),
            errorGeneric: translate('mona.editEmail.errorGeneric'),
            placeholder: translate('mona.editEmail.placeholder'),
            placeholderConfirmation: translate('mona.editEmail.placeholderConfirmation'),
            success: translate('mona.editEmail.success'),
        },
        editPassword: {
            newPasswordPlaceholder: translate('mona.editPassword.newPasswordPlaceholder'),
            newPasswordPlaceholderConfirmation: translate('mona.editPassword.newPasswordPlaceholderConfirmation'),
            oldPasswordPlaceholder: translate('mona.editPassword.oldPasswordPlaceholder'),
            success: translate('mona.editPassword.success'),
            title: translate('mona.editPassword.title'),
        },
        events: {
            errorAlreadyParticipated: translate('mona.events.errorAlreadyParticipated'),
            errorInvalidEvent: translate('mona.events.errorInvalidEvent'),
            errorParticipationEnded: translate('mona.events.errorParticipationEnded'),
            eventCta: translate('mona.events.eventCta'),
            eventCtaEnded: translate('mona.events.eventCtaEnded'),
            eventCtaParticipated: translate('mona.events.eventCtaParticipated'),
            formDescription: translate('mona.events.formDescription'),
            formTitle: translate('mona.events.formTitle'),
            listTitle: translate('mona.events.listTitle'),
            moreInfos: translate('mona.events.moreInfos'),
            participatedToastMessage: translate('mona.events.participatedToastMessage'),
            rules: translate('mona.events.rules'),
            rulesLabel: translate('mona.events.rulesLabel'),
        },
        fsk: {
            description: translate('mona.fsk.description'),
            title: translate('mona.fsk.title'),
            verified16: translate('mona.fsk.verified16'),
            verified18: translate('mona.fsk.verified18'),
            verifybutton: translate('mona.fsk.verifybutton'),
        },
        login: {
            emailNotVerified: translate('mona.login.emailNotVerified'),
            footerLinkLabel: translate('mona.login.footerLinkLabel'),
            footerLinkUrl: translate('mona.login.footerLinkUrl'),
            forgotPassword: translate('mona.login.forgotPassword'),
            invalidCredentials: translate('mona.login.invalidCredentials'),
            landingButton: translate('mona.login.landingButton'),
            landingTitle: translate('mona.login.landingTitle'),
            loginButton: translate('mona.login.loginButton'),
            magicLinkButton: translate('mona.login.magicLinkButton'),
            magicLinkTitle: translate('mona.login.magicLinkTitle'),
            newPasswordTitle: translate('mona.login.newPasswordTitle'),
            passwordPlaceholder: translate('mona.login.passwordPlaceholder'),
            requestPasswordButton: translate('mona.login.requestPasswordButton'),
            requestPasswordCancelButton: translate('mona.login.requestPasswordCancelButton'),
            requestPasswordResetButton: translate('mona.login.requestPasswordResetButton'),
            requestPasswordSubtitle: translate('mona.login.requestPasswordSubtitle'),
            requestPasswordSuccessButton: translate('mona.login.requestPasswordSuccessButton'),
            requestPasswordSuccessTitle: translate('mona.login.requestPasswordSuccessTitle'),
            requestPasswordTitle: translate('mona.login.requestPasswordTitle'),
            title: translate('mona.login.title'),
            tvLoginDescription: translate('mona.login.tvLoginDescription'),
            tvTitle: translate('mona.login.tvTitle'),
        },
        magicLink: {
            reSendMailButton: translate('mona.magicLink.reSendMailButton'),
            subtitle: translate('mona.magicLink.subtitle'),
        },
        myVideos: {
            emptyFavoriteTeaserTitle: translate('mona.myVideos.emptyFavoriteTeaserTitle'),
            emptyHistoryTeaserTitle: translate('mona.myVideos.emptyHistoryTeaserTitle'),
            emptyPersonalZoneTeaserTitle: translate('mona.myVideos.emptyPersonalZoneTeaserTitle'),
            purgeFavoriteModalText: translate('mona.myVideos.purgeFavoriteModalText'),
            purgeFavoriteModalTitle: translate('mona.myVideos.purgeFavoriteModalTitle'),
            purgeHistoryModalText: translate('mona.myVideos.purgeHistoryModalText'),
            purgeHistoryModalTitle: translate('mona.myVideos.purgeHistoryModalTitle'),
            purgeModalCancel: translate('mona.myVideos.purgeModalCancel'),
            purgeModalConfirm: translate('mona.myVideos.purgeModalConfirm'),
        },
        navigation: {
            changeAvatar: translate('mona.navigation.changeAvatar'),
            connectMyTv: translate('mona.navigation.connectMyTv'),
            disconnect: translate('mona.navigation.disconnect'),
            disconnection: translate('mona.navigation.disconnection'),
            events: translate('mona.navigation.events'),
            eventsFavorite: translate('mona.navigation.eventsFavorite'),
            magazine: translate('mona.navigation.magazine'),
            myAccount: translate('mona.navigation.myAccount'),
            myAccountDescription: translate('mona.navigation.myAccountDescription'),
            myVideosTitle: translate('mona.navigation.myVideosTitle'),
        },
        profile: {
            addressDescription: translate('mona.profile.addressDescription'),
            addressTitle: translate('mona.profile.addressTitle'),
            avatarListTitle: translate('mona.profile.avatarListTitle'),
            backLabel: translate('mona.profile.backLabel'),
            birthdayDayLabel: translate('mona.profile.birthdayDayLabel'),
            birthdayMonthLabel: translate('mona.profile.birthdayMonthLabel'),
            birthdayTitle: translate('mona.profile.birthdayTitle'),
            birthdayYearLabel: translate('mona.profile.birthdayYearLabel'),
            cityPlaceholder: translate('mona.profile.cityPlaceholder'),
            countryLabel: translate('mona.profile.countryLabel'),
            emailPlaceholder: translate('mona.profile.emailPlaceholder'),
            errorMessage: translate('mona.profile.errorMessage'),
            firstNamePlaceholder: translate('mona.profile.firstNamePlaceholder'),
            genderMadamValue: translate('mona.profile.genderMadamValue'),
            genderPlaceHolder: translate('mona.profile.genderPlaceHolder'),
            genderSirValue: translate('mona.profile.genderSirValue'),
            lastNamePlaceholder: translate('mona.profile.lastNamePlaceholder'),
            postCodePlaceholder: translate('mona.profile.postCodePlaceholder'),
            streetPlaceholder: translate('mona.profile.streetPlaceholder'),
            sucessMessage: translate('mona.profile.sucessMessage'),
            title: translate('mona.profile.title'),
        },
        register: {
            acceptTosCguLabel: translate('mona.register.acceptTosCguLabel'),
            acceptTosCguLink: translate('mona.register.acceptTosCguLink'),
            acceptTosDataLabel: translate('mona.register.acceptTosDataLabel'),
            acceptTosDataLink: translate('mona.register.acceptTosDataLink'),
            description: translate('mona.register.description'),
            emailAccessibilityLabel: translate('mona.register.emailAccessibilityLabel'),
            emailActionLabel: translate('mona.register.emailActionLabel'),
            footerLabel: translate('mona.register.footerLabel'),
            footerLinkLabel: translate('mona.register.footerLinkLabel'),
            footerLinkUrl: translate('mona.register.footerLinkUrl'),
            passwordAccessibilityLabel: translate('mona.register.passwordAccessibilityLabel'),
            passwordAccessibilityLabelOff: translate('mona.register.passwordAccessibilityLabelOff'),
            passwordAccessibilityLabelOn: translate('mona.register.passwordAccessibilityLabelOn'),
            passwordActionLabelOff: translate('mona.register.passwordActionLabelOff'),
            passwordActionLabelOn: translate('mona.register.passwordActionLabelOn'),
            registerButton: translate('mona.register.registerButton'),
            title: translate('mona.register.title'),
            verifyDescription: translate('mona.register.verifyDescription'),
            verifyFooter: translate('mona.register.verifyFooter'),
            verifyMainButton: translate('mona.register.verifyMainButton'),
            verifySecondaryButton: translate('mona.register.verifySecondaryButton'),
            verifySubtitle: translate('mona.register.verifySubtitle'),
        },
        subHeader: {
            accountCreatedLinkLabel: translate('mona.subHeader.accountCreatedLinkLabel'),
            accountCreatedLinkUrl: translate('mona.subHeader.accountCreatedLinkUrl'),
            accountCreatedText: translate('mona.subHeader.accountCreatedText'),
            accountLoggedInText: translate('mona.subHeader.accountLoggedInText'),
            expiredMagicLinkText: translate('mona.subHeader.expiredMagicLinkText'),
            updatedPasswordSuccessText: translate('mona.subHeader.updatedPasswordSuccessText'),
        },
        tvAgeVerification: {
            ageVerifiedDescription: translate('mona.tvAgeVerification.ageVerifiedDescription'),
            ageVerifiedPrimaryButton: translate('mona.tvAgeVerification.ageVerifiedPrimaryButton'),
            userCheckConnectedAccountDescription: translate(
                'mona.tvAgeVerification.userCheckConnectedAccountDescription',
            ),
            userCheckDescription: translate('mona.tvAgeVerification.userCheckDescription'),
            userCheckPrimaryButton: translate('mona.tvAgeVerification.userCheckPrimaryButton'),
            userCheckSecondaryButton: translate('mona.tvAgeVerification.userCheckSecondaryButton'),
            userCheckTitle: translate('mona.tvAgeVerification.userCheckTitle'),
        },
        tvConnection: {
            description: translate('mona.tvConnection.description'),
            title: translate('mona.tvConnection.title'),
        },
        tvLogin: {
            ageVerifiedTitle: translate('mona.tvLogin.ageVerifiedTitle'),
        },
        verificationFailed: {
            buttonLabel: translate('mona.verificationFailed.buttonLabel'),
            contactButtonLabel: translate('mona.verificationFailed.contactButtonLabel'),
            contactButtonLinkUrl: translate('mona.verificationFailed.contactButtonLinkUrl'),
            contactLabel: translate('mona.verificationFailed.contactLabel'),
            subtitle: translate('mona.verificationFailed.subtitle'),
            title: translate('mona.verificationFailed.title'),
        },
        verificationPending: {
            buttonLabel: translate('mona.verificationPending.buttonLabel'),
            subtitle: translate('mona.verificationPending.subtitle'),
            text: translate('mona.verificationPending.text'),
            title: translate('mona.verificationPending.title'),
        },
    },
    navbar: {
        mainNavigation: translate('navbar.mainNavigation'),
        secondaryNavigation: translate('navbar.secondaryNavigation'),
    },
    program: {
        accessible: translate('program.accessible'),
        ageRestriction_cta: translate('program.ageRestriction_cta'),
        ageRestriction_label: translate('program.ageRestriction_label'),
        audiodescription: translate('program.audiodescription'),
        available: translate('program.available'),
        chapter_title: translate('program.chapter_title'),
        chapter_title_concert: translate('program.chapter_title_concert'),
        duration: translate('program.duration'),
        genre: translate('program.genre'),
        geoblockedLabel: translate('program.geoblockedLabel'),
        geoblockedLinkLabel: translate('program.geoblockedLinkLabel'),
        geoblockedLinkUrl: translate('program.geoblockedLinkUrl'),
        history_delete: translate('program.history_delete'),
        in_partnership_with: translate('program.in_partnership_with'),
        live_on: translate('program.live_on'),
        next_broadcast: translate('program.next_broadcast'),
        premiere_date_format: translate('program.premiere_date_format'),
        showOf: translate('program.showOf'),
        to_be_continued: translate('program.to_be_continued'),
        trailer_button: translate('program.trailer_button'),
        vod: translate('program.vod'),
        vod_discover: translate('program.vod_discover'),
    },
    reminder: {
        alert: {
            add: translate('reminder.alert.add'),
        },
        subscribe: {
            add: translate('reminder.subscribe.add'),
            remove: translate('reminder.subscribe.remove'),
        },
    },
    replay_internal: {
        ebuBox: {
            id: translate('replay_internal.ebuBox.id'),
            subtitle: translate('replay_internal.ebuBox.subtitle'),
            title: translate('replay_internal.ebuBox.title'),
        },
        events: {
            listDescription: translate('replay_internal.events.listDescription'),
            listFavoriteDescription: translate('replay_internal.events.listFavoriteDescription'),
            listFavoriteLabel: translate('replay_internal.events.listFavoriteLabel'),
            listFavoriteSubtitle: translate('replay_internal.events.listFavoriteSubtitle'),
            listLabel: translate('replay_internal.events.listLabel'),
            listSubtitle: translate('replay_internal.events.listSubtitle'),
            magazineLabel: translate('replay_internal.events.magazineLabel'),
        },
        fail: {
            recommendationTitle: translate('replay_internal.fail.recommendationTitle'),
        },
        formaters: {
            accessibility: {
                watchTrailer: translate('replay_internal.formaters.accessibility.watchTrailer'),
            },
            collection: {
                continue_watching: translate('replay_internal.formaters.collection.continue_watching'),
                season_count_one: translate('replay_internal.formaters.collection.season_count_one'),
                season_count_other: translate('replay_internal.formaters.collection.season_count_other'),
                season_number: translate('replay_internal.formaters.collection.season_number'),
            },
            feedback: {
                acceptData: translate('replay_internal.formaters.feedback.acceptData'),
                checkFaq: translate('replay_internal.formaters.feedback.checkFaq'),
            },
            formValidation: {
                stringMax: translate('replay_internal.formaters.formValidation.stringMax'),
                stringMin: translate('replay_internal.formaters.formValidation.stringMin'),
            },
            guide_concerts: {
                weekInterval: {
                    firstDateFormat: translate('replay_internal.formaters.guide_concerts.weekInterval.firstDateFormat'),
                    label: translate('replay_internal.formaters.guide_concerts.weekInterval.label'),
                    lastDateFormat: translate('replay_internal.formaters.guide_concerts.weekInterval.lastDateFormat'),
                },
            },
            guide_tv: {
                daySlider_infoSelectedDay: translate('replay_internal.formaters.guide_tv.daySlider_infoSelectedDay'),
            },
            live: {
                dateFormat: translate('replay_internal.formaters.live.dateFormat'),
            },
            myArte: {
                acceptTosLabel: translate('replay_internal.formaters.myArte.acceptTosLabel'),
                forgottenPasswordSuccess: translate('replay_internal.formaters.myArte.forgottenPasswordSuccess'),
                login: {
                    forgottenPasswordSuccess: translate(
                        'replay_internal.formaters.myArte.login.forgottenPasswordSuccess',
                    ),
                    magicLink: translate('replay_internal.formaters.myArte.login.magicLink'),
                },
                magicLink: translate('replay_internal.formaters.myArte.magicLink'),
                register: {
                    acceptTosLabel: translate('replay_internal.formaters.myArte.register.acceptTosLabel'),
                    registerConfirmSuccess: translate(
                        'replay_internal.formaters.myArte.register.registerConfirmSuccess',
                    ),
                },
            },
            program: {
                available_between: translate('replay_internal.formaters.program.available_between'),
                broadcast_date_format: translate('replay_internal.formaters.program.broadcast_date_format'),
                date_format: translate('replay_internal.formaters.program.date_format'),
                formatted_daysremaining_one: translate('replay_internal.formaters.program.formatted_daysremaining_one'),
                formatted_daysremaining_other: translate(
                    'replay_internal.formaters.program.formatted_daysremaining_other',
                ),
                formatted_notavailable: translate('replay_internal.formaters.program.formatted_notavailable'),
                formatted_until: translate('replay_internal.formaters.program.formatted_until'),
                playerFskFaqUrl: translate('replay_internal.formaters.program.playerFskFaqUrl'),
                videoplayer: translate('replay_internal.formaters.program.videoplayer'),
                watch_full_video: translate('replay_internal.formaters.program.watch_full_video'),
            },
            search: {
                sort_by: {
                    all: translate('replay_internal.formaters.search.sort_by.all'),
                    concerts: translate('replay_internal.formaters.search.sort_by.concerts'),
                    documentaries: translate('replay_internal.formaters.search.sort_by.documentaries'),
                    movies: translate('replay_internal.formaters.search.sort_by.movies'),
                    tv_series: translate('replay_internal.formaters.search.sort_by.tv_series'),
                    tv_shows: translate('replay_internal.formaters.search.sort_by.tv_shows'),
                },
            },
            teasers: {
                availability: {
                    available_on: translate('replay_internal.formaters.teasers.availability.available_on'),
                    available_on_today: translate('replay_internal.formaters.teasers.availability.available_on_today'),
                    broadcast: translate('replay_internal.formaters.teasers.availability.broadcast'),
                    broadcast_on: translate('replay_internal.formaters.teasers.availability.broadcast_on'),
                    broadcast_on_today: translate('replay_internal.formaters.teasers.availability.broadcast_on_today'),
                    formats: {
                        date: translate('replay_internal.formaters.teasers.availability.formats.date'),
                        hour: translate('replay_internal.formaters.teasers.availability.formats.hour'),
                    },
                    live_on: translate('replay_internal.formaters.teasers.availability.live_on'),
                    live_on_today: translate('replay_internal.formaters.teasers.availability.live_on_today'),
                    livestream_on: translate('replay_internal.formaters.teasers.availability.livestream_on'),
                    livestream_on_today: translate(
                        'replay_internal.formaters.teasers.availability.livestream_on_today',
                    ),
                },
                favorite: {
                    add: translate('replay_internal.formaters.teasers.favorite.add'),
                    remove: translate('replay_internal.formaters.teasers.favorite.remove'),
                },
                hourFormat: translate('replay_internal.formaters.teasers.hourFormat'),
                longDateFormat: translate('replay_internal.formaters.teasers.longDateFormat'),
                progress: {
                    label: translate('replay_internal.formaters.teasers.progress.label'),
                },
                reminder: {
                    add: translate('replay_internal.formaters.teasers.reminder.add'),
                    remove: translate('replay_internal.formaters.teasers.reminder.remove'),
                },
                shortDateFormat: translate('replay_internal.formaters.teasers.shortDateFormat'),
            },
            videos: {
                sort_by: {
                    AUDIO_DESCRIPTION: translate('replay_internal.formaters.videos.sort_by.AUDIO_DESCRIPTION'),
                    CLOSED_CAPTIONING: translate('replay_internal.formaters.videos.sort_by.CLOSED_CAPTIONING'),
                    LAST_CHANCE: translate('replay_internal.formaters.videos.sort_by.LAST_CHANCE'),
                    LONGER_DURATION: translate('replay_internal.formaters.videos.sort_by.LONGER_DURATION'),
                    LONG_DURATION: translate('replay_internal.formaters.videos.sort_by.LONG_DURATION'),
                    MEDIUM_DURATION: translate('replay_internal.formaters.videos.sort_by.MEDIUM_DURATION'),
                    MOST_RECENT: translate('replay_internal.formaters.videos.sort_by.MOST_RECENT'),
                    MOST_VIEWED: translate('replay_internal.formaters.videos.sort_by.MOST_VIEWED'),
                    SHORT_DURATION: translate('replay_internal.formaters.videos.sort_by.SHORT_DURATION'),
                },
            },
        },
        newsletter: {
            duplicate: translate('replay_internal.newsletter.duplicate'),
            error: translate('replay_internal.newsletter.error'),
            success: translate('replay_internal.newsletter.success'),
        },
        search: {
            browse_categories: translate('replay_internal.search.browse_categories'),
            find_home_selection: translate('replay_internal.search.find_home_selection'),
            links: {
                AUDIO_DESCRIPTION: translate('replay_internal.search.links.AUDIO_DESCRIPTION'),
                LAST_CHANCE: translate('replay_internal.search.links.LAST_CHANCE'),
                MAGAZINES: translate('replay_internal.search.links.MAGAZINES'),
                MOST_RECENT: translate('replay_internal.search.links.MOST_RECENT'),
                MOST_VIEWED: translate('replay_internal.search.links.MOST_VIEWED'),
            },
            no_result_subtitle: translate('replay_internal.search.no_result_subtitle'),
            try_with_other_keywords: translate('replay_internal.search.try_with_other_keywords'),
        },
        seo: {
            description: {
                category: {
                    ACT: translate('replay_internal.seo.description.category.ACT'),
                    CIN: translate('replay_internal.seo.description.category.CIN'),
                    CPO: translate('replay_internal.seo.description.category.CPO'),
                    DEC: translate('replay_internal.seo.description.category.DEC'),
                    DOR: translate('replay_internal.seo.description.category.DOR'),
                    EMI: translate('replay_internal.seo.description.category.EMI'),
                    HIS: translate('replay_internal.seo.description.category.HIS'),
                    SCI: translate('replay_internal.seo.description.category.SCI'),
                    SER: translate('replay_internal.seo.description.category.SER'),
                },
                concert: translate('replay_internal.seo.description.concert'),
                concert_genre: translate('replay_internal.seo.description.concert_genre'),
                eventList: translate('replay_internal.seo.description.eventList'),
                eventListFavorite: translate('replay_internal.seo.description.eventListFavorite'),
                feedback: translate('replay_internal.seo.description.feedback'),
                feedbackSuccess: translate('replay_internal.seo.description.feedbackSuccess'),
                genre: {
                    DOR: translate('replay_internal.seo.description.genre.DOR'),
                    EMI: translate('replay_internal.seo.description.genre.EMI'),
                },
                login: translate('replay_internal.seo.description.login'),
                profile: translate('replay_internal.seo.description.profile'),
                recommendation: translate('replay_internal.seo.description.recommendation'),
                register: translate('replay_internal.seo.description.register'),
                'reset-password': translate('replay_internal.seo.description.reset-password'),
                setting: translate('replay_internal.seo.description.setting'),
                sitemap: translate('replay_internal.seo.description.sitemap'),
                'tv-age-verification': translate('replay_internal.seo.description.tv-age-verification'),
                'tv-login': translate('replay_internal.seo.description.tv-login'),
                'verification-failed': translate('replay_internal.seo.description.verification-failed'),
                'verification-pending': translate('replay_internal.seo.description.verification-pending'),
                'verify-email': translate('replay_internal.seo.description.verify-email'),
            },
            label: {
                arte: translate('replay_internal.seo.label.arte'),
                arte_concert: translate('replay_internal.seo.label.arte_concert'),
            },
            title: {
                avatar: translate('replay_internal.seo.title.avatar'),
                category: {
                    ACT: translate('replay_internal.seo.title.category.ACT'),
                    CIN: translate('replay_internal.seo.title.category.CIN'),
                    CPO: translate('replay_internal.seo.title.category.CPO'),
                    DEC: translate('replay_internal.seo.title.category.DEC'),
                    DOR: translate('replay_internal.seo.title.category.DOR'),
                    EMI: translate('replay_internal.seo.title.category.EMI'),
                    HIS: translate('replay_internal.seo.title.category.HIS'),
                    SCI: translate('replay_internal.seo.title.category.SCI'),
                    SER: translate('replay_internal.seo.title.category.SER'),
                },
                concert_classic: translate('replay_internal.seo.title.concert_classic'),
                concert_modern: translate('replay_internal.seo.title.concert_modern'),
                eventList: translate('replay_internal.seo.title.eventList'),
                eventListFavorite: translate('replay_internal.seo.title.eventListFavorite'),
                eventUnitary: translate('replay_internal.seo.title.eventUnitary'),
                feedback: translate('replay_internal.seo.title.feedback'),
                feedbackSuccess: translate('replay_internal.seo.title.feedbackSuccess'),
                login: translate('replay_internal.seo.title.login'),
                profile: translate('replay_internal.seo.title.profile'),
                recommendation: translate('replay_internal.seo.title.recommendation'),
                register: translate('replay_internal.seo.title.register'),
                'reset-password': translate('replay_internal.seo.title.reset-password'),
                sitemap: translate('replay_internal.seo.title.sitemap'),
                'tv-age-verification': translate('replay_internal.seo.title.tv-age-verification'),
                'tv-login': translate('replay_internal.seo.title.tv-login'),
                'verification-failed': translate('replay_internal.seo.title.verification-failed'),
                'verification-pending': translate('replay_internal.seo.title.verification-pending'),
                'verify-email': translate('replay_internal.seo.title.verify-email'),
            },
        },
    },
    search: {
        currently_loading: translate('search.currently_loading'),
        placeholder: translate('search.placeholder'),
        search_results_for: translate('search.search_results_for'),
        searching: translate('search.searching'),
        what_are_you_looking_for: translate('search.what_are_you_looking_for'),
    },
    setting: {
        autoplayDescription: translate('setting.autoplayDescription'),
        autoplayTitle: translate('setting.autoplayTitle'),
        clipAutoplayDescription: translate('setting.clipAutoplayDescription'),
        clipAutoplayTitle: translate('setting.clipAutoplayTitle'),
        cookieDescription: translate('setting.cookieDescription'),
        cookieLabel: translate('setting.cookieLabel'),
        cookieTitle: translate('setting.cookieTitle'),
        playerAutoplayDescription: translate('setting.playerAutoplayDescription'),
        playerAutoplayTitle: translate('setting.playerAutoplayTitle'),
        sobrietyMessageAriaLabel: translate('setting.sobrietyMessageAriaLabel'),
        sobrietyMessageLinkLabel: translate('setting.sobrietyMessageLinkLabel'),
        sobrietyMessageLinkUrl: translate('setting.sobrietyMessageLinkUrl'),
        sobrietyMessageText: translate('setting.sobrietyMessageText'),
        sobrietyMessageTitle: translate('setting.sobrietyMessageTitle'),
        userDescription: translate('setting.userDescription'),
        userTargetedCommunicationDescription: translate('setting.userTargetedCommunicationDescription'),
        userTargetedCommunicationTitle: translate('setting.userTargetedCommunicationTitle'),
        userTitle: translate('setting.userTitle'),
        videoQualityAuto: translate('setting.videoQualityAuto'),
        videoQualityDescription: translate('setting.videoQualityDescription'),
        videoQualityEco: translate('setting.videoQualityEco'),
        videoQualityTitle: translate('setting.videoQualityTitle'),
    },
    shorts: {
        more_shorts: translate('shorts.more_shorts'),
        title: translate('shorts.title'),
    },
    teaser: {
        durationUnit: translate('teaser.durationUnit'),
    },
    teasers: {
        carousel: {
            goToSlide: translate('teasers.carousel.goToSlide'),
            pause: translate('teasers.carousel.pause'),
        },
        continueWatching: translate('teasers.continueWatching'),
        live: translate('teasers.live'),
        livestream: translate('teasers.livestream'),
        moreOptions: translate('teasers.moreOptions'),
        watch: translate('teasers.watch'),
        watchAgain: translate('teasers.watchAgain'),
        watchLive: translate('teasers.watchLive'),
        watchLivestream: translate('teasers.watchLivestream'),
    },
    userPref: {
        message: translate('userPref.message'),
        setupCookies: translate('userPref.setupCookies'),
    },
    videos: {
        sort_by_label: translate('videos.sort_by_label'),
    },
});
