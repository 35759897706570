// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

var display = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.grid);

var direction = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "row");

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());

function Atom_Grid(props) {
  var __blockSpace = props.blockSpace;
  var blockSpace$1 = __blockSpace !== undefined ? Caml_option.valFromOption(__blockSpace) : blockSpace;
  var gapColumnRules = Core__Option.map(props.gapColumn, (function (dsScreen) {
          return Particle_Screen_Ds.map(dsScreen, (function (value) {
                        return [CssJs.gridColumnGap(Particle_Css.spacerSizeInside(value))];
                      }));
        }));
  var gapRowRules = Core__Option.map(props.gapRow, (function (dsScreen) {
          return Particle_Screen_Ds.map(dsScreen, (function (value) {
                        return [CssJs.gridRowGap(Particle_Css.spacerSizeInside(value))];
                      }));
        }));
  var rules = Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
            gapRowRules,
            gapColumnRules,
            props.rules
          ]));
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              alignItems: props.alignItems,
              alignSelf: props.alignSelf,
              area: props.area,
              autoSize: props.autoSize,
              background: props.background,
              border: props.border,
              children: Caml_option.some(props.children),
              deviceRules: props.deviceRules,
              direction: Caml_option.some(direction),
              display: Caml_option.some(display),
              displayOrder: props.displayOrder,
              gap: props.gap,
              height: props.height,
              id: props.id,
              identifier: props.identifier,
              justifyContent: props.justifyContent,
              kind: props.kind,
              maxWidth: props.maxWidth,
              rules: Caml_option.some(rules),
              spacer: Caml_option.some(blockSpace$1),
              width: props.width,
              ref_: props.ref_
            });
}

Atom_Grid.displayName = "Grid";

var make = Atom_Grid;

export {
  display ,
  direction ,
  blockSpace ,
  make ,
}
/* display Not a pure module */
