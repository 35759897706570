// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Type_String from "./Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "./Type_Sticker.res.mjs";

function status_encode(v) {
  if (v === "Favorite") {
    return ["Favorite"];
  } else {
    return ["NotFavorite"];
  }
}

function status_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Favorite" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Favorite"
                  };
          }
      case "NotFavorite" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "NotFavorite"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function t_encode(v) {
  if (typeof v !== "object") {
    return ["NotAvailable"];
  } else if (v.TAG === "Available") {
    return [
            "Available",
            status_encode(v._0)
          ];
  } else {
    return [
            "Upcoming",
            status_encode(v._0)
          ];
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Available" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = status_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Available",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "NotAvailable" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "NotAvailable"
                  };
          }
      case "Upcoming" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = status_decode(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Upcoming",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

var Favorite = {
  status_encode: status_encode,
  status_decode: status_decode,
  t_encode: t_encode,
  t_decode: t_decode
};

function fromStickers(stickers) {
  var match = stickers.topLeft;
  if (match === undefined) {
    return "Standard";
  }
  if (typeof match !== "object") {
    if (match === "PrimeTime") {
      return "PrimeTime";
    } else {
      return "Standard";
    }
  }
  var variant = match.NAME;
  if (variant === "Direct") {
    return "Direct";
  } else if (variant === "Live") {
    return "Live";
  } else {
    return "Standard";
  }
}

function view(t) {
  return t;
}

var Kind_progress = {
  NAME: "Progress",
  VAL: 50.3
};

var Kind = {
  fromStickers: fromStickers,
  view: view,
  standard: "Standard",
  live: "Live",
  progress: Kind_progress,
  direct: "Direct"
};

function getSubtitleDsStyle(titleStyle, subtitleStyle, style) {
  if (style === "TitleStyle") {
    return titleStyle;
  } else {
    return subtitleStyle;
  }
}

function computeDuration(duration) {
  return Math.ceil(duration / 60) | 0;
}

function getDurationLabel(minutesLabel, duration) {
  var durationInMinutes;
  durationInMinutes = duration.TAG === "Minute" ? duration._0 : computeDuration(duration._0);
  return durationInMinutes.toString() + (" " + minutesLabel);
}

function addDurationSticker(durationLabel, teaserKind, stickers) {
  if (typeof teaserKind !== "object" && (teaserKind === "Live" || teaserKind === "Direct")) {
    return stickers;
  }
  return Core__Option.getOr(Core__Option.map(Core__Option.map(durationLabel, Type_String.Teaser.DurationLabel.toString), (function (extra) {
                    return Type_Sticker.addTimeSticker(stickers, extra);
                  })), stickers);
}

function addClubDurationSticker(transformDuration, duration, teaserKind, stickers) {
  if (typeof teaserKind !== "object" && (teaserKind === "Live" || teaserKind === "Direct" || teaserKind === "PrimeTime")) {
    return stickers;
  }
  return Core__Option.getOr(Core__Option.map(Core__Option.map(duration, transformDuration), (function (extra) {
                    return Type_Sticker.addTimeSticker(stickers, extra);
                  })), stickers);
}

function makeDsId(programId, teaserId) {
  if (programId !== undefined) {
    return programId;
  } else if (teaserId !== undefined) {
    return teaserId;
  } else {
    return Uuid.v4();
  }
}

function makeKey(zoneId, dsId) {
  return Type_String.Teaser.Key.make(zoneId !== undefined ? (
                dsId !== undefined ? Type_String.Zone.Id.toString(zoneId) + Type_String.Teaser.DsId.toString(dsId) : Uuid.v4()
              ) : (
                dsId !== undefined ? Type_String.Teaser.DsId.toString(dsId) : Uuid.v4()
              ));
}

function extractTitle(teaser) {
  var match = teaser.title;
  if (match !== undefined) {
    return match._0;
  }
  
}

function titleToString(title) {
  return Type_String.Teaser.Title.toString(title._0);
}

function extractSubtitleString(subtitle) {
  if (subtitle !== undefined) {
    if (subtitle.TAG === "Displayed") {
      return subtitle.label;
    } else {
      return subtitle._0;
    }
  }
  
}

var Helper = {
  computeDuration: computeDuration,
  getDurationLabel: getDurationLabel,
  addDurationSticker: addDurationSticker,
  addClubDurationSticker: addClubDurationSticker,
  makeDsId: makeDsId,
  makeKey: makeKey,
  extractTitle: extractTitle,
  titleToString: titleToString,
  extractSubtitleString: extractSubtitleString
};

export {
  Favorite ,
  Kind ,
  getSubtitleDsStyle ,
  Helper ,
}
/* uuid Not a pure module */
