// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var rules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.paddingTop(CssJs.px(0))], undefined, undefined, [CssJs.paddingTop(CssJs.pct(56.25))]);

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Constants.Zone.firstDesktop), undefined, undefined, Particle_Spacer_Constants.Zone.firstMobile);

var display = Util_Css.Display.untilL;

var blockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("M")), undefined, Particle_Spacer_Ds.makeBottom("S"));

var Backlink = {
  blockSpace: blockSpace$1
};

var containerRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.justifyContent(CssJs.flexEnd)], undefined, undefined, []);

var width = Particle_Screen_Ds.make(undefined, undefined, Css.pct(70), undefined, undefined, CssJs.pct(100));

var rules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.opacity(0.8),
      CssJs.alignSelf(CssJs.flexStart)
    ]);

var $$Image = {
  containerRules: containerRules,
  widthBreakpointM: 70,
  width: width,
  rules: rules$1
};

var width$1 = Particle_Screen_Ds.make(undefined, undefined, Css.pct(45), undefined, undefined, CssJs.pct(100));

var rules$2 = Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.minHeight(CssJs.px(495))], undefined, [Particle_zIndex.Particle_zIndex_Css.toCss({
            TAG: "Exact",
            _0: "ContentCollection"
          })]);

var containerRules$1 = Particle_Screen_Ds.make(undefined, undefined, [CssJs.flex({
            NAME: "num",
            VAL: 1
          })], undefined, undefined, []);

var containerInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var containerJustifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, "center", undefined, "flexEnd");

var titleAlignItems = Particle_Screen_Ds.make(undefined, undefined, "flexStart", undefined, undefined, "center");

var descriptionDisplay = Util_Css.Display.fromL;

var Content = {
  width: width$1,
  rules: rules$2,
  containerRules: containerRules$1,
  containerInnerSpace: containerInnerSpace,
  containerJustifyContent: containerJustifyContent,
  titleAlignItems: titleAlignItems,
  descriptionDisplay: descriptionDisplay
};

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var ContentButtons = {
  gap: gap
};

var blockSpace$2 = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.Outside.makeAll("Zero")), undefined, undefined, Particle_Spacer_Ds.Outside.make("L", "None", "None", "None"));

var width$2 = Particle_Screen_Ds.map(width$1, (function (width) {
        if (typeof width !== "object") {
          return width;
        }
        if (width.NAME !== "percent") {
          return width;
        }
        var value = width.VAL;
        if (value < 100) {
          return CssJs.pct(100 - value);
        } else {
          return CssJs.pct(value);
        }
      }));

var rules$3 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Css.preserveAspectRatioRules("R16_9"));

var Trailer = {
  blockSpace: blockSpace$2,
  width: width$2,
  rules: rules$3
};

export {
  rules ,
  blockSpace ,
  display ,
  Backlink ,
  $$Image ,
  Content ,
  ContentButtons ,
  Trailer ,
}
/* rules Not a pure module */
