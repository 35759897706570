// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Type_NextEpisode from "../../type/Type_NextEpisode.res.mjs";

function endpoint_1(args) {
  return "nextEpisode/" + Util_I18n.localeToString(args.locale) + "/" + Type_String.Program.Id.toString(args.programId);
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  userRestriction: "Anonymous"
};

function decodeApiResponse(resp) {
  var __x = (function (__x) {
        return Core__Result.map(__x, Type_NextEpisode.ApiResponse.get);
      })(Type_NextEpisode.ApiResponse.response_decode(resp));
  return Core__Result.map(__x, (function (__x) {
                return Belt_Option.map(__x, Type_NextEpisode.make);
              }));
}

var GetConfig = {
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: decodeApiResponse
};

var Get = Api.User.MakeRequest({
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var get = Get.call;

export {
  GetConfig ,
  Get ,
  get ,
}
/* Get Not a pure module */
