// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "acceptCom",
                    false,
                    Spice.boolToJson(v.acceptCom)
                  ]]));
}

var endpoint = {
  NAME: "Static",
  VAL: "acceptCom"
};

var options = {
  userRestriction: "Anonymous"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: "AcceptComUpdated"
        };
}

var PutConfig = {
  args_encode: args_encode,
  domain: "Sso",
  endpoint: endpoint,
  method: "Put",
  options: options,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Put = Api.User.MakeMutation({
      domain: "Sso",
      method: "Put",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var set = Put.call;

export {
  PutConfig ,
  Put ,
  set ,
}
/* Put Not a pure module */
