// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Context from "../../provider/Context.res.mjs";
import * as UseForm from "../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as UseUserFetch from "../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../atom/Atom_Textfield.res.mjs";
import * as Organism_Dropdown from "./dropdown/Organism_Dropdown.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_DangerousHtml from "../molecule/Molecule_DangerousHtml.res.mjs";
import * as Molecule_DangerousHtml_Ds from "../molecule/Molecule_DangerousHtml_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XL")), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "M"));

var contentBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("M")), undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var containerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.maxWidth(CssJs.px(600))]);

function ratingElems(one, two, three, four, five) {
  return Util_Array.NonEmpty.make([
              {
                value: two,
                label: two
              },
              {
                value: three,
                label: three
              },
              {
                value: four,
                label: four
              },
              {
                value: five,
                label: five
              }
            ], {
              value: one,
              label: one
            });
}

function get(values, field) {
  switch (field) {
    case "Rating" :
        return values.rating;
    case "Liked" :
        return values.liked;
    case "Disliked" :
        return values.disliked;
    case "Email" :
        return values.email;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case "Rating" :
        return {
                rating: value,
                liked: values.liked,
                disliked: values.disliked,
                email: values.email
              };
    case "Liked" :
        return {
                rating: values.rating,
                liked: value,
                disliked: values.disliked,
                email: values.email
              };
    case "Disliked" :
        return {
                rating: values.rating,
                liked: values.liked,
                disliked: value,
                email: values.email
              };
    case "Email" :
        return {
                rating: values.rating,
                liked: values.liked,
                disliked: values.disliked,
                email: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

function initialState(defaultRating) {
  return {
          rating: defaultRating,
          liked: "",
          disliked: "",
          email: ""
        };
}

var Form = UseForm.Make({
      set: set,
      get: get
    });

function makeSchema() {
  return Form.Schema.Validation.schema([
              Form.Schema.Validation.nonEmpty("Rating"),
              Form.Schema.Validation.eitherNonEmpty("Liked", "Disliked"),
              Form.Schema.Validation.eitherNonEmpty("Disliked", "Liked")
            ]);
}

function make(param) {
  var onSubmit = param.onSubmit;
  var match = Context.I18n.getState();
  var labels = match.labels;
  var formaters = match.formaters;
  var match$1 = labels.feedback;
  var ratingLabel = match$1.ratingLabel;
  var likedLabel = match$1.likedLabel;
  var labelFive = match$1.labelFive;
  var genericError = match$1.genericError;
  var emailLabel = match$1.emailLabel;
  var dislikedLabel = match$1.dislikedLabel;
  var dataText = formaters.acceptDataLabel(match$1.dataLabel, match$1.dataLink);
  var faqText = formaters.checkFaqLabel(match$1.faqLabel, match$1.faqLink);
  var match$2 = UseUserFetch.make();
  var setSuccess = match$2.setSuccess;
  var setError = match$2.setError;
  var setLoading = match$2.setLoading;
  var handleSubmit = function (param) {
    setLoading();
    onSubmit(param.state.values).then(function (response) {
          if (response === "Ok") {
            return setSuccess("");
          } else {
            return setError(genericError);
          }
        });
  };
  var schema = makeSchema();
  var form = Form.use(initialState(labelFive), schema, handleSubmit, labels, formaters, undefined, undefined);
  var match$3 = form.values;
  var onSubmit$1 = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(contentBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.title),
                              kind: "h1",
                              style: Atom_Text_Ds.User.title("MyAccount")
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                        dangerousHtml: faqText,
                                        variant: Molecule_DangerousHtml_Ds.userDescription
                                      })),
                              kind: "div",
                              style: Atom_Text_Ds.User.text("MyAccount")
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.description),
                              style: Atom_Text_Ds.User.text("MyAccount")
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(contentBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Column.make, {
                              children: JsxRuntime.jsx(Organism_Dropdown.make, {
                                    kind: {
                                      TAG: "Select",
                                      _0: {
                                        name: ratingLabel,
                                        title: ratingLabel,
                                        autoComplete: undefined,
                                        variant: "myProfileNewDesign"
                                      }
                                    },
                                    items: ratingElems(Type_String.Dropdown.Item.make(match$1.labelOne), Type_String.Dropdown.Item.make(match$1.labelTwo), Type_String.Dropdown.Item.make(match$1.labelThree), Type_String.Dropdown.Item.make(match$1.labelFour), Type_String.Dropdown.Item.make(labelFive)),
                                    label: ratingLabel,
                                    value: match$3.rating,
                                    onChange: (function (item) {
                                        form.handleChange("Rating", Type_String.Dropdown.Item.toString(item.value));
                                      })
                                  }),
                              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                            }),
                        JsxRuntime.jsxs(Atom_Column.make, {
                              children: [
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      type_: {
                                        NAME: "textArea",
                                        VAL: 5
                                      },
                                      value: match$3.liked,
                                      label: likedLabel,
                                      onChange: (function (extra) {
                                          return Curry._2(form.handleChange, "Liked", extra);
                                        }),
                                      id: "liked",
                                      name: likedLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "Liked"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "Liked"
                                          })
                                    }),
                                JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                      type_: {
                                        NAME: "textArea",
                                        VAL: 5
                                      },
                                      value: match$3.disliked,
                                      label: dislikedLabel,
                                      onChange: (function (extra) {
                                          return Curry._2(form.handleChange, "Disliked", extra);
                                        }),
                                      id: "disliked",
                                      name: dislikedLabel,
                                      errorMessage: form.getFieldError({
                                            TAG: "Field",
                                            _0: "Disliked"
                                          }),
                                      inputRef: form.getFieldRef({
                                            TAG: "Field",
                                            _0: "Disliked"
                                          })
                                    })
                              ],
                              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                            }),
                        JsxRuntime.jsx(Atom_Column.make, {
                              children: JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                    autoComplete: "email",
                                    type_: "email",
                                    value: match$3.email,
                                    label: emailLabel,
                                    pattern: "Email",
                                    onChange: (function (extra) {
                                        return Curry._2(form.handleChange, "Email", extra);
                                      }),
                                    id: "email",
                                    name: emailLabel,
                                    errorMessage: form.getFieldError({
                                          TAG: "Field",
                                          _0: "Email"
                                        }),
                                    inputRef: form.getFieldRef({
                                          TAG: "Field",
                                          _0: "Email"
                                        })
                                  }),
                              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                            }),
                        match$2.component,
                        JsxRuntime.jsx(Atom_Row.make, {
                              children: JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                              dangerousHtml: dataText,
                                              variant: Molecule_DangerousHtml_Ds.userCheckbox
                                            })),
                                    kind: "div",
                                    style: Atom_Text_Ds.User.footer
                                  }),
                              innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace)
                            }),
                        JsxRuntime.jsxs(Atom_Row.make, {
                              children: [
                                JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                      label: match$1.saveLabel,
                                      variant: "base",
                                      type_: "submit"
                                    }),
                                JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                      label: match$1.closeLabel,
                                      onClick: param.onClose,
                                      variant: "outline"
                                    })
                              ],
                              innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace)
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
                      kind: {
                        TAG: "Form",
                        _0: onSubmit$1
                      },
                      rules: Caml_option.some(containerRules),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    })
              ],
              kind: "Section"
            });
}

function Organism_Feedback_Form$Success(props) {
  var match = Context.I18n.getState();
  var match$1 = match.labels.feedback;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(contentBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.successTitle),
                              kind: "h1",
                              style: Atom_Text_Ds.User.title("MyAccount")
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.successDescription),
                              style: Atom_Text_Ds.User.text("MyAccount")
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: Caml_option.some(contentBlockSpace),
                      children: JsxRuntime.jsx(Atom_Row.make, {
                            children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                  label: match$1.successButton,
                                  onClick: props.onClose,
                                  variant: "base"
                                }),
                            innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace)
                          }),
                      innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
                      rules: Caml_option.some(containerRules),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    })
              ],
              kind: "Section"
            });
}

var Success = {
  make: Organism_Feedback_Form$Success
};

export {
  containerBlockSpace ,
  contentBlockSpace ,
  containerRules ,
  ratingElems ,
  FormFields ,
  initialState ,
  Form ,
  makeSchema ,
  make ,
  Success ,
}
/* containerBlockSpace Not a pure module */
