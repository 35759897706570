// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "./Atom_Cta.res.mjs";
import * as Atom_Column from "./Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_ContentCollection_Css from "../molecule/Molecule_ContentCollection_Css.res.mjs";

function Atom_Backlink$Internal(props) {
  var label = props.label;
  var dataAnchor = props.dataAnchor;
  var onClick = props.onClick;
  var variant = props.variant;
  var href = props.href;
  if (href !== undefined) {
    return JsxRuntime.jsx(Atom_Cta.Link.make, {
                dataAnchor: dataAnchor,
                href: href,
                label: label,
                onClick: onClick,
                style: variant
              });
  } else {
    return JsxRuntime.jsx(Atom_Cta.Button.make, {
                dataAnchor: dataAnchor,
                label: label,
                onClick: onClick,
                style: variant
              });
  }
}

var Internal = {
  make: Atom_Backlink$Internal
};

function make(param) {
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(Molecule_ContentCollection_Css.Backlink.blockSpace),
              children: JsxRuntime.jsx(Atom_Backlink$Internal, {
                    href: param.href,
                    variant: Atom_Cta_Ds.backLink,
                    dataAnchor: true,
                    label: param.label
                  })
            });
}

function make$1(param) {
  return JsxRuntime.jsx(Atom_Backlink$Internal, {
              href: param.href,
              variant: Atom_Cta_Ds.Papa.backLink,
              onClick: param.onClick,
              label: param.label
            });
}

var Papa = {
  make: make$1
};

function make$2(param) {
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("S"))),
              children: JsxRuntime.jsx(Atom_Backlink$Internal, {
                    href: param.href,
                    variant: Atom_Cta_Ds.Presse.backLink,
                    onClick: param.onClick,
                    label: param.label
                  })
            });
}

var Presse = {
  make: make$2
};

function make$3(param) {
  var match = Context.I18n.getState();
  var match$1 = Context.Config.getState();
  var api = match$1.api;
  var match$2 = match.labels.mona.profile;
  var defaultOnClick = function () {
    api.goBack();
  };
  var onClick = Core__Option.getOr(param.onClick, defaultOnClick);
  return JsxRuntime.jsx(Atom_Column.make, {
              blockSpace: Caml_option.some(Molecule_ContentCollection_Css.Backlink.blockSpace),
              children: JsxRuntime.jsx(Atom_Backlink$Internal, {
                    variant: Atom_Cta_Ds.User.backlink,
                    onClick: (function (param) {
                        onClick();
                      }),
                    label: match$2.backLabel
                  })
            });
}

var User = {
  make: make$3
};

export {
  Internal ,
  make ,
  Papa ,
  Presse ,
  User ,
}
/* Context Not a pure module */
