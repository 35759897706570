// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Emac_Footer from "../../../emac/Emac_Footer.res.mjs";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Organism_AppLinks from "./Organism_AppLinks.res.mjs";
import * as Organism_European from "./Organism_European.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as UseScreenResolution from "../../../hook/UseScreenResolution.res.mjs";
import * as Organism_FooterLabels from "./Organism_FooterLabels.res.mjs";
import * as Organism_LinksSection from "./Organism_LinksSection.res.mjs";
import * as Organism_LegacySection from "./Organism_LegacySection.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Organism_PartnersSection from "./Organism_PartnersSection.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function make(param) {
  var displayOverFooter = Core__Option.getOr(param.displayOverFooter, "Hide");
  var screenSize = UseScreenResolution.make({
        NAME: "XL",
        VAL: 0
      }, undefined);
  var match = Context.I18n.getState();
  var footerItems = Core__Option.map(param.footerItems, Emac_Footer.toDsFooter);
  var match$1 = Context.Config.getState();
  var match$2 = Organism_FooterLabels.getLabels(match.locale);
  var overFooter;
  overFooter = displayOverFooter === "Show" ? JsxRuntime.jsx(Organism_AppLinks.make, {}) : null;
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "contentinfo", undefined);
  return JsxRuntime.jsxs(Atom_Column.make, {
              accessibility: accessibility,
              background: Caml_option.some(Particle_Background_Ds.footer),
              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeAll("L")), Caml_option.some(Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "XL")), undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "XS"))),
              children: [
                overFooter,
                JsxRuntime.jsx(Organism_European.make, {
                      text: match$2.european
                    }),
                JsxRuntime.jsx(Organism_LinksSection.make, {
                      columnLinks: footerItems,
                      screenSize: screenSize,
                      newsletter: match$2.newsletter,
                      showPrivacyCenter: match$1.api.showPrivacyCenter,
                      newsletterSource: param.newsletterSource
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.footer
                    }),
                JsxRuntime.jsx(Organism_PartnersSection.make, {
                      partners: match$2.partners
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.footer
                    }),
                JsxRuntime.jsx(Organism_LegacySection.make, {
                      label: match$2.legacyLabel
                    })
              ],
              kind: "Footer",
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

export {
  make ,
}
/* Context Not a pure module */
