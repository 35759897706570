'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Emac_Zone from "../emac/Emac_Zone.res.mjs";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as Zone_Type from "../zone/Zone_Type.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as UsePagination from "./UsePagination.res.mjs";
import * as User_Api__Sso__Favorites from "../user/User_Api/User_Api__Sso__Favorites.res.mjs";
import * as User_Api__Sso__LastVieweds from "../user/User_Api/User_Api__Sso__LastVieweds.res.mjs";
import * as User_Api__Sso__PersonalZone from "../user/User_Api/User_Api__Sso__PersonalZone.res.mjs";

function make(zone) {
  var controller = new AbortController();
  var signal = controller.signal;
  var match = Context.Config.getState();
  var loadNextPage = match.api.loadNextPage;
  var timeReference = match.serverTime;
  var match$1 = Context.I18n.getState();
  var locale = match$1.locale;
  var formaters = match$1.formaters;
  var match$2 = match$1.labels.teaser;
  var nextBroadcastDate = formaters.nextBroadcastDate;
  var betweenDates = formaters.betweenDates;
  var dateFormater = formaters.availabilityDate;
  var minutesLabel = match$2.durationUnit;
  var loadNextPageRef = React.useRef(function (param, param$1) {
        return Promise.resolve("NotReady");
      });
  loadNextPageRef.current = (async function (zone, nextPage) {
      if (nextPage.TAG === "Club") {
        var page = nextPage._1;
        if (page === undefined) {
          return "NoMoreData";
        }
        var kind = nextPage._0;
        var tmp;
        switch (kind) {
          case "Favorites" :
              tmp = User_Api__Sso__Favorites.get({
                    args: {
                      page: page,
                      language: locale
                    },
                    signal: signal,
                    locale: locale
                  });
              break;
          case "LastVieweds" :
              tmp = User_Api__Sso__LastVieweds.Get.call({
                    args: {
                      page: page,
                      language: locale
                    },
                    signal: signal,
                    locale: locale
                  });
              break;
          case "PersonalZone" :
              tmp = User_Api__Sso__PersonalZone.get({
                    args: {
                      page: page,
                      language: locale
                    },
                    signal: signal,
                    locale: locale
                  });
              break;
          
        }
        var data = await tmp;
        var clubData = (function (__x) {
              return Core__Result.map(__x, (function (response) {
                            return [
                                    Type_Club.$$Response.getData(response),
                                    Type_Club.$$Response.getNextPage(response)
                                  ];
                          }));
            })(data);
        if (clubData.TAG === "Ok") {
          var match = clubData._0;
          var zone$1 = Zone_Type.makeFromClub(minutesLabel, zone, kind, match[1], match[0]);
          return {
                  TAG: "Ready",
                  _0: {
                    nextPage: zone$1.nextPage,
                    data: zone$1.data
                  }
                };
        }
        var tmp$1 = clubData._0;
        if (typeof tmp$1 !== "object" && tmp$1 === "InvalidAuthent") {
          return "NotReady";
        } else {
          return "NoMoreData";
        }
      }
      var response = await loadNextPage(nextPage._0);
      var zone$2 = Core__Option.flatMap((response == null) ? undefined : Caml_option.some(response), (function (resp) {
              var nextPage = Emac_Zone.nextPage_decode(resp);
              if (nextPage.TAG === "Ok") {
                return (function (__x) {
                            return Zone_Type.makeFromNextZone(dateFormater, betweenDates, nextBroadcastDate, timeReference, zone, __x);
                          })(nextPage._0);
              }
              console.log("cant parse next page", nextPage._0);
            }));
      if (zone$2 !== undefined) {
        return {
                TAG: "Ready",
                _0: {
                  nextPage: zone$2.nextPage,
                  data: zone$2.data
                }
              };
      } else {
        return "NoMoreData";
      }
    });
  var async = React.useRef(UsePagination.asyncMode(zone.nextPage, (function (extra) {
              return Curry._2(loadNextPageRef.current, zone, extra);
            })));
  return {
          value: async.current,
          reset: (function (zone) {
              async.current = UsePagination.asyncMode(zone.nextPage, (function (extra) {
                      return Curry._2(loadNextPageRef.current, zone, extra);
                    }));
              return async.current;
            })
        };
}

export {
  make ,
}
/* react Not a pure module */
