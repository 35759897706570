// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Atom_Row from "./Atom_Row.res.mjs";
import * as Atom_Cell from "./Atom_Cell.res.mjs";
import * as Atom_Icon from "./Atom_Icon.res.mjs";
import * as Atom_Logo from "./Atom_Logo.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Text_Css from "./Atom_Text_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function Atom_Text$ScreenreadersLabel$Internal(props) {
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : "span";
  var rules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
        CssJs.width(CssJs.px(1)),
        CssJs.height(CssJs.px(1)),
        CssJs.padding(CssJs.px(0)),
        CssJs.overflow(CssJs.hidden),
        CssJs.unsafe("clip", "rect(0 0 0 0)"),
        CssJs.whiteSpace(CssJs.nowrap),
        CssJs.border(CssJs.px(0), CssJs.solid, CssJs.transparent)
      ]);
  return JsxRuntime.jsx(Atom_Row.make, {
              accessibility: props.accessibility,
              children: props.children,
              id: props.id,
              kind: {
                TAG: "Text",
                _0: kind
              },
              position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
              rules: Caml_option.some(rules)
            });
}

var Internal = {
  make: Atom_Text$ScreenreadersLabel$Internal
};

function Atom_Text$ScreenreadersLabel(props) {
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : "span";
  return JsxRuntime.jsx(Atom_Text$ScreenreadersLabel$Internal, {
              children: props.children,
              kind: kind,
              id: props.id,
              accessibility: props.accessibility
            });
}

function Atom_Text$ScreenreadersLabel$Replay(props) {
  return JsxRuntime.jsx(Atom_Text$ScreenreadersLabel$Internal, {
              children: props.label,
              kind: "h1"
            });
}

var Replay = {
  make: Atom_Text$ScreenreadersLabel$Replay
};

var ScreenreadersLabel = {
  Internal: Internal,
  make: Atom_Text$ScreenreadersLabel,
  Replay: Replay
};

function renderChildrenWithAsset(children, asset, param) {
  if (children !== undefined) {
    var children$1 = Caml_option.valFromOption(children);
    if (asset === undefined) {
      return [
              children$1,
              undefined,
              undefined
            ];
    }
    if (asset.TAG === "Logo") {
      var match = asset._0;
      var logo = match.logo;
      var position = match.position;
      if (typeof position !== "object") {
        return [
                JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Atom_Logo.make, {
                              logo: logo
                            }),
                        JsxRuntime.jsx(Atom_Text$ScreenreadersLabel, {
                              children: children$1
                            })
                      ]
                    }),
                undefined,
                Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
              ];
      } else if (position.TAG === "Left") {
        return [
                JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Atom_Cell.make, {
                              kind: "Span",
                              children: JsxRuntime.jsx(Atom_Logo.make, {
                                    logo: logo
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              kind: "Span",
                              children: children$1
                            })
                      ]
                    }),
                Caml_option.some(position._0),
                Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
              ];
      } else {
        return [
                JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Atom_Cell.make, {
                              kind: "Span",
                              children: children$1
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              kind: "Span",
                              children: JsxRuntime.jsx(Atom_Logo.make, {
                                    logo: logo
                                  })
                            })
                      ]
                    }),
                Caml_option.some(position._0),
                Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
              ];
      }
    }
    var match$1 = asset._0;
    var size = match$1.size;
    var icon = match$1.icon;
    var position$1 = match$1.position;
    if (typeof position$1 !== "object") {
      return [
              JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(Atom_Icon.make, {
                            icon: icon,
                            size: size
                          }),
                      JsxRuntime.jsx(Atom_Text$ScreenreadersLabel, {
                            children: children$1
                          })
                    ]
                  }),
              undefined,
              Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
            ];
    } else if (position$1.TAG === "Left") {
      return [
              JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(Atom_Cell.make, {
                            kind: "Span",
                            children: JsxRuntime.jsx(Atom_Icon.make, {
                                  icon: icon,
                                  size: size
                                })
                          }),
                      JsxRuntime.jsx(Atom_Cell.make, {
                            kind: "Span",
                            children: children$1
                          })
                    ]
                  }),
              Caml_option.some(position$1._0),
              Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
            ];
    } else {
      return [
              JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx(Atom_Cell.make, {
                            kind: "Span",
                            children: children$1
                          }),
                      JsxRuntime.jsx(Atom_Cell.make, {
                            kind: "Span",
                            children: JsxRuntime.jsx(Atom_Icon.make, {
                                  icon: icon,
                                  size: size
                                })
                          })
                    ]
                  }),
              Caml_option.some(position$1._0),
              Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
            ];
    }
  }
  if (asset === undefined) {
    return [
            null,
            undefined,
            undefined
          ];
  }
  if (asset.TAG === "Logo") {
    return [
            JsxRuntime.jsx(Atom_Logo.make, {
                  logo: asset._0.logo
                }),
            undefined,
            undefined
          ];
  }
  var match$2 = asset._0;
  return [
          JsxRuntime.jsx(Atom_Icon.make, {
                icon: match$2.icon,
                size: match$2.size
              }),
          undefined,
          Caml_option.some(Particle_Screen_Constants.alignItemsCenter)
        ];
}

function Atom_Text$Internal(props) {
  var __style = props.style;
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : ({
        TAG: "Text",
        _0: "p"
      });
  var style = __style !== undefined ? __style : Atom_Text_Ds.default;
  var view = Atom_Text_Ds.view(style);
  var textRules = Atom_Text_Css.toCss(view);
  var rules = Particle_Screen_Ds.$$Array.concatOrDefault(textRules, props.rules);
  var match = renderChildrenWithAsset(props.children, view.asset, undefined);
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              alignItems: match[2],
              autoSize: props.autoSize,
              children: Caml_option.some(match[0]),
              direction: Caml_option.some(Particle_Screen_Constants.flexDirectionRow),
              display: Caml_option.some(view.display),
              height: props.height,
              id: props.id,
              identifier: props.identifier,
              justifyContent: props.justifyContent,
              kind: kind,
              maxWidth: props.maxWidth,
              mediaQueryKind: props.mediaQueryKind,
              rules: Caml_option.some(rules),
              spacer: props.blockSpace,
              width: props.width,
              innerSpace: match[1],
              ref_: props.textRef
            });
}

var Internal$1 = {
  renderChildrenWithAsset: renderChildrenWithAsset,
  make: Atom_Text$Internal
};

function Atom_Text$Responsive$ChildrenResponsive(props) {
  var label = props.label;
  if (label !== undefined) {
    return JsxRuntime.jsx(Atom_Text$Internal, {
                children: Caml_option.some(label),
                mediaQueryKind: "MinMaxWidth",
                style: props.style,
                rules: Caml_option.some(props.rules)
              }, Uuid.v4());
  } else {
    return null;
  }
}

var ChildrenResponsive = {
  make: Atom_Text$Responsive$ChildrenResponsive
};

function getDisplayRules(elements, currentElement) {
  var getDisplayValue = function (value1, value2) {
    if (value1 !== undefined) {
      if (value2 !== undefined) {
        if (value1 === value2) {
          return [CssJs.display("flex")];
        } else {
          return [CssJs.display("none")];
        }
      } else {
        return [CssJs.display("none")];
      }
    } else {
      return [CssJs.display("none")];
    }
  };
  return Particle_Screen_Ds.zipWith(getDisplayValue, elements, currentElement);
}

function Atom_Text$Responsive$Children(props) {
  var labels = props.labels;
  var style = props.style;
  var label = Particle_Screen_Ds.view(labels);
  if (label.TAG === "AllSize") {
    return JsxRuntime.jsx(Atom_Text$Internal, {
                children: Caml_option.some(Core__Option.mapOr(label._0, null, (function (prim) {
                            return prim;
                          }))),
                style: style
              });
  }
  var defaultLabel = label._0;
  var elements = label._1.map(function (localSize) {
        var localString = Particle_Screen_Ds.extractValue(localSize);
        var dsScreenLocalSize = Particle_Screen_Ds.makeForScreenSize(undefined, localSize);
        var rules = getDisplayRules(labels, dsScreenLocalSize);
        return JsxRuntime.jsx(Atom_Text$Responsive$ChildrenResponsive, {
                    label: localString,
                    rules: rules,
                    style: style
                  }, Particle_Screen_Ds.screenSizeToString(localSize));
      });
  var dsScreenLocalSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, defaultLabel);
  var rules = getDisplayRules(labels, dsScreenLocalSize);
  var defaultBox = JsxRuntime.jsx(Atom_Text$Responsive$ChildrenResponsive, {
        label: defaultLabel,
        rules: rules,
        style: style
      }, "default");
  return Util_Array.prepend(elements, defaultBox);
}

var Children = {
  make: Atom_Text$Responsive$Children
};

function Atom_Text$Responsive(props) {
  var __kind = props.kind;
  var __style = props.style;
  var style = __style !== undefined ? __style : Atom_Text_Ds.default;
  var kind = __kind !== undefined ? __kind : "div";
  var view = Atom_Text_Ds.view(style);
  var rules = Atom_Text_Css.toCss(view);
  return JsxRuntime.jsx(Atom_Box.make, {
              accessibility: props.accessibility,
              autoSize: props.autoSize,
              children: Caml_option.some(JsxRuntime.jsx(Atom_Text$Responsive$Children, {
                        style: style,
                        labels: props.labels
                      })),
              direction: Caml_option.some(Particle_Screen_Constants.flexDirectionRow),
              id: props.id,
              kind: {
                TAG: "Text",
                _0: kind
              },
              rules: Caml_option.some(rules),
              width: props.width,
              ref_: props.textRef
            });
}

var Responsive = {
  ChildrenResponsive: ChildrenResponsive,
  getDisplayRules: getDisplayRules,
  Children: Children,
  make: Atom_Text$Responsive
};

function Atom_Text$InputLabel(props) {
  return JsxRuntime.jsx(Atom_Text$Internal, {
              accessibility: props.accessibility,
              autoSize: props.autoSize,
              blockSpace: props.blockSpace,
              children: props.children,
              id: props.id,
              identifier: props.identifier,
              justifyContent: props.justifyContent,
              kind: props.kind,
              maxWidth: props.maxWidth,
              style: props.style,
              width: props.width,
              textRef: props.textRef
            });
}

var InputLabel = {
  make: Atom_Text$InputLabel
};

function Atom_Text(props) {
  var kind = props.kind;
  return JsxRuntime.jsx(Atom_Text$Internal, {
              accessibility: props.accessibility,
              autoSize: props.autoSize,
              blockSpace: props.blockSpace,
              children: props.children,
              id: props.id,
              identifier: props.identifier,
              justifyContent: props.justifyContent,
              kind: kind !== undefined ? ({
                    TAG: "Text",
                    _0: kind
                  }) : ({
                    TAG: "Text",
                    _0: "p"
                  }),
              maxWidth: props.maxWidth,
              style: props.style,
              width: props.width,
              textRef: props.textRef
            });
}

function Atom_Text$DangerouslySetInnerHTML(props) {
  var __style = props.style;
  var style = __style !== undefined ? __style : Atom_Text_Ds.default;
  return JsxRuntime.jsx(Atom_Text$Internal, {
              id: props.id,
              kind: {
                TAG: "DangerouslySetInnerHTML",
                _0: props.dangerouslySetInnerHTML
              },
              style: style,
              textRef: props.textRef
            });
}

var DangerouslySetInnerHTML = {
  make: Atom_Text$DangerouslySetInnerHTML
};

function makeDsVariant(variant) {
  if (variant === "date") {
    return Atom_Text_Ds.Papa.date;
  } else if (variant === "title") {
    return Atom_Text_Ds.Papa.title;
  } else {
    return Atom_Text_Ds.Papa.$$default;
  }
}

function Atom_Text$Papa(props) {
  var __tag = props.tag;
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "default";
  var tag = __tag !== undefined ? __tag : "p";
  var dsVariant = makeDsVariant(variant);
  return JsxRuntime.jsx(Atom_Text$Internal, {
              children: Caml_option.some(props.children),
              kind: {
                TAG: "Text",
                _0: tag
              },
              style: dsVariant,
              textRef: props.textRef
            });
}

var Papa = {
  makeDsVariant: makeDsVariant,
  make: Atom_Text$Papa
};

function makeDsVariant$1(variant, theme) {
  switch (variant) {
    case "HeroTitle" :
        return Atom_Text_Ds.AndroidReleases.heroTitle;
    case "HeroSubTitle" :
        return Atom_Text_Ds.AndroidReleases.heroSubTitle;
    case "HeroTutorial" :
        return Atom_Text_Ds.AndroidReleases.heroTutorial;
    case "PageTitle" :
        return Atom_Text_Ds.AndroidReleases.pageTitle;
    case "ListTitle" :
        return Atom_Text_Ds.AndroidReleases.listTitle;
    case "ReleaseTitle" :
        return Atom_Text_Ds.AndroidReleases.releaseTitle(theme);
    
  }
}

function Atom_Text$AndroidReleases(props) {
  var __theme = props.theme;
  var __tag = props.tag;
  var tag = __tag !== undefined ? __tag : "p";
  var theme = __theme !== undefined ? __theme : Particle_Color.Var.$$default;
  var dsVariant = makeDsVariant$1(props.variant, theme);
  return JsxRuntime.jsx(Atom_Text$Internal, {
              children: Caml_option.some(props.children),
              kind: {
                TAG: "Text",
                _0: tag
              },
              style: dsVariant,
              textRef: props.textRef
            });
}

var AndroidReleases = {
  makeDsVariant: makeDsVariant$1,
  make: Atom_Text$AndroidReleases
};

function Atom_Text$Player(props) {
  var __tag = props.tag;
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "default";
  var tag = __tag !== undefined ? __tag : "p";
  var dsVariant = variant === "programTitle" ? Atom_Text_Ds.Player.programTitle : (
      variant === "subtitle" ? Atom_Text_Ds.Player.subtitle : (
          variant === "hoverChapter" ? Atom_Text_Ds.Player.hoverChapter : (
              variant === "title" ? Atom_Text_Ds.Player.title : (
                  variant === "hoverNextChapter" ? Atom_Text_Ds.Player.hoverNextChapter : (
                      variant === "message" ? Atom_Text_Ds.Player.message : (
                          variant === "default" ? Atom_Text_Ds.Player.$$default : Atom_Text_Ds.Player.date
                        )
                    )
                )
            )
        )
    );
  return JsxRuntime.jsx(Atom_Text$Internal, {
              children: Caml_option.some(props.children),
              kind: {
                TAG: "Text",
                _0: tag
              },
              style: dsVariant,
              textRef: props.textRef
            });
}

var Player = {
  make: Atom_Text$Player
};

var variantSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("size1"),
      S$RescriptSchema.literal("size2"),
      S$RescriptSchema.literal("size3"),
      S$RescriptSchema.literal("size4"),
      S$RescriptSchema.literal("size5"),
      S$RescriptSchema.literal("size6"),
      S$RescriptSchema.literal("info"),
      S$RescriptSchema.literal("default"),
      S$RescriptSchema.literal("success"),
      S$RescriptSchema.literal("error")
    ]);

function Atom_Text$Presse$Internal(props) {
  var kind = props.kind;
  var children = props.children;
  var __tag = props.tag;
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "default";
  var tag = __tag !== undefined ? __tag : "p";
  var dsVariant = variant === "error" ? Atom_Text_Ds.makeFeedback("Error") : (
      variant === "size1" ? Atom_Text_Ds.Presse.title1 : (
          variant === "size2" ? Atom_Text_Ds.Presse.title2 : (
              variant === "size3" ? Atom_Text_Ds.Presse.title3 : (
                  variant === "size4" ? Atom_Text_Ds.Presse.title4 : (
                      variant === "size5" ? Atom_Text_Ds.Presse.title5 : (
                          variant === "size6" ? Atom_Text_Ds.Presse.title6 : (
                              variant === "default" ? Atom_Text_Ds.Presse.$$default : (
                                  variant === "success" ? Atom_Text_Ds.makeFeedback("Success") : Atom_Text_Ds.Presse.info
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  var kind$1 = kind !== undefined ? kind : ({
        TAG: "Text",
        _0: tag
      });
  return JsxRuntime.jsx(Atom_Text$Internal, {
              children: Caml_option.some(children !== undefined ? Caml_option.valFromOption(children) : null),
              kind: kind$1,
              style: dsVariant,
              height: props.height,
              textRef: props.textRef
            });
}

var Internal$2 = {
  make: Atom_Text$Presse$Internal
};

function Atom_Text$Presse(props) {
  var __tag = props.tag;
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "default";
  var tag = __tag !== undefined ? __tag : "p";
  return JsxRuntime.jsx(Atom_Text$Presse$Internal, {
              variant: variant,
              tag: tag,
              children: Caml_option.some(props.children),
              textRef: props.textRef
            });
}

function Atom_Text$Presse$Raw(props) {
  var __tag = props.tag;
  var __variant = props.variant;
  var variant = __variant !== undefined ? __variant : "default";
  var tag = __tag !== undefined ? __tag : "p";
  return JsxRuntime.jsx(Atom_Text$Presse$Internal, {
              variant: variant,
              tag: tag,
              kind: {
                TAG: "DangerouslySetInnerHTML",
                _0: props.innerHtml
              },
              textRef: props.textRef
            });
}

var Raw = {
  make: Atom_Text$Presse$Raw
};

var Presse = {
  variantSchema: variantSchema,
  Internal: Internal$2,
  make: Atom_Text$Presse,
  Raw: Raw
};

var make = Atom_Text;

export {
  ScreenreadersLabel ,
  Internal$1 as Internal,
  Responsive ,
  InputLabel ,
  make ,
  DangerouslySetInnerHTML ,
  Papa ,
  AndroidReleases ,
  Player ,
  Presse ,
}
/* variantSchema Not a pure module */
