// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";

function make(style) {
  if (typeof style !== "object") {
    switch (style) {
      case "Icon" :
          return {
                  height: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        NAME: "px",
                        VAL: 34
                      }),
                  width: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                        NAME: "px",
                        VAL: 34
                      }),
                  border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Avatar.medium)),
                  blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXXS")))
                };
      case "Card" :
          return {
                  height: Particle_Screen_Ds.make(undefined, undefined, {
                        NAME: "px",
                        VAL: 300
                      }, undefined, undefined, {
                        NAME: "px",
                        VAL: 100
                      }),
                  width: Particle_Screen_Ds.make(undefined, undefined, {
                        NAME: "px",
                        VAL: 300
                      }, undefined, undefined, {
                        NAME: "px",
                        VAL: 100
                      })
                };
      case "UserPanel" :
          return {
                  height: Particle_Screen_Ds.make(undefined, undefined, "auto", undefined, undefined, "auto"),
                  width: Particle_Screen_Ds.make(undefined, undefined, "auto", undefined, undefined, "auto"),
                  border: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Avatar.medium)),
                  blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS")))
                };
      
    }
  } else {
    var tmp;
    tmp = style._0 === "Selected" ? Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Border_Ds.Avatar.semiBold)) : undefined;
    return {
            height: Particle_Screen_Ds.make(undefined, undefined, {
                  NAME: "px",
                  VAL: 195
                }, undefined, undefined, {
                  NAME: "px",
                  VAL: 100
                }),
            width: Particle_Screen_Ds.make(undefined, undefined, {
                  NAME: "px",
                  VAL: 195
                }, undefined, undefined, {
                  NAME: "px",
                  VAL: 100
                }),
            border: tmp
          };
  }
}

export {
  make ,
}
/* Particle_Border_Ds Not a pure module */
