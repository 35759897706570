import { type ReactElement } from 'react';
import { FeedbackButton as FeedbackButtonDs } from '@artegeie/design-system/replay';
import { type Locale } from '@replay/i18n/types/locale';
import { env } from '@replay/env-generator';
import { useRouter } from 'next/router';
import { useRedirect } from './useAuth';

export const FeedbackButton = ({ locale }: { locale: Locale }): ReactElement | null => {
    const { asPath } = useRouter();
    const { saveRedirect } = useRedirect();
    if (asPath.includes('feedback') || !env.NEXT_PUBLIC_FEATURE_FLAGS_FEEDBACK_FORM) return null;
    return (
        <FeedbackButtonDs
            href={`/${locale}/feedback`}
            onClick={() => {
                saveRedirect({
                    returnPage: asPath,
                    returnType: 'internal',
                });
            }}
        />
    );
};
