// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as PervasivesU from "@rescript/std/lib/es6/pervasivesU.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as $$Date from "@artegeie/date";

function dateToJson(date) {
  return date.toISOString();
}

function dateFromJson(json) {
  var date = Core__Option.map(Core__JSON.Decode.string(json), (function (prim) {
          return new Date(prim);
        }));
  if (date !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(date)
          };
  } else {
    return PervasivesU.failwith("invalid date");
  }
}

function t_encode(v) {
  return v.toISOString();
}

function t_decode(v) {
  return dateFromJson(v);
}

function addMinutes(date, minutes) {
  return $$Date.addSeconds(date, Math.imul(minutes, 60));
}

function getDay(date) {
  var match = date.getDay();
  switch (match) {
    case 0 :
        return 0;
    case 1 :
        return 1;
    case 2 :
        return 2;
    case 3 :
        return 3;
    case 4 :
        return 4;
    case 5 :
        return 5;
    case 6 :
        return 6;
    default:
      return PervasivesU.failwith("invalid day");
  }
}

function getWeek(date, locale, day) {
  return $$Date.getWeek(date, locale, day);
}

function startOfWeek(date, locale, day) {
  return $$Date.startOfWeek(date, locale, day);
}

function dateToAdverbOfTime(date) {
  var match = $$Date.isTomorrow(date);
  var match$1 = $$Date.isToday(date);
  var match$2 = $$Date.isYesterday(date);
  if (match) {
    return "Tomorrow";
  } else if (match$1) {
    return "Today";
  } else if (match$2) {
    return "Yesterday";
  } else {
    return "Other";
  }
}

function compareDatesByDay(referenceDate, dateToCompare) {
  if ($$Date.isSameDay(referenceDate, dateToCompare)) {
    return "Same";
  } else if (Caml_obj.lessthan(referenceDate, dateToCompare)) {
    return "After";
  } else {
    return "Before";
  }
}

function compareDates(referenceDate, dateToCompare) {
  if (Caml_obj.greaterthan(referenceDate, dateToCompare)) {
    return "Before";
  } else if (Caml_obj.lessthan(referenceDate, dateToCompare)) {
    return "After";
  } else {
    return "Same";
  }
}

var Format = {
  fullWeekDay: "eeee"
};

function range(startDate, numberOfDays) {
  var acc = [];
  var _date = startDate;
  var _numberOfDays = numberOfDays;
  while(true) {
    var numberOfDays$1 = _numberOfDays;
    var date = _date;
    if (numberOfDays$1 === 0) {
      return acc;
    }
    acc.push(date);
    _numberOfDays = numberOfDays$1 - 1 | 0;
    _date = $$Date.addDays(date, 1);
    continue ;
  };
}

function updateTime(date, hours, minutes) {
  var dateWithTime = new Date(date);
  dateWithTime.setHours(hours);
  dateWithTime.setMinutes(minutes);
  return dateWithTime;
}

function formatDateFromString(schedule, dateFormater) {
  return $$Date.format(new Date(schedule), dateFormater);
}

var hourInSecond = 3600;

export {
  dateToJson ,
  dateFromJson ,
  t_encode ,
  t_decode ,
  addMinutes ,
  getDay ,
  getWeek ,
  startOfWeek ,
  dateToAdverbOfTime ,
  compareDatesByDay ,
  compareDates ,
  Format ,
  range ,
  updateTime ,
  hourInSecond ,
  formatDateFromString ,
}
/* @artegeie/date Not a pure module */
