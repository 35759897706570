// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";

function spacerSizeToOption(spacerSize) {
  if (spacerSize === "None") {
    return ;
  } else {
    return spacerSize;
  }
}

function getT(top, bottom, left, right) {
  return {
          inside: {
            top: Core__Option.flatMap(top, spacerSizeToOption),
            bottom: Core__Option.flatMap(bottom, spacerSizeToOption),
            left: Core__Option.flatMap(left, spacerSizeToOption),
            right: Core__Option.flatMap(right, spacerSizeToOption)
          },
          outside: undefined
        };
}

function getSpacerSizeOrDefault($$default, spacerSize) {
  if (spacerSize !== undefined) {
    return spacerSizeToOption(spacerSize);
  } else {
    return $$default;
  }
}

function make(top, bottom, left, right) {
  return getT(top, bottom, left, right);
}

function makeNone() {
  return getT(undefined, undefined, undefined, undefined);
}

function makeAll(spacerSize) {
  return getT(spacerSize, spacerSize, spacerSize, spacerSize);
}

function makeTop(spacerSize) {
  return getT(spacerSize, undefined, undefined, undefined);
}

function makeBottom(spacerSize) {
  return getT(undefined, spacerSize, undefined, undefined);
}

function makeLeft(spacerSize) {
  return getT(undefined, undefined, spacerSize, undefined);
}

function makeRight(spacerSize) {
  return getT(undefined, undefined, undefined, spacerSize);
}

function makeVertical(spacerSize) {
  return getT(spacerSize, spacerSize, undefined, undefined);
}

function makeHorizontal(spacerSize) {
  return getT(undefined, undefined, spacerSize, spacerSize);
}

function makeVerticalAndHorizontal(v, h) {
  return getT(v, v, h, h);
}

function makeHorizontalWithLeftAndRight(left, right) {
  return getT(undefined, undefined, left, right);
}

function override(top, bottom, left, right, t) {
  var match = t.inside;
  var match$1 = match !== undefined ? ({
        top: match.top,
        bottom: match.bottom,
        left: match.left,
        right: match.right
      }) : ({
        top: undefined,
        bottom: undefined,
        left: undefined,
        right: undefined
      });
  return {
          inside: {
            top: getSpacerSizeOrDefault(match$1.top, top),
            bottom: getSpacerSizeOrDefault(match$1.bottom, bottom),
            left: getSpacerSizeOrDefault(match$1.left, left),
            right: getSpacerSizeOrDefault(match$1.right, right)
          },
          outside: undefined
        };
}

function spacerSizeToOption$1(spacerSize) {
  if (spacerSize === "None") {
    return ;
  } else {
    return spacerSize;
  }
}

function getT$1(top, bottom, left, right) {
  return {
          inside: undefined,
          outside: {
            top: Core__Option.flatMap(top, spacerSizeToOption$1),
            bottom: Core__Option.flatMap(bottom, spacerSizeToOption$1),
            left: Core__Option.flatMap(left, spacerSizeToOption$1),
            right: Core__Option.flatMap(right, spacerSizeToOption$1)
          }
        };
}

function getSpacerSizeOrDefault$1($$default, spacerSize) {
  if (spacerSize !== undefined) {
    return spacerSizeToOption$1(spacerSize);
  } else {
    return $$default;
  }
}

function make$1(top, bottom, left, right) {
  return getT$1(top, bottom, left, right);
}

function makeNone$1() {
  return getT$1(undefined, undefined, undefined, undefined);
}

function makeAll$1(spacerSize) {
  return getT$1(spacerSize, spacerSize, spacerSize, spacerSize);
}

function makeBottom$1(spacerSize) {
  return getT$1(undefined, spacerSize, undefined, undefined);
}

function makeVerticalAndHorizontal$1(v, h) {
  return getT$1(v, v, h, h);
}

function override$1(top, t) {
  var match = t.outside;
  var match$1 = match !== undefined ? ({
        top: match.top,
        bottom: match.bottom,
        left: match.left,
        right: match.right
      }) : ({
        top: undefined,
        bottom: undefined,
        left: undefined,
        right: undefined
      });
  return {
          inside: undefined,
          outside: {
            top: getSpacerSizeOrDefault$1(match$1.top, top),
            bottom: match$1.bottom,
            left: match$1.left,
            right: match$1.right
          }
        };
}

function spacerSizeToOption$2(spacerSize) {
  if (spacerSize === "None") {
    return ;
  } else {
    return spacerSize;
  }
}

function getT$2(topInside, bottomInside, leftInside, rightInside, topOutside, bottomOutside, leftOutside, rightOutside) {
  return {
          inside: {
            top: Core__Option.flatMap(topInside, spacerSizeToOption$2),
            bottom: Core__Option.flatMap(bottomInside, spacerSizeToOption$2),
            left: Core__Option.flatMap(leftInside, spacerSizeToOption$2),
            right: Core__Option.flatMap(rightInside, spacerSizeToOption$2)
          },
          outside: {
            top: Core__Option.flatMap(topOutside, spacerSizeToOption$2),
            bottom: Core__Option.flatMap(bottomOutside, spacerSizeToOption$2),
            left: Core__Option.flatMap(leftOutside, spacerSizeToOption$2),
            right: Core__Option.flatMap(rightOutside, spacerSizeToOption$2)
          }
        };
}

function make$2(inside, outside) {
  return getT$2(inside.top, inside.bottom, inside.left, inside.right, outside.top, outside.bottom, outside.left, outside.right);
}

function makeTop$1(inside, outside) {
  return getT$2(inside, undefined, undefined, undefined, outside, undefined, undefined, undefined);
}

function makeAll$2(inside, outside) {
  return getT$2(inside, inside, inside, inside, outside, outside, outside, outside);
}

function view(param) {
  var outside = param.outside;
  var inside = param.inside;
  if (inside === undefined) {
    if (outside !== undefined) {
      return {
              TAG: "Outside",
              _0: {
                top: outside.top,
                bottom: outside.bottom,
                left: outside.left,
                right: outside.right
              }
            };
    } else {
      return {
              TAG: "Both",
              _0: {
                inside: {
                  top: undefined,
                  bottom: undefined,
                  left: undefined,
                  right: undefined
                },
                outside: {
                  top: undefined,
                  bottom: undefined,
                  left: undefined,
                  right: undefined
                }
              }
            };
    }
  }
  var rightInside = inside.right;
  var leftInside = inside.left;
  var bottomInside = inside.bottom;
  var topInside = inside.top;
  if (outside !== undefined) {
    return {
            TAG: "Both",
            _0: {
              inside: {
                top: topInside,
                bottom: bottomInside,
                left: leftInside,
                right: rightInside
              },
              outside: {
                top: outside.top,
                bottom: outside.bottom,
                left: outside.left,
                right: outside.right
              }
            }
          };
  } else {
    return {
            TAG: "Inside",
            _0: {
              top: topInside,
              bottom: bottomInside,
              left: leftInside,
              right: rightInside
            }
          };
  }
}

var Inside = {
  make: make,
  makeTop: makeTop,
  makeBottom: makeBottom,
  makeHorizontal: makeHorizontal,
  makeVertical: makeVertical,
  makeLeft: makeLeft
};

var Outside = {
  make: make$1,
  makeNone: makeNone$1,
  makeAll: makeAll$1,
  makeBottom: makeBottom$1,
  makeVerticalAndHorizontal: makeVerticalAndHorizontal$1,
  override: override$1
};

var Both = {
  make: make$2,
  makeAll: makeAll$2,
  makeTop: makeTop$1
};

export {
  Inside ,
  Outside ,
  Both ,
  make ,
  makeNone ,
  makeAll ,
  makeTop ,
  makeBottom ,
  makeLeft ,
  makeRight ,
  makeVertical ,
  makeHorizontal ,
  makeVerticalAndHorizontal ,
  makeHorizontalWithLeftAndRight ,
  override ,
  view ,
}
/* No side effect */
