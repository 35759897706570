'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Loader from "../../../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as User_Api__Sso__Token from "../../../user/User_Api/User_Api__Sso__Token.res.mjs";

function make(param) {
  var redirectUri = param.redirectUri;
  var code = param.code;
  var onError = param.onError;
  var onSuccess = param.onSuccess;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var match$1 = match.labels.mona.login;
  var controller = new AbortController();
  var signal = controller.signal;
  React.useEffect((function () {
          var code$1 = Type_String.NotEmpty.make(code);
          if (code$1 !== undefined) {
            User_Api__Sso__Token.getTokenWithAuthorizationCode({
                    args: {
                      code: Type_String.NotEmpty.toString(Caml_option.valFromOption(code$1)),
                      redirectUri: redirectUri
                    },
                    signal: signal,
                    locale: locale
                  }).then(function (t) {
                  if (t.TAG !== "Ok") {
                    return onError();
                  }
                  User_Provider.loginUser(t._0, locale, signal);
                  onSuccess();
                });
            return ;
          }
          
        }), code);
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: match$1.title,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: JsxRuntime.jsx(Atom_Loader.make, {})
            });
}

export {
  make ,
}
/* react Not a pure module */
