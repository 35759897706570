'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as MyResult from "../MyResult.res.mjs";
import * as SetFocus from "./SetFocus.res.mjs";
import * as Js_string from "@rescript/std/lib/es6/js_string.js";
import * as Util_I18n from "../util/Util_I18n.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Util_Logger from "../util/Util_Logger.res.mjs";
import * as Atom_Icon_Ds from "../design-system/atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Type_Pattern from "../type/Type_Pattern.res.mjs";

var emailRegex = Type_Pattern.make("Email");

var initialState = {
  email: {
    TAG: "Invalid",
    _0: undefined
  },
  checkbox: false,
  feedback: "None"
};

function reducer(state, action) {
  switch (action.TAG) {
    case "UpdateEmail" :
        return {
                email: action._0,
                checkbox: state.checkbox,
                feedback: state.feedback
              };
    case "UpdateCheckbox" :
        return {
                email: state.email,
                checkbox: action._0,
                feedback: state.feedback
              };
    case "UpdateFeedback" :
        return {
                email: state.email,
                checkbox: state.checkbox,
                feedback: action._0
              };
    
  }
}

function subscribeToNewsletter(dispatch, mail, locale, request, source, focusInput) {
  return request(mail, Util_I18n.localeToString(locale), source).then(function (result) {
              var message = MyResult.toResult(result);
              var action;
              if (message.TAG === "Ok") {
                action = {
                  TAG: "UpdateFeedback",
                  _0: {
                    TAG: "Success",
                    _0: message._0
                  }
                };
              } else {
                focusInput();
                action = {
                  TAG: "UpdateFeedback",
                  _0: {
                    TAG: "Error",
                    _0: message._0
                  }
                };
              }
              return Promise.resolve(dispatch(action));
            });
}

function make(source, inputRef) {
  var match = Context.Config.getState();
  var subscribeToNewsletterRequest = match.api.subscribeToNewsletter;
  var match$1 = Context.I18n.getState();
  var locale = match$1.locale;
  var match$2 = React.useReducer(reducer, initialState);
  var dispatch = match$2[1];
  var state = match$2[0];
  var subscribeToNewsletter$1 = function (none) {
    return subscribeToNewsletter(dispatch, none, locale, subscribeToNewsletterRequest, source, (function () {
                  SetFocus.ByRef.make(inputRef);
                }));
  };
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    var match = state.email;
    var match$1 = state.checkbox;
    if (match.TAG === "Valid" && match$1) {
      subscribeToNewsletter$1(match._0);
      return ;
    }
    if (match$1) {
      Util_Logger.error2("Newsletter", "Email invalid");
      return SetFocus.ByRef.make(inputRef);
    } else {
      return Util_Logger.error2("Newsletter", "checkbox not checked");
    }
  };
  var onChange = function (value) {
    var match = Js_string.match_(emailRegex, value);
    var newValue = value === "" ? ({
          TAG: "Invalid",
          _0: undefined
        }) : (
        match !== undefined ? ({
              TAG: "Valid",
              _0: value
            }) : ({
              TAG: "Invalid",
              _0: value
            })
      );
    dispatch({
          TAG: "UpdateEmail",
          _0: newValue
        });
  };
  var onCheckboxClick = function (checkboxValue) {
    dispatch({
          TAG: "UpdateCheckbox",
          _0: checkboxValue
        });
  };
  return {
          onChange: onChange,
          onSubmit: onSubmit,
          onCheckboxClick: onCheckboxClick,
          state: state
        };
}

function make$1(feedback) {
  if (typeof feedback !== "object") {
    return {
            textFeedback: Type_String.SubscribeToNewsletter.$$Error.toString(Type_String.SubscribeToNewsletter.$$Error.empty),
            textStyleFeedback: Atom_Text_Ds.makeFeedback("Error")
          };
  } else if (feedback.TAG === "Error") {
    return {
            textFeedback: Type_String.SubscribeToNewsletter.$$Error.toString(feedback._0),
            textStyleFeedback: Atom_Text_Ds.makeFeedback("Error"),
            icon: Caml_option.some(Atom_Icon_Ds.error)
          };
  } else {
    return {
            textFeedback: Type_String.SubscribeToNewsletter.Success.toString(feedback._0),
            textStyleFeedback: Atom_Text_Ds.makeFeedback("Success"),
            icon: Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.circleCheck, "Success"))
          };
  }
}

var UseSubscribeToNewsletter_Ds = {
  make: make$1
};

export {
  emailRegex ,
  initialState ,
  reducer ,
  subscribeToNewsletter ,
  make ,
  UseSubscribeToNewsletter_Ds ,
}
/* emailRegex Not a pure module */
