import { IncomingMessage } from 'http';
import { getCookie } from 'cookies-next';
import { env } from '@replay/env-generator';

const DEFAULT_ABV_GROUP = 'A';

export const getAbGroupsFromRequest = (req: IncomingMessage | undefined): string => {
    if (!env.NEXT_PUBLIC_FEATURE_FLAGS_AB_TESTING) {
        return DEFAULT_ABV_GROUP;
    }
    const xAbvHeader = req?.headers['x-arte-abv'] ?? req?.headers['X-ARTE-abv'];

    let abvGroups = Array.isArray(xAbvHeader) ? xAbvHeader[0] : xAbvHeader || '';

    if (abvGroups !== '') {
        return abvGroups;
    }

    if (req?.headers.cookie) {
        const parsedCookie = getCookie('abv', { req });
        if (parsedCookie) {
            const badCookieFormat = parsedCookie.toString().length > 1;
            if (!badCookieFormat) {
                abvGroups = parsedCookie.toString();
            }
        }
    }
    return abvGroups !== '' ? abvGroups : DEFAULT_ABV_GROUP;
};
