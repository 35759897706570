// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as UseClickTracking from "../../../../hook/UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_BurgerMenu from "./Organism_BurgerMenu.res.mjs";

function Organism_BaseNavigation(props) {
  var searchState = props.searchState;
  var logoNavigation = props.logoNavigation;
  var country = props.country;
  var match = Context.I18n.getState();
  var ffComingSoonEuropeEntry = Context.Config.getFeatureFlippingStatus("ComingSoonEuropeEntry");
  var match$1 = match.labels.navbar;
  var clickTracking = UseClickTracking.use();
  var displayMobileRules;
  displayMobileRules = searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none") : Particle_Screen_Ds.make(undefined, undefined, "inlineFlex", undefined, undefined, "none");
  var navigationLinks;
  var exit = 0;
  if (ffComingSoonEuropeEntry === "On") {
    if (country === "Europe") {
      navigationLinks = logoNavigation.links;
    } else {
      exit = 1;
    }
  } else if (country === "Europe") {
    navigationLinks = logoNavigation.links.filter(function (param) {
          return param.id !== "upcoming";
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    navigationLinks = (function (__x) {
          return Js_array.filter((function (param) {
                        return param.id !== "search";
                      }), __x);
        })(props.desktopNavigation.links);
  }
  var burgerMenuDisplay;
  burgerMenuDisplay = searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none") : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex");
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Atom_Row.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: logoNavigation.href,
                              onClick: (function (param) {
                                  clickTracking({
                                        eventGroup: "header",
                                        eventName: "home"
                                      });
                                }),
                              screenReadersLabel: logoNavigation.title,
                              style: Atom_Cta_Ds.Header.makeLogo("Vertical", props.logoBerne, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "inlineFlex"))
                            }),
                        JsxRuntime.jsx(Organism_BurgerMenu.make, {
                              menuNavigation: props.menuNavigation,
                              category: props.category,
                              display: Caml_option.some(burgerMenuDisplay),
                              country: country
                            })
                      ],
                      rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.unset)]))
                    }),
                JsxRuntime.jsx(Atom_Row.make, {
                      children: JsxRuntime.jsx(JsxRuntime.Fragment, {
                            children: Caml_option.some(navigationLinks.map(function (param) {
                                      var id = param.id;
                                      var tmp;
                                      tmp = id === "arte_concert" ? Atom_Cta_Ds.Header.makeConcert("Europe") : Atom_Cta_Ds.Header.baseNavigation;
                                      return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                  href: param.href,
                                                  label: param.label,
                                                  onClick: (function (param) {
                                                      clickTracking({
                                                            eventGroup: "header",
                                                            eventName: id
                                                          });
                                                    }),
                                                  style: tmp
                                                }, id);
                                    }))
                          }),
                      display: Caml_option.some(displayMobileRules),
                      kind: {
                        TAG: "Nav",
                        _0: match$1.secondaryNavigation
                      }
                    })
              ]
            });
}

var make = Organism_BaseNavigation;

export {
  make ,
}
/* CssJs Not a pure module */
