import { create, type StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { isFuture, addHours } from '@artegeie/date';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import type { subheader } from './Navbar';
import type { Locale } from '@replay/i18n/types/locale';

type AuthRedirect = {
    returnType: 'external' | 'internal';
    returnPage: string;
    expirationDate: Date;
    query?: {
        [key: string]: string;
    };
};

interface AuthData {
    savedRedirect: AuthRedirect | null;
    subheaders: subheader[];
    userEmail: string | undefined;
    setUserEmail: (email: string) => void;
    addSubheader: (subheader: subheader) => void;
    removeSubheader: (subheader: subheader) => void;
    saveRedirect: ({
        returnPage,
        returnType,
        query,
    }: {
        returnPage: string;
        returnType: 'external' | 'internal';
        query?:
            | {
                  [key: string]: string;
              }
            | undefined;
    }) => void;
    clearRedirect: () => void;
    getRedirect: () => {
        returnPage: string;
        returnType: 'external' | 'internal';
        query:
            | {
                  [key: string]: string;
              }
            | undefined;
    } | null;
}

const createAuthData: StateCreator<AuthData> = (set, get) => ({
    savedRedirect: null,
    subheaders: [],
    userEmail: undefined,
    setUserEmail: (email) => {
        set({
            userEmail: email,
        });
    },
    addSubheader: (sub) => {
        set((state) => {
            return { ...state, subheaders: [...state.subheaders, sub] };
        });
    },
    removeSubheader: (sub) => {
        set((state) => {
            return { ...state, subheaders: state.subheaders.filter((s) => s !== sub) };
        });
    },
    saveRedirect: ({ returnPage, returnType, query }) => {
        set({
            savedRedirect: {
                returnType,
                returnPage,
                expirationDate: addHours(new Date(), 1),
                query,
            },
        });
    },
    clearRedirect: () => {
        set({
            savedRedirect: null,
        });
    },
    getRedirect: () => {
        const savedRedirect = get().savedRedirect;
        if (!savedRedirect) {
            return null;
        }
        const { returnPage, expirationDate, returnType, query } = savedRedirect;
        if (returnPage && expirationDate && isFuture(expirationDate)) {
            return { returnPage, returnType, query };
        } else {
            return null;
        }
    },
});

export const useAuthStore = create<AuthData>()(
    devtools(
        persist(
            (...a) => ({
                ...createAuthData(...a),
            }),
            { name: 't_auth_replay' },
        ),
    ),
);

export const validateEmailQuery = (email: string | string[] | undefined): string | undefined => {
    if (typeof email === 'string') {
        return email;
    }
    if (Array.isArray(email)) {
        return email[0];
    }
    return undefined;
};

export const validateFromQuery = (from: string | string[] | undefined | null): 'Web' | 'Tv' | 'AgeVerification' => {
    let ensuredFrom = undefined;
    if (typeof from === 'string') {
        ensuredFrom = from;
    }
    if (Array.isArray(from)) {
        ensuredFrom = from[0];
    }
    let ensuredOrigin: 'Web' | 'Tv' | 'AgeVerification' = 'Web';
    switch (ensuredFrom) {
        case 'tv-login':
            ensuredOrigin = 'Tv';
            break;
        case 'tv-age-verification':
            ensuredOrigin = 'AgeVerification';
            break;
        default:
            break;
    }
    return ensuredOrigin;
};

export const getRedirectUrl = (from: 'Web' | 'Tv' | 'AgeVerification', locale: Locale): string | undefined => {
    if (from === 'Tv') {
        return `/${locale}/profile/tv-login/`;
    }
    if (from === 'AgeVerification') {
        return `/${locale}/profile/tv-age-verification/`;
    }
    return;
};

export const useRedirect = () => {
    const { push, locale } = useRouter();
    const {
        getRedirect,
        clearRedirect,
        saveRedirect,
        setUserEmail,
        userEmail,
        addSubheader,
        subheaders,
        removeSubheader,
    } = useAuthStore.getState();

    const redirect = useCallback(() => {
        const savedRedirect = getRedirect();
        if (savedRedirect) {
            const { returnPage, returnType, query } = savedRedirect;
            if (returnType === 'external') {
                window.location.assign(returnPage);
            } else {
                push({ pathname: returnPage, query });
            }
            clearRedirect();
        } else {
            push(`/${locale}/`);
        }
    }, [clearRedirect, getRedirect, push, locale]);

    return { saveRedirect, redirect, getRedirect, setUserEmail, userEmail, addSubheader, subheaders, removeSubheader };
};
