// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Util_Dom from "../../../../util/Util_Dom.res.mjs";
import * as Util_Array from "../../../../util/Util_Array.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Binding_Date from "../../../../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseGuideZone from "../../../../hook/UseGuideZone.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Organism_Slider from "../../../organism/slider/Organism_Slider.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Ds from "../../../organism/slider/Organism_Slider_Ds.res.mjs";
import * as Layout_Guide_Column from "../Layout_Guide_Column.res.mjs";
import * as Type_Layout_Guide_Concert from "./Type_Layout_Guide_Concert.res.mjs";

function getStartOfWeek(date, locale) {
  return Binding_Date.startOfWeek(date, locale, Type_Layout_Guide_Concert.weekStartsOn);
}

function isIncludeIntoWeek(startOfWeek, teaserDate, locale) {
  return Binding_Date.compareDatesByDay(getStartOfWeek(teaserDate, locale), startOfWeek);
}

function getTeasersByWeek(teasers, locale) {
  var firstTeaser = Util_Array.NonEmpty.head(teasers);
  var firstTeaserStartOfWeek = Core__Option.map(firstTeaser.availability, (function (param) {
          return getStartOfWeek(new Date(param.upcomingDate), locale);
        }));
  var startOfSecondWeek = Core__Option.map(firstTeaserStartOfWeek, (function (value) {
          return $$Date.addWeeks(value, 1);
        }));
  var teaserWeekEmpty_firstWeek = {
    date: firstTeaserStartOfWeek,
    teaserList: undefined
  };
  var teaserWeekEmpty_secondWeek = {
    date: startOfSecondWeek,
    teaserList: undefined
  };
  var teaserWeekEmpty_otherWeek = {
    date: undefined,
    teaserList: undefined
  };
  var teaserWeekEmpty = {
    firstWeek: teaserWeekEmpty_firstWeek,
    secondWeek: teaserWeekEmpty_secondWeek,
    otherWeek: teaserWeekEmpty_otherWeek
  };
  return Util_Array.NonEmpty.reduce(teasers, teaserWeekEmpty, (function (teasersByWeek, currentTeaser) {
                var match = currentTeaser.availability;
                if (match === undefined) {
                  return teasersByWeek;
                }
                var upcomingDate = match.upcomingDate;
                var isInFirstWeek = Core__Option.map(firstTeaserStartOfWeek, (function (firstTeaserStartOfWeek) {
                        return isIncludeIntoWeek(firstTeaserStartOfWeek, new Date(upcomingDate), locale);
                      }));
                var isInSecondWeek = Core__Option.map(startOfSecondWeek, (function (startOfSecondWeek) {
                        return isIncludeIntoWeek(startOfSecondWeek, new Date(upcomingDate), locale);
                      }));
                var exit = 0;
                if (isInFirstWeek !== undefined) {
                  switch (isInFirstWeek) {
                    case "Same" :
                        return Type_Layout_Guide_Concert.pushTeaserIntoColumn(currentTeaser, "FirstWeek", teasersByWeek);
                    case "After" :
                    case "Before" :
                        exit = 2;
                        break;
                    
                  }
                } else {
                  exit = 2;
                }
                if (exit === 2 && isInSecondWeek !== undefined) {
                  switch (isInSecondWeek) {
                    case "Same" :
                        return Type_Layout_Guide_Concert.pushTeaserIntoColumn(currentTeaser, "SecondWeek", teasersByWeek);
                    case "After" :
                    case "Before" :
                        break;
                    
                  }
                }
                return Type_Layout_Guide_Concert.pushTeaserIntoColumn(currentTeaser, "OtherWeek", teasersByWeek);
              }));
}

function Layout_Guide_Concert(props) {
  var pageKind = props.pageKind;
  var sliderRef = React.useRef(null);
  var match = Context.I18n.getState();
  var locale = match.locale;
  var match$1 = match.labels.guide.concerts;
  var weekIntervalFormater = match.formaters.weekInterval;
  var guideSoonColumnTitle = match$1.soonColumnTitle;
  var titleRef = React.useRef(null);
  var zone = UseGuideZone.make(props.page);
  var teasers = Core__Option.getOr(Core__Option.map(zone, (function (param) {
              return param.data;
            })), []);
  var teasersByWeek = React.useMemo((function () {
          var teasers$1 = Util_Array.NonEmpty.makeFromArray(teasers);
          if (teasers$1 === undefined) {
            return null;
          }
          var match = getTeasersByWeek(Caml_option.valFromOption(teasers$1), locale);
          var secondWeek = match.secondWeek;
          var firstWeek = match.firstWeek;
          var titleFromDate = function (date) {
            return Core__Option.mapOr(date, "", (function (date) {
                          return weekIntervalFormater(date);
                        }));
          };
          return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        JsxRuntime.jsx(Layout_Guide_Column.make, {
                              title: titleFromDate(firstWeek.date),
                              teasers: firstWeek.teaserList,
                              titleRef: titleRef,
                              pageKind: pageKind
                            }, Core__Option.mapOr(firstWeek.date, "", (function (date) {
                                    return weekIntervalFormater(date);
                                  }))),
                        JsxRuntime.jsx(Layout_Guide_Column.make, {
                              title: titleFromDate(secondWeek.date),
                              teasers: secondWeek.teaserList,
                              pageKind: pageKind
                            }, Core__Option.mapOr(secondWeek.date, "", (function (date) {
                                    return weekIntervalFormater(date);
                                  }))),
                        JsxRuntime.jsx(Layout_Guide_Column.make, {
                              title: guideSoonColumnTitle,
                              teasers: match.otherWeek.teaserList,
                              pageKind: pageKind
                            }, guideSoonColumnTitle)
                      ]
                    });
        }), [
        teasers,
        locale,
        pageKind,
        guideSoonColumnTitle
      ]);
  var heightComputation = React.useCallback((function (param) {
          var match = Util_Dom.getElementSize(Util_Dom.getDomRef(titleRef));
          return match.height | 0;
        }), [titleRef.current]);
  return JsxRuntime.jsx(Atom_Column.make, {
              children: zone !== undefined ? JsxRuntime.jsx(Organism_Slider.make, {
                      children: [teasersByWeek],
                      heightComputation: {
                        TAG: "Custom",
                        _0: heightComputation
                      },
                      itemSpace: Caml_option.some(Layout_Guide_Column.columnSpace),
                      selectItems: Layout_Guide_Column.selectItems,
                      sliderRef: sliderRef,
                      verticalPadding: "L",
                      variant: Organism_Slider_Ds.guide
                    }, zone.id) : null,
              kind: "Main"
            });
}

var verticalPadding = "L";

var make = Layout_Guide_Concert;

export {
  getStartOfWeek ,
  isIncludeIntoWeek ,
  getTeasersByWeek ,
  verticalPadding ,
  make ,
}
/* react Not a pure module */
