// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as User_Type from "../../user/User_Type.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../type/Type_Avatar.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Backlink from "../atom/Atom_Backlink.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_User_Card from "../organism/Organism_User_Card.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XL")), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "M"));

var contentBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "M", "XL", "Zero")), undefined, Particle_Spacer_Ds.makeNone());

var Layout_Account_Css = {
  containerBlockSpace: containerBlockSpace,
  contentBlockSpace: contentBlockSpace
};

var avatarRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.flexDirection("row"),
      CssJs.flexWrap("wrap")
    ], undefined, undefined, [
      CssJs.flexDirection("column"),
      CssJs.flexWrap("nowrap")
    ]);

var cardDisplay = Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, "none");

var cardDisplayOverride = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flex");

function Layout_Account$WithAvatar(props) {
  var __cardMobileDisplay = props.cardMobileDisplay;
  var cardMobileDisplay = __cardMobileDisplay !== undefined ? __cardMobileDisplay : "Hidden";
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  var firstName = Core__Option.flatMap(userInfos, User_Type.Info.firstName);
  var email = Core__Option.flatMap(userInfos, User_Type.Info.email);
  var surname = firstName !== undefined && firstName !== "" ? ({
        TAG: "FirstName",
        _0: firstName
      }) : ({
        TAG: "Email",
        _0: Core__Option.getOr(email, "")
      });
  var tmp;
  tmp = cardMobileDisplay === "Displayed" ? cardDisplayOverride : cardDisplay;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(Particle_Screen_Constants.lBottomBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Row.make, {
                      blockSpace: Caml_option.some(containerBlockSpace),
                      children: JsxRuntime.jsx(Atom_Backlink.User.make, {})
                    }),
                JsxRuntime.jsxs(Atom_Cell.make, {
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                      blockSpace: Caml_option.some(containerBlockSpace),
                      children: [
                        JsxRuntime.jsx(Organism_User_Card.make, {
                              surname: surname,
                              avatar: userAvatar,
                              display: Caml_option.some(tmp)
                            }),
                        JsxRuntime.jsx(Atom_Column.make, {
                              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                              blockSpace: Caml_option.some(contentBlockSpace),
                              children: props.children,
                              innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart),
                              kind: "Main",
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                            })
                      ],
                      rules: Caml_option.some(avatarRules)
                    })
              ]
            });
}

var WithAvatar = {
  avatarRules: avatarRules,
  cardDisplay: cardDisplay,
  cardDisplayOverride: cardDisplayOverride,
  make: Layout_Account$WithAvatar
};

export {
  Layout_Account_Css ,
  WithAvatar ,
}
/* containerBlockSpace Not a pure module */
