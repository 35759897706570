import { Geoblocking } from '@artegeie/design-system/type';

const GEOBLOCKING_DEFAULTVALUE: Geoblocking = 'DE_FR';

export const makeGeoblockingFromString = (geoblocking: string | null): Geoblocking => {
    if (!geoblocking) {
        return GEOBLOCKING_DEFAULTVALUE;
    }
    return geoblocking as Geoblocking;
};
