// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Grid from "../atom/Atom_Grid.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Type_Page_SiteMap from "../../type/Type_Page_SiteMap.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

function Page_SiteMap$LinkArray(props) {
  return JsxRuntime.jsx(Atom_Column.make, {
              children: props.arrayItem.map(function (item) {
                    return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                href: item.href,
                                label: item.label,
                                style: Atom_Cta_Ds.siteMap
                              }, item.id);
                  }),
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S"))
            });
}

var LinkArray = {
  make: Page_SiteMap$LinkArray
};

var gridBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("M"));

var titleBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var gapColumn = Particle_Screen_Ds.make(undefined, undefined, "XL", undefined, undefined, "M");

var gapRow = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "L");

var blockSpaceContainer = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Constants.Zone.desktop), undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

var rules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.justifyItems("start"),
      CssJs.gridTemplateColumns([
            {
              NAME: "fr",
              VAL: 1
            },
            {
              NAME: "fr",
              VAL: 1
            },
            {
              NAME: "fr",
              VAL: 1
            }
          ])
    ], undefined, undefined, [CssJs.gridTemplateColumns([{
              NAME: "fr",
              VAL: 1
            }])]);

function make(param) {
  var siteMap = Type_Page_SiteMap.UseSiteMapData.make();
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpaceContainer),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      blockSpace: Caml_option.some(titleBlockspace),
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(siteMap.title),
                            kind: "h1",
                            style: Atom_Text_Ds.SiteMap.title
                          })
                    }),
                JsxRuntime.jsxs(Atom_Grid.make, {
                      blockSpace: Caml_option.some(gridBlockSpace),
                      children: [
                        JsxRuntime.jsx(Page_SiteMap$LinkArray, {
                              arrayItem: siteMap.general
                            }),
                        JsxRuntime.jsx(Page_SiteMap$LinkArray, {
                              arrayItem: siteMap.categories
                            }),
                        JsxRuntime.jsx(Page_SiteMap$LinkArray, {
                              arrayItem: siteMap.menuNavigation
                            })
                      ],
                      gapColumn: Caml_option.some(gapColumn),
                      gapRow: Caml_option.some(gapRow),
                      rules: Caml_option.some(rules)
                    })
              ],
              kind: "Main"
            });
}

export {
  LinkArray ,
  gridBlockSpace ,
  titleBlockspace ,
  gapColumn ,
  gapRow ,
  blockSpaceContainer ,
  rules ,
  make ,
}
/* gridBlockSpace Not a pure module */
