// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function make(param) {
  var forceTrailer = param.forceTrailer;
  var match = Context.I18n.getState();
  var formaters = match.formaters;
  var match$1 = Context.Config.getState();
  var trailerMode = forceTrailer !== undefined && forceTrailer ? "Forced" : "OnButtonClick";
  var pageKind = {
    TAG: "Rc",
    _0: trailerMode
  };
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, formaters.betweenDatesFormatted, formaters.seasonCount, pageKind, param.domain, match$1.serverTime, param.page);
  return JsxRuntime.jsx(Zone_Layout.make, {
              page: page,
              pageKind: pageKind,
              numberZone: param.numberZone
            });
}

export {
  make ,
}
/* Context Not a pure module */
