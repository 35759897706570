// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Icon_Ds from "../../atom/Atom_Icon_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../../../type/Type_Sticker.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Type_Progression from "../../../type/Type_Progression.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Teaser_Util from "./Organism_Teaser_Util.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function Organism_Teaser_Hover_Play(props) {
  var teaser = props.teaser;
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.teasers;
  var watchAgainLabel = match$1.watchAgain;
  var playLabel = match$1.watch;
  var continueWatchingLabel = match$1.continueWatching;
  var match$2 = labels.common;
  var match$3 = labels.program;
  var playableSticker = Type_Sticker.toArray(teaser.stickers).find(function (sticker) {
        if (typeof sticker !== "object") {
          return false;
        }
        var variant = sticker.NAME;
        if (variant === "Live" || variant === "Playable") {
          return true;
        } else {
          return variant === "Direct";
        }
      });
  var match$4 = teaser.availability;
  var programStatus = playableSticker !== undefined || match$4 === undefined ? "Playable" : "NotPlayable";
  var progression = User_Observable.Progression.Hook.use(teaser.dsId);
  var progressionPct = Type_Progression.getPct(progression.timeProgression);
  var availabilityType = Core__Option.map(teaser.availability, (function (param) {
          return param.type;
        }));
  var labels$1;
  var exit = 0;
  if (availabilityType !== undefined) {
    if (availabilityType === "LIVESTREAM_TV") {
      labels$1 = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(match$1.watchLive), undefined, undefined, match$1.live);
    } else if (availabilityType === "LIVESTREAM_WEB") {
      labels$1 = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(match$1.watchLivestream), undefined, undefined, match$1.livestream);
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var exit$1 = 0;
    if (progressionPct !== undefined) {
      if (progressionPct >= 100) {
        labels$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, watchAgainLabel);
      } else if (progressionPct > 0) {
        labels$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, continueWatchingLabel);
      } else {
        exit$1 = 2;
      }
    } else {
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      labels$1 = programStatus === "Playable" ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, playLabel) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, match$2.moreInfos);
    }
    
  }
  var match$5 = teaser.program.kind;
  var icon;
  icon = programStatus === "Playable" && !(match$5 !== undefined && (match$5 === "EXTERNAL" || match$5 === "INT")) ? Caml_option.some(Atom_Icon_Ds.play) : undefined;
  var overrideSticker = Type_Sticker.toArray(teaser.stickers).find(function (sticker) {
        if (typeof sticker === "object") {
          return sticker.NAME === "LabelOverride";
        } else {
          return false;
        }
      });
  var match$6;
  if (overrideSticker !== undefined && typeof overrideSticker === "object" && overrideSticker.NAME === "LabelOverride") {
    var match$7 = overrideSticker.VAL;
    var label = match$7[0];
    match$6 = match$7[1] === "play" ? [
        (function (label) {
              return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, label);
            })(Type_String.NotEmpty.toString(label)),
        Caml_option.some(Atom_Icon_Ds.play)
      ] : [
        (function (label) {
              return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, label);
            })(Type_String.NotEmpty.toString(label)),
        undefined
      ];
  } else {
    match$6 = [
      labels$1,
      icon
    ];
  }
  var style = Atom_Cta_Ds.Teaser.makeTeaserPlay(progressionPct, match$6[1], props.parentComponent);
  var screenReadersLabel = Organism_Teaser_Util.getScreenReadersLabel(progressionPct, continueWatchingLabel, playLabel, watchAgainLabel, match.formaters.progress, match$3.to_be_continued, teaser);
  return JsxRuntime.jsx(Atom_Cta.Link.Responsive.make, {
              href: Type_String.Href.toString(teaser.href),
              identifier: props.identifier,
              labels: match$6[0],
              onClick: props.onClick,
              screenReadersLabel: screenReadersLabel,
              style: style,
              position: Caml_option.some(Particle_Screen_Constants.positionRelative)
            });
}

var make = Organism_Teaser_Hover_Play;

export {
  make ,
}
/* Context Not a pure module */
