'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../CssJs.res.mjs";
import * as Page_Rc from "../design-system/page/Page_Rc.res.mjs";
import * as Atom_Text from "../design-system/atom/Atom_Text.res.mjs";
import * as Page_Home from "../design-system/page/Page_Home.res.mjs";
import * as Page_List from "../design-system/page/Page_List.res.mjs";
import * as Page_Event from "../design-system/page/Page_Event.res.mjs";
import * as Page_Genre from "../design-system/page/Page_Genre.res.mjs";
import * as Page_Login from "./Portal/page/Page_Login.res.mjs";
import * as Page_Short from "../design-system/page/Page_Short.res.mjs";
import * as GlobalStyle from "../design-system/GlobalStyle.res.mjs";
import * as Page_Direct from "../design-system/page/Page_Direct.res.mjs";
import * as Page_Search from "../design-system/page/Page_Search.res.mjs";
import * as Type_Common from "../type/Type_Common.res.mjs";
import * as AdsException from "../AdsException.res.mjs";
import * as Page_Concert from "../design-system/page/Page_Concert.res.mjs";
import * as Page_Program from "../design-system/page/Page_Program.res.mjs";
import * as Page_SiteMap from "../design-system/page/Page_SiteMap.res.mjs";
import * as Layout_Search from "../design-system/layout/Layout_Search.res.mjs";
import * as Page_Error404 from "../design-system/page/Page_Error404.res.mjs";
import * as Page_Error500 from "../design-system/page/Page_Error500.res.mjs";
import * as Page_Feedback from "../design-system/page/Page_Feedback.res.mjs";
import * as Page_Guide_Tv from "../design-system/page/Page_Guide_Tv.res.mjs";
import * as Page_MyVideos from "../design-system/page/Page_MyVideos.res.mjs";
import * as Page_Register from "./Portal/page/Page_Register.res.mjs";
import * as Page_Settings from "../design-system/page/Page_Settings.res.mjs";
import * as User_Provider from "../user/User_Provider.res.mjs";
import * as Page_MyAccount from "../design-system/page/myAccount/Page_MyAccount.res.mjs";
import * as Organism_Footer from "../design-system/organism/footer/Organism_Footer.res.mjs";
import * as Organism_Header from "../design-system/organism/header/Organism_Header.res.mjs";
import * as Page_Magic_Link from "./Portal/page/Page_Magic_Link.res.mjs";
import * as Provider_Config from "../provider/Provider_Config.res.mjs";
import * as User_Observable from "../user/User_Observable.res.mjs";
import * as Page_SubCategory from "../design-system/page/Page_SubCategory.res.mjs";
import * as Organism_Error500 from "../design-system/organism/oops/Organism_Error500.res.mjs";
import * as Page_Verify_Email from "./Portal/page/Page_Verify_Email.res.mjs";
import * as Page_Guide_Concert from "../design-system/page/Page_Guide_Concert.res.mjs";
import * as Page_Login_Landing from "./Portal/page/Page_Login_Landing.res.mjs";
import * as Page_Password_Reset from "./Portal/page/Page_Password_Reset.res.mjs";
import * as Page_MyAccount_Email from "../design-system/page/myAccount/Page_MyAccount_Email.res.mjs";
import * as Page_MyAccount_Event from "../design-system/page/myAccount/Page_MyAccount_Event.res.mjs";
import * as Replay_I18n_Provider from "./Replay/Replay_I18n_Provider.res.mjs";
import * as Molecule_Guide_Loader from "../design-system/layout/guide/Molecule_Guide_Loader.res.mjs";
import * as Organism_HeaderConcert from "../design-system/organism/header/Organism_HeaderConcert.res.mjs";
import * as Molecule_FeedbackButton from "../design-system/molecule/Molecule_FeedbackButton.res.mjs";
import * as Page_MyAccount_Password from "../design-system/page/myAccount/Page_MyAccount_Password.res.mjs";
import * as Page_MyAccount_Tv_Login from "../design-system/page/myAccount/Page_MyAccount_Tv_Login.res.mjs";
import * as Page_MyAccount_EventList from "../design-system/page/myAccount/Page_MyAccount_EventList.res.mjs";
import * as Page_Tv_Age_Verification from "./Portal/page/Page_Tv_Age_Verification.res.mjs";
import * as Page_Verification_Failed from "./Portal/page/Page_Verification_Failed.res.mjs";
import * as Page_Verification_Pending from "./Portal/page/Page_Verification_Pending.res.mjs";
import CreateInstance from "@emotion/css/create-instance";
import * as Page_MyAccount_NewDesign_Avatar from "../design-system/page/myAccount/Page_MyAccount_NewDesign_Avatar.res.mjs";
import * as Page_MyAccount_NewDesign_DeleteAccount from "../design-system/page/myAccount/Page_MyAccount_NewDesign_DeleteAccount.res.mjs";

Replay_I18n_Provider.make.displayName = "I18nProvider";

Provider_Config.make.displayName = "ConfigProvider";

User_Provider.make.displayName = "UserProvider";

function globalStyle(withFont, theme) {
  GlobalStyle.replayGlobalStyle(withFont, theme);
}

Organism_Header.make.displayName = "Header";

Organism_HeaderConcert.make.displayName = "HeaderConcert";

Organism_Footer.make.displayName = "Footer";

Page_Genre.make.displayName = "GenrePage";

Page_Rc.make.displayName = "RcPage";

Page_Concert.make.displayName = "ConcertPage";

Page_Home.make.displayName = "HomePage";

Page_List.make.displayName = "ListPage";

Page_Program.make.displayName = "ProgramPage";

Page_Search.make.displayName = "SearchPage";

Page_SubCategory.make.displayName = "SubCategoryPage";

Page_Direct.make.displayName = "DirectPage";

Organism_Error500.make.displayName = "Error500";

var TitleForScreenreaders = Atom_Text.ScreenreadersLabel.Replay.make;

TitleForScreenreaders.displayName = "TitleForScreenreaders";

Page_SiteMap.make.displayName = "SiteMapPage";

Page_Event.make.displayName = "EventPage";

Page_Settings.make.displayName = "SettingsPage";

function parseAdsError(e) {
  return AdsException.fromError(e);
}

Page_Guide_Concert.make.displayName = "GuideConcertPage";

Page_Guide_Tv.make.displayName = "GuideTvPage";

Page_MyVideos.make.displayName = "MyVideosPage";

Molecule_Guide_Loader.make.displayName = "MoleculeGuideLoader";

var NotFoundHaiku = Page_Error404.Haiku.make;

NotFoundHaiku.displayName = "NotFoundHaiku";

var useTokenStatus = User_Provider.Hook.useTokenStatus;

var useConnectedToken = User_Provider.Hook.useConnectedToken;

var useUserTrackingInfo = User_Observable.UserInfo.Tracking.use;

var SearchFilter = Layout_Search.SearchFilter.make;

SearchFilter.displayName = "SearchFilter";

var SearchTitle = Layout_Search.SearchTitle.make;

SearchTitle.displayName = "SearchTitle";

Page_MyAccount_Email.make.displayName = "MyAccountEmailPage";

Page_MyAccount_Password.make.displayName = "MyAccountPasswordPage";

Page_MyAccount_NewDesign_DeleteAccount.make.displayName = "MyAccountDeleteAccountPageNewDesign";

Page_MyAccount_NewDesign_Avatar.make.displayName = "MyAccountAvatarPageNewDesign";

Page_MyAccount_Tv_Login.make.displayName = "MyAccountTvLoginPage";

Page_MyAccount_EventList.make.displayName = "MyAccountEventListPage";

Page_MyAccount_Event.make.displayName = "MyAccountEventPage";

Molecule_FeedbackButton.make.displayName = "FeedbackButton";

Page_Feedback.make.displayName = "FeedbackPage";

var FeedbackSuccessPage = Page_Feedback.Success.make;

FeedbackSuccessPage.displayName = "FeedbackSuccessPage";

function createEmotionCache() {
  var match = CreateInstance({
        key: "ds"
      });
  var cache = match.cache;
  CssJs.setAll(cache, match.cx, match.keyframes, match.injectGlobal, match.css);
  return cache;
}

Page_Login_Landing.make.displayName = "AuthLandingPage";

Page_Login.make.displayName = "LoginPage";

Page_Register.make.displayName = "RegisterPage";

Page_Verify_Email.make.displayName = "VerifyEmailPage";

Page_Password_Reset.make.displayName = "PasswordResetPage";

Page_Verification_Pending.make.displayName = "VerificationPendingPage";

Page_Verification_Failed.make.displayName = "VerificationFailedPage";

Page_Tv_Age_Verification.make.displayName = "TvAgeVerificationPage";

Page_Magic_Link.make.displayName = "MagicLinkPage";

Page_Short.make.displayName = "ShortsPage";

var ErrorFrontendPage = Page_Error500.Frontend.make;

ErrorFrontendPage.displayName = "ErrorFrontendPage";

var I18nProvider = Replay_I18n_Provider.make;

var ConfigProvider = Provider_Config.make;

var UserProvider = User_Provider.make;

var Header = Organism_Header.make;

var HeaderConcert = Organism_HeaderConcert.make;

var Footer = Organism_Footer.make;

var GenrePage = Page_Genre.make;

var RcPage = Page_Rc.make;

var ConcertPage = Page_Concert.make;

var HomePage = Page_Home.make;

var ListPage = Page_List.make;

var ProgramPage = Page_Program.make;

var SearchPage = Page_Search.make;

var SubCategoryPage = Page_SubCategory.make;

var DirectPage = Page_Direct.make;

var geoblockingOrdered = Type_Common.geoblockingOrdered;

var Error500 = Organism_Error500.make;

var SiteMapPage = Page_SiteMap.make;

var EventPage = Page_Event.make;

var SettingsPage = Page_Settings.make;

var GuideConcertPage = Page_Guide_Concert.make;

var GuideTvPage = Page_Guide_Tv.make;

var MyVideosPage = Page_MyVideos.make;

var MoleculeGuideLoader = Molecule_Guide_Loader.make;

var MyAccountPage = Page_MyAccount.make;

var MyAccountEmailPage = Page_MyAccount_Email.make;

var MyAccountPasswordPage = Page_MyAccount_Password.make;

var MyAccountDeleteAccountPageNewDesign = Page_MyAccount_NewDesign_DeleteAccount.make;

var MyAccountAvatarPageNewDesign = Page_MyAccount_NewDesign_Avatar.make;

var MyAccountTvLoginPage = Page_MyAccount_Tv_Login.make;

var MyAccountEventListPage = Page_MyAccount_EventList.make;

var MyAccountEventPage = Page_MyAccount_Event.make;

var FeedbackButton = Molecule_FeedbackButton.make;

var FeedbackPage = Page_Feedback.make;

var AuthLandingPage = Page_Login_Landing.make;

var LoginPage = Page_Login.make;

var RegisterPage = Page_Register.make;

var VerifyEmailPage = Page_Verify_Email.make;

var PasswordResetPage = Page_Password_Reset.make;

var VerificationPendingPage = Page_Verification_Pending.make;

var VerificationFailedPage = Page_Verification_Failed.make;

var TvAgeVerificationPage = Page_Tv_Age_Verification.make;

var MagicLinkPage = Page_Magic_Link.make;

var ShortsPage = Page_Short.make;

export {
  I18nProvider ,
  ConfigProvider ,
  UserProvider ,
  globalStyle ,
  Header ,
  HeaderConcert ,
  Footer ,
  GenrePage ,
  RcPage ,
  ConcertPage ,
  HomePage ,
  ListPage ,
  ProgramPage ,
  SearchPage ,
  SubCategoryPage ,
  DirectPage ,
  geoblockingOrdered ,
  Error500 ,
  TitleForScreenreaders ,
  SiteMapPage ,
  EventPage ,
  SettingsPage ,
  parseAdsError ,
  GuideConcertPage ,
  GuideTvPage ,
  MyVideosPage ,
  MoleculeGuideLoader ,
  NotFoundHaiku ,
  useTokenStatus ,
  useConnectedToken ,
  useUserTrackingInfo ,
  SearchFilter ,
  SearchTitle ,
  MyAccountPage ,
  MyAccountEmailPage ,
  MyAccountPasswordPage ,
  MyAccountDeleteAccountPageNewDesign ,
  MyAccountAvatarPageNewDesign ,
  MyAccountTvLoginPage ,
  MyAccountEventListPage ,
  MyAccountEventPage ,
  FeedbackButton ,
  FeedbackPage ,
  FeedbackSuccessPage ,
  createEmotionCache ,
  AuthLandingPage ,
  LoginPage ,
  RegisterPage ,
  VerifyEmailPage ,
  PasswordResetPage ,
  VerificationPendingPage ,
  VerificationFailedPage ,
  TvAgeVerificationPage ,
  MagicLinkPage ,
  ShortsPage ,
  ErrorFrontendPage ,
}
/*  Not a pure module */
