// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Type_Club from "../../../club/Type_Club.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../../type/Type_Teaser.res.mjs";
import * as Zone_Layout from "../../../zone/Zone_Layout.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Backlink from "../../atom/Atom_Backlink.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as Layout_Constants from "../../layout/Layout_Constants.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_EventZone from "../../molecule/Molecule_EventZone.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as User_Api__Sso__Event from "../../../user/User_Api/User_Api__Sso__Event.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../../particle/Particle_Spacer_Constants.res.mjs";
import * as Organism_User_Navigation_Header from "../../organism/Organism_User_Navigation_Header.res.mjs";

var minHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      NAME: "vh",
      VAL: 80
    });

var headerBlockspace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "L", "XL", "XL")), undefined, undefined, Particle_Spacer_Ds.make("S", "M", "M", "M"));

var zoneBlockspace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Constants.Zone.desktop), undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

function makeTeaserZone(teasers) {
  return {
          id: "1",
          title: {
            TAG: "Hidden",
            _0: ""
          },
          itemTitle: "NeverDisplayed",
          itemTemplate: "Landscape",
          theme: "User",
          data: teasers,
          genreStickerBehavior: "HideGenreSticker"
        };
}

function make(param) {
  var highlight = param.highlight;
  var match = Context.I18n.getState();
  var formatDate = match.formaters.date;
  var match$1 = match.labels.mona.events;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var highlightId = Core__Option.getOr(Caml_option.nullable_to_opt(highlight.id), "");
  var match$2 = User_Api__Sso__Event.useGetEventStatus(Core__Option.flatMap(userInfos, User_Type.Info.eventsParticipation), highlightId, Caml_option.nullable_to_opt(highlight.participationEndDate));
  var match$3;
  switch (match$2[0]) {
    case "Participated" :
        match$3 = [
          "Disabled",
          Type_String.CallToAction.make(match$1.eventCtaParticipated)
        ];
        break;
    case "NotParticipated" :
        match$3 = [
          "Enabled",
          Type_String.CallToAction.make(match$1.eventCta)
        ];
        break;
    case "Ended" :
        match$3 = [
          "Disabled",
          Type_String.CallToAction.make(match$1.eventCtaEnded)
        ];
        break;
    
  }
  var highlightTeaser = Type_Club.Teaser.toDsTeaser("min", undefined, highlight);
  var highlightTitle = Core__Option.getOr(Type_Teaser.Helper.extractTitle(highlightTeaser), Type_String.Teaser.Title.empty);
  var highlightSubtitle = Type_String.Teaser.Subtitle.makeFromNullableOrEmpty(highlight.location);
  var formattedStart = Core__Option.map(Core__Option.map(Caml_option.nullable_to_opt(highlight.startDate), (function (prim) {
              return new Date(prim);
            })), formatDate);
  var formattedEnd = Core__Option.map(Core__Option.map(Caml_option.nullable_to_opt(highlight.endDate), (function (prim) {
              return new Date(prim);
            })), formatDate);
  var highlightText = formattedStart !== undefined && formattedEnd !== undefined ? Type_String.Teaser.Description.make(formattedStart + " - " + formattedEnd) : undefined;
  return JsxRuntime.jsx(Molecule_EventZone.make, {
              title: highlightTitle,
              subtitle: highlightSubtitle,
              teaserText: highlightText,
              images: highlightTeaser.imageSrc,
              href: highlightTeaser.href,
              trackingPixel: highlightTeaser.trackingPixel,
              id: highlightTeaser.id,
              buttonLabel: match$3[1],
              textPosition: "TextOnRightSide",
              blockSpace: Caml_option.some(zoneBlockspace),
              sharebar: "With",
              ctaStatus: match$3[0],
              eventShareUrl: Type_String.Href.makeFromNullableOrEmpty(highlight.eventShareUrl)
            });
}

var HighlightZone = {
  make: make
};

function make$1(param) {
  var events = param.events;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.events;
  var otherTeasers = events.others.map(function (t) {
          var newrecord = Caml_obj.obj_dup(t);
          newrecord.subtitle = t.specialTxt;
          return newrecord;
        }).map(function (__x) {
        return Type_Club.Teaser.toDsTeaser("min", "Disabled", __x);
      });
  var highlight = events.highlight;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(headerBlockspace),
                      children: [
                        JsxRuntime.jsx(Atom_Backlink.User.make, {}),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.listTitle),
                              kind: "h1",
                              style: Atom_Text_Ds.User.title("MyAccount")
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsx(Organism_User_Navigation_Header.make, {
                      currentKey: param.currentKey
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: Caml_option.some(headerBlockspace),
                      children: JsxRuntime.jsxs(Atom_Column.make, {
                            children: [
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(param.subtitle),
                                    kind: "h2",
                                    style: Atom_Text_Ds.User.subtitle("MyAccount")
                                  }),
                              JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(param.description),
                                    style: Atom_Text_Ds.User.text("MyAccount")
                                  })
                            ]
                          }),
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(Layout_Constants.blockSpace),
                      children: [
                        highlight !== undefined ? JsxRuntime.jsx(make, {
                                highlight: highlight
                              }) : null,
                        JsxRuntime.jsx(Zone_Layout.PaginatedGrid.make, {
                              blockSpace: zoneBlockspace,
                              zone: makeTeaserZone(otherTeasers)
                            })
                      ]
                    })
              ],
              kind: "Main",
              minHeight: Caml_option.some(minHeight)
            });
}

export {
  minHeight ,
  headerBlockspace ,
  zoneBlockspace ,
  makeTeaserZone ,
  HighlightZone ,
  make$1 as make,
}
/* minHeight Not a pure module */
