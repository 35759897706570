'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Atom_Text from "../design-system/atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../design-system/atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function UseUserFetch$Component(props) {
  var state = props.state;
  var match = Context.I18n.getState();
  var match$1 = match.labels.common;
  if (typeof state !== "object") {
    if (state === "Loading") {
      return JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(match$1.loading),
                  style: Atom_Text_Ds.User.info
                });
    } else {
      return null;
    }
  } else if (state.TAG === "Success") {
    return JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(state._0),
                style: Atom_Text_Ds.makeFeedback("Success")
              });
  } else {
    return JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(state._0),
                style: Atom_Text_Ds.makeFeedback("Error")
              });
  }
}

function reducer(param, actions) {
  if (typeof actions !== "object") {
    return "Loading";
  } else if (actions.TAG === "SetSuccess") {
    return {
            TAG: "Success",
            _0: actions._0
          };
  } else {
    return {
            TAG: "Error",
            _0: actions._0
          };
  }
}

function make() {
  var match = React.useReducer(reducer, "Idle");
  var dispatch = match[1];
  var state = match[0];
  return {
          component: JsxRuntime.jsx(UseUserFetch$Component, {
                state: state
              }),
          state: state,
          setLoading: (function () {
              dispatch("Fetching");
            }),
          setError: (function (response) {
              dispatch({
                    TAG: "SetError",
                    _0: response
                  });
            }),
          setSuccess: (function (response) {
              dispatch({
                    TAG: "SetSuccess",
                    _0: response
                  });
            })
        };
}

export {
  make ,
}
/* react Not a pure module */
