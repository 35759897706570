// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cell from "./Atom_Cell.res.mjs";
import * as Type_Image from "../../type/Type_Image.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Box_Type from "./Atom_Box_Type.res.mjs";
import * as Atom_Image_Ds from "./Atom_Image_Ds.res.mjs";
import * as Atom_Image_Css from "./Atom_Image_Css.res.mjs";
import * as Belt_SetString from "@rescript/std/lib/es6/belt_SetString.js";
import * as Css_AtomicTypes from "bs-css/src/Css_AtomicTypes.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_ImageCdn_Ds from "../particle/Particle_ImageCdn_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function srcFromDisplay(extension, display, src) {
  var width;
  var ratio;
  var exit = 0;
  if (typeof display !== "object") {
    if (typeof src === "object") {
      switch (src.TAG) {
        case "Mami" :
        case "Resolution" :
            console.error("Mami or Resolution images with display auto will not work!");
            break;
        default:
          
      }
    }
    return Type_Image.Src.toString(extension, src, 0, "Unknow");
  }
  switch (display.TAG) {
    case "Fixed" :
        var match = display._0;
        width = match.width;
        ratio = match.ratio;
        break;
    case "Fluid" :
    case "FullPlace" :
        exit = 2;
        break;
    
  }
  if (exit === 2) {
    var match$1 = display._0;
    width = match$1.maxWidth;
    ratio = match$1.ratio;
  }
  return Type_Image.Src.toString(extension, src, width, ratio);
}

function getFakeWidth(dict, url, fakeWidth) {
  var width = dict[url];
  if (width !== undefined) {
    return Caml_option.valFromOption(width);
  } else {
    dict[url] = fakeWidth;
    return fakeWidth;
  }
}

function make(extension, globalRatio, sizeVariation, src, display) {
  var match;
  switch (sizeVariation) {
    case "Increasing" :
        match = [
          1,
          (function (v) {
              return 100 - v | 0;
            })
        ];
        break;
    case "Random" :
        match = [
          1,
          (function (v) {
              return 100 - v | 0;
            })
        ];
        break;
    case "Decreasing" :
        match = [
          200,
          (function (v) {
              return 100 + v | 0;
            })
        ];
        break;
    case "Static" :
        match = [
          200,
          (function (v) {
              return 100 + v | 0;
            })
        ];
        break;
    
  }
  var computeOffset = match[1];
  var defaultFakeWidth = match[0];
  var display$1 = Particle_Screen_Ds.view(display);
  if (display$1.TAG === "AllSize") {
    return {
            src: srcFromDisplay(extension, display$1._0, src)
          };
  }
  var screens = display$1._1;
  var dict = {};
  var defaultUrl = srcFromDisplay(extension, display$1._0, src);
  dict[defaultUrl] = defaultFakeWidth;
  screens.reverse();
  var imageDescriptors = screens.map(function (screen, i) {
        var i$1 = computeOffset(i);
        var value = Particle_Screen_Ds.extractValue(screen);
        var url = srcFromDisplay(extension, value, src);
        var fakeWidth = getFakeWidth(dict, url, i$1);
        var mediaQuery = (function (__x) {
              return Particle_Screen_Css.mediaQueryTransformer("MinWidth", __x, undefined);
            })(screen);
        return [
                url + " " + fakeWidth.toString() + "w",
                mediaQuery + " " + Css_AtomicTypes.Length.toString(CssJs.px(fakeWidth)),
                {
                  src: url,
                  media: mediaQuery
                }
              ];
      });
  imageDescriptors.push([
        defaultUrl + " " + defaultFakeWidth.toString() + "w",
        Css_AtomicTypes.Length.toString(CssJs.px(defaultFakeWidth)),
        undefined
      ]);
  var tmp;
  var exit = 0;
  if (globalRatio === "Uniform" && sizeVariation !== "Random") {
    tmp = undefined;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = Util_Array.catOption(imageDescriptors.map(function (param) {
              return param[2];
            }));
  }
  return {
          src: defaultUrl,
          srcSet: Belt_SetString.toArray(Belt_SetString.fromArray(imageDescriptors.map(function (param) {
                          return param[0];
                        }))).join(","),
          sizes: imageDescriptors.map(function (param) {
                  return param[1];
                }).join(","),
          pictures: tmp
        };
}

function make$1(param) {
  var loading = param.loading;
  var identifier = param.identifier;
  var alt = param.alt;
  var className = param.className;
  var src = param.src;
  var pictures = param.pictures;
  if (pictures !== undefined) {
    return JsxRuntime.jsxs("picture", {
                children: [
                  (function (__x) {
                        return __x.map(function (picture) {
                                    return JsxRuntime.jsx("source", {
                                                media: picture.media,
                                                sizes: "1px",
                                                srcSet: picture.src + " 1w"
                                              }, picture.media);
                                  });
                      })(pictures),
                  JsxRuntime.jsx("img", {
                        className: className,
                        "data-testid": Core__Option.map(identifier, Identifiers.toString),
                        alt: alt,
                        loading: loading,
                        src: src
                      })
                ],
                className: className
              });
  } else {
    return JsxRuntime.jsx("img", {
                "aria-hidden": param.ariaHidden,
                className: className,
                "data-testid": Core__Option.map(identifier, Identifiers.toString),
                alt: alt,
                loading: loading,
                sizes: param.sizes,
                src: src,
                srcSet: param.srcSet
              });
  }
}

function Atom_Image$Internal$WithWrapper(props) {
  var children = props.children;
  var gradient = props.gradient;
  var displayOrder = props.displayOrder;
  var display = props.display;
  var autoSize = props.autoSize;
  var animation = props.animation;
  if (animation === undefined && autoSize === undefined && display === undefined && displayOrder === undefined && gradient === undefined) {
    return children;
  }
  return JsxRuntime.jsx(Atom_Cell.make, {
              autoSize: autoSize,
              display: display,
              displayOrder: displayOrder,
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              children: children,
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              rules: gradient,
              animation: animation
            });
}

function Atom_Image$Internal(props) {
  var forceExtension = props.forceExtension;
  var src = props.src;
  var style = Atom_Image_Ds.make(props.style);
  var match = React.useMemo((function () {
          return Atom_Image_Ds.view(style);
        }), [style]);
  var sizeVariation = match.sizeVariation;
  var globalRatio = match.globalRatio;
  var displayDs = match.display;
  var match$1 = Atom_Image_Css.toCss(style);
  var rules = Particle_Screen_Ds.$$Array.concatOrDefault(match$1.image, props.rules);
  var baseClassname = (function (__x) {
        return CssJs.merge(__x);
      })((function (__x) {
            return __x.map(function (rule) {
                        var __x = [rule];
                        return CssJs.style(__x);
                      });
          })((function (__x) {
                return Particle_Screen_Css.toRules(undefined, undefined, (function (l) {
                              return l;
                            }), __x);
              })(rules)));
  var match$2 = React.useMemo((function () {
          return make(forceExtension, globalRatio, sizeVariation, src, displayDs);
        }), [
        displayDs,
        src
      ]);
  var commonClassName = Cn.make(Atom_Box_Type.getBaseRules(undefined, props.alignSelf, undefined, undefined, undefined, undefined, props.border, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined).map(function (s) {
            return CssJs.style(s);
          }));
  var className = CssJs.merge([
        baseClassname,
        commonClassName
      ]);
  return JsxRuntime.jsx(Atom_Image$Internal$WithWrapper, {
              animation: props.animation,
              autoSize: props.autoSize,
              display: props.display,
              displayOrder: props.displayOrder,
              gradient: match$1.gradient,
              children: JsxRuntime.jsx(make$1, {
                    pictures: match$2.pictures,
                    src: match$2.src,
                    sizes: match$2.sizes,
                    srcSet: match$2.srcSet,
                    className: className,
                    alt: props.alt,
                    identifier: props.identifier,
                    ariaHidden: props.ariaHidden,
                    loading: match.loadMethod
                  })
            });
}

function make$2(param) {
  return JsxRuntime.jsx(Atom_Image$Internal, {
              ariaHidden: param.ariaHidden,
              alignSelf: param.alignSelf,
              alt: param.alt,
              animation: param.animation,
              autoSize: param.autoSize,
              border: param.border,
              display: param.display,
              displayOrder: param.displayOrder,
              identifier: param.identifier,
              rules: param.rules,
              src: param.src,
              style: param.style
            });
}

function makeSrc(style, cdnUrl) {
  if (typeof style !== "object" || style.TAG !== "FromCdn") {
    return ;
  } else {
    return Particle_ImageCdn_Ds.make(style._0, cdnUrl);
  }
}

function Atom_Image$ImageFromCdn(props) {
  var style = props.style;
  var match = Context.Config.getState();
  var src = makeSrc(style, match.cdnUrl);
  if (src !== undefined) {
    return JsxRuntime.jsx(Atom_Image$Internal, {
                alt: props.alt,
                src: Type_Image.Src.makeCdn(Type_String.$$String.toString(src)),
                forceExtension: props.extension,
                style: style
              });
  } else {
    return null;
  }
}

var ImageFromCdn = {
  makeSrc: makeSrc,
  make: Atom_Image$ImageFromCdn
};

var Url = {
  _DONT_USE_IT_make_forTest: make
};

export {
  Url ,
  make$2 as make,
  ImageFromCdn ,
}
/* CssJs Not a pure module */
