// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Atom_Divider from "../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Icon_Css from "../atom/Atom_Icon_Css.res.mjs";
import * as Atom_Text_Css from "../atom/Atom_Text_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";

function titleCss(titleStyle) {
  return Core__Option.getOr(Particle_Screen_Css.toRules(undefined, undefined, (function (a) {
                    return a;
                  }), Atom_Text_Css.toCss(Atom_Text_Ds.view(titleStyle))), []);
}

function Molecule_Accordion$Internal(props) {
  var titleStyleOn = props.titleStyleOn;
  var titleStyleOff = props.titleStyleOff;
  var svgRules = function (asset) {
    if (asset === undefined) {
      return [];
    }
    if (asset.TAG === "Logo") {
      return [];
    }
    var match = asset._0;
    return Atom_Icon_Css.toCss(match.icon, match.size).svg;
  };
  var wrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
        CssJs.selector("summary", [
                titleCss(titleStyleOff),
                [CssJs.cursor(CssJs.pointer)]
              ].flat()),
        CssJs.selector("summary:focus", [CssJs.outline({
                    NAME: "px",
                    VAL: 2
                  }, CssJs.solid, Particle_Color.Css.color("Outline"))]),
        CssJs.selector("&[open] summary", titleCss(titleStyleOn)),
        CssJs.selector("&[open] summary svg", svgRules(titleStyleOn.asset)),
        CssJs.selector("summary::-webkit-details-marker", [CssJs.display(CssJs.none)]),
        CssJs.selector("summary ", [CssJs.display(CssJs.inlineBlock)]),
        CssJs.selector("summary span", [CssJs.display(CssJs.inlineBlock)])
      ]);
  return JsxRuntime.jsxs(Atom_Row.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      blockSpace: Caml_option.some(props.titleBlockSpace),
                      children: Caml_option.some(props.title),
                      kind: "summary",
                      style: titleStyleOff
                    }),
                props.children
              ],
              identifier: Caml_option.some(Identifiers.Accordion.item),
              kind: "Details",
              rules: Caml_option.some(wrapperRules)
            });
}

var Internal = {
  titleCss: titleCss,
  make: Molecule_Accordion$Internal
};

var wrapperSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeNone()), undefined, undefined, Particle_Spacer_Ds.makeBottom("S"));

var internalSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("S", "None", "L", "None"));

var internalWidth = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.em(10));

var internalInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var titleStyleOn = Atom_Text_Ds.Footer.makeTitle("On");

var titleStyleOff = Atom_Text_Ds.Footer.makeTitle("Off");

var titleBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("S"));

function make(param) {
  return JsxRuntime.jsx(Atom_Column.make, {
              area: param.area,
              blockSpace: Caml_option.some(wrapperSpace),
              children: JsxRuntime.jsx(Molecule_Accordion$Internal, {
                    title: param.title,
                    titleStyleOff: titleStyleOff,
                    titleStyleOn: titleStyleOn,
                    titleBlockSpace: titleBlockSpace,
                    children: JsxRuntime.jsx(Atom_Column.make, {
                          blockSpace: Caml_option.some(internalSpace),
                          children: param.children,
                          innerSpace: Caml_option.some(internalInnerSpace),
                          kind: "List",
                          width: Caml_option.some(internalWidth)
                        })
                  }),
              innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "None")),
              kind: "ItemList"
            });
}

var Footer = {
  wrapperSpace: wrapperSpace,
  internalSpace: internalSpace,
  internalWidth: internalWidth,
  internalInnerSpace: internalInnerSpace,
  titleStyleOn: titleStyleOn,
  titleStyleOff: titleStyleOff,
  titleBlockSpace: titleBlockSpace,
  make: make
};

var wrapperSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("None", "S", "S", "None"));

var wrapperInnerSpace = Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "None");

var internalSpace$1 = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("S", "S", "Zero", "None")), undefined, undefined, Particle_Spacer_Ds.make("S", "S", "S", "None"));

var internalInnerSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var titleStyleOn$1 = Atom_Text_Ds.Presse.accordionTitleOn;

var titleStyleOff$1 = Atom_Text_Ds.Presse.accordionTitleOff;

var titleBlockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());

var Presse_Css = {
  wrapperSpace: wrapperSpace$1,
  wrapperInnerSpace: wrapperInnerSpace,
  internalSpace: internalSpace$1,
  internalInnerSpace: internalInnerSpace$1,
  titleStyleOn: titleStyleOn$1,
  titleStyleOff: titleStyleOff$1,
  titleBlockSpace: titleBlockSpace$1
};

function Molecule_Accordion$Presse$Internal(props) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(wrapperSpace$1),
              children: [
                JsxRuntime.jsx(Molecule_Accordion$Internal, {
                      title: props.title,
                      titleStyleOff: titleStyleOff$1,
                      titleStyleOn: titleStyleOn$1,
                      titleBlockSpace: titleBlockSpace$1,
                      children: JsxRuntime.jsx(Atom_Column.make, {
                            blockSpace: Caml_option.some(internalSpace$1),
                            children: props.children,
                            innerSpace: Caml_option.some(internalInnerSpace$1)
                          })
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.presse
                    })
              ],
              innerSpace: Caml_option.some(wrapperInnerSpace)
            });
}

var Internal$1 = {
  make: Molecule_Accordion$Presse$Internal
};

function make$1(param) {
  return JsxRuntime.jsx(Molecule_Accordion$Presse$Internal, {
              title: param.title,
              children: param.children
            });
}

var WithChildren = {
  make: make$1
};

function make$2(param) {
  return JsxRuntime.jsx(Molecule_Accordion$Presse$Internal, {
              title: param.title,
              children: JsxRuntime.jsx(Atom_Text.Presse.Raw.make, {
                    tag: "div",
                    innerHtml: param.innerHtml
                  })
            });
}

var Raw = {
  make: make$2
};

var Presse = {
  Internal: Internal$1,
  WithChildren: WithChildren,
  Raw: Raw
};

export {
  Internal ,
  Footer ,
  Presse_Css ,
  Presse ,
}
/* wrapperSpace Not a pure module */
