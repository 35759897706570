// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Divider from "../atom/Atom_Divider.res.mjs";
import * as Atom_Icon_Ds from "../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

function view(param) {
  return {
          ctaButtonStyle: param.ctaButtonStyle,
          ctaSecondaryButtonStyle: param.ctaSecondaryButtonStyle,
          ctaLinkStyle: param.ctaLinkStyle,
          innerSpace: param.innerSpace,
          messageStyle: param.messageStyle,
          messageHighlightedStyle: param.messageHighlightedStyle,
          messageQuestionStyle: param.messageQuestionStyle
        };
}

var player_ctaButtonStyle = Atom_Cta_Ds.Player.warningCta;

var player_ctaSecondaryButtonStyle = Atom_Cta_Ds.Player.warningSecondaryCta;

var player_ctaLinkStyle = Atom_Cta_Ds.Player.warningLink;

var player_messageStyle = Atom_Text_Ds.ProgramContent.make("Desc");

var player_messageHighlightedStyle = Atom_Text_Ds.Player.ctaWarningMessageHighlighted;

var player_messageQuestionStyle = Atom_Text_Ds.Player.ctaWarningMessageHighlighted;

var player = {
  ctaButtonStyle: player_ctaButtonStyle,
  ctaSecondaryButtonStyle: player_ctaSecondaryButtonStyle,
  ctaLinkStyle: player_ctaLinkStyle,
  innerSpace: undefined,
  messageStyle: player_messageStyle,
  messageHighlightedStyle: player_messageHighlightedStyle,
  messageQuestionStyle: player_messageQuestionStyle
};

var program_ctaButtonStyle = player_ctaButtonStyle;

var program_ctaSecondaryButtonStyle = player_ctaSecondaryButtonStyle;

var program_innerSpace = Caml_option.some(Particle_Screen_Constants.xxsInnerSpace);

var program_messageStyle = player_messageStyle;

var program_messageHighlightedStyle = player_messageHighlightedStyle;

var program_messageQuestionStyle = player_messageQuestionStyle;

var program = {
  ctaButtonStyle: program_ctaButtonStyle,
  ctaSecondaryButtonStyle: program_ctaSecondaryButtonStyle,
  ctaLinkStyle: Atom_Cta_Ds.descriptionLink,
  innerSpace: program_innerSpace,
  messageStyle: program_messageStyle,
  messageHighlightedStyle: program_messageHighlightedStyle,
  messageQuestionStyle: program_messageQuestionStyle
};

var Organism_Warning_Message_Ds = {
  view: view,
  player: player,
  program: program
};

var primaryButtonBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

var buttonAlignSelf = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var dividerWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(50), CssJs.pct(30), undefined, CssJs.pct(90));

var dividerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("None", "S", "None", "None"));

var ctaBoxDirection = Particle_Screen_Ds.make(undefined, undefined, undefined, "column", undefined, "row");

function Organism_WarningMessage$Internal(props) {
  var messageQuestion = props.messageQuestion;
  var messageHighlighted = props.messageHighlighted;
  var id = props.id;
  var secondaryButton = props.secondaryButton;
  var primaryButton = props.primaryButton;
  var ariaDescribedby = Particle_Accessibility.AriaDescribedby.make(id);
  var accessibility = Particle_Accessibility.make(undefined, undefined, Caml_option.some(ariaDescribedby), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var match = view(props.style);
  var messageStyle = match.messageStyle;
  var ctaLinkStyle = match.ctaLinkStyle;
  var ctaButtonStyle = match.ctaButtonStyle;
  var match$1 = primaryButton.variant;
  var tmp;
  tmp = match$1 !== undefined && match$1 !== "Button" ? ctaLinkStyle : ctaButtonStyle;
  var primaryButton$1 = JsxRuntime.jsx(Atom_Cell.make, {
        alignSelf: Caml_option.some(buttonAlignSelf),
        blockSpace: Caml_option.some(primaryButtonBlockSpace),
        children: JsxRuntime.jsx(Atom_Cta.Button.make, {
              accessibility: accessibility,
              label: primaryButton.label,
              onClick: primaryButton.onClick,
              screenReadersLabel: props.programTitle,
              style: tmp
            })
      });
  var secondaryButton$1;
  if (secondaryButton !== undefined) {
    var variant = secondaryButton.variant;
    var onClick = secondaryButton.onClick;
    var label = secondaryButton.label;
    var exit = 0;
    if (variant !== undefined && variant !== "Button") {
      secondaryButton$1 = JsxRuntime.jsx(Atom_Cta.Button.make, {
            label: label,
            onClick: onClick,
            style: ctaLinkStyle
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      secondaryButton$1 = JsxRuntime.jsx(Atom_Cell.make, {
            alignSelf: Caml_option.some(buttonAlignSelf),
            children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                  label: label,
                  onClick: onClick,
                  style: match.ctaSecondaryButtonStyle
                })
          });
    }
    
  } else {
    secondaryButton$1 = null;
  }
  var messageHighlighted$1 = messageHighlighted !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(messageHighlighted),
                  style: Core__Option.getOr(match.messageHighlightedStyle, messageStyle)
                }),
            JsxRuntime.jsx(Atom_Cell.make, {
                  justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                  blockSpace: Caml_option.some(dividerBlockSpace),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                  children: JsxRuntime.jsx(Atom_Divider.make, {
                        style: Atom_Divider.player,
                        width: Caml_option.some(dividerWidth)
                      })
                })
          ]
        }) : null;
  var messageQuestion$1 = messageQuestion !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
          children: Caml_option.some(messageQuestion),
          style: Core__Option.getOr(match.messageQuestionStyle, messageStyle)
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(props.message),
                      id: id,
                      style: messageStyle
                    }),
                messageHighlighted$1,
                messageQuestion$1,
                JsxRuntime.jsxs(Atom_Box.make, {
                      children: [
                        primaryButton$1,
                        secondaryButton$1
                      ],
                      direction: Caml_option.some(ctaBoxDirection),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                    })
              ],
              innerSpace: match.innerSpace,
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center"))
            });
}

var Internal = {
  primaryButtonBlockSpace: primaryButtonBlockSpace,
  buttonAlignSelf: buttonAlignSelf,
  dividerWidth: dividerWidth,
  dividerBlockSpace: dividerBlockSpace,
  ctaBoxDirection: ctaBoxDirection,
  make: Organism_WarningMessage$Internal
};

function getIconFromRestriction(restriction) {
  switch (restriction) {
    case "12" :
        return Atom_Icon_Ds.restrictions12;
    case "16" :
        return Atom_Icon_Ds.restrictions16;
    case "18" :
        return Atom_Icon_Ds.restrictions18;
    
  }
}

var iconRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.display("flex")], undefined, [CssJs.display("none")]);

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XL");

var iconBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

var containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XXS"));

function make(param) {
  var ageRestriction = param.ageRestriction;
  var iconRestricttion = ageRestriction !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
          blockSpace: Caml_option.some(iconBlockSpace),
          children: JsxRuntime.jsx(Atom_Icon.make, {
                icon: Atom_Icon_Ds.updateColor(getIconFromRestriction(ageRestriction), "Text"),
                size: iconSize,
                ariaHidden: "Visible",
                ariaLabel: param.ariaLabel,
                role: "img"
              }),
          rules: Caml_option.some(iconRules)
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Row.make, {
                      children: iconRestricttion,
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    }),
                JsxRuntime.jsx(Organism_WarningMessage$Internal, {
                      primaryButton: param.primaryButton,
                      secondaryButton: param.secondaryButton,
                      id: param.id,
                      message: param.message,
                      messageHighlighted: param.messageHighlighted,
                      messageQuestion: param.messageQuestion,
                      programTitle: param.programTitle,
                      style: player
                    })
              ]
            });
}

var Player = {
  iconRules: iconRules,
  iconSize: iconSize,
  iconBlockSpace: iconBlockSpace,
  containerBlockSpace: containerBlockSpace,
  make: make
};

var iconSize$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var containerBlockSpace$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("XS"));

var iconRules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.alignSelf(CssJs.center),
      CssJs.color(Particle_Color.Css.color("Primary"))
    ]);

function Organism_WarningMessage$Program(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = Context.Config.getState();
  var api = match$1.api;
  var id = "age-restriction";
  var match$2 = view(program);
  var ariaDescribedby = Particle_Accessibility.AriaDescribedby.make(id);
  var accessibility = Particle_Accessibility.make(undefined, undefined, Caml_option.some(ariaDescribedby), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(containerBlockSpace$1),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      children: JsxRuntime.jsx(Atom_Icon.make, {
                            icon: getIconFromRestriction(props.ageRestriction),
                            size: iconSize$1
                          }),
                      rules: Caml_option.some(iconRules$1)
                    }),
                JsxRuntime.jsxs(Atom_Text.make, {
                      children: [
                        labels.program.ageRestriction_label,
                        " ",
                        JsxRuntime.jsx(Atom_Cta.Button.make, {
                              accessibility: accessibility,
                              label: labels.program.ageRestriction_cta,
                              onClick: (function (param) {
                                  api.startAgeVerification();
                                }),
                              screenReadersLabel: props.programTitle,
                              style: match$2.ctaLinkStyle
                            })
                      ],
                      id: id,
                      style: match$2.messageStyle
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
              wrap: "OnOneLine"
            });
}

var Program = {
  iconSize: iconSize$1,
  containerBlockSpace: containerBlockSpace$1,
  iconRules: iconRules$1,
  make: Organism_WarningMessage$Program
};

export {
  Organism_Warning_Message_Ds ,
  Internal ,
  getIconFromRestriction ,
  Player ,
  Program ,
}
/* player Not a pure module */
