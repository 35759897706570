// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Label from "../atom/Atom_Label.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";

var base = {
  label: Atom_Label.base,
  zIndex: undefined
};

function direct(dotColor) {
  return {
          label: Atom_Label.Atom_Label_Ds.TeaserGuide.direct(dotColor),
          zIndex: undefined
        };
}

function live(dotColor) {
  return {
          label: Atom_Label.live(dotColor),
          zIndex: undefined
        };
}

var highlight = {
  label: Atom_Label.highlight,
  zIndex: undefined
};

var time = {
  label: Atom_Label.time,
  zIndex: undefined
};

var genre = {
  label: Atom_Label.time,
  zIndex: undefined
};

var disabledTime_zIndex = {
  TAG: "Exact",
  _0: "StickerDisabledTime"
};

var disabledTime = {
  label: Atom_Label.disabledTime,
  zIndex: disabledTime_zIndex
};

var base_label = Atom_Label.Atom_Label_Ds.TeaserGuide.base;

var base$1 = {
  label: base_label,
  zIndex: undefined
};

function direct$1(dotColor) {
  return {
          label: Atom_Label.direct(dotColor),
          zIndex: undefined
        };
}

function live$1(dotColor) {
  return {
          label: Atom_Label.Atom_Label_Ds.TeaserGuide.live(dotColor),
          zIndex: undefined
        };
}

var time_label = Atom_Label.Atom_Label_Ds.TeaserGuide.time;

var time$1 = {
  label: time_label,
  zIndex: undefined
};

var genre_label = Atom_Label.Atom_Label_Ds.TeaserGuide.time;

var genre$1 = {
  label: genre_label,
  zIndex: undefined
};

var disabledTime_label = Atom_Label.Atom_Label_Ds.TeaserGuide.disabledTime;

var disabledTime_zIndex$1 = {
  TAG: "Exact",
  _0: "StickerDisabledTime"
};

var disabledTime$1 = {
  label: disabledTime_label,
  zIndex: disabledTime_zIndex$1
};

var Guide = {
  base: base$1,
  direct: direct$1,
  live: live$1,
  time: time$1,
  genre: genre$1,
  disabledTime: disabledTime$1
};

function view(param) {
  return {
          label: param.label,
          zIndex: param.zIndex
        };
}

var Molecule_Sticker_Ds = {
  base: base,
  direct: direct,
  live: live,
  time: time,
  genre: genre,
  disabledTime: disabledTime,
  highlight: highlight,
  topTeaser: time,
  followNext: time,
  Guide: Guide,
  view: view
};

var baseSpacerPosition = Particle_Screen_Ds.make(undefined, "XS", undefined, undefined, undefined, "XXS");

function toCss(t, position, spacerPositionOpt, param) {
  var spacerPosition = spacerPositionOpt !== undefined ? Caml_option.valFromOption(spacerPositionOpt) : baseSpacerPosition;
  var zIndex = t.zIndex;
  var spacerPosition$1 = Particle_Screen_Ds.map(spacerPosition, Particle_Css.spacerSizeInside);
  var optionalRules = zIndex !== undefined ? Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss(zIndex)]) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  var positionRules;
  switch (position) {
    case "BottomRight" :
        positionRules = Particle_Screen_Ds.map(spacerPosition$1, (function (spacer) {
                return [
                        CssJs.right(spacer),
                        CssJs.bottom(spacer),
                        CssJs.position(CssJs.absolute)
                      ];
              }));
        break;
    case "TopLeft" :
        positionRules = Particle_Screen_Ds.map(spacerPosition$1, (function (spacer) {
                return [
                        CssJs.left(spacer),
                        CssJs.top(spacer),
                        CssJs.position(CssJs.absolute)
                      ];
              }));
        break;
    case "BottomLeft" :
        positionRules = Particle_Screen_Ds.map(spacerPosition$1, (function (spacer) {
                return [
                        CssJs.left(spacer),
                        CssJs.bottom(spacer),
                        CssJs.position(CssJs.absolute)
                      ];
              }));
        break;
    case "TopRight" :
        positionRules = Particle_Screen_Ds.map(spacerPosition$1, (function (spacer) {
                return [
                        CssJs.right(spacer),
                        CssJs.top(spacer),
                        CssJs.position(CssJs.absolute)
                      ];
              }));
        break;
    case "Inherit" :
        positionRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
        break;
    
  }
  var widthRules = Particle_Screen_Ds.map(spacerPosition$1, (function (spacer) {
          return [CssJs.maxWidth({
                        NAME: "calc",
                        VAL: [
                          "sub",
                          {
                            NAME: "percent",
                            VAL: 100
                          },
                          spacer
                        ]
                      })];
        }));
  return Particle_Screen_Ds.$$Array.concat(widthRules, Particle_Screen_Ds.$$Array.concat(optionalRules, positionRules));
}

var Molecule_Sticker_Css = {
  toCss: toCss
};

function make(param) {
  var style = Core__Option.getOr(param.style, base);
  var view$1 = view(style);
  var rules = toCss(view$1, param.position, undefined, undefined);
  return JsxRuntime.jsx(Atom_Label.make, {
              text: param.text,
              style: Caml_option.some(view$1.label),
              id: param.id,
              rules: Caml_option.some(rules),
              identifier: param.identifier
            });
}

var spacerPosition = Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S");

function Molecule_Sticker$Haiku(props) {
  var __style = props.style;
  var style = __style !== undefined ? Caml_option.valFromOption(__style) : base;
  var view$1 = view(style);
  var rules = toCss(view$1, props.position, Caml_option.some(spacerPosition), undefined);
  return JsxRuntime.jsx(Atom_Label.make, {
              text: props.text,
              style: Caml_option.some(view$1.label),
              id: props.id,
              rules: Caml_option.some(rules)
            });
}

var Haiku = {
  spacerPosition: spacerPosition,
  make: Molecule_Sticker$Haiku
};

export {
  Molecule_Sticker_Ds ,
  baseSpacerPosition ,
  Molecule_Sticker_Css ,
  make ,
  Haiku ,
}
/* baseSpacerPosition Not a pure module */
