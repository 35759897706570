'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as UseRefs from "./UseRefs.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as SetFocus from "./SetFocus.res.mjs";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as ReactUpdate from "rescript-react-update/src/ReactUpdate.res.mjs";
import * as Type_Schema from "../type/Type_Schema.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_SchemaI18n from "../type/Type_SchemaI18n.res.mjs";

function Make(Config) {
  var Schema = Type_Schema.Make(Config);
  var getInitialFieldsState = function (schema) {
    return schema.map(function (validator) {
                switch (validator.TAG) {
                  case "Confirmation" :
                  case "EitherStringNonEmpty" :
                      return {
                              field: {
                                TAG: "Field",
                                _0: validator.field1
                              },
                              fieldState: "Pristine"
                            };
                  default:
                    return {
                            field: {
                              TAG: "Field",
                              _0: validator.field
                            },
                            fieldState: "Pristine"
                          };
                }
              });
  };
  var reducer = function (onSubmit, i18n, schema, getRef, state, action) {
    if (typeof action !== "object") {
      if (action === "TrySubmit") {
        return {
                TAG: "SideEffects",
                _0: (function (self) {
                    self.send({
                          TAG: "ValidateForm",
                          _0: true
                        });
                  })
              };
      } else {
        return {
                TAG: "UpdateWithSideEffects",
                _0: {
                  fieldsState: state.fieldsState,
                  formState: "Submitting",
                  values: state.values,
                  validationType: state.validationType
                },
                _1: (function (self) {
                    return onSubmit({
                                state: self.state
                              });
                  })
              };
      }
    }
    switch (action.TAG) {
      case "ValidateField" :
          var field = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (self) {
                      var fieldState = Schema.validateOne(field, self.state.values, i18n, schema);
                      var newFieldState;
                      if (fieldState !== undefined) {
                        var message = fieldState[1];
                        newFieldState = typeof message !== "object" ? "Valid" : ({
                              TAG: "Error",
                              _0: message._0
                            });
                      } else {
                        newFieldState = "Valid";
                      }
                      var newFieldsState = state.fieldsState.filter(function (param) {
                              return Caml_obj.notequal(param.field, field);
                            }).concat([{
                              field: field,
                              fieldState: newFieldState
                            }]);
                      self.send({
                            TAG: "SetFieldsState",
                            _0: newFieldsState
                          });
                    })
                };
      case "ValidateForm" :
          var shouldSubmit = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (self) {
                      var recordState = Schema.validateNonEmpty(i18n, self.state.values, schema);
                      if (typeof recordState !== "object") {
                        var newFieldsState = self.state.fieldsState.map(function (param) {
                              return {
                                      field: param.field,
                                      fieldState: "Valid"
                                    };
                            });
                        self.send({
                              TAG: "SetFieldsState",
                              _0: newFieldsState
                            });
                        if (shouldSubmit) {
                          self.send("Submit");
                        }
                        self.send({
                              TAG: "SetFormState",
                              _0: "Valid"
                            });
                      } else if (recordState.TAG !== "Errors") {
                        var newFieldsState$1 = Util_Array.NonEmpty.map(recordState._0, (function (param) {
                                return {
                                        field: param[0],
                                        fieldState: {
                                          TAG: "Error",
                                          _0: param[1]
                                        }
                                      };
                              }));
                        var firstErrorField = Util_Array.NonEmpty.head(newFieldsState$1);
                        self.send({
                              TAG: "SelectField",
                              _0: firstErrorField
                            });
                        self.send({
                              TAG: "SetFieldsState",
                              _0: Util_Array.NonEmpty.toArray(newFieldsState$1)
                            });
                        self.send({
                              TAG: "SetFormState",
                              _0: "Errored"
                            });
                      }
                      
                    })
                };
      case "SetFieldsState" :
          return {
                  TAG: "Update",
                  _0: {
                    fieldsState: action._0,
                    formState: state.formState,
                    values: state.values,
                    validationType: state.validationType
                  }
                };
      case "FieldChangeValue" :
          var field$1 = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    fieldsState: state.fieldsState,
                    formState: state.formState === "Errored" ? "Errored" : "Dirty",
                    values: Curry._3(Config.set, state.values, field$1, action._1),
                    validationType: state.validationType
                  },
                  _1: (function (self) {
                      var match = self.state.validationType;
                      if (match === "OnChange") {
                        self.send({
                              TAG: "ValidateField",
                              _0: {
                                TAG: "Field",
                                _0: field$1
                              }
                            });
                      }
                      
                    })
                };
      case "SetFormState" :
          return {
                  TAG: "Update",
                  _0: {
                    fieldsState: state.fieldsState,
                    formState: action._0,
                    values: state.values,
                    validationType: state.validationType
                  }
                };
      case "SelectField" :
          var field$2 = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (param) {
                      Core__Option.forEach(Core__Option.map(getRef(field$2.field), Util_Dom.getDomRef), SetFocus.setFocus);
                    })
                };
      
    }
  };
  var makeI18n = function (labels, formaters) {
    if (labels === undefined) {
      return Type_SchemaI18n.default;
    }
    if (formaters === undefined) {
      return Type_SchemaI18n.default;
    }
    var match = labels.formValidation;
    var formValidationStringMax = formaters.formValidationStringMax;
    var formValidationStringMin = formaters.formValidationStringMin;
    var formValidationRequired = match.required;
    var formValidationEmail = match.email;
    var formValidationEitherRequired = match.eitherRequired;
    return {
            confirmation: Type_SchemaI18n.default.confirmation,
            email: (function (param) {
                return formValidationEmail;
              }),
            stringNonEmpty: (function (param) {
                return formValidationRequired;
              }),
            eitherStringNonEmpty: (function (param, param$1) {
                return formValidationEitherRequired;
              }),
            stringRegExp: Type_SchemaI18n.default.stringRegExp,
            stringMin: (function (_value, min) {
                return formValidationStringMin(min);
              }),
            stringMax: (function (_value, max) {
                return formValidationStringMax(max);
              }),
            date: match.date,
            required: formValidationRequired,
            password: match.password
          };
  };
  var cmp = function (a, b) {
    if (Caml_obj.equal(a, b)) {
      return 0;
    } else {
      return 1;
    }
  };
  var FieldComparator = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var useRefs = function () {
    return UseRefs.make(FieldComparator);
  };
  var use = function (initialValues, schema, onSubmit, labels, formaters, validationTypeOpt, param) {
    var validationType = validationTypeOpt !== undefined ? validationTypeOpt : "OnSubmit";
    var i18n = makeI18n(labels, formaters);
    var initialState_fieldsState = getInitialFieldsState(schema);
    var initialState = {
      fieldsState: initialState_fieldsState,
      formState: "Pristine",
      values: initialValues,
      validationType: validationType
    };
    var match = UseRefs.make(FieldComparator);
    var getRef = match[0];
    var match$1 = ReactUpdate.useReducer((function (extra, extra$1) {
            return reducer(onSubmit, i18n, schema, getRef, extra, extra$1);
          }), initialState);
    var send = match$1[1];
    var state = match$1[0];
    var getFieldState = function (field) {
      return Core__Option.mapOr(state.fieldsState.find(function (param) {
                      return Caml_obj.equal(param.field, field);
                    }), "Pristine", (function (param) {
                    return param.fieldState;
                  }));
    };
    var getFieldError = function (field) {
      var error = getFieldState(field);
      if (typeof error !== "object") {
        return ;
      } else {
        return error._0;
      }
    };
    return {
            values: state.values,
            formState: state.formState,
            getFieldState: getFieldState,
            getFieldError: getFieldError,
            getFieldRef: match[1],
            handleChange: (function (field, value) {
                send({
                      TAG: "FieldChangeValue",
                      _0: field,
                      _1: value
                    });
              }),
            submit: (function () {
                send("TrySubmit");
              })
          };
  };
  return {
          Schema: Schema,
          Validation: undefined,
          getInitialFieldsState: getInitialFieldsState,
          reducer: reducer,
          makeI18n: makeI18n,
          FieldComparator: FieldComparator,
          useRefs: useRefs,
          use: use
        };
}

export {
  Make ,
}
/* UseRefs Not a pure module */
