// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User_Type from "./User_Type.res.mjs";
import * as User_Storage from "./User_Storage.res.mjs";

var userRef = {
  contents: User_Type.disconnected
};

function set(u) {
  userRef.contents = u;
  User_Storage.save(u);
}

function get() {
  return userRef.contents;
}

function getToken() {
  return User_Type.getToken(userRef.contents);
}

var ref = {
  contents: "NotInitialized"
};

function set$1(s) {
  ref.contents = s;
}

function get$1() {
  return ref.contents;
}

var Status = {
  ref: ref,
  set: set$1,
  get: get$1
};

var ref$1 = {
  contents: "prod"
};

function get$2() {
  return ref$1.contents;
}

function set$2(e) {
  ref$1.contents = e;
}

var Env = {
  ref: ref$1,
  get: get$2,
  set: set$2
};

var ssoApiKeyRef = {
  contents: "Bearer I6k2z58YGO08P1X0E8A7VBOjDxr8Lecg"
};

function ssoApiKey() {
  return ssoApiKeyRef.contents;
}

function setSsoApiKey(key) {
  ssoApiKeyRef.contents = key;
}

var Api = {
  ssoApiKeyRef: ssoApiKeyRef,
  ssoApiKey: ssoApiKey,
  setSsoApiKey: setSsoApiKey
};

export {
  userRef ,
  set ,
  get ,
  getToken ,
  Status ,
  Env ,
  Api ,
}
/* User_Type Not a pure module */
