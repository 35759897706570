// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";

function makeStyle(backgroundOpt, blockSpaceOpt, borderOpt, icon, textStyleOpt, direction, alignItems, width, height, labelInnerspaceOpt, labelTextStyleOpt) {
  var background = backgroundOpt !== undefined ? Caml_option.valFromOption(backgroundOpt) : Particle_Background_Ds.transparent;
  var blockSpace = blockSpaceOpt !== undefined ? Caml_option.valFromOption(blockSpaceOpt) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));
  var border = borderOpt !== undefined ? borderOpt : Particle_Border_Ds.none;
  var textStyle = textStyleOpt !== undefined ? textStyleOpt : Atom_Text_Ds.textfield;
  var labelInnerspace = labelInnerspaceOpt !== undefined ? Caml_option.valFromOption(labelInnerspaceOpt) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");
  var labelTextStyle = labelTextStyleOpt !== undefined ? labelTextStyleOpt : Atom_Text_Ds.User.label;
  return {
          background: background,
          blockSpace: blockSpace,
          border: border,
          icon: icon,
          textStyle: textStyle,
          direction: direction,
          alignItems: alignItems,
          labelTextStyle: labelTextStyle,
          labelInnerspace: labelInnerspace,
          width: width,
          height: height
        };
}

function make(style) {
  var exec = function (style) {
    if (typeof style !== "object") {
      switch (style) {
        case "Default" :
            return makeStyle(undefined, undefined, Particle_Border_Ds.Textfield.inactive, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        case "FooterNewsletter" :
            return makeStyle(undefined, undefined, Particle_Border_Ds.Textfield.inactive, undefined, Atom_Text_Ds.Footer.textfield, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                NAME: "percent",
                                VAL: 100
                              })), undefined, undefined);
        case "HeaderSearch" :
            return makeStyle(undefined, undefined, undefined, undefined, Atom_Text_Ds.searchHeader, undefined, undefined, undefined, undefined, undefined, undefined);
        case "TvCodeCell" :
            return makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeVertical("S")), undefined, Particle_Spacer_Ds.makeVertical("XS"))), Particle_Border_Ds.Textfield.tvCodeCell, undefined, Atom_Text_Ds.User.tvCellField, undefined, undefined, undefined, undefined, undefined, undefined);
        case "NewsletterZone" :
            return makeStyle(undefined, undefined, Particle_Border_Ds.Textfield.newsletterZone, undefined, Atom_Text_Ds.NewsletterZone.textfield, undefined, undefined, undefined, undefined, undefined, undefined);
        case "PageSearch" :
            return makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "M", "M", "XL")), undefined, undefined, Particle_Spacer_Ds.make("XS", "XS", "XS", "S"))), Particle_Border_Ds.Textfield.searchPage, {
                        icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.search, "Text"),
                        size: Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")
                      }, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        
      }
    } else {
      switch (style.TAG) {
        case "User" :
            return makeStyle(undefined, undefined, Particle_Border_Ds.Textfield.userInactive, undefined, Atom_Text_Ds.User.makeTextfield(style._0), undefined, undefined, undefined, undefined, undefined, undefined);
        case "Presse" :
            switch (style._0) {
              case "Default" :
                  return makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"))), Particle_Border_Ds.Presse.textField, undefined, Atom_Text_Ds.Presse.textfield, undefined, undefined, undefined, undefined, undefined, undefined);
              case "DateRange" :
                  return makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"))), Particle_Border_Ds.Presse.textField, undefined, Atom_Text_Ds.Presse.textfield, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "center", undefined, undefined, "flexStart")), Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "auto", undefined, undefined, {
                                      NAME: "percent",
                                      VAL: 100
                                    })), undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "None")), Atom_Text_Ds.Presse.dateRangeLabel);
              case "Search" :
                  return makeStyle(undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.make("M", "M", "M", "XL")), undefined, undefined, Particle_Spacer_Ds.make("XS", "XS", "XS", "S"))), Particle_Border_Ds.Textfield.searchPage, {
                              icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.search, "Text"),
                              size: Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "S")
                            }, undefined, undefined, undefined, Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
                                      NAME: "percent",
                                      VAL: 100
                                    })), undefined, undefined, undefined);
              
            }
        case "WithIcon" :
            var newrecord = Caml_obj.obj_dup(exec(style._0));
            newrecord.icon = style._1;
            return newrecord;
        
      }
    }
  };
  return exec(style);
}

function view(param) {
  return {
          background: param.background,
          blockSpace: param.blockSpace,
          border: param.border,
          icon: param.icon,
          textStyle: param.textStyle,
          direction: param.direction,
          alignItems: param.alignItems,
          labelTextStyle: param.labelTextStyle,
          labelInnerspace: param.labelInnerspace,
          width: param.width,
          height: param.height
        };
}

export {
  makeStyle ,
  make ,
  view ,
}
/* Atom_Icon_Ds Not a pure module */
