// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as Particle_Block_Ds from "../particle/Particle_Block_Ds.res.mjs";
import * as Particle_Block_Css from "../particle/Particle_Block_Css.res.mjs";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Gradient_Ds from "../particle/Particle_Gradient_Ds.res.mjs";
import * as Particle_Gradient_Css from "../particle/Particle_Gradient_Css.res.mjs";

var transitionTimingIn = CssJs.cubicBezier(0.67, 0.29, 0.21, 1.03);

var transitionInvertedTimingIn = CssJs.cubicBezier(0.23, 0.89, 0.64, 1);

function toCss(dsStyle) {
  var match = Atom_Cta_Ds.view(dsStyle);
  var hover = match.hover;
  var border = match.border;
  if (match.kind !== "Normal") {
    return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                CssJs.transform(CssJs.translate3d(CssJs.pct(-100), CssJs.px(0), CssJs.px(0))),
                CssJs.top(CssJs.px(0)),
                CssJs.left(CssJs.px(0))
              ]);
  }
  var shapeRules = (function (__x) {
        return Particle_Screen_Ds.map(__x, Particle_Css.shape);
      })(match.shape);
  var displayRules = Core__Option.getOr(Core__Option.map(match.display, (function (__x) {
              return Particle_Screen_Ds.map(__x, (function (display) {
                            return [Particle_Css.font_display(display)];
                          }));
            })), Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []));
  var blockRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Core__Option.getOr(Core__Option.map(match.block, (function (blockStyle) {
                  return Particle_Block_Css.toCss(Particle_Block_Ds.make(blockStyle));
                })), []));
  var getFocusRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.focus(Particle_Border_Css.toCss(match.borderFocus))]);
  var getColorRules = function (color) {
    return [
            CssJs.selector("svg", [
                  Css_Js_Core.SVG.fill(Particle_Color.Css.color(color)),
                  CssJs.opacity(1)
                ]),
            CssJs.selector("> span", [CssJs.color(Particle_Color.Css.color(color))])
          ];
  };
  var getHoverRules;
  if (hover !== undefined) {
    if (typeof hover !== "object") {
      switch (hover) {
        case "CursorDefault" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.cursor("default")]);
            break;
        case "Underline" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                    CssJs.transition(300, undefined, transitionTimingIn, "border"),
                    CssJs.hover(Particle_Border_Css.toCss(Particle_Border_Ds.underlineActive)),
                    CssJs.active(Particle_Border_Css.toCss(Particle_Border_Ds.underlineActive))
                  ].concat(Particle_Border_Css.toCss(Particle_Border_Ds.underlineInactive)));
            break;
        case "Scale" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  CssJs.transition(300, undefined, transitionTimingIn, "transform"),
                  CssJs.hover([CssJs.transform(CssJs.scale(1.1, 1.1))]),
                  CssJs.active([CssJs.transform(CssJs.scale(1.1, 1.1))])
                ]);
            break;
        case "NotAllowed" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.cursor(CssJs.notAllowed)]);
            break;
        case "Opacity" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  CssJs.transition(300, undefined, transitionTimingIn, "opacity"),
                  CssJs.hover([CssJs.opacity(0.7)]),
                  CssJs.active([CssJs.opacity(0.7)])
                ]);
            break;
        
      }
    } else {
      switch (hover.TAG) {
        case "Border" :
            var activeBorderStyle = hover._0;
            var exit = 0;
            if (activeBorderStyle !== undefined && border !== undefined) {
              getHoverRules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                        CssJs.transition(300, undefined, transitionTimingIn, "border"),
                        CssJs.hover(Particle_Border_Css.toCss(activeBorderStyle)),
                        CssJs.active(Particle_Border_Css.toCss(activeBorderStyle))
                      ]), Particle_Screen_Ds.map(Caml_option.valFromOption(border), (function (borderStyle) {
                          return Particle_Border_Css.toCss(borderStyle);
                        })));
            } else {
              exit = 1;
            }
            if (exit === 1) {
              getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                      CssJs.transition(300, undefined, transitionTimingIn, "border"),
                      CssJs.hover(Particle_Border_Css.toCss(Particle_Border_Ds.Cta.activeMedium)),
                      CssJs.active(Particle_Border_Css.toCss(Particle_Border_Ds.Cta.activeMedium))
                    ].concat(Particle_Border_Css.toCss(Particle_Border_Ds.Cta.inactiveMedium)));
            }
            break;
        case "Color" :
            var color = hover._0;
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  CssJs.transition(300, undefined, transitionTimingIn, "color"),
                  CssJs.hover(getColorRules(color)),
                  CssJs.active(getColorRules(color))
                ]);
            break;
        case "Background" :
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                  CssJs.transition(300, undefined, transitionTimingIn, "background"),
                  CssJs.hover([CssJs.backgroundColor(Particle_Color.Css.color(hover._0))].concat(Particle_Border_Css.toCss(Particle_Border_Ds.none)))
                ]);
            break;
        case "Gradient" :
            getHoverRules = Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
                      CssJs.hover([CssJs.after([CssJs.transform(CssJs.translateX(CssJs.pct(0)))])]),
                      CssJs.after([
                            CssJs.transition(300, undefined, transitionTimingIn, "transform"),
                            CssJs.transform(CssJs.translateX(CssJs.pct(-100)))
                          ])
                    ]), Particle_Gradient_Css.toCss(Particle_Gradient_Ds.view(hover._0)));
            break;
        case "Dash" :
            var activeState = [CssJs.before([
                    CssJs.transform(CssJs.translateX("zero")),
                    CssJs.opacity(1)
                  ])];
            getHoverRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.before([CssJs.bottom({
                              NAME: "px",
                              VAL: 15
                            })])], undefined, undefined, [
                  CssJs.before([
                        CssJs.unsafe("content", ""),
                        CssJs.position("absolute"),
                        CssJs.bottom({
                              NAME: "px",
                              VAL: 5
                            }),
                        CssJs.left(Particle_Css.spacerSizeInside(hover._1)),
                        CssJs.borderBottom({
                              NAME: "px",
                              VAL: 2
                            }, "solid", Particle_Color.Css.color(hover._0)),
                        CssJs.opacity(0),
                        CssJs.width({
                              NAME: "px",
                              VAL: 20
                            }),
                        CssJs.transform(CssJs.translateX({
                                  NAME: "percent",
                                  VAL: 50
                                })),
                        CssJs.transitions([
                              Css_Js_Core.Transition.shorthand(300, undefined, "ease", "transform"),
                              Css_Js_Core.Transition.shorthand(300, undefined, "ease", "opacity")
                            ])
                      ]),
                  CssJs.hover(activeState),
                  CssJs.focus(activeState),
                  CssJs.active(activeState)
                ]);
            break;
        
      }
    }
  } else {
    getHoverRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, []);
  }
  var zIndexRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Core__Option.getOr(Core__Option.map(match.zIndex, (function (zIndex) {
                  return Util_Array.pure(Particle_zIndex.Particle_zIndex_Css.toCss(zIndex));
                })), []));
  var tmp;
  if (hover !== undefined) {
    if (typeof hover !== "object") {
      switch (hover) {
        case "CursorDefault" :
        case "NotAllowed" :
            tmp = [];
            break;
        default:
          tmp = [CssJs.cursor("pointer")];
      }
    } else {
      tmp = [CssJs.cursor("pointer")];
    }
  } else {
    tmp = [CssJs.cursor("pointer")];
  }
  var otherRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, tmp);
  return Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(Particle_Screen_Ds.$$Array.concat(otherRules, shapeRules), blockRules), getFocusRules), getHoverRules), displayRules), zIndexRules);
}

var transitionDurationIn = 300;

var zoomCoef = 1.1;

var hoverOpacityCoef = 0.7;

export {
  transitionDurationIn ,
  zoomCoef ,
  hoverOpacityCoef ,
  transitionTimingIn ,
  transitionInvertedTimingIn ,
  toCss ,
}
/* transitionTimingIn Not a pure module */
