// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "./User.res.mjs";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as User_Type from "./User_Type.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Util_Result from "../util/Util_Result.res.mjs";
import * as AdsException from "../AdsException.res.mjs";
import * as Binding_Date from "../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Storage from "./User_Storage.res.mjs";
import * as User_Observable from "./User_Observable.res.mjs";
import * as User_Api__Sso__Me from "./User_Api/User_Api__Sso__Me.res.mjs";
import * as UseSyncProgression from "../hook/UseSyncProgression.res.mjs";
import * as User_Api__SsoLogin from "./User_Api/User_Api__SsoLogin.res.mjs";
import * as User_Api__Sso__Token from "./User_Api/User_Api__Sso__Token.res.mjs";
import * as User_Api__Sso__Logout from "./User_Api/User_Api__Sso__Logout.res.mjs";
import * as Shim from "use-sync-external-store/shim";

var notifyUserStatusChanged = User_Observable.UserStatus.notify;

var notifyAnonymousFeatureStatus = User_Observable.AnonymousFeatureStatus.notify;

var notifyUserInfo = User_Observable.UserInfo.notify;

function notifyAll(user) {
  Core__Option.forEach(User_Type.getAllProgressionsAndFavoriteStatus(user), (function (__x) {
          __x.forEach(function (param) {
                var dsId = param.dsId;
                User_Observable.Favorite.FavoriteStatus.notify(dsId, param.favoriteStatus);
                User_Observable.Progression.notify(dsId, param.progression);
              });
        }));
  notifyAnonymousFeatureStatus(User_Type.getAnonymousFeatureStatus(user));
  notifyUserStatusChanged(User_Type.getUserStatus(user));
  Core__Option.forEach(Core__Option.map(User_Type.getUserInfo(user), (function (a) {
              return Caml_option.some(a);
            })), notifyUserInfo);
}

function setStatus(newStatus) {
  User.Status.set(newStatus);
  User_Observable.Status.notify(newStatus);
}

function persistUser(user) {
  User.set(user);
  notifyAll(user);
}

var useUserStatus = User_Observable.UserStatus.Hook.use;

var useStatus = User_Observable.Status.Hook.use;

function useTokenStatus() {
  var match = React.useState(function () {
        return "NotReady";
      });
  var setState = match[1];
  var user = User.get();
  var status = useStatus();
  React.useEffect((function () {
          var match = User_Type.getUserStatus(user);
          var exit = 0;
          switch (match) {
            case "Anonymous" :
                switch (status) {
                  case "Initialized" :
                      setState(function (param) {
                            return "Anonymous";
                          });
                      break;
                  case "NotInitialized" :
                  case "ForceReInit" :
                      exit = 1;
                      break;
                  
                }
                break;
            case "Connected" :
                switch (status) {
                  case "Initialized" :
                      setState(function (param) {
                            return "Connected";
                          });
                      break;
                  case "NotInitialized" :
                  case "ForceReInit" :
                      exit = 1;
                      break;
                  
                }
                break;
            case "Disconnected" :
                switch (status) {
                  case "Initialized" :
                      setState(function (param) {
                            return "Disconnected";
                          });
                      break;
                  case "NotInitialized" :
                  case "ForceReInit" :
                      exit = 1;
                      break;
                  
                }
                break;
            
          }
          if (exit === 1) {
            setState(function (param) {
                  return "NotReady";
                });
          }
          
        }), [
        user,
        status
      ]);
  return match[0];
}

function useConnectedToken() {
  return Core__Option.map(User_Type.getConnectedToken(User.get()), User_Type.Token.toString);
}

var useAnonymousFeatureStatus = User_Observable.AnonymousFeatureStatus.Hook.use;

var Hook = {
  useUserStatus: useUserStatus,
  useStatus: useStatus,
  useTokenStatus: useTokenStatus,
  useConnectedToken: useConnectedToken,
  useAnonymousFeatureStatus: useAnonymousFeatureStatus
};

function reconcileUser(fromStorage, fromServer) {
  return User_Type.merge(fromStorage, fromServer);
}

async function fetchDatas(signal, locale, token) {
  var match = User_Type.Token.get(token);
  var match$1 = User_Type.Status.fromToken(token);
  var tmp;
  if (match !== undefined) {
    switch (match$1) {
      case "Connected" :
          tmp = User_Api__Sso__Me.Get.call({
                signal: signal,
                locale: locale,
                overrideToken: Caml_option.some(token)
              });
          break;
      case "Anonymous" :
      case "Disconnected" :
          tmp = Promise.resolve({
                TAG: "Error",
                _0: "RequestSkipped"
              });
          break;
      
    }
  } else {
    tmp = Promise.resolve({
          TAG: "Error",
          _0: "RequestSkipped"
        });
  }
  var fetches_0 = User_Api__Sso__Me.getData({
        args: {
          language: locale
        },
        signal: signal,
        locale: locale,
        overrideToken: Caml_option.some(token)
      });
  var fetches = [
    fetches_0,
    tmp
  ];
  var match$2 = await Promise.all(fetches);
  var me = match$2[1];
  var datas = match$2[0];
  if (datas.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: datas._0
          };
  }
  var datas$1 = datas._0;
  if (me.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: [
              datas$1,
              me._0
            ]
          };
  }
  var tmp$1 = me._0;
  if (typeof tmp$1 !== "object" && tmp$1 === "RequestSkipped") {
    return {
            TAG: "Ok",
            _0: [
              datas$1,
              Type_Club.User.defaultValue
            ]
          };
  } else {
    return {
            TAG: "Error",
            _0: me._0
          };
  }
}

function disconnect(callback, token, locale) {
  return function (param) {
    var controller = new AbortController();
    var signal = controller.signal;
    var token$1 = token !== undefined ? token : User.getToken();
    var match = Core__Option.map(token$1, User_Type.Status.fromToken);
    if (match === undefined) {
      return ;
    }
    switch (match) {
      case "Anonymous" :
          User_Api__SsoLogin.deleteToken({
                  signal: signal,
                  locale: locale
                }).then(function (param) {
                persistUser(User_Type.disconnected);
                if (callback !== undefined) {
                  return callback();
                }
                
              });
          return ;
      case "Connected" :
          User_Api__Sso__Logout.logout({
                    signal: signal,
                    locale: locale
                  }).then(function (param) {
                  return User_Api__SsoLogin.deleteToken({
                              signal: signal,
                              locale: locale
                            });
                }).then(function (param) {
                persistUser(User_Type.emptyAnonymousUser);
                if (callback !== undefined) {
                  return callback();
                }
                
              });
          return ;
      case "Disconnected" :
          return ;
      
    }
  };
}

async function getDatas(signal, fetchUserFrom, locale, storedUser, token) {
  var user = storedUser !== undefined ? Caml_option.some(User_Type.updateToken(Caml_option.valFromOption(storedUser), token)) : undefined;
  var userApiAccess = Core__Option.map(user, User_Type.getApiAccess);
  if (fetchUserFrom === "Storage") {
    return user;
  }
  var result = await fetchDatas(signal, locale, token);
  if (result.TAG === "Ok") {
    var match = result._0;
    var match$1 = match[0];
    var favorites = Core__Option.getOr(match$1.favorites, []);
    var lastvieweds = Core__Option.getOr(match$1.lastvieweds, []);
    var userFromUser = User_Type.make(Core__Option.map(match$1.subscriptions, (function (__x) {
                return __x.map(Type_String.Teaser.DsId.make);
              })), favorites, lastvieweds, new Date(), userApiAccess, token);
    return Caml_option.some(User_Type.addUserDetailFromClub(Core__Option.mapOr(storedUser, userFromUser, (function (__x) {
                          return User_Type.merge(__x, userFromUser);
                        })), match[1]));
  }
  var tmp = result._0;
  if (typeof tmp === "object") {
    return user;
  }
  if (tmp !== "InvalidAuthent") {
    return user;
  }
  disconnect(undefined, Caml_option.some(token), locale)();
  return Caml_option.some(User_Type.emptyAnonymousUser);
}

function loginUser(token, locale, signal) {
  var storedUser = User_Storage.get();
  var getDatas$1 = function (extra, extra$1) {
    return getDatas(signal, "Remote", locale, extra, extra$1);
  };
  var storedUserOpt = Util_Result.toOption(storedUser);
  return getDatas$1(storedUserOpt, token).then(function (user) {
              Core__Option.forEach(user, (function (__x) {
                      persistUser(__x);
                    }));
              setStatus("Initialized");
              return user;
            });
}

async function loginAnonymousUser(getDatas, signal, locale, param) {
  var token = await User_Api__Sso__Token.getAnonymousToken({
        signal: signal,
        locale: locale
      });
  if (token.TAG !== "Ok") {
    return Caml_option.some(User_Type.disconnected);
  }
  var token$1 = token._0;
  var ssoLoginResponse = await User_Api__SsoLogin.setToken({
        args: {
          token: token$1
        },
        signal: signal,
        locale: locale,
        overrideToken: Caml_option.some(token$1)
      });
  if (ssoLoginResponse.TAG === "Ok") {
    return await getDatas(undefined, token$1);
  } else {
    return Caml_option.some(User_Type.disconnected);
  }
}

function getFrom(lastFetched, ttl) {
  if (lastFetched === undefined) {
    return "Remote";
  }
  var lastFetchedWithTtl = Binding_Date.addMinutes(Caml_option.valFromOption(lastFetched), ttl);
  var match = Binding_Date.compareDates(lastFetchedWithTtl, new Date());
  switch (match) {
    case "After" :
        return "Remote";
    case "Same" :
    case "Before" :
        return "Storage";
    
  }
}

function getSnapshot() {
  
}

function makeSubscribe(ttl, locale) {
  var controller = new AbortController();
  var signal = controller.signal;
  var status = User_Observable.Status.Base.getInitialValue(undefined, undefined);
  var init = async function (status) {
    var exit = 0;
    if (status === undefined) {
      return ;
    }
    switch (status) {
      case "Initialized" :
          return ;
      case "NotInitialized" :
      case "ForceReInit" :
          exit = 1;
          break;
      
    }
    if (exit === 1) {
      var storedUser = User_Storage.get();
      var storedUserOpt = Util_Result.toOption(storedUser);
      Core__Option.forEach(storedUserOpt, (function (__x) {
              notifyAll(__x);
            }));
      var match;
      if (storedUser.TAG === "Ok") {
        var user = storedUser._0;
        match = [
          User_Type.getAnonymousFeatureStatus(user),
          User_Type.getLastFetched(user)
        ];
      } else {
        match = [
          "Enabled",
          undefined
        ];
      }
      var fetchUserFrom = getFrom(match[1], ttl);
      var getDatas$1 = function (extra, extra$1) {
        return getDatas(signal, fetchUserFrom, locale, extra, extra$1);
      };
      var loginAnonymousUser$1 = function (extra) {
        return loginAnonymousUser(getDatas$1, signal, locale, extra);
      };
      var token = await User_Api__SsoLogin.getTokenFromCookie({
            signal: signal,
            locale: locale
          });
      var exit$1 = 0;
      if (storedUser.TAG === "Ok") {
        exit$1 = 2;
      } else {
        switch (storedUser._0) {
          case "NotFound" :
              exit$1 = 2;
              break;
          case "FromDummyStorage" :
              setStatus("Initialized");
              persistUser(User_Type.disconnected);
              console.log(AdsException.exnToString("FirefoxStorage"));
              return ;
          case "CantParse" :
          case "StorageError" :
              return ;
          
        }
      }
      if (exit$1 === 2) {
        var match$1;
        if (token.TAG === "Ok") {
          var token$1 = token._0;
          match$1 = [
            User_Type.Status.fromToken(token$1),
            Caml_option.some(token$1)
          ];
        } else {
          match$1 = [
            "Disconnected",
            undefined
          ];
        }
        var token$2 = match$1[1];
        var user$1;
        var exit$2 = 0;
        var exit$3 = 0;
        switch (match$1[0]) {
          case "Anonymous" :
          case "Connected" :
              exit$3 = 4;
              break;
          case "Disconnected" :
              exit$2 = 3;
              break;
          
        }
        if (exit$3 === 4) {
          if (token$2 !== undefined) {
            user$1 = await getDatas$1(storedUserOpt, Caml_option.valFromOption(token$2));
          } else {
            exit$2 = 3;
          }
        }
        if (exit$2 === 3) {
          user$1 = match[0] === "Enabled" ? await loginAnonymousUser$1() : Caml_option.some(User_Type.disconnected);
        }
        User_Observable.AnonymousFeatureStatus.notify(Core__Option.getOr(Core__Option.map(user$1, (function (__x) {
                        return User_Type.getAnonymousFeatureStatus(__x);
                      })), "Enabled"));
        Core__Option.forEach(user$1, (function (__x) {
                persistUser(__x);
              }));
        return setStatus("Initialized");
      }
      
    }
    
  };
  var observableStatusId = User_Observable.Status.subscribe(function (newStatus) {
        init(newStatus);
      });
  init(status);
  return function () {
    User_Observable.Status.unsubscribe(observableStatusId);
    controller.abort();
  };
}

function User_Provider(props) {
  var __progressionTTl = props.progressionTTl;
  var __ttl = props.ttl;
  var ttl = __ttl !== undefined ? __ttl : 5;
  var progressionTTl = __progressionTTl !== undefined ? __progressionTTl : 3;
  var match = Context.I18n.getState();
  var locale = match.locale;
  Core__Option.forEach(props.env, User.Env.set);
  Core__Option.forEach(props.ssoApiKey, User.Api.setSsoApiKey);
  Shim.useSyncExternalStore(React.useCallback((function (param) {
              return makeSubscribe(ttl, locale);
            }), []), getSnapshot, getSnapshot);
  UseSyncProgression.make(progressionTTl);
  return props.children;
}

var make = User_Provider;

export {
  notifyUserStatusChanged ,
  notifyAnonymousFeatureStatus ,
  notifyUserInfo ,
  notifyAll ,
  setStatus ,
  persistUser ,
  Hook ,
  reconcileUser ,
  fetchDatas ,
  disconnect ,
  getDatas ,
  loginUser ,
  loginAnonymousUser ,
  getFrom ,
  getSnapshot ,
  makeSubscribe ,
  make ,
}
/* User Not a pure module */
