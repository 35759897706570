// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../../CssJs.res.mjs";
import * as Atom_Box from "../../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../../../type/Type_Common.res.mjs";
import * as Type_Header from "../../../../type/Type_Header.res.mjs";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as Particle_Css from "../../../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Header_Css from "../Organism_Header_Css.res.mjs";
import * as Organism_SettingsMenu from "../Organism_SettingsMenu.res.mjs";
import * as Particle_Background_Ds from "../../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Concert_SocialLinks from "./Organism_Concert_SocialLinks.res.mjs";

var linkBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("None", "S", "XS", "XS"));

var linkSpacer = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XS"));

var linkDirection = Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column");

function getLinksByKind(links, kind) {
  return Belt_Array.keep(links, (function (link) {
                return link.kind === kind;
              }));
}

var position = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.m)
      }
    }, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.default),
        left: CssJs.px(0)
      }
    });

function Organism_ConcertMenuNavigation(props) {
  var selectGenre = props.selectGenre;
  var links = props.links;
  var preferenceLinks = getLinksByKind(links, Type_Common.linkKindToJs("preference"));
  var otherLinks = links.filter(function (link) {
        return link.id === "guide_concert";
      });
  var rules = Organism_Header_Css.ConcertMenuNavigation.makeRules;
  var genresRules = Particle_Screen_Ds.make(undefined, undefined, [
        CssJs.display("grid"),
        CssJs.gridTemplateColumns([{
                NAME: "repeat",
                VAL: [
                  {
                    NAME: "num",
                    VAL: 2
                  },
                  {
                    NAME: "minmax",
                    VAL: [
                      {
                        NAME: "percent",
                        VAL: 50
                      },
                      "minContent"
                    ]
                  }
                ]
              }])
      ], undefined, undefined, [
        CssJs.display("flex"),
        CssJs.flexDirection("column")
      ]);
  if (props.state === "On") {
    return JsxRuntime.jsxs(Atom_Column.make, {
                background: Caml_option.some(Particle_Background_Ds.dropdownMenu),
                blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("S"))),
                children: [
                  preferenceLinks.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                          children: [
                            JsxRuntime.jsx(Atom_Box.make, {
                                  children: Caml_option.some(preferenceLinks.map(function (param) {
                                            var href = param.href;
                                            return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                        href: href,
                                                        label: param.label,
                                                        style: Atom_Cta_Ds.Header.makeMenuNavigation("Inactive")
                                                      }, href);
                                          })),
                                  direction: Caml_option.some(linkDirection),
                                  spacer: Caml_option.some(linkSpacer)
                                }),
                            JsxRuntime.jsx(Atom_Divider.make, {
                                  style: Atom_Divider.menuNavigation
                                }),
                            JsxRuntime.jsxs(Atom_Box.make, {
                                  children: [
                                    otherLinks.map(function (param) {
                                          var href = param.href;
                                          return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                      href: href,
                                                      label: param.label,
                                                      style: Atom_Cta_Ds.Header.makeMenuNavigation("Inactive")
                                                    }, href);
                                        }),
                                    JsxRuntime.jsx(Organism_SettingsMenu.Link.make, {})
                                  ],
                                  direction: Caml_option.some(linkDirection),
                                  spacer: Caml_option.some(linkSpacer)
                                })
                          ]
                        }) : null,
                  JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsxs(Atom_Column.make, {
                                blockSpace: Caml_option.some(linkSpacer),
                                children: [
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        blockSpace: Caml_option.some(linkBlockSpace),
                                        children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                              href: selectGenre.title.href,
                                              label: selectGenre.title.label,
                                              style: Atom_Cta_Ds.Header.concertMenuNavigation
                                            }, selectGenre.title.href)
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        children: selectGenre.items.map(function (param) {
                                              var href = param.href;
                                              return JsxRuntime.jsx(Atom_Cell.make, {
                                                          blockSpace: Caml_option.some(linkBlockSpace),
                                                          children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                                href: href,
                                                                label: param.label,
                                                                style: Atom_Cta_Ds.Header.genreItem
                                                              }, href)
                                                        });
                                            }),
                                        rules: Caml_option.some(genresRules)
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.menuNavigation
                              }),
                          JsxRuntime.jsxs(Atom_Column.make, {
                                blockSpace: Caml_option.some(linkBlockSpace),
                                children: [
                                  otherLinks.map(function (param) {
                                        var href = param.href;
                                        return JsxRuntime.jsx(Atom_Cell.make, {
                                                    blockSpace: Caml_option.some(linkBlockSpace),
                                                    children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                                          href: href,
                                                          label: param.label,
                                                          style: Atom_Cta_Ds.Header.concertMenuNavigation
                                                        }, href)
                                                  });
                                      }),
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                                        display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, CssJs.none, undefined, undefined, CssJs.inlineFlex)),
                                        blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("S"))),
                                        children: JsxRuntime.jsx(Organism_Concert_SocialLinks.make, {
                                              socialNetworks: props.socialNetworks
                                            })
                                      })
                                ]
                              })
                        ]
                      })
                ],
                position: Caml_option.some(position),
                ref_: props.ref_,
                rules: Caml_option.some(rules)
              });
  } else {
    return null;
  }
}

var make = Organism_ConcertMenuNavigation;

export {
  linkBlockSpace ,
  linkSpacer ,
  linkDirection ,
  getLinksByKind ,
  position ,
  make ,
}
/* linkBlockSpace Not a pure module */
