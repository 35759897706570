// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Toaster from "../../toast/Toaster.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Molecule_Message from "../molecule/Molecule_Message.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Atom_FavAlertToaster$ToastAlertAdded(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var text = labels.reminder.alert.add;
  return JsxRuntime.jsx(Molecule_Message.Toast.make, {
              variant: "subHeader",
              text: text,
              closeLabel: match$1.closeSubheader,
              icon: Caml_option.some(props.icon),
              delay: props.delay,
              clickOnCloseButton: props.onClose
            });
}

var ToastAlertAdded = {
  make: Atom_FavAlertToaster$ToastAlertAdded
};

function get(onClose, delay, value) {
  return JsxRuntime.jsx(Atom_FavAlertToaster$ToastAlertAdded, {
              onClose: onClose,
              delay: delay,
              icon: value.icon
            }, "teaser_alert_Added");
}

function make(param) {
  return get(param.remove, param.delay, param.value);
}

var Toast = {
  make: make
};

var Base = {
  get: get,
  Toast: Toast
};

var Toaster$1 = Toaster.Make({
      Toast: Toast
    });

export {
  ToastAlertAdded ,
  Base ,
  Toaster$1 as Toaster,
}
/* Toaster Not a pure module */
