// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Divider from "../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Type_User_Navigation from "../../type/Type_User_Navigation.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var header_direction = Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column");

function header_makeStyle(state) {
  return Atom_Cta_Ds.User.makeHeaderNavigationItemList(state);
}

var header_innerSpace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "XS"));

var header_autoSize = Particle_Screen_Ds.make(undefined, undefined, "AllPlaceAvailable", undefined, undefined, "AllPlaceNeeded");

var header = {
  direction: header_direction,
  makeStyle: header_makeStyle,
  innerSpace: header_innerSpace,
  autoSize: header_autoSize,
  parent: "header",
  divider: "with"
};

function view(param) {
  return {
          direction: param.direction,
          makeStyle: param.makeStyle,
          innerSpace: param.innerSpace,
          autoSize: param.autoSize,
          parent: param.parent
        };
}

var Organism_User_Navigation_Ds = {
  header: header,
  view: view
};

var gapValue = Particle_Css.spacerSizeInsideValue("L");

var dividerWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.relative)]);

var dividerAlignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch");

var dividerRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.display("flex"),
      CssJs.height(CssJs.px(Particle_Css.font_size_int("M"))),
      CssJs.position(CssJs.absolute),
      CssJs.right(CssJs.pct(100)),
      CssJs.top(CssJs.pct(50)),
      CssJs.transform(CssJs.translateY(CssJs.pct(-50))),
      CssJs.marginRight(CssJs.px(gapValue / 2 | 0))
    ], undefined, undefined, [CssJs.display("none")]);

var boxRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.alignItems("center")], undefined, undefined, [CssJs.alignItems("left")]);

var divider = JsxRuntime.jsx(Atom_Row.make, {
      alignItems: Caml_option.some(dividerAlignItems),
      children: JsxRuntime.jsx(Atom_Divider.make, {
            style: Atom_Divider.userNavigation
          }),
      rules: Caml_option.some(dividerRules)
    });

function Organism_User_Navigation(props) {
  var variant = props.variant;
  var match = view(variant);
  var parent = match.parent;
  var makeStyle = match.makeStyle;
  var match$1 = Util_Array.NonEmpty.extractHead(props.items);
  var renderItem = function (item) {
    var match = item._0;
    var state = match.state;
    var label = match.label;
    var key = match.key;
    var style = makeStyle(state);
    if (state === "Current" && parent !== "header") {
      return JsxRuntime.jsx(Atom_Cell.make, {
                  blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("S", "S", "L", "None"))),
                  kind: "ItemList",
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                  background: Caml_option.some(Particle_Background_Ds.userNavigationItemListCurrentUser),
                  children: JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(label),
                        style: Atom_Text_Ds.User.profileNavigationItemList
                      })
                }, Type_User_Navigation.keyToString(key));
    }
    return JsxRuntime.jsx(Atom_Cell.make, {
                kind: "ItemList",
                children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                      href: Type_String.Href.toString(match.href),
                      label: label,
                      style: style
                    })
              }, Type_User_Navigation.keyToString(key));
  };
  return JsxRuntime.jsxs(Atom_Box.make, {
              autoSize: Caml_option.some(match.autoSize),
              children: [
                renderItem(match$1[0]),
                Core__Option.mapOr(match$1[1], null, (function (items) {
                        return Util_Array.NonEmpty.toReactElement(Util_Array.NonEmpty.mapWithIndex(items, (function (item, index) {
                                          var match = variant.divider;
                                          if (match === "without") {
                                            return renderItem(item);
                                          } else {
                                            return JsxRuntime.jsxs(Atom_Cell.make, {
                                                        children: [
                                                          divider,
                                                          renderItem(item)
                                                        ],
                                                        rules: Caml_option.some(dividerWrapperRules)
                                                      }, "userNavigationItems" + index.toString());
                                          }
                                        })));
                      }))
              ],
              direction: Caml_option.some(match.direction),
              kind: "List",
              rules: Caml_option.some(boxRules),
              innerSpace: match.innerSpace
            });
}

var make = Organism_User_Navigation;

export {
  Organism_User_Navigation_Ds ,
  header ,
  view ,
  gapValue ,
  dividerWrapperRules ,
  dividerAlignItems ,
  dividerRules ,
  boxRules ,
  divider ,
  make ,
}
/* header Not a pure module */
