// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as UseOnBlur from "../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_Header from "../../type/Type_Header.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as UseClickOutside from "../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Header_Css from "../organism/header/Organism_Header_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_HeaderAccessibility from "../organism/header/Organism_HeaderAccessibility.res.mjs";

function make(baseUrl) {
  return {
          languages: [
            {
              acronym: "FR",
              href: baseUrl + "/fr/",
              label: "Français",
              language: "fr",
              title: "ARTE en 6 langues"
            },
            {
              acronym: "DE",
              href: baseUrl + "/de/",
              label: "Deutsch",
              language: "de",
              title: "ARTE in 6 Sprachen"
            },
            {
              acronym: "EN",
              href: baseUrl + "/en/",
              label: "English",
              language: "en",
              title: "ARTE in 6 languages"
            },
            {
              acronym: "ES",
              href: baseUrl + "/es/",
              label: "Español",
              language: "es",
              title: "ARTE en 6 idiomas"
            },
            {
              acronym: "PL",
              href: baseUrl + "/pl/",
              label: "Polski",
              language: "pl",
              title: "ARTE  6 językach"
            },
            {
              acronym: "IT",
              href: baseUrl + "/it/",
              label: "Italiano",
              language: "it",
              title: "ARTE in 6 lingue"
            }
          ]
        };
}

var Labels = {
  make: make
};

function Molecule_LanguageSwitcher$Item(props) {
  var languageItem = props.languageItem;
  var language = languageItem.language;
  var label = languageItem.label;
  var acronym = languageItem.acronym;
  var close = props.close;
  var status = props.status;
  var languageSwitcherBehavior = props.languageSwitcherBehavior;
  var tmp;
  tmp = typeof status !== "object" ? "false" : "true";
  var accessibility = Particle_Accessibility.make(undefined, tmp, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, acronym, undefined, undefined);
  if (typeof languageSwitcherBehavior !== "object") {
    return JsxRuntime.jsx(Atom_Cta.Link.make, {
                accessibility: accessibility,
                href: props.href,
                identifier: Caml_option.some(Identifiers.LanguageSwitcher.language(acronym)),
                label: label + (" (" + (acronym + ")")),
                style: Atom_Cta_Ds.Header.makeLanguageSwitcherItem(status)
              });
  }
  var changeLanguageHandler = languageSwitcherBehavior._0;
  var handleClick = function (param) {
    changeLanguageHandler(language);
    close();
  };
  return JsxRuntime.jsx(Atom_Cta.Button.make, {
              accessibility: accessibility,
              identifier: Caml_option.some(Identifiers.LanguageSwitcher.language(acronym)),
              label: label + (" (" + (acronym + ")")),
              onClick: handleClick,
              style: Atom_Cta_Ds.Header.makeLanguageSwitcherItem(status)
            });
}

var Item = {
  make: Molecule_LanguageSwitcher$Item
};

var position = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.m)
      }
    }, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: Particle_Css.pxToRem(Type_Header.Constants.height.default),
        right: CssJs.px(0)
      }
    });

function make$1(param) {
  var languageSwitcherBehavior = param.languageSwitcherBehavior;
  var alternativeLanguages = param.alternativeLanguages;
  var locale = param.locale;
  var match = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var close = match.close;
  var state = match.state;
  var match$1 = Context.Config.getState();
  var baseUrl = match$1.baseUrl;
  var match$2 = React.useMemo((function () {
          return make(baseUrl);
        }), [
        baseUrl,
        locale
      ]);
  var languages = match$2.languages;
  var ref = React.useRef(null);
  UseClickOutside.make(ref, close);
  UseOnBlur.make(ref, close);
  var tmp;
  tmp = state === "On" ? CssJs.flexBox : CssJs.none;
  var displayLanguagesPanel = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, tmp);
  var activeLanguage = Core__Option.getOr(languages.find(function (language) {
            return Util_I18n.stringToLanguage(Util_I18n.localeToString(locale)) === language.language;
          }), Core__Option.getOr(languages[0], {
            acronym: "fr",
            href: "/",
            label: "Français",
            language: "fr",
            title: "Français"
          }));
  var languageItems = languages.map(function (languageItem) {
        var match = languageItem.language;
        var status = match === activeLanguage.language ? ({
              TAG: "Active",
              _0: undefined
            }) : "Inactive";
        var currentAlternativeLanguage = alternativeLanguages !== undefined ? alternativeLanguages.find(function (alternativeLanguage) {
                return alternativeLanguage.code === languageItem.language;
              }) : undefined;
        var href = Core__Option.getOr(Core__Option.flatMap(currentAlternativeLanguage, (function (v) {
                    return v.url;
                  })), languageItem.href);
        return JsxRuntime.jsx(Atom_Cell.make, {
                    display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.inlineFlex)),
                    kind: "ItemList",
                    children: JsxRuntime.jsx(Molecule_LanguageSwitcher$Item, {
                          languageSwitcherBehavior: languageSwitcherBehavior,
                          languageItem: languageItem,
                          status: status,
                          href: href,
                          close: close
                        })
                  }, languageItem.acronym);
      });
  var languagesPanel = JsxRuntime.jsx(Atom_Column.make, {
        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsStretch),
        background: Caml_option.some(Particle_Background_Ds.dropdownMenu),
        children: languageItems,
        display: Caml_option.some(displayLanguagesPanel),
        id: "languagesSwitcher",
        kind: "List",
        position: Caml_option.some(position),
        ref_: ref,
        rules: Caml_option.some(Organism_Header_Css.LanguageSwitcher.rules),
        wrap: "OnOneLine"
      });
  return JsxRuntime.jsxs(Atom_Box.make, {
              background: Caml_option.some(Particle_Background_Ds.header),
              children: [
                JsxRuntime.jsx(Atom_Cta.Button.make, {
                      accessibility: Organism_HeaderAccessibility.accessibility(state),
                      identifier: Caml_option.some(Identifiers.LanguageSwitcher.button),
                      label: activeLanguage.acronym,
                      onClick: match.toggle,
                      screenReadersLabel: activeLanguage.title,
                      style: Atom_Cta_Ds.Header.makeLanguageSwitcher(state),
                      textAccessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                    }),
                languagesPanel
              ],
              direction: Caml_option.some(Particle_Screen_Constants.flexDirectionColumn),
              display: Caml_option.some(Organism_Header_Css.LanguageSwitcher.display(param.searchState)),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              ref_: ref
            });
}

export {
  Labels ,
  Item ,
  position ,
  make$1 as make,
}
/* position Not a pure module */
