// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "./Atom_Text_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";

var defaultActiveProperty_icon = Atom_Icon_Ds.updateColor(Atom_Icon_Ds.circleCheck, "Arte");

var defaultActiveProperty = {
  background: Particle_Background_Ds.switchActive,
  circle: Particle_Background_Ds.switchCircleActive,
  color: "White",
  outline: "Outline",
  icon: defaultActiveProperty_icon,
  transform: "Right"
};

var defaultInactiveProperty = {
  background: Particle_Background_Ds.switchInactive,
  circle: Particle_Background_Ds.switchCircleInactive,
  color: "SwitchInactiveForeground",
  outline: "Outline",
  icon: Atom_Icon_Ds.off,
  transform: "Left"
};

var makeProgramPage_inactive = {
  background: Particle_Background_Ds.switchInactiveProgram,
  circle: Particle_Background_Ds.switchCircleInactiveProgram,
  color: "DarkGrey",
  outline: "Outline",
  icon: Atom_Icon_Ds.off,
  transform: "Left"
};

var makeProgramPage = {
  labelPosition: "Before",
  active: defaultActiveProperty,
  inactive: makeProgramPage_inactive,
  size: "XS",
  labelTextStyle: Atom_Text_Ds.switchLabel
};

var makeInfoBox = {
  labelPosition: "After",
  active: defaultActiveProperty,
  inactive: defaultInactiveProperty,
  size: "XS",
  labelTextStyle: Atom_Text_Ds.switchLabel
};

var makeSettingPage_labelTextStyle = Atom_Text_Ds.Setting.itemTitle;

var makeSettingPage = {
  labelPosition: "Before",
  active: defaultActiveProperty,
  inactive: defaultInactiveProperty,
  size: "S",
  labelTextStyle: makeSettingPage_labelTextStyle
};

var theme_active = {
  background: Particle_Background_Ds.switchInactiveProgram,
  circle: Particle_Background_Ds.switchCircleTheme,
  color: "Secondary",
  outline: "Outline",
  icon: Atom_Icon_Ds.off,
  transform: "Right"
};

var theme_inactive = {
  background: Particle_Background_Ds.switchInactiveProgram,
  circle: Particle_Background_Ds.switchCircleTheme,
  color: "Secondary",
  outline: "Outline",
  icon: Atom_Icon_Ds.off,
  transform: "Left"
};

var theme = {
  labelPosition: "Both",
  active: theme_active,
  inactive: theme_inactive,
  size: "S",
  labelTextStyle: Atom_Text_Ds.switchLabel
};

function view(state, param) {
  var size = param.size;
  var inactive = param.inactive;
  var active = param.active;
  if (state === "On") {
    return {
            background: active.background,
            circle: active.circle,
            color: active.color,
            outline: active.outline,
            icon: active.icon,
            transform: active.transform,
            size: size
          };
  } else {
    return {
            background: inactive.background,
            circle: inactive.circle,
            color: inactive.color,
            outline: inactive.outline,
            icon: inactive.icon,
            transform: inactive.transform,
            size: size
          };
  }
}

export {
  defaultActiveProperty ,
  defaultInactiveProperty ,
  makeProgramPage ,
  makeInfoBox ,
  makeSettingPage ,
  theme ,
  view ,
}
/* defaultActiveProperty Not a pure module */
