'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Divider from "../../../design-system/atom/Atom_Divider.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../../../design-system/atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as User_Api__Sso__CheckEmailExists from "../../../user/User_Api/User_Api__Sso__CheckEmailExists.res.mjs";

function get(values, field) {
  return values.email;
}

function set(values, field, value) {
  return {
          email: value
        };
}

var FormFields = {
  get: get,
  set: set
};

function makeInitialState(email) {
  return {
          email: Core__Option.getOr(email, "")
        };
}

var Form = UseForm.Make({
      set: set,
      get: get
    });

function makeSchema() {
  return Form.Schema.Validation.schema([
              Form.Schema.Validation.nonEmpty("Email"),
              Form.Schema.Validation.email("Email")
            ]);
}

function make(param) {
  var redirectToLogin = param.redirectToLogin;
  var redirectToRegister = param.redirectToRegister;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var userErrorGenericLabel = labels.mona.editEmail.errorGeneric;
  var controller = new AbortController();
  var signal = controller.signal;
  var schema = makeSchema();
  var match$2 = labels.mona.profile;
  var emailPlaceholder = match$2.emailPlaceholder;
  var match$3 = UseUserFetch.make();
  var setError = match$3.setError;
  var setLoading = match$3.setLoading;
  var handleSubmit = function (param) {
    var match = param.state.values;
    var email = match.email;
    setLoading();
    User_Api__Sso__CheckEmailExists.check({
            args: {
              email: email
            },
            signal: signal,
            locale: locale
          }).then(function (t) {
          if (t.TAG === "Ok") {
            if (t._0.emailExist) {
              return redirectToLogin(email);
            } else {
              return redirectToRegister(email);
            }
          } else {
            return setError(userErrorGenericLabel);
          }
        });
  };
  var form = Form.use(makeInitialState(param.email), schema, handleSubmit, labels, match.formaters, undefined, undefined);
  var match$4 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: match$1.landingTitle,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    children: [
                      JsxRuntime.jsx(Atom_Column.make, {
                            children: JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                                  autoComplete: "email",
                                  type_: "email",
                                  value: match$4.email,
                                  label: emailPlaceholder,
                                  pattern: "Email",
                                  onChange: (function (__x) {
                                      form.handleChange("Email", __x);
                                    }),
                                  required: true,
                                  id: "email",
                                  name: emailPlaceholder,
                                  errorMessage: form.getFieldError({
                                        TAG: "Field",
                                        _0: "Email"
                                      }),
                                  inputRef: form.getFieldRef({
                                        TAG: "Field",
                                        _0: "Email"
                                      }),
                                  autoFocus: true
                                }),
                            innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                          }),
                      match$3.component,
                      JsxRuntime.jsx(Atom_Column.make, {
                            children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                  label: match$1.landingButton,
                                  variant: "fullWidth",
                                  type_: "submit"
                                }),
                            innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M"))
                          }),
                      JsxRuntime.jsx(Atom_Divider.make, {
                            style: Atom_Divider.user
                          })
                    ],
                    innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
                    kind: {
                      TAG: "Form",
                      _0: onSubmit
                    },
                    width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
                  })
            });
}

export {
  FormFields ,
  makeInitialState ,
  Form ,
  makeSchema ,
  make ,
}
/* Form Not a pure module */
