// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Js_array from "@rescript/std/lib/es6/js_array.js";
import * as Atom_Cell from "../../../atom/Atom_Cell.res.mjs";
import * as Atom_Grid from "../../../atom/Atom_Grid.res.mjs";
import * as Atom_Icon from "../../../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../../../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../../../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../../../type/Type_Common.res.mjs";
import * as Type_String from "../../../../type/Type_String.res.mjs";
import * as Atom_Divider from "../../../atom/Atom_Divider.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../../../particle/Particle_Css.res.mjs";
import * as Atom_Icon_Css from "../../../atom/Atom_Icon_Css.res.mjs";
import * as Molecule_Partners from "../../../molecule/Molecule_Partners.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_FavAlertButton from "../../../atom/Atom_FavAlertButton.res.mjs";
import * as Template_ProgramContent from "./Template_ProgramContent.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

function renderItems(_items, _parent) {
  while(true) {
    var parent = _parent;
    var items = _items;
    if (parent === undefined) {
      return items;
    }
    var url = parent.url;
    var label = parent.label;
    var match = items.find((function(label,url){
        return function (param) {
          if (param[0] === label) {
            return true;
          } else {
            return param[1] === url;
          }
        }
        }(label,url)));
    var items$1 = match !== undefined ? items : Js_array.concat(items, [[
              label,
              url
            ]]);
    _parent = parent.parent;
    _items = items$1;
    continue ;
  };
}

function Template_ProgramContent_Metas$Tags(props) {
  var genre = props.genre;
  var initialItems = genre !== undefined ? [[
        Type_String.Program.Genre.Label.toString(genre.label),
        Core__Option.getOr(Core__Option.map(genre.url, (function (url) {
                    return Type_String.Program.Genre.Url.toString(url);
                  })), "")
      ]] : [];
  var items = renderItems(initialItems, props.parent);
  return JsxRuntime.jsx(Atom_Row.make, {
              blockSpace: Caml_option.some(Particle_Screen_Constants.xsBottomBlockSpace),
              children: items.map(function (param, index) {
                    var label = param[0];
                    return JsxRuntime.jsx(Atom_Cell.make, {
                                blockSpace: Caml_option.some(Particle_Screen_Constants.xxsBottomBlockSpace),
                                kind: "ItemList",
                                children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                      href: param[1],
                                      label: label,
                                      style: Atom_Cta_Ds.tag
                                    })
                              }, label + String(index));
                  }),
              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
              kind: "List"
            });
}

var gapValue = Particle_Css.spacerSizeInsideValue("M");

var dividerWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.relative)]);

var metaRowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gap2(Particle_Css.spacerSizeInside("XXS"), CssJs.px(gapValue)),
      CssJs.overflow("hidden")
    ]);

var dividerAlignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "stretch");

var dividerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.display("flex"),
      CssJs.height(CssJs.px(Particle_Css.font_size_int("M"))),
      CssJs.position(CssJs.absolute),
      CssJs.right(CssJs.pct(100)),
      CssJs.top(CssJs.pct(50)),
      CssJs.transform(CssJs.translateY(CssJs.pct(-50))),
      CssJs.marginRight(CssJs.px(gapValue / 2 | 0))
    ]);

var divider = JsxRuntime.jsx(Atom_Row.make, {
      alignItems: Caml_option.some(dividerAlignItems),
      children: JsxRuntime.jsx(Atom_Divider.make, {
            style: Atom_Divider.contentCollectionMeta
          }),
      rules: Caml_option.some(dividerRules)
    });

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("XS")), undefined, undefined, Particle_Spacer_Ds.makeVertical("XXS"));

function Template_ProgramContent_Metas$Tags$Links(props) {
  var genre = props.genre;
  var initialItems = genre !== undefined ? [[
        Type_String.Program.Genre.Label.toString(genre.label),
        Core__Option.getOr(Core__Option.map(genre.url, (function (url) {
                    return Type_String.Program.Genre.Url.toString(url);
                  })), "")
      ]] : [];
  var items = renderItems(initialItems, props.parent);
  return JsxRuntime.jsx(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(blockSpace),
              children: items.map(function (param, index) {
                    var label = param[0];
                    return JsxRuntime.jsxs(Atom_Cell.make, {
                                children: [
                                  index === 0 ? null : divider,
                                  JsxRuntime.jsx(Atom_Cell.make, {
                                        blockSpace: Caml_option.some(Particle_Screen_Constants.xxxsBottomBlockSpace),
                                        kind: "ItemList",
                                        children: JsxRuntime.jsx(Template_ProgramContent.Value.Link.make, {
                                              value: label,
                                              href: param[1]
                                            })
                                      })
                                ],
                                rules: Caml_option.some(dividerWrapperRules)
                              }, label + String(index));
                  }),
              kind: "List",
              rules: Caml_option.some(metaRowRules)
            });
}

var Links = {
  gapValue: gapValue,
  dividerWrapperRules: dividerWrapperRules,
  metaRowRules: metaRowRules,
  dividerAlignItems: dividerAlignItems,
  dividerRules: dividerRules,
  divider: divider,
  blockSpace: blockSpace,
  make: Template_ProgramContent_Metas$Tags$Links
};

var Tags = {
  renderItems: renderItems,
  make: Template_ProgramContent_Metas$Tags,
  Links: Links
};

function Template_ProgramContent_Metas$VodDvd$Display(props) {
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(Particle_Screen_Constants.lTopBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      width: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, Css.pct(50), undefined, "auto")),
                      children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                            href: props.url,
                            label: props.label,
                            style: Atom_Cta_Ds.buyProgram,
                            target: "_blank"
                          })
                    }),
                JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                      alt: "",
                      style: {
                        TAG: "FromCdn",
                        _0: {
                          TAG: "BuyProgram",
                          _0: props.availability
                        }
                      }
                    })
              ],
              wrap: "OnOneLine"
            });
}

var Display = {
  make: Template_ProgramContent_Metas$VodDvd$Display
};

function Template_ProgramContent_Metas$VodDvd(props) {
  var shopUrl = props.shopUrl;
  var match = Context.Config.getState();
  var vodStoreHomepageUrl = match.vodStoreHomepageUrl;
  var match$1 = Context.I18n.getState();
  var locale = match$1.locale;
  var match$2 = match$1.labels.program;
  if (shopUrl !== undefined) {
    if (locale === "Fr") {
      return JsxRuntime.jsx(Template_ProgramContent_Metas$VodDvd$Display, {
                  label: match$2.vod,
                  url: shopUrl,
                  availability: "Available"
                });
    } else {
      return null;
    }
  } else if (locale === "Fr" && vodStoreHomepageUrl !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_Metas$VodDvd$Display, {
                label: match$2.vod_discover,
                url: vodStoreHomepageUrl,
                availability: "NotAvailable"
              });
  } else {
    return null;
  }
}

var VodDvd = {
  Display: Display,
  make: Template_ProgramContent_Metas$VodDvd
};

function audioSpacingPerSize(size) {
  return Atom_Icon_Css.getShirtSpacing(size);
}

function audioOffsetX(spacing) {
  return CssJs.px(-(spacing | 0) | 0);
}

function audioOffsetY(spacing) {
  return CssJs.px(-(spacing | 0) | 0);
}

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

function renderString(label, value) {
  if (value === "") {
    return null;
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Template_ProgramContent.Label.make, {
                        label: label
                      }),
                  JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                        value: value
                      })
                ]
              });
  }
}

function renderAudioVersions(label, audioVersions) {
  if (audioVersions.length !== 0) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Template_ProgramContent.Label.make, {
                        label: label
                      }),
                  JsxRuntime.jsx(Atom_Row.make, {
                        alignSelf: Caml_option.some(Particle_Screen_Constants.alignSelfStart),
                        children: audioVersions.map(function (param, index) {
                              var label = param.label;
                              return JsxRuntime.jsxs(Atom_Cell.make, {
                                          alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                          blockSpace: Caml_option.some(Particle_Screen_Constants.xxsVerticalBlockSpace),
                                          kind: "ItemList",
                                          children: [
                                            JsxRuntime.jsx(Atom_Icon.make, {
                                                  icon: Type_Common.audioVersionCodeToIcon(param.code, "Text"),
                                                  size: iconSize,
                                                  title: label
                                                }),
                                            JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                                                  children: label
                                                })
                                          ]
                                        }, label + String(index));
                            }),
                        display: Caml_option.some(Particle_Screen_Constants.displayInlineFlex),
                        kind: "List",
                        rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, [CssJs.transform(CssJs.translate(audioOffsetX(Atom_Icon_Css.getShirtSpacing("XL")), audioOffsetY(Atom_Icon_Css.getShirtSpacing("XL"))))], undefined, undefined, [CssJs.transform(CssJs.translate(audioOffsetX(Atom_Icon_Css.getShirtSpacing("L")), audioOffsetY(Atom_Icon_Css.getShirtSpacing("L"))))]))
                      })
                ]
              });
  } else {
    return null;
  }
}

function renderGenre(genreLabel, param) {
  var url = param.url;
  var label = param.label;
  var match = Type_String.Program.Genre.Label.toString(label);
  if (match === "") {
    return null;
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Template_ProgramContent.Label.make, {
                        label: genreLabel
                      }),
                  url !== undefined ? JsxRuntime.jsx(Template_ProgramContent.Value.Link.make, {
                          value: Type_String.Program.Genre.Label.toString(label),
                          href: Type_String.Program.Genre.Url.toString(url)
                        }) : JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                          value: Type_String.Program.Genre.Label.toString(label)
                        })
                ]
              });
  }
}

function renderMultiString(label, values) {
  if (values.length !== 0) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Template_ProgramContent.Label.make, {
                        label: label
                      }),
                  JsxRuntime.jsx(Atom_Column.make, {
                        children: values.map(function (value, index) {
                              return JsxRuntime.jsx(Atom_Cell.make, {
                                          alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                          kind: "ItemList",
                                          children: JsxRuntime.jsx(Template_ProgramContent.Value.make, {
                                                value: value
                                              })
                                        }, label + String(index));
                            }),
                        display: Caml_option.some(Particle_Screen_Constants.displayInlineFlex),
                        innerSpace: Caml_option.some(Particle_Screen_Constants.xxsInnerSpace),
                        kind: "List"
                      })
                ]
              });
  } else {
    return null;
  }
}

var gridRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gridTemplateColumns([
            CssJs.fr(1),
            CssJs.fr(2)
          ]),
      CssJs.alignItems("baseline")
    ]);

var gridColumnGap = Particle_Screen_Ds.make(undefined, undefined, undefined, "L", undefined, "S");

var gridRowGap = Particle_Screen_Ds.make(undefined, undefined, undefined, "S", undefined, "XS");

function Template_ProgramContent_Metas$Infos(props) {
  return JsxRuntime.jsx(Atom_Grid.make, {
              children: props.meta.map(function (elem, index) {
                    var value = elem.value;
                    var label = elem.label;
                    var tmp;
                    switch (value.TAG) {
                      case "String" :
                          tmp = renderString(label, value._0);
                          break;
                      case "AudioVersions" :
                          tmp = renderAudioVersions(label, value._0);
                          break;
                      case "Genre" :
                          tmp = renderGenre(label, value._0);
                          break;
                      case "MultiString" :
                          tmp = renderMultiString(label, value._0);
                          break;
                      
                    }
                    return JsxRuntime.jsx(React.Fragment, {
                                children: tmp
                              }, elem.label + String(index));
                  }),
              gapColumn: Caml_option.some(gridColumnGap),
              gapRow: Caml_option.some(gridRowGap),
              rules: Caml_option.some(gridRules)
            });
}

var Infos = {
  iconSize: iconSize,
  renderString: renderString,
  renderAudioVersions: renderAudioVersions,
  renderGenre: renderGenre,
  renderMultiString: renderMultiString,
  gridRules: gridRules,
  gridColumnGap: gridColumnGap,
  gridRowGap: gridRowGap,
  make: Template_ProgramContent_Metas$Infos
};

function Template_ProgramContent_Metas(props) {
  var parent = props.parent;
  var teaser = props.teaser;
  var favoriteAvailability = Atom_FavAlertButton.availabilityFromTeaserFavorite(teaser.favoriteStatus);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Molecule_Partners.make, {
                      partners: teaser.partners,
                      variant: Molecule_Partners.Molecule_Partners_Ds.program
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      blockSpace: Caml_option.some(Particle_Screen_Constants.mBottomBlockSpace),
                      children: [
                        props.buttons,
                        JsxRuntime.jsx(Atom_FavAlertButton.make, {
                              teaser: teaser,
                              styleVariation: "Program",
                              availability: favoriteAvailability
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                parent !== undefined ? JsxRuntime.jsx(Template_ProgramContent_Metas$Tags, {
                        parent: parent
                      }) : null,
                JsxRuntime.jsx(Template_ProgramContent_Metas$Infos, {
                      meta: props.meta
                    }),
                JsxRuntime.jsx(Template_ProgramContent_Metas$VodDvd, {
                      shopUrl: teaser.shopUrl
                    })
              ],
              ref_: props.ref_
            });
}

Template_ProgramContent_Metas.displayName = "Template_ProgramContent_Metas";

var make = Template_ProgramContent_Metas;

export {
  Tags ,
  VodDvd ,
  audioSpacingPerSize ,
  audioOffsetX ,
  audioOffsetY ,
  Infos ,
  make ,
}
/* gapValue Not a pure module */
