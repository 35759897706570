// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User_Type from "../user/User_Type.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Observable from "../user/User_Observable.res.mjs";
import * as User_Api__Sso__Me from "../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as User_Api__Sso__AcceptCom from "../user/User_Api/User_Api__Sso__AcceptCom.res.mjs";

function makeTeaserAction(code) {
  return Core__Option.flatMap((code == null) ? undefined : Caml_option.some(code), (function (code) {
                var code$1 = code.split("_");
                var match = code$1[0];
                var match$1 = code$1[1];
                if (match === undefined) {
                  return ;
                }
                if (match !== "ACTION") {
                  return ;
                }
                if (match$1 === undefined) {
                  return ;
                }
                switch (match$1) {
                  case "newsletter" :
                      return "SubscribeToNewsletter";
                  case "personnal" :
                      return "AcceptCom";
                  default:
                    return ;
                }
              }));
}

function actionFromZoneCode(zoneCode) {
  var controller = new AbortController();
  var signal = controller.signal;
  var match = makeTeaserAction(zoneCode);
  if (match !== undefined) {
    if (match === "SubscribeToNewsletter") {
      return {
              TAG: "ToggleAsync",
              _0: {
                getStatus: (function (user) {
                    var match = Core__Option.flatMap(user, User_Type.Info.isSubscribedToNewsletter);
                    if (match !== undefined && match) {
                      return "On";
                    } else {
                      return "Off";
                    }
                  }),
                sendAction: (async function (previousStatus, locale) {
                    var newNewsletterValue;
                    newNewsletterValue = previousStatus === "On" ? false : true;
                    var user = User_Observable.UserInfo.All.getSnapshot();
                    var email = Core__Option.flatMap(user, User_Type.Info.email);
                    if (email === undefined) {
                      return {
                              TAG: "Error",
                              _0: undefined
                            };
                    }
                    var response = await User_Api__Sso__Me.Newsletter.Subscribe.call({
                          args: {
                            email: email,
                            source: "replay_advantages",
                            nlarte: newNewsletterValue,
                            acceptCom: Core__Option.getOr(Core__Option.flatMap(user, User_Type.Info.acceptCom), false),
                            locale: locale
                          },
                          signal: signal,
                          locale: locale
                        });
                    if (response.TAG !== "Ok") {
                      return {
                              TAG: "Error",
                              _0: undefined
                            };
                    }
                    User_Observable.UserInfo.updateIsSubscribedToNewsletter(newNewsletterValue);
                    return {
                            TAG: "Ok",
                            _0: undefined
                          };
                  }),
                initialStatus: "Off"
              }
            };
    } else {
      return {
              TAG: "ToggleAsync",
              _0: {
                getStatus: (function (user) {
                    var match = Core__Option.flatMap(user, User_Type.Info.acceptCom);
                    if (match !== undefined && match) {
                      return "On";
                    } else {
                      return "Off";
                    }
                  }),
                sendAction: (async function (previousStatus, locale) {
                    var newAcceptComValue;
                    newAcceptComValue = previousStatus === "On" ? false : true;
                    var response = await User_Api__Sso__AcceptCom.set({
                          args: {
                            acceptCom: newAcceptComValue
                          },
                          signal: signal,
                          locale: locale
                        });
                    if (response.TAG !== "Ok") {
                      return {
                              TAG: "Error",
                              _0: undefined
                            };
                    }
                    User_Observable.UserInfo.updateAcceptCom(newAcceptComValue);
                    return {
                            TAG: "Ok",
                            _0: undefined
                          };
                  }),
                initialStatus: "Off"
              }
            };
    }
  }
  
}

export {
  makeTeaserAction ,
  actionFromZoneCode ,
}
/* User_Type Not a pure module */
