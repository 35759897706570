// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";

var endpoint = {
  NAME: "Static",
  VAL: "logout"
};

var options = {
  userRestriction: "Connected"
};

function makeBody(param) {
  
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var PostConfig = {
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  options: options,
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Post = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var logout = Post.call;

export {
  PostConfig ,
  Post ,
  logout ,
}
/* Post Not a pure module */
