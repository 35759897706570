import { useEffect } from 'react';
import { env } from '@replay/env-generator';
import { eventStore } from './eventStore';
import { userStore } from './userStore';
import { toApiEvent } from './event';
import { send } from './sendEvent';

/* istanbul ignore next */
export const unsubscribe = eventStore.subscribe(({ queue: eventQueue, forceSend }, { queue: previousEventQueue }) => {
    if (eventQueue.length === 0) {
        return;
    }
    const data = userStore.getState().getReadyData();
    if (!data) {
        return;
    }
    const [event, ...restEvents] = eventQueue;
    if (!event) {
        return;
    }
    if (event.behavior === 'DELAYED') {
        if (env.NODE_ENV === 'development') {
            console.info('====SST EVENT DELAYED====', event);
        }
        eventStore.setState(
            {
                queue: [
                    {
                        ...event,
                        behavior: 'IMMEDIATE',
                    },
                    ...restEvents,
                ],
                forceSend: false,
            },
            false,
            {
                type: 'DELAYED_EVENT',
                newEvent: event,
            },
        );
        return;
    }
    if (eventQueue.length !== previousEventQueue.length || forceSend) {
        // See tests/pageview.spec.tsx, 'should send event when data and event is ready'
        /* istanbul ignore next */
        // We should only send events allowed by feature flipping
        if (!env.NEXT_PUBLIC_SERVER_SIDE_TRACKING_TYPES.includes(event.type)) {
            eventStore.setState({ queue: restEvents, forceSend: false }, false, {
                type: 'FEATURE_FLIPPING_SKIP_HIT',
                newEvent: event,
            });
            if (env.NODE_ENV === 'development') {
                console.log(`TRACKING: ${event.type} event skipped because of feature flipping rules`);
            }
            return;
        }

        send(data, toApiEvent(event));
        eventStore.setState({ queue: restEvents, forceSend: false }, false, {
            type: 'SEND_HIT',
            newEvent: event,
        });
    }
});

export const unsubscribeWithSelector = userStore.subscribe(
    (state) => state.status,
    (state, prevState) => {
        if (state === 'ready' && state !== prevState) {
            eventStore.setState({ forceSend: true });
        }
    },
);

export const useSendPageView = () => {
    // See tests/pageview.spec.tsx, 'should call unsubscribe and unsubscribeWithSelector on cleanup'
    /* istanbul ignore next */
    useEffect(() => {
        return () => {
            unsubscribe();
            unsubscribeWithSelector();
        };
    }, []);
};
