// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as UsePlayer from "../../hook/UsePlayer.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Backlink from "../atom/Atom_Backlink.res.mjs";
import * as UseNextEpisode from "../../hook/UseNextEpisode.res.mjs";
import * as Molecule_Partners from "./Molecule_Partners.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Atom_FavAlertButton from "../atom/Atom_FavAlertButton.res.mjs";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Atom_NextEpisodeButton from "../atom/Atom_NextEpisodeButton.res.mjs";
import * as Helper_HighlightTitleTag from "../helper/Helper_HighlightTitleTag.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Molecule_ContentCollection_Css from "./Molecule_ContentCollection_Css.res.mjs";
import * as Molecule_ContentCollection_Metas from "./Molecule_ContentCollection_Metas.res.mjs";

var forceMountOnDesktop = Particle_Screen_Ds.make(undefined, undefined, true, undefined, undefined, false);

var imageContainerPosition = Particle_Screen_Ds.make(undefined, undefined, "Relative", {
      TAG: "Absolute",
      _0: undefined
    }, undefined, {
      TAG: "Absolute",
      _0: {
        top: CssJs.px(0)
      }
    });

var contentPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, "Relative", undefined, {
      TAG: "Absolute",
      _0: {
        top: CssJs.px(0),
        bottom: CssJs.px(0)
      }
    });

var videoContainerPosition = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        right: CssJs.px(0)
      }
    }, {
      TAG: "Absolute",
      _0: {
        top: CssJs.px(0)
      }
    }, undefined, "Relative");

function Molecule_ContentCollection(props) {
  var teaser = props.teaser;
  var video = props.video;
  var __favoriteButtonAvailability = props.favoriteButtonAvailability;
  var __nextEpisodeButtonAvailability = props.nextEpisodeButtonAvailability;
  var parent = props.parent;
  var teaserTitle = props.teaserTitle;
  var subtitle = props.subtitle;
  var images = props.images;
  var nextEpisodeButtonAvailability = __nextEpisodeButtonAvailability !== undefined ? __nextEpisodeButtonAvailability : "Available";
  var favoriteButtonAvailability = __favoriteButtonAvailability !== undefined ? __favoriteButtonAvailability : "Available";
  var nextEpisodeStatus = UseNextEpisode.make(props.programId);
  var match = Helper_HighlightTitleTag.make(props.primaryTitle, undefined);
  var match$1 = Context.I18n.getState();
  var watchTrailer = match$1.formaters.watchTrailer;
  var mountOnInit = video !== undefined && (video.TAG === "Clip" || video._1 === "Forced") ? Caml_option.some(forceMountOnDesktop) : undefined;
  var player = UsePlayer.make(video, mountOnInit, images, undefined);
  var trailerButton = UsePlayer.Trailer.Button.make({
        t: player,
        screenReadersLabel: Core__Option.map(teaserTitle, (function (title) {
                return watchTrailer(Type_String.Teaser.Title.toString(title));
              })),
        pageKind: props.pageKind
      });
  var trailerButton$1 = video !== undefined ? (
      video.TAG === "Clip" ? JsxRuntime.jsx(Atom_Cell.make, {
              display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "none", undefined, undefined, "flex")),
              children: trailerButton
            }) : trailerButton
    ) : null;
  var id = Type_String.Teaser.Id.toString(props.id);
  var match$2 = Atom_FavAlertButton.availabilityFromTeaserFavorite(teaser.favoriteStatus);
  var favoriteAvailability = favoriteButtonAvailability === "Available" && match$2 === "Available" ? "Available" : "NotAvailable";
  var favoriteButton = JsxRuntime.jsx(Atom_FavAlertButton.make, {
        teaser: teaser,
        styleVariation: "ContentCollection",
        availability: favoriteAvailability
      });
  var tmp;
  if (typeof nextEpisodeStatus !== "object" || nextEpisodeStatus.TAG !== "Completed") {
    tmp = null;
  } else {
    var nextEpisode = nextEpisodeStatus._0;
    tmp = nextEpisode !== undefined ? JsxRuntime.jsx(Atom_NextEpisodeButton.make, {
            nextEpisode: nextEpisode,
            availability: nextEpisodeButtonAvailability
          }) : null;
  }
  var description = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(Type_String.Teaser.Description.toString(props.description)),
                kind: "p",
                style: Atom_Text_Ds.makeContentCollection("Desc")
              }),
          JsxRuntime.jsxs(Atom_Row.make, {
                children: [
                  tmp,
                  trailerButton$1,
                  favoriteButton
                ],
                gap: Caml_option.some(Molecule_ContentCollection_Css.ContentButtons.gap)
              }),
          JsxRuntime.jsx(Molecule_Partners.make, {
                partners: props.partners,
                variant: Molecule_Partners.Molecule_Partners_Ds.rc
              })
        ]
      });
  var title = JsxRuntime.jsxs(Atom_Column.make, {
        children: [
          JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(Type_String.Teaser.Title.toString(Core__Option.getOr(teaserTitle, Type_String.Teaser.Title.empty))),
                id: id + "-title",
                kind: match[0],
                style: Atom_Text_Ds.makeContentCollection("Title")
              }),
          subtitle !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(Type_String.Teaser.Subtitle.toString(subtitle)),
                  id: id + "-subtitle",
                  kind: match[1],
                  style: Atom_Text_Ds.makeContentCollection("Subtitle")
                }) : null
        ]
      });
  var match$3 = parent !== undefined ? [
      JsxRuntime.jsx(Atom_Backlink.make, {
            href: parent.url,
            label: parent.label
          }),
      Particle_Screen_Constants.justifyContentSpaceBetween
    ] : [
      null,
      Particle_Screen_Constants.justifyContentFlexEnd
    ];
  var image = JsxRuntime.jsx(Atom_Cell.make, {
        width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
        children: JsxRuntime.jsx(Atom_Cell.make, {
              width: Caml_option.some(Molecule_ContentCollection_Css.$$Image.width),
              children: JsxRuntime.jsx(Atom_Image.make, {
                    alt: "",
                    animation: UsePlayer.Trailer.getBackgroundAnimation(player),
                    identifier: Caml_option.some(Identifiers.Player.imageElement),
                    rules: Caml_option.some(Molecule_ContentCollection_Css.$$Image.rules),
                    src: images,
                    style: "ContentCollection"
                  })
            }),
        position: Caml_option.some(imageContainerPosition),
        rules: Caml_option.some(Molecule_ContentCollection_Css.$$Image.containerRules)
      });
  var match$4 = UsePlayer.getPlayerElement(player);
  var match$5 = UsePlayer.getRef(player);
  var videoContainer = match$4 !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
          blockSpace: Caml_option.some(Molecule_ContentCollection_Css.Trailer.blockSpace),
          width: Caml_option.some(Molecule_ContentCollection_Css.Trailer.width),
          maxHeight: Caml_option.some(Particle_Screen_Constants.oneHundredPctMaxHeight),
          children: JsxRuntime.jsx(Atom_Cell.make, {
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                children: Caml_option.valFromOption(match$4),
                identifier: Caml_option.some(Identifiers.Player.videoContainer),
                animation: Caml_option.some(Particle_Animation_Ds.Trailer.On.element)
              }),
          position: Caml_option.some(videoContainerPosition),
          rules: Caml_option.some(Molecule_ContentCollection_Css.Trailer.rules),
          cbRef: (function (ref) {
              match$5(ref);
            })
        }) : null;
  var match$6 = Core__Option.flatMap(props.metas, (function (__x) {
          return Util_Array.NonEmpty.makeFromArray(__x);
        }));
  var match$7 = teaser.program.kind;
  var metas = match$6 !== undefined && match$7 === "TV_SERIES" ? JsxRuntime.jsx(Molecule_ContentCollection_Metas.make, {
          metas: Caml_option.valFromOption(match$6)
        }) : null;
  var content = JsxRuntime.jsxs(Atom_Column.make, {
        blockSpace: Caml_option.some(Molecule_ContentCollection_Css.blockSpace),
        children: [
          match$3[0],
          JsxRuntime.jsxs(Atom_Column.make, {
                children: [
                  JsxRuntime.jsx(Atom_Row.make, {
                        alignItems: Caml_option.some(Molecule_ContentCollection_Css.Content.titleAlignItems),
                        children: title,
                        innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
                        wrap: "OnOneLine"
                      }),
                  JsxRuntime.jsxs(Atom_Column.make, {
                        alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                        children: [
                          metas,
                          description
                        ],
                        display: Caml_option.some(Molecule_ContentCollection_Css.Content.descriptionDisplay),
                        innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                      })
                ],
                innerSpace: Caml_option.some(Molecule_ContentCollection_Css.Content.containerInnerSpace),
                justifyContent: Caml_option.some(Molecule_ContentCollection_Css.Content.containerJustifyContent),
                rules: Caml_option.some(Molecule_ContentCollection_Css.Content.containerRules)
              })
        ],
        justifyContent: Caml_option.some(match$3[1]),
        position: Caml_option.some(contentPosition),
        rules: Caml_option.some(Molecule_ContentCollection_Css.Content.rules),
        width: Caml_option.some(Molecule_ContentCollection_Css.Content.width)
      });
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: props.blockSpace,
              children: [
                JsxRuntime.jsxs(Atom_Cell.make, {
                      children: [
                        image,
                        content
                      ],
                      position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                      rules: Caml_option.some(Molecule_ContentCollection_Css.rules)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                      blockSpace: Caml_option.some(Molecule_ContentCollection_Css.blockSpace),
                      children: [
                        metas,
                        description
                      ],
                      display: Caml_option.some(Molecule_ContentCollection_Css.display),
                      innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace)
                    }),
                videoContainer
              ],
              id: id,
              identifier: props.identifier,
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              ref_: props.ref_,
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

Molecule_ContentCollection.displayName = "ContentCollection";

var make = Molecule_ContentCollection;

export {
  forceMountOnDesktop ,
  imageContainerPosition ,
  contentPosition ,
  videoContainerPosition ,
  make ,
}
/* forceMountOnDesktop Not a pure module */
