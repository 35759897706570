// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Set from "@rescript/std/lib/es6/belt_Set.js";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Belt_Result from "@rescript/std/lib/es6/belt_Result.js";
import * as Atom_Icon_Ds from "../design-system/atom/Atom_Icon_Ds.res.mjs";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";

var _map = {"external_":"EXTERNAL","preference":"preference","other":"other","internal":"internal"};

var _revMap = {"EXTERNAL":"external_","preference":"preference","other":"other","internal":"internal"};

function linkKindToJs(param) {
  return _map[param];
}

function linkKindFromJs(param) {
  return _revMap[param];
}

function geoblocking_encode(geoblocking) {
  if (geoblocking === "EUR_DE_FR") {
    return "EUR_DE_FR";
  } else if (geoblocking === "ALL") {
    return "ALL";
  } else if (geoblocking === "SAT") {
    return "SAT";
  } else {
    return "DE_FR";
  }
}

function geoblocking_decode(geoblocking) {
  var match = Core__JSON.Decode.string(geoblocking);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid geoblocking", geoblocking);
  }
  switch (match) {
    case "ALL" :
        return {
                TAG: "Ok",
                _0: "ALL"
              };
    case "DE_FR" :
        return {
                TAG: "Ok",
                _0: "DE_FR"
              };
    case "EUR_DE_FR" :
        return {
                TAG: "Ok",
                _0: "EUR_DE_FR"
              };
    case "SAT" :
        return {
                TAG: "Ok",
                _0: "SAT"
              };
    default:
      return Spice.error(undefined, "Not a valid geoblocking", geoblocking);
  }
}

var geoblockingOrdered = [
  "DE_FR",
  "EUR_DE_FR",
  "SAT",
  "ALL"
];

var languageSchema = S$RescriptSchema.union([
      S$RescriptSchema.literal("fr"),
      S$RescriptSchema.literal("de"),
      S$RescriptSchema.literal("pl"),
      S$RescriptSchema.literal("it"),
      S$RescriptSchema.literal("en"),
      S$RescriptSchema.literal("es")
    ]);

function language_encode(language) {
  if (language === "en") {
    return "en";
  } else if (language === "es") {
    return "es";
  } else if (language === "fr") {
    return "fr";
  } else if (language === "it") {
    return "it";
  } else if (language === "pl") {
    return "pl";
  } else {
    return "de";
  }
}

function language_decode(language) {
  var match = Core__JSON.Decode.string(language);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid language", language);
  }
  switch (match) {
    case "de" :
        return {
                TAG: "Ok",
                _0: "de"
              };
    case "en" :
        return {
                TAG: "Ok",
                _0: "en"
              };
    case "es" :
        return {
                TAG: "Ok",
                _0: "es"
              };
    case "fr" :
        return {
                TAG: "Ok",
                _0: "fr"
              };
    case "it" :
        return {
                TAG: "Ok",
                _0: "it"
              };
    case "pl" :
        return {
                TAG: "Ok",
                _0: "pl"
              };
    default:
      return Spice.error(undefined, "Not a valid language", language);
  }
}

var _map$1 = {"BONUS":"BONUS","EXTERNAL":"EXTERNAL","HIGHLIGHT":"HIGHLIGHT","INT":"INT","MAGAZINE":"MAGAZINE","MANUAL_CLIP":"MANUAL_CLIP","PARTNER":"PARTNER","PLAYLIST":"PLAYLIST","PLAYLIST_LIVE":"PLAYLIST_LIVE","SHOW":"SHOW","TOPIC":"TOPIC","TV_SERIES":"TV_SERIES","TRAILER":"TRAILER","VOD":"VOD","EVENT":"EVENT","CLIP":"CLIP","PRESSE":"PRESSE","ACTION":"ACTION"};

function programKindToJs(param) {
  return param;
}

function programKindFromJs(param) {
  return _map$1[param];
}

function programKind_encode(programKind) {
  if (programKind === "PLAYLIST_LIVE") {
    return "PLAYLIST_LIVE";
  } else if (programKind === "MAGAZINE") {
    return "MAGAZINE";
  } else if (programKind === "PRESSE") {
    return "PRESSE";
  } else if (programKind === "HIGHLIGHT") {
    return "HIGHLIGHT";
  } else if (programKind === "PARTNER") {
    return "PARTNER";
  } else if (programKind === "EVENT") {
    return "EVENT";
  } else if (programKind === "TV_SERIES") {
    return "TV_SERIES";
  } else if (programKind === "INT") {
    return "INT";
  } else if (programKind === "VOD") {
    return "VOD";
  } else if (programKind === "TRAILER") {
    return "TRAILER";
  } else if (programKind === "TOPIC") {
    return "TOPIC";
  } else if (programKind === "PLAYLIST") {
    return "PLAYLIST";
  } else if (programKind === "MANUAL_CLIP") {
    return "MANUAL_CLIP";
  } else if (programKind === "CLIP") {
    return "CLIP";
  } else if (programKind === "BONUS") {
    return "BONUS";
  } else if (programKind === "SHOW") {
    return "SHOW";
  } else if (programKind === "EXTERNAL") {
    return "EXTERNAL";
  } else {
    return "ACTION";
  }
}

function programKind_decode(programKind) {
  var match = Core__JSON.Decode.string(programKind);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid programKind", programKind);
  }
  switch (match) {
    case "ACTION" :
        return {
                TAG: "Ok",
                _0: "ACTION"
              };
    case "BONUS" :
        return {
                TAG: "Ok",
                _0: "BONUS"
              };
    case "CLIP" :
        return {
                TAG: "Ok",
                _0: "CLIP"
              };
    case "EVENT" :
        return {
                TAG: "Ok",
                _0: "EVENT"
              };
    case "EXTERNAL" :
        return {
                TAG: "Ok",
                _0: "EXTERNAL"
              };
    case "HIGHLIGHT" :
        return {
                TAG: "Ok",
                _0: "HIGHLIGHT"
              };
    case "INT" :
        return {
                TAG: "Ok",
                _0: "INT"
              };
    case "MAGAZINE" :
        return {
                TAG: "Ok",
                _0: "MAGAZINE"
              };
    case "MANUAL_CLIP" :
        return {
                TAG: "Ok",
                _0: "MANUAL_CLIP"
              };
    case "PARTNER" :
        return {
                TAG: "Ok",
                _0: "PARTNER"
              };
    case "PLAYLIST" :
        return {
                TAG: "Ok",
                _0: "PLAYLIST"
              };
    case "PLAYLIST_LIVE" :
        return {
                TAG: "Ok",
                _0: "PLAYLIST_LIVE"
              };
    case "PRESSE" :
        return {
                TAG: "Ok",
                _0: "PRESSE"
              };
    case "SHOW" :
        return {
                TAG: "Ok",
                _0: "SHOW"
              };
    case "TOPIC" :
        return {
                TAG: "Ok",
                _0: "TOPIC"
              };
    case "TRAILER" :
        return {
                TAG: "Ok",
                _0: "TRAILER"
              };
    case "TV_SERIES" :
        return {
                TAG: "Ok",
                _0: "TV_SERIES"
              };
    case "VOD" :
        return {
                TAG: "Ok",
                _0: "VOD"
              };
    default:
      return Spice.error(undefined, "Not a valid programKind", programKind);
  }
}

function sticker_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ]
                ]));
}

function sticker_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                code: code._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function duration_in_min_from_js_encode(v) {
  return Spice.intToJson(v);
}

function duration_in_min_from_js_decode(v) {
  return Belt_Result.map(Spice.intFromJson(v), (function (v) {
                return v;
              }));
}

function duration_in_sec_from_js_encode(v) {
  return Spice.intToJson(v);
}

function duration_in_sec_from_js_decode(v) {
  return Belt_Result.map(Spice.intFromJson(v), (function (v) {
                return v;
              }));
}

function duration_encode(v) {
  if (v.TAG === "Minute") {
    return [
            "Minute",
            Spice.intToJson(v._0)
          ];
  } else {
    return [
            "Second",
            Spice.intToJson(v._0)
          ];
  }
}

function duration_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Minute" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Spice.intFromJson(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Minute",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Second" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = Spice.intFromJson(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Second",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function jsMinDurationToDuration(min) {
  return {
          TAG: "Minute",
          _0: min
        };
}

function jsSecondDurationToDuration(min) {
  return {
          TAG: "Second",
          _0: min
        };
}

function stickers_encode(v) {
  return Spice.arrayToJson(sticker_encode, v);
}

function stickers_decode(v) {
  return Spice.arrayFromJson(sticker_decode, v);
}

function availabilityType_encode(availabilityType) {
  if (availabilityType === "VOD") {
    return "VOD";
  } else if (availabilityType === "LIVESTREAM_WEB") {
    return "LIVESTREAM_WEB";
  } else {
    return "LIVESTREAM_TV";
  }
}

function availabilityType_decode(availabilityType) {
  var match = Core__JSON.Decode.string(availabilityType);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid availabilityType", availabilityType);
  }
  switch (match) {
    case "LIVESTREAM_TV" :
        return {
                TAG: "Ok",
                _0: "LIVESTREAM_TV"
              };
    case "LIVESTREAM_WEB" :
        return {
                TAG: "Ok",
                _0: "LIVESTREAM_WEB"
              };
    case "VOD" :
        return {
                TAG: "Ok",
                _0: "VOD"
              };
    default:
      return Spice.error(undefined, "Not a valid availabilityType", availabilityType);
  }
}

function availability_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type_",
                    false,
                    availabilityType_encode(v.type)
                  ],
                  [
                    "label",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.label)
                  ],
                  [
                    "start",
                    false,
                    Spice.stringToJson(v.start)
                  ],
                  [
                    "upcomingDate",
                    false,
                    Spice.stringToJson(v.upcomingDate)
                  ],
                  [
                    "end",
                    false,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.end)
                  ]
                ]));
}

function availability_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = availabilityType_decode(Belt_Option.getWithDefault(Js_dict.get(v, "type_"), null));
  if (type_.TAG === "Ok") {
    var label = (function (extra) {
          return Spice.optionFromJson(Spice.stringFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      var start = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
      if (start.TAG === "Ok") {
        var upcomingDate = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "upcomingDate"), null));
        if (upcomingDate.TAG === "Ok") {
          var end = (function (extra) {
                return Spice.optionFromJson(Spice.stringFromJson, extra);
              })(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
          if (end.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      type: type_._0,
                      label: label._0,
                      start: start._0,
                      upcomingDate: upcomingDate._0,
                      end: end._0
                    }
                  };
          }
          var e = end._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".end" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = upcomingDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".upcomingDate" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = start._0;
      return {
              TAG: "Error",
              _0: {
                path: ".start" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: ".type_" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var DateFormater = {};

var _map$2 = {"AD":"AD","STM":"STM","VF":"VF","VO":"VO","ST":"ST","OV":"OV","UT":"UT","DE":"DE","OmU":"OmU","STE":"STE","ADU":"ADU","BW":"BW","DOL":"DOL","HD":"HD","WID":"WID","WRN":"WRN"};

function audioVersionCodeToJs(param) {
  return param;
}

function audioVersionCodeFromJs(param) {
  return _map$2[param];
}

function audioVersionCode_encode(audioVersionCode) {
  if (audioVersionCode === "BW") {
    return "BW";
  } else if (audioVersionCode === "DE") {
    return "DE";
  } else if (audioVersionCode === "HD") {
    return "HD";
  } else if (audioVersionCode === "OV") {
    return "OV";
  } else if (audioVersionCode === "ST") {
    return "ST";
  } else if (audioVersionCode === "UT") {
    return "UT";
  } else if (audioVersionCode === "VF") {
    return "VF";
  } else if (audioVersionCode === "VO") {
    return "VO";
  } else if (audioVersionCode === "ADU") {
    return "ADU";
  } else if (audioVersionCode === "DOL") {
    return "DOL";
  } else if (audioVersionCode === "OmU") {
    return "OmU";
  } else if (audioVersionCode === "STE") {
    return "STE";
  } else if (audioVersionCode === "STM") {
    return "STM";
  } else if (audioVersionCode === "WID") {
    return "WID";
  } else if (audioVersionCode === "WRN") {
    return "WRN";
  } else {
    return "AD";
  }
}

function audioVersionCode_decode(audioVersionCode) {
  var match = Core__JSON.Decode.string(audioVersionCode);
  if (match === undefined) {
    return Spice.error(undefined, "Not a valid audioVersionCode", audioVersionCode);
  }
  switch (match) {
    case "AD" :
        return {
                TAG: "Ok",
                _0: "AD"
              };
    case "ADU" :
        return {
                TAG: "Ok",
                _0: "ADU"
              };
    case "BW" :
        return {
                TAG: "Ok",
                _0: "BW"
              };
    case "DE" :
        return {
                TAG: "Ok",
                _0: "DE"
              };
    case "DOL" :
        return {
                TAG: "Ok",
                _0: "DOL"
              };
    case "HD" :
        return {
                TAG: "Ok",
                _0: "HD"
              };
    case "OV" :
        return {
                TAG: "Ok",
                _0: "OV"
              };
    case "OmU" :
        return {
                TAG: "Ok",
                _0: "OmU"
              };
    case "ST" :
        return {
                TAG: "Ok",
                _0: "ST"
              };
    case "STE" :
        return {
                TAG: "Ok",
                _0: "STE"
              };
    case "STM" :
        return {
                TAG: "Ok",
                _0: "STM"
              };
    case "UT" :
        return {
                TAG: "Ok",
                _0: "UT"
              };
    case "VF" :
        return {
                TAG: "Ok",
                _0: "VF"
              };
    case "VO" :
        return {
                TAG: "Ok",
                _0: "VO"
              };
    case "WID" :
        return {
                TAG: "Ok",
                _0: "WID"
              };
    case "WRN" :
        return {
                TAG: "Ok",
                _0: "WRN"
              };
    default:
      return Spice.error(undefined, "Not a valid audioVersionCode", audioVersionCode);
  }
}

function audioVersionCodeToIcon(audioVersion, color) {
  return Atom_Icon_Ds.updateColor(audioVersion === "BW" ? Atom_Icon_Ds.AudioVersion.bw : (
                audioVersion === "DE" ? Atom_Icon_Ds.AudioVersion.de : (
                    audioVersion === "HD" ? Atom_Icon_Ds.AudioVersion.hd : (
                        audioVersion === "OV" ? Atom_Icon_Ds.AudioVersion.ov : (
                            audioVersion === "ST" ? Atom_Icon_Ds.AudioVersion.st : (
                                audioVersion === "UT" ? Atom_Icon_Ds.AudioVersion.ut : (
                                    audioVersion === "VF" ? Atom_Icon_Ds.AudioVersion.vf : (
                                        audioVersion === "VO" ? Atom_Icon_Ds.AudioVersion.vo : (
                                            audioVersion === "ADU" ? Atom_Icon_Ds.AudioVersion.adu : (
                                                audioVersion === "DOL" ? Atom_Icon_Ds.AudioVersion.dol : (
                                                    audioVersion === "OmU" ? Atom_Icon_Ds.AudioVersion.omu : (
                                                        audioVersion === "STE" ? Atom_Icon_Ds.AudioVersion.ste : (
                                                            audioVersion === "STM" ? Atom_Icon_Ds.AudioVersion.stm : (
                                                                audioVersion === "WID" ? Atom_Icon_Ds.AudioVersion.wid : (
                                                                    audioVersion === "WRN" ? Atom_Icon_Ds.AudioVersion.wrn : Atom_Icon_Ds.AudioVersion.ad
                                                                  )
                                                              )
                                                          )
                                                      )
                                                  )
                                              )
                                          )
                                      )
                                  )
                              )
                          )
                      )
                  )
              ), color);
}

function audioVersion_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    audioVersionCode_encode(v.code)
                  ],
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ]
                ]));
}

function audioVersion_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = audioVersionCode_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                code: code._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function audioVersions_encode(v) {
  return Spice.arrayToJson(audioVersion_encode, v);
}

function audioVersions_decode(v) {
  return Spice.arrayFromJson(audioVersion_decode, v);
}

function cmp(a, b) {
  if (a !== b) {
    return 1;
  } else if (a === b) {
    return 0;
  } else {
    return -1;
  }
}

var RelCmp = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var add = Belt_Set.add;

function make(value) {
  return Belt_Set.add(Belt_Set.make(RelCmp), value);
}

function toString(t) {
  return Belt_Set.toArray(t).map(function (value) {
                return value;
              }).join(" ");
}

var Rel = {
  add: add,
  make: make,
  toString: toString
};

function guide_encode(v) {
  if (v === "Concert") {
    return ["Concert"];
  } else {
    return ["Tv"];
  }
}

function guide_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Concert" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Concert"
                  };
          }
      case "Tv" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Tv"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function trailerBehavior_encode(v) {
  if (v === "Forced") {
    return ["Forced"];
  } else {
    return ["OnButtonClick"];
  }
}

function trailerBehavior_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Forced" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Forced"
                  };
          }
      case "OnButtonClick" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "OnButtonClick"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function clipBehavior_encode(v) {
  if (v === "Standard") {
    return ["Standard"];
  } else {
    return ["Preview"];
  }
}

function clipBehavior_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Preview" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Preview"
                  };
          }
      case "Standard" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Standard"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function pageKind_encode(v) {
  if (typeof v === "object") {
    if (v.TAG === "Guide") {
      return [
              "Guide",
              guide_encode(v._0)
            ];
    } else {
      return [
              "Rc",
              trailerBehavior_encode(v._0)
            ];
    }
  }
  switch (v) {
    case "Concert" :
        return ["Concert"];
    case "Direct" :
        return ["Direct"];
    case "Event" :
        return ["Event"];
    case "Genre" :
        return ["Genre"];
    case "Home" :
        return ["Home"];
    case "Program" :
        return ["Program"];
    case "Search" :
        return ["Search"];
    case "SubCategory" :
        return ["SubCategory"];
    case "User" :
        return ["User"];
    
  }
}

function pageKind_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "Concert" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Concert"
                  };
          }
      case "Direct" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Direct"
                  };
          }
      case "Event" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Event"
                  };
          }
      case "Genre" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Genre"
                  };
          }
      case "Guide" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = guide_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Guide",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "Home" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Home"
                  };
          }
      case "Program" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Program"
                  };
          }
      case "Rc" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = trailerBehavior_decode(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Rc",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      case "Search" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Search"
                  };
          }
      case "SubCategory" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "SubCategory"
                  };
          }
      case "User" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "User"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

export {
  linkKindToJs ,
  linkKindFromJs ,
  geoblocking_encode ,
  geoblocking_decode ,
  geoblockingOrdered ,
  languageSchema ,
  language_encode ,
  language_decode ,
  programKindToJs ,
  programKindFromJs ,
  programKind_encode ,
  programKind_decode ,
  sticker_encode ,
  sticker_decode ,
  duration_in_min_from_js_encode ,
  duration_in_min_from_js_decode ,
  duration_in_sec_from_js_encode ,
  duration_in_sec_from_js_decode ,
  duration_encode ,
  duration_decode ,
  jsMinDurationToDuration ,
  jsSecondDurationToDuration ,
  stickers_encode ,
  stickers_decode ,
  availabilityType_encode ,
  availabilityType_decode ,
  availability_encode ,
  availability_decode ,
  DateFormater ,
  audioVersionCodeToJs ,
  audioVersionCodeFromJs ,
  audioVersionCode_encode ,
  audioVersionCode_decode ,
  audioVersionCodeToIcon ,
  audioVersion_encode ,
  audioVersion_decode ,
  audioVersions_encode ,
  audioVersions_decode ,
  Rel ,
  guide_encode ,
  guide_decode ,
  trailerBehavior_encode ,
  trailerBehavior_decode ,
  clipBehavior_encode ,
  clipBehavior_decode ,
  pageKind_encode ,
  pageKind_decode ,
}
/* languageSchema Not a pure module */
