// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Type_Common from "../type/Type_Common.res.mjs";
import * as Util_Nullable from "../util/Util_Nullable.res.mjs";
import * as $$Date from "@artegeie/date";
import * as S$RescriptSchema from "rescript-schema/src/S.res.mjs";
import * as Type_Translation from "../generated/Type_Translation.res.mjs";

var alternativeLanguageSchema = S$RescriptSchema.$$Object.factory(function (s) {
      return {
              code: s.f("code", Type_Common.languageSchema),
              url: s.f("url", S$RescriptSchema.string)
            };
    });

function defaultNextEpisode(param) {
  var season = param.season;
  var progress = param.progress;
  var match = progress > 15;
  var match$1 = progress < 95;
  var labelAction = match && match$1 ? "Continue watching" : "Watch";
  var labelSeason = season !== 0 ? " : season " + season.toString() : "";
  return labelAction + labelSeason + " (" + param.episode.toString() + "/" + param.totalEpisodes.toString() + ")";
}

function defaultFormaters_availabilityDate(param) {
  return "date: " + param.start.toLocaleDateString();
}

function defaultFormaters_betweenDates(param) {
  return "du " + param.start.toLocaleDateString() + " au " + param.end.toLocaleDateString();
}

function defaultFormaters_betweenDatesFormatted(param) {
  var end_ = param.end;
  var start = param.start;
  var today = new Date();
  if ($$Date.isAfter(start, today)) {
    return "Disponible à partir du " + start.toLocaleDateString();
  }
  var tenDaysFromNow = $$Date.addDays(today, 10);
  if (!$$Date.isBetween(today, tenDaysFromNow, end_)) {
    return "Disponible jusqu'au " + end_.toLocaleDateString();
  }
  var timeLeft = $$Date.differenceInCalendarDays(end_, today);
  if (timeLeft > 1) {
    return "Plus que " + timeLeft.toString() + " jours";
  } else {
    return "Dernier jour";
  }
}

function defaultFormaters_date(date) {
  return date.toLocaleDateString();
}

function defaultFormaters_dateWithFormat(date, format) {
  return $$Date.format(date, format);
}

function defaultFormaters_nextBroadcastDate(date) {
  return date.toLocaleDateString();
}

function defaultFormaters_untilDateFormated(endDate) {
  return "Disponible jusqu'au : " + endDate.toLocaleDateString();
}

function defaultFormaters_weekInterval(date) {
  return "Week of " + date.toDateString();
}

function defaultFormaters_addFavorite(title) {
  return "Add " + (title + " to my favorites");
}

function defaultFormaters_addReminder(title) {
  return "Add an alert for" + title;
}

function defaultFormaters_progress(progress) {
  return "progress: " + (progress.toString() + "%");
}

function defaultFormaters_removeFavorite(title) {
  return "Remove " + (title + " from my favorites");
}

function defaultFormaters_removeReminder(title) {
  return "Remove the alert for " + title;
}

function defaultFormaters_watchTrailer(title) {
  return "Watch " + (title + " trailer");
}

function defaultFormaters_infoSelectedDay(selectionDate, daysBefore, daysAfter) {
  return "Votre sélection actuelle : " + selectionDate + ". Vous pouvez consulter les programmes des " + daysBefore + " jours avant et des " + daysAfter + " jours après cette date.";
}

function defaultFormaters_formValidationStringMin(min) {
  return "Le champ doit comporter au moins " + min.toString() + " caractères";
}

function defaultFormaters_formValidationStringMax(max) {
  return "Le champ ne doit pas dépasser " + max.toString() + " caractères";
}

function defaultFormaters_forgottenPasswordSuccess(email) {
  return "Si un compte Arte est associé à " + email + ", vous recevrez un lien dans votre boîte de réception pour réinitialiser votre mot de passe. ";
}

function defaultFormaters_registerConfirmSuccess(email) {
  return "Nous avons envoyé un e-mail à votre adresse " + email + ".Veuillez vérifier votre boîte de réception et cliquer sur le lien de confirmation pour activer votre compte.";
}

function defaultFormaters_acceptTosLabel(acceptTosCguLabel, acceptTosCguLink, acceptTosDataLabel, acceptTosDataLink) {
  return "J'accepte les <a href=\"" + acceptTosCguLink + "\">" + acceptTosCguLabel + "</a> et les <a href=\"" + acceptTosDataLink + "\">" + acceptTosDataLabel + "</a>";
}

function defaultFormaters_checkFaqLabel(faqLabel, faqLink) {
  return "Avez-vous visité notre <a href=\"" + faqLink + "\">" + faqLabel + "</a> ? Les réponses aux questions les plus posées s'y trouvent";
}

function defaultFormaters_acceptDataLabel(acceptTosDataLabel, acceptTosDataLink) {
  return "En cliquant sur « Envoyer » vous consentez au traitement de vos données par ARTE et reconnaissez avoir pris connaissance des modalités de traitement définies dans <a target=\"_blank\" href=\"" + acceptTosDataLink + "\">" + acceptTosDataLabel + "</a>.";
}

function defaultFormaters_magicLink(email) {
  return "Si un compte Arte est associé à " + email + ", vous recevrez un lien dans votre boîte de réception pour vous connecter sans mot de passe. ";
}

function defaultFormaters_seasonCount(count) {
  return count.toString() + " " + (
          count > 1 || count === 0 ? "saisons" : "saison"
        );
}

var defaultFormaters = {
  availabilityDate: defaultFormaters_availabilityDate,
  betweenDates: defaultFormaters_betweenDates,
  betweenDatesFormatted: defaultFormaters_betweenDatesFormatted,
  date: defaultFormaters_date,
  dateWithFormat: defaultFormaters_dateWithFormat,
  nextBroadcastDate: defaultFormaters_nextBroadcastDate,
  untilDateFormated: defaultFormaters_untilDateFormated,
  weekInterval: defaultFormaters_weekInterval,
  addFavorite: defaultFormaters_addFavorite,
  addReminder: defaultFormaters_addReminder,
  nextEpisode: defaultNextEpisode,
  progress: defaultFormaters_progress,
  removeFavorite: defaultFormaters_removeFavorite,
  removeReminder: defaultFormaters_removeReminder,
  watchTrailer: defaultFormaters_watchTrailer,
  infoSelectedDay: defaultFormaters_infoSelectedDay,
  formValidationStringMin: defaultFormaters_formValidationStringMin,
  formValidationStringMax: defaultFormaters_formValidationStringMax,
  forgottenPasswordSuccess: defaultFormaters_forgottenPasswordSuccess,
  registerConfirmSuccess: defaultFormaters_registerConfirmSuccess,
  acceptTosLabel: defaultFormaters_acceptTosLabel,
  checkFaqLabel: defaultFormaters_checkFaqLabel,
  acceptDataLabel: defaultFormaters_acceptDataLabel,
  magicLink: defaultFormaters_magicLink,
  seasonCount: defaultFormaters_seasonCount
};

function ensureFormaters(formaters) {
  return {
          availabilityDate: Util_Nullable.getOrElse(defaultFormaters_availabilityDate, formaters.availabilityDate),
          betweenDates: Util_Nullable.getOrElse(defaultFormaters_betweenDates, formaters.betweenDates),
          betweenDatesFormatted: Util_Nullable.getOrElse(defaultFormaters_betweenDatesFormatted, formaters.betweenDatesFormatted),
          date: Util_Nullable.getOrElse(defaultFormaters_date, formaters.date),
          dateWithFormat: Util_Nullable.getOrElse(defaultFormaters_dateWithFormat, formaters.dateWithFormat),
          nextBroadcastDate: Util_Nullable.getOrElse(defaultFormaters_nextBroadcastDate, formaters.nextBroadcastDate),
          untilDateFormated: Util_Nullable.getOrElse(defaultFormaters_untilDateFormated, formaters.untilDateFormated),
          weekInterval: Util_Nullable.getOrElse(defaultFormaters_weekInterval, formaters.weekInterval),
          addFavorite: Util_Nullable.getOrElse(defaultFormaters_addFavorite, formaters.addFavorite),
          addReminder: Util_Nullable.getOrElse(defaultFormaters_addReminder, formaters.addReminder),
          nextEpisode: Util_Nullable.getOrElse(defaultNextEpisode, formaters.nextEpisode),
          progress: Util_Nullable.getOrElse(defaultFormaters_progress, formaters.progress),
          removeFavorite: Util_Nullable.getOrElse(defaultFormaters_removeFavorite, formaters.removeFavorite),
          removeReminder: Util_Nullable.getOrElse(defaultFormaters_removeReminder, formaters.removeReminder),
          watchTrailer: Util_Nullable.getOrElse(defaultFormaters_watchTrailer, formaters.watchTrailer),
          infoSelectedDay: Util_Nullable.getOrElse(defaultFormaters_infoSelectedDay, formaters.infoSelectedDay),
          formValidationStringMin: Util_Nullable.getOrElse(defaultFormaters_formValidationStringMin, formaters.formValidationStringMin),
          formValidationStringMax: Util_Nullable.getOrElse(defaultFormaters_formValidationStringMax, formaters.formValidationStringMax),
          forgottenPasswordSuccess: Util_Nullable.getOrElse(defaultFormaters_forgottenPasswordSuccess, formaters.forgottenPasswordSuccess),
          registerConfirmSuccess: Util_Nullable.getOrElse(defaultFormaters_registerConfirmSuccess, formaters.registerConfirmSuccess),
          acceptTosLabel: Util_Nullable.getOrElse(defaultFormaters_acceptTosLabel, formaters.acceptTosLabel),
          checkFaqLabel: Util_Nullable.getOrElse(defaultFormaters_checkFaqLabel, formaters.checkFaqLabel),
          acceptDataLabel: Util_Nullable.getOrElse(defaultFormaters_acceptDataLabel, formaters.acceptDataLabel),
          magicLink: Util_Nullable.getOrElse(defaultFormaters_magicLink, formaters.magicLink),
          seasonCount: Util_Nullable.getOrElse(defaultFormaters_seasonCount, formaters.seasonCount)
        };
}

var defaultAlternativeLanguages = [];

function ensureAlternativeLanguages(alternativeLanguages) {
  return Util_Nullable.getOrElse(defaultAlternativeLanguages, alternativeLanguages);
}

var initialState = {
  formaters: defaultFormaters,
  labels: Type_Translation.default,
  locale: "Fr",
  loginUrl: "https://my.arte.tv/index.php?lang=fr#section/landing",
  profileUrl: "https://www.arte.tv/fr/profile"
};

export {
  alternativeLanguageSchema ,
  defaultNextEpisode ,
  defaultFormaters ,
  ensureFormaters ,
  defaultAlternativeLanguages ,
  ensureAlternativeLanguages ,
  initialState ,
}
/* alternativeLanguageSchema Not a pure module */
