// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Type_String from "../../type/Type_String.res.mjs";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Type_String.User.Email.t_encode(v.email)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "acceptTOS",
                    false,
                    Spice.boolToJson(v.acceptTOS)
                  ],
                  [
                    "nlarte",
                    false,
                    Spice.boolToJson(v.nlarte)
                  ],
                  [
                    "acceptCom",
                    false,
                    Spice.boolToJson(v.acceptCom)
                  ],
                  [
                    "language",
                    false,
                    Spice.stringToJson(v.language)
                  ]
                ]));
}

var endpoint = {
  NAME: "Static",
  VAL: "register"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: undefined
        };
}

var options = {
  tokenKind: "Sso"
};

var PostConfig = {
  args_encode: args_encode,
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse,
  options: options
};

var Post = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var post = Post.call;

export {
  PostConfig ,
  Post ,
  post ,
}
/* Post Not a pure module */
