// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Type_Image from "../../../type/Type_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as Organism_Slider from "./Organism_Slider.res.mjs";
import * as Organism_Teaser from "../teaser/Organism_Teaser.res.mjs";
import * as Molecule_Download from "../../molecule/Molecule_Download.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Ds from "./Organism_Slider_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Teaser_Css from "../teaser/Organism_Teaser_Css.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function updateImageHeight(image, screenSize) {
  return Core__Option.map(Organism_Slider_Ds.getWidthAndHeight(image, screenSize), (function (param) {
                return param.height;
              }));
}

var heightComputation = {
  TAG: "Custom",
  _0: (function (__x) {
      return updateImageHeight("StandardTeaser", __x);
    })
};

function selectDownloadItems(__x) {
  return __x.querySelectorAll(Molecule_Download.downloadItemIdentifier);
}

function selectTeaserItems(__x) {
  return __x.querySelectorAll(Organism_Teaser_Css.Selectors.teaserItemIdentifier);
}

var itemDownloadSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var itemVideoGap = Particle_Screen_Ds.make(undefined, undefined, undefined, "Zero", undefined, "M");

var gapRule = CssJs.rowGap(CssJs.px(Particle_Css.spacerSizeInsideValue("M")));

var gridRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [
      CssJs.display("grid"),
      CssJs.justifyContent(CssJs.spaceBetween),
      gapRule,
      CssJs.gridTemplateColumns([{
              NAME: "repeat",
              VAL: [
                {
                  NAME: "num",
                  VAL: 4
                },
                CssJs.auto
              ]
            }])
    ], [CssJs.gridTemplateColumns([{
              NAME: "repeat",
              VAL: [
                {
                  NAME: "num",
                  VAL: 5
                },
                CssJs.auto
              ]
            }])], [CssJs.display("flex")]);

function make(param) {
  var items = param.items;
  var titleId = param.id + "_title";
  var sliderRef = React.useRef(null);
  var match = Context.I18n.getState();
  var match$1 = match.labels.common;
  var downloadAll = match$1.downloadAll;
  var tmp;
  tmp = items.TAG === "Download" ? JsxRuntime.jsx(Organism_Slider.make, {
          children: items._0.map(function (param, i) {
                return JsxRuntime.jsx(Molecule_Download.make, {
                            title: param.title,
                            subtitle: param.subtitle,
                            href: param.href,
                            imageSrc: Type_Image.Src.makeResolution(param.imageSrc),
                            id: param.id
                          }, i.toString());
              }),
          heightComputation: heightComputation,
          itemSpace: Caml_option.some(itemDownloadSpace),
          selectItems: selectDownloadItems,
          sliderRef: sliderRef,
          verticalPadding: "M"
        }) : JsxRuntime.jsx(Organism_Slider.make, {
          children: [JsxRuntime.jsx(Atom_Box.make, {
                  children: Caml_option.some(items._0.map(function (teaser) {
                            return JsxRuntime.jsx(Organism_Teaser.make, {
                                        teaser: teaser
                                      }, Type_String.Teaser.Key.toString(teaser.key));
                          })),
                  gap: Caml_option.some(itemVideoGap),
                  rules: Caml_option.some(gridRules),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                })],
          heightComputation: heightComputation,
          selectItems: selectTeaserItems,
          sliderRef: sliderRef,
          verticalPadding: "M",
          variant: Organism_Slider_Ds.default
        });
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Row.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexEnd),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(param.title),
                              id: titleId,
                              kind: "h2",
                              style: Atom_Text_Ds.Slider.make("Title")
                            }),
                        Core__Option.mapOr(param.href, null, (function (href) {
                                return JsxRuntime.jsx(Atom_Cta.Link.make, {
                                            href: href,
                                            label: downloadAll,
                                            style: Atom_Cta_Ds.Slider.downloadAll
                                          });
                              }))
                      ],
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    }),
                tmp
              ],
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

export {
  updateImageHeight ,
  heightComputation ,
  selectDownloadItems ,
  selectTeaserItems ,
  itemDownloadSpace ,
  itemVideoGap ,
  gapRule ,
  gridRules ,
  make ,
}
/* itemDownloadSpace Not a pure module */
