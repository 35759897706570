import { validateAuthType, validateConsent } from './utils';
import { env } from '@replay/env-generator';
import { fetchJson } from '@replay/fetch';
import type { ApiBody, EventData, RequiredData } from '@replay/types/Tracking';

type Headers = {
    'Content-Type': 'application/json';
};

const headers: Headers = {
    'Content-Type': 'application/json',
};

export const postEvent = async ({ body, url }: { body: ApiBody; url: string }) => {
    if (env.NODE_ENV === 'development') {
        console.info('====SST====', body);
    }
    const resp = await fetchJson<Record<string, unknown>>(url, {
        requestInit: {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        },
        withoutXCorrelationHeader: true,
    });

    return resp;
};

export const toApiBody = ({ data, event }: { data: RequiredData; event: EventData }): ApiBody => {
    const consentAudienceMeasurement = validateConsent(data.audienceMeasurementConsent);
    const consentTechnicalMeasurement = validateConsent(data.technicalMeasurementConsent);
    const convertedAuthType = validateAuthType(data.loginStatus);
    const teaser = event.clickTrackingUrl
        ? {
              teaser: {
                  clickTrackingUrl: `${env.NEXT_PUBLIC_REPLAY_BASE_URL.replace(/\/$/, '')}${event.clickTrackingUrl}`,
              },
          }
        : {};
    return {
        time: event.time,
        type: event.type,
        action: event.action,
        client: {
            id: data.trackingId,
            abv: data.abv,
            language: data.locale,
            app: {
                name: `Replay - ${env.NEXT_PUBLIC_SERVER_NAME}`,
                version: data.releaseInfos.release,
                build: data.releaseInfos.commit_id,
                deviceType: undefined, // TODO : get device type in "DESKTOP", "MOBILE", "TABLET", "TV"
                os: undefined, // TODO : get os in "WINDOWS", "MACOS", "LINUX", "IOS", "ANDROID", "OTHER"*
            },
            user: {
                id: data.userId,
                ageVerification: data.ageVerification,
                type: convertedAuthType,
            },
            consent: {
                audience: consentAudienceMeasurement,
                push: false, // mobile only
                technical: consentTechnicalMeasurement,
            },
        },
        frontendContext: event.frontendContext,
        apiContext: {
            emac: {
                ...event.emacApiContext,
                ...teaser,
            } as Record<string, never>,
            player: event.playerApiContext as Record<string, never> | undefined,
        },
        source: {
            referrer: event.referrer,
            utm_source: data.utm?.utm_source,
            utm_medium: data.utm?.utm_medium,
            utm_campaign: data.utm?.utm_campaign,
        },
    };
};
export const send = async (data: RequiredData, event: EventData) => {
    const body = toApiBody({ data, event });
    const resp = await postEvent({ body, url: env.NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL });
    return resp;
};
