'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../design-system/atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

function make(param) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var match$2 = labels.mona.verificationFailed;
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: match$2.title,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                    children: [
                      JsxRuntime.jsx(Atom_Column.make, {
                            children: JsxRuntime.jsx(Atom_Text.make, {
                                  children: Caml_option.some(match$2.subtitle),
                                  style: Atom_Text_Ds.User.text("Auth")
                                }),
                            innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                          }),
                      JsxRuntime.jsx(Atom_Row.make, {
                            children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                  label: match$2.buttonLabel,
                                  onClick: param.redirectToHome,
                                  variant: "base"
                                }),
                            gap: Caml_option.some(Layout_Auth.Form_Css.formBottomGap)
                          }),
                      JsxRuntime.jsx(Atom_Column.make, {
                            children: JsxRuntime.jsx(Atom_Text.make, {
                                  children: Caml_option.some(match$2.contactLabel),
                                  style: Atom_Text_Ds.User.text("Auth")
                                }),
                            innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                          }),
                      JsxRuntime.jsx(Atom_Row.make, {
                            children: JsxRuntime.jsx(Atom_Cta.User.Link.make, {
                                  label: match$2.contactButtonLabel,
                                  href: match$2.contactButtonLinkUrl,
                                  variant: "outline"
                                }),
                            gap: Caml_option.some(Layout_Auth.Form_Css.formBottomGap)
                          })
                    ],
                    innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
                    justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                    width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
                  })
            });
}

export {
  make ,
}
/* Context Not a pure module */
