// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../user/User.res.mjs";
import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as UseToggle from "../../hook/UseToggle.res.mjs";
import * as Atom_Emoji from "../atom/Atom_Emoji.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Atom_Switch from "../atom/Atom_Switch.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as User_Provider from "../../user/User_Provider.res.mjs";
import * as Atom_Switch_Ds from "../atom/Atom_Switch_Ds.res.mjs";
import * as Organism_Dropdown from "./dropdown/Organism_Dropdown.res.mjs";
import * as User_Api__Sso__Me from "../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as User_Api__Sso__AcceptCom from "../../user/User_Api/User_Api__Sso__AcceptCom.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var anonUserSwitchId = "setting-anonymous-user-switch";

var autoplaySwitchId = "setting-autoplay-switch";

var cookieCtaId = "setting-cookie-cta";

var playerAutoplaySwitchId = "setting-player-autoplay-switch";

var clipAutoplaySwitchId = "setting-clip-autoplay-switch";

var targetedCommunicationSwitchId = "setting-targeted-communication-switch";

var videoQualityId = "setting-video-quality";

var containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("L"));

var descriptionRowGap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var descriptionRowGapSmall = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS");

var descriptionWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(70), undefined, undefined, "auto");

var titleStyle = Atom_Text_Ds.Setting.itemTitle;

var descStyle = Atom_Text_Ds.Setting.itemDesc;

var messageStyle = Atom_Text_Ds.Setting.itemMessage;

var emojiBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeLeft("XXS"));

function Organism_Setting_Item$Internal$Switch(props) {
  var switchId = props.switchId;
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(switchId);
  var ariaDescribedby = Particle_Accessibility.AriaDescribedby.make(switchId);
  var titleId = Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-title");
  var descriptionId = Particle_Accessibility.AriaDescribedby.newId(ariaDescribedby, "-description");
  var accessibility = Particle_Accessibility.make(undefined, undefined, Caml_option.some(ariaDescribedby), undefined, undefined, undefined, undefined, Caml_option.some(ariaLabelledby), undefined, undefined, undefined, undefined, undefined, undefined);
  var match = Context.I18n.getState();
  var match$1 = match.labels.setting;
  var emoji = props.category !== undefined ? JsxRuntime.jsx(Atom_Emoji.make, {
          emoji: "Sobriety",
          textStyle: Atom_Text_Ds.Setting.emoji,
          ariaLabel: match$1.sobrietyMessageAriaLabel,
          blockSpace: Caml_option.some(emojiBlockSpace)
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Row.make, {
                      children: [
                        JsxRuntime.jsxs(Atom_Row.make, {
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(props.title),
                                      id: titleId,
                                      kind: "h2",
                                      style: Atom_Text_Ds.Setting.itemTitle
                                    }),
                                emoji
                              ]
                            }),
                        JsxRuntime.jsx(Atom_Switch.make, {
                              id: switchId,
                              onChange: props.onChange,
                              initialState: props.initialState,
                              style: Atom_Switch_Ds.makeSettingPage,
                              accessibility: accessibility,
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
                            })
                      ],
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween),
                      wrap: "OnOneLine"
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      width: Caml_option.some(descriptionWidth),
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(props.description + " "),
                            id: descriptionId,
                            style: descStyle
                          })
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
            });
}

var Switch = {
  make: Organism_Setting_Item$Internal$Switch
};

function Organism_Setting_Item$Internal$Cta(props) {
  return JsxRuntime.jsxs(Atom_Row.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(props.title),
                              kind: "h2",
                              style: titleStyle
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(props.description),
                              style: descStyle
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Cta.Button.make, {
                      id: props.ctaId,
                      label: props.ctaLabel,
                      onClick: props.onClick,
                      style: Atom_Cta_Ds.Setting.button
                    })
              ],
              gap: Caml_option.some(descriptionRowGap),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
            });
}

var Cta = {
  make: Organism_Setting_Item$Internal$Cta
};

var Internal = {
  Switch: Switch,
  Cta: Cta
};

function Organism_Setting_Item$Autoplay(props) {
  var initialState = UseToggle.toStatus(props.isPlayNextActivated);
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Switch, {
              title: props.title,
              description: props.description,
              onChange: props.onChange,
              initialState: initialState,
              switchId: autoplaySwitchId,
              category: "Sobriety"
            });
}

var Autoplay = {
  make: Organism_Setting_Item$Autoplay
};

function Organism_Setting_Item$CookieManagement(props) {
  var match = Context.Config.getState();
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Cta, {
              ctaId: cookieCtaId,
              title: props.title,
              description: props.description,
              ctaLabel: props.ctaLabel,
              onClick: match.api.showPrivacyCenter
            });
}

var CookieManagement = {
  make: Organism_Setting_Item$CookieManagement
};

function Organism_Setting_Item$Clip(props) {
  var initialState = UseToggle.toStatus(props.isClipAutoplayActivated);
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Switch, {
              title: props.title,
              description: props.description,
              onChange: props.onChange,
              initialState: initialState,
              switchId: clipAutoplaySwitchId,
              category: "Sobriety"
            });
}

var Clip = {
  make: Organism_Setting_Item$Clip
};

function Organism_Setting_Item$Player(props) {
  var initialState = UseToggle.toStatus(props.isPlayerAutoplayActivated);
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Switch, {
              title: props.title,
              description: props.description,
              onChange: props.onChange,
              initialState: initialState,
              switchId: playerAutoplaySwitchId,
              category: "Sobriety"
            });
}

var Player = {
  make: Organism_Setting_Item$Player
};

function toStatus(userConsentStatus) {
  if (userConsentStatus === "Enabled") {
    return "On";
  } else {
    return "Off";
  }
}

function Organism_Setting_Item$UserInterface(props) {
  var initialState = toStatus(props.userConsentStatus);
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Switch, {
              title: props.title,
              description: props.description,
              onChange: props.onChange,
              initialState: initialState,
              switchId: anonUserSwitchId
            });
}

var UserInterface = {
  toStatus: toStatus,
  make: Organism_Setting_Item$UserInterface
};

function Organism_Setting_Item$TargetedCommunication(props) {
  var status = User_Provider.Hook.useStatus();
  var env = User.Env.get();
  var match = Context.I18n.getState();
  var locale = match.locale;
  var match$1 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var setOn = match$1.setOn;
  var close = match$1.close;
  var controller = new AbortController();
  var signal = controller.signal;
  React.useEffect((function () {
          var controller = new AbortController();
          var signal = controller.signal;
          var call = async function () {
            var user = await User_Api__Sso__Me.Get.call({
                  signal: signal,
                  locale: locale
                });
            if (user.TAG === "Ok" && Type_Club.User.getAcceptCom(user._0)) {
              return setOn();
            } else {
              return close();
            }
          };
          call();
          return (function () {
                    controller.abort();
                  });
        }), [
        env,
        status
      ]);
  var onChange = function (value) {
    User_Api__Sso__AcceptCom.Put.call({
          args: {
            acceptCom: value
          },
          signal: signal,
          locale: locale
        });
    if (value) {
      return setOn();
    } else {
      return close();
    }
  };
  return JsxRuntime.jsx(Organism_Setting_Item$Internal$Switch, {
              title: props.title,
              description: props.description,
              onChange: onChange,
              initialState: match$1.state,
              switchId: targetedCommunicationSwitchId
            });
}

var TargetedCommunication = {
  make: Organism_Setting_Item$TargetedCommunication
};

function Organism_Setting_Item$SobrietyMessage(props) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                      children: props.title,
                      kind: "h2"
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Emoji.make, {
                              emoji: "Sobriety",
                              blockSpace: Caml_option.some(emojiBlockSpace)
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(props.message),
                              style: messageStyle
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: props.linkUrl,
                              label: props.linkLabel,
                              style: Atom_Cta_Ds.Setting.message
                            })
                      ],
                      display: Caml_option.some(Particle_Screen_Constants.displayInline),
                      gap: Caml_option.some(descriptionRowGapSmall),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart)
                    })
              ]
            });
}

var SobrietyMessage = {
  make: Organism_Setting_Item$SobrietyMessage
};

function stringToQuality(value) {
  switch (value) {
    case "savedata" :
        return "savedata";
    default:
      return "auto";
  }
}

var Quality = {
  auto: "auto",
  savedata: "savedata",
  stringToQuality: stringToQuality
};

function Organism_Setting_Item$VideoQuality(props) {
  var onChange = props.onChange;
  var match = Context.I18n.getState();
  var match$1 = match.labels.setting;
  var items = Util_Array.NonEmpty.make([{
          value: Type_String.Dropdown.Item.make("savedata"),
          label: Type_String.Dropdown.Item.make(match$1.videoQualityEco)
        }], {
        value: Type_String.Dropdown.Item.make("auto"),
        label: Type_String.Dropdown.Item.make(match$1.videoQualityAuto)
      });
  var onChange$1 = function (param) {
    onChange(stringToQuality(Type_String.Dropdown.Item.toString(param.value)));
  };
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(videoQualityId);
  var ariaDescribedby = Particle_Accessibility.AriaDescribedby.make(videoQualityId);
  var accessibility = Particle_Accessibility.make(undefined, undefined, Caml_option.some(ariaDescribedby), undefined, undefined, undefined, undefined, Caml_option.some(ariaLabelledby), undefined, undefined, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(containerBlockSpace),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsxs(Atom_Row.make, {
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(match$1.videoQualityTitle),
                                      kind: "h2",
                                      style: titleStyle
                                    }),
                                JsxRuntime.jsx(Atom_Emoji.make, {
                                      emoji: "Sobriety",
                                      textStyle: Atom_Text_Ds.Setting.emoji,
                                      ariaLabel: match$1.sobrietyMessageAriaLabel,
                                      blockSpace: Caml_option.some(emojiBlockSpace)
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.videoQualityDescription),
                              style: descStyle
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                JsxRuntime.jsx(Organism_Dropdown.make, {
                      kind: {
                        TAG: "Select",
                        _0: {
                          name: videoQualityId + "-select",
                          title: undefined,
                          autoComplete: undefined,
                          variant: "settings"
                        }
                      },
                      items: items,
                      value: props.value,
                      onChange: onChange$1,
                      accessibility: accessibility
                    })
              ],
              gap: Caml_option.some(descriptionRowGap),
              id: videoQualityId,
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
            });
}

var VideoQuality = {
  Quality: Quality,
  make: Organism_Setting_Item$VideoQuality
};

export {
  anonUserSwitchId ,
  autoplaySwitchId ,
  cookieCtaId ,
  playerAutoplaySwitchId ,
  clipAutoplaySwitchId ,
  targetedCommunicationSwitchId ,
  videoQualityId ,
  containerBlockSpace ,
  descriptionRowGap ,
  descriptionRowGapSmall ,
  descriptionWidth ,
  titleStyle ,
  descStyle ,
  messageStyle ,
  emojiBlockSpace ,
  Internal ,
  Autoplay ,
  CookieManagement ,
  Clip ,
  Player ,
  UserInterface ,
  TargetedCommunication ,
  SobrietyMessage ,
  VideoQuality ,
}
/* containerBlockSpace Not a pure module */
