// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

var endpoint = {
  NAME: "Static",
  VAL: "password/forgot"
};

var options = {
  tokenKind: "Sso"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: "EmailSent"
        };
}

var ForgotConfig = {
  args_encode: args_encode,
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  method: "Post",
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Forgot = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

function args_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "resettoken",
                    false,
                    Spice.stringToJson(v.resetToken)
                  ]
                ]));
}

var endpoint$1 = {
  NAME: "Static",
  VAL: "password/reset"
};

var options$1 = {
  tokenKind: "Sso"
};

function makeBody$1(args) {
  return Api.Utils.makeJSONBody(args, args_encode$1);
}

function decodeApiResponse$1(param) {
  return {
          TAG: "Ok",
          _0: "PasswordReset"
        };
}

var ResetConfig = {
  args_encode: args_encode$1,
  domain: "Sso",
  endpoint: endpoint$1,
  options: options$1,
  method: "Put",
  makeBody: makeBody$1,
  decodeApiResponse: decodeApiResponse$1
};

var Reset = Api.User.MakeMutation({
      domain: "Sso",
      method: "Put",
      endpoint: endpoint$1,
      makeBody: makeBody$1,
      decodeApiResponse: decodeApiResponse$1,
      options: options$1
    });

var forgot = Forgot.call;

var reset = Reset.call;

export {
  ForgotConfig ,
  Forgot ,
  forgot ,
  ResetConfig ,
  Reset ,
  reset ,
}
/* Forgot Not a pure module */
