import { Footer } from '@artegeie/design-system/replay';
import { type ReactNode } from 'react';

import { type LayoutProps } from '@replay/types/Layout';
import { Navbar } from './Navbar';
import { FeedbackButton } from './FeedbackButton';
import { TagCommanderScriptTag } from '@replay/tracking/tagCommander';

export const Layout = ({
    children,
    footerItems,
    alternativeLanguages,
    stats,
    parentUrl,
    locale,
    isStandalone: isStandalone,
}: LayoutProps): ReactNode => {
    // get stats and alternativeLanguages from emac or from a static source (see in the dedicated page component)
    const statsProp = stats;

    if (isStandalone) {
        return children;
    }
    return (
        <>
            {statsProp && <TagCommanderScriptTag stats={stats} />}
            <div className="container">
                <Navbar alternativeLanguages={alternativeLanguages} parentUrl={parentUrl} />
                {children}
                <Footer footerItems={footerItems} displayOverFooter="Show" />
                <FeedbackButton locale={locale} />
            </div>
        </>
    );
};
