// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../../provider/Context.res.mjs";
import * as UseRefs from "../../../../hook/UseRefs.res.mjs";
import * as SetFocus from "../../../../hook/SetFocus.res.mjs";
import * as Util_Css from "../../../../util/Util_Css.res.mjs";
import * as Util_Dom from "../../../../util/Util_Dom.res.mjs";
import * as Atom_Column from "../../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../../selector/Identifiers.res.mjs";
import * as Binding_Date from "../../../../binding/Binding_Date.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_GuideTv from "../../../../type/Type_GuideTv.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Organism_Slider from "../Organism_Slider.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Ds from "../Organism_Slider_Ds.res.mjs";
import * as UseScreenResolution from "../../../../hook/UseScreenResolution.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";
import * as Particle_Background_Ds from "../../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";
import * as Molecule_RedirectionAnchor from "../../../molecule/Molecule_RedirectionAnchor.res.mjs";
import * as Organism_Slider_GuideTvCalendar_Item from "./Organism_Slider_GuideTvCalendar_Item.res.mjs";

function selectItems(__x) {
  return __x.querySelectorAll(Util_Css.createIdentifierSelector([Identifiers.toString(Identifiers.GuideTV.calendarItem)]));
}

function onClickElement(__x) {
  Core__Option.forEach(__x, (function (el) {
          Core__Option.forEach(Webapi__Dom__Element.asHtmlElement(el), (function (prim) {
                  prim.click();
                }));
        }));
}

function autoSelectItem(date, gap, getRef) {
  return Core__Option.flatMap(getRef(Type_GuideTv.makeDateWithoutTime($$Date.addDays(date, gap))), Util_Dom.getDomRef);
}

function make(param) {
  var onItemClick = param.onItemClick;
  var items = param.items;
  var currentValue = param.currentValue;
  var currentAriaLabel = param.currentAriaLabel;
  var match = Context.I18n.getState();
  var dateWithFormat = match.formaters.dateWithFormat;
  var match$1 = match.labels.guide.tv.daySlider;
  var match$2 = UseRefs.use();
  var setRef = match$2[1];
  var getRef = match$2[0];
  var getRef$1 = function (date) {
    var dateStr = dateWithFormat(Type_GuideTv.makeDateWithoutTime(date), "dd_MM");
    return getRef(dateStr);
  };
  var setRef$1 = function (date) {
    var dateStr = dateWithFormat(Type_GuideTv.makeDateWithoutTime(date), "dd_MM");
    return setRef(dateStr);
  };
  var screenSize = UseScreenResolution.make({
        NAME: "L",
        VAL: 0
      }, undefined);
  var onDirectionChange = function (direction) {
    if (typeof screenSize === "object" && screenSize.NAME === "XS") {
      if (direction === "Next") {
        return onClickElement(autoSelectItem(currentValue, 1, getRef$1));
      } else {
        return onClickElement(autoSelectItem(currentValue, -1, getRef$1));
      }
    }
    
  };
  var currentGuideTvCalendarRef = React.useRef(null);
  var guideTvCalendarItems = React.useMemo((function () {
          return items.map(function (param) {
                      var value = param.value;
                      var match = Binding_Date.compareDatesByDay(value, currentValue);
                      var match$1;
                      switch (match) {
                        case "Same" :
                            match$1 = [
                              "Current",
                              currentAriaLabel,
                              undefined
                            ];
                            break;
                        case "After" :
                        case "Before" :
                            match$1 = [
                              "Default",
                              undefined,
                              onItemClick
                            ];
                            break;
                        
                      }
                      return JsxRuntime.jsx(Organism_Slider_GuideTvCalendar_Item.make, {
                                  ariaLabel: match$1[1],
                                  guideTvCalendarRef: setRef$1(value),
                                  onClick: match$1[2],
                                  status: match$1[0],
                                  dateLabel: param.dateLabel,
                                  dayLabel: param.dayLabel,
                                  value: value
                                }, value.toISOString());
                    });
        }), [
        items,
        currentGuideTvCalendarRef,
        currentValue,
        currentAriaLabel,
        onItemClick
      ]);
  var rightOnClick = function (param) {
    var ref = getRef$1(currentValue);
    var ref$1 = ref !== undefined ? ref : currentGuideTvCalendarRef;
    SetFocus.setFocus(Util_Dom.getDomRef(ref$1));
  };
  var rightAction_1 = {
    onClick: rightOnClick,
    label: match$1.redirectChooseDate
  };
  var rightAction = {
    NAME: "Button",
    VAL: rightAction_1
  };
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Molecule_RedirectionAnchor.make, {
                      left: param.leftAction,
                      right: rightAction
                    }),
                JsxRuntime.jsx(Organism_Slider.make, {
                      background: Caml_option.some(Particle_Background_Ds.background),
                      children: guideTvCalendarItems,
                      selectedItem: getRef$1(currentValue),
                      selectItems: selectItems,
                      sliderRef: currentGuideTvCalendarRef,
                      verticalPadding: "XXS",
                      variant: Organism_Slider_Ds.guide,
                      onDirectionChange: onDirectionChange
                    })
              ],
              position: Caml_option.some(Particle_Screen_Constants.positionRelative)
            });
}

export {
  selectItems ,
  onClickElement ,
  autoSelectItem ,
  make ,
}
/* react Not a pure module */
