// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Icon from "../atom/Atom_Icon.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Common from "../../type/Type_Common.res.mjs";
import * as Atom_Divider from "../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var gapValue = Particle_Css.spacerSizeInsideValue("M");

var metaRowRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.gap2(Particle_Css.spacerSizeInside("XXS"), CssJs.px(gapValue)),
      CssJs.overflow("hidden")
    ]);

var iconSize = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

var dividerWrapperRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.position(CssJs.relative)]);

var dividerRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.height(CssJs.px(Particle_Css.font_size_int("M"))),
      CssJs.position(CssJs.absolute),
      CssJs.right(CssJs.pct(100)),
      CssJs.top(CssJs.pct(50)),
      CssJs.transform(CssJs.translateY(CssJs.pct(-50))),
      CssJs.marginRight(CssJs.px(gapValue / 2 | 0))
    ]);

function withDivider(children, index) {
  return JsxRuntime.jsxs(Atom_Cell.make, {
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      children: JsxRuntime.jsx(Atom_Divider.make, {
                            style: Atom_Divider.contentCollectionMeta
                          }),
                      rules: Caml_option.some(dividerRules)
                    }),
                children
              ],
              rules: Caml_option.some(dividerWrapperRules)
            }, "item" + index.toString());
}

function Molecule_ContentCollection_Metas(props) {
  var metaItems = Util_Array.NonEmpty.catOption(Util_Array.NonEmpty.mapWithIndex(props.metas, (function (meta, index) {
              switch (meta.TAG) {
                case "String" :
                    return Caml_option.some(JsxRuntime.jsx(Atom_Text.make, {
                                    children: Caml_option.some(meta._0),
                                    style: Atom_Text_Ds.makeContentCollection("Desc")
                                  }, "meta" + String(index)));
                case "AudioVersions" :
                    return Caml_option.some(JsxRuntime.jsx(Atom_Row.make, {
                                    children: meta._0.map(function (param, index) {
                                          var label = param.label;
                                          return JsxRuntime.jsxs(Atom_Cell.make, {
                                                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                                      kind: "ItemList",
                                                      children: [
                                                        JsxRuntime.jsx(Atom_Icon.make, {
                                                              icon: Type_Common.audioVersionCodeToIcon(param.code, "Text"),
                                                              size: iconSize,
                                                              title: label
                                                            }),
                                                        JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
                                                              children: label
                                                            })
                                                      ]
                                                    }, label + String(index));
                                        }),
                                    display: Caml_option.some(Particle_Screen_Constants.displayInlineFlex),
                                    kind: "List"
                                  }, "meta" + String(index)));
                case "Genre" :
                case "MultiString" :
                    return ;
                
              }
            })));
  if (metaItems !== undefined) {
    return JsxRuntime.jsx(Atom_Row.make, {
                alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                children: Util_Array.NonEmpty.toReactElement(Util_Array.NonEmpty.mapWithIndex(Caml_option.valFromOption(metaItems), (function (item, index) {
                            return withDivider(item, index);
                          }))),
                rules: Caml_option.some(metaRowRules),
                wrap: "MultiLine"
              });
  } else {
    return null;
  }
}

var iconShirtSize = "M";

var make = Molecule_ContentCollection_Metas;

export {
  gapValue ,
  metaRowRules ,
  iconShirtSize ,
  iconSize ,
  dividerWrapperRules ,
  dividerRules ,
  withDivider ,
  make ,
}
/* gapValue Not a pure module */
