import { type Translate, type TranslateLabels } from '../types/translations';
import {
    type DateLike,
    type DateLocale,
    isToday,
    subSeconds,
    startOfWeek,
    addDays,
    sanitizeDate,
    isBetween,
    isAfter,
    differenceInCalendarDays,
} from '@artegeie/date';
import { formatInTimeZone } from 'date-fns-tz';
import { isAvailabilityType, type AvailabilityType } from '@replay/types/AvailabilityType';

const AVAILABILITY_TRANSLATION_KEYS = {
    VOD: 'replay_internal.formaters.teasers.availability.available_on',
    LIVESTREAM_TV: 'replay_internal.formaters.teasers.availability.live_on',
    LIVESTREAM_WEB: 'replay_internal.formaters.teasers.availability.livestream_on',
    BROADCAST: 'replay_internal.formaters.teasers.availability.broadcast_on',
} as const;
type AVAILABILITY_TRANSLATION_KEYS = typeof AVAILABILITY_TRANSLATION_KEYS;
type AvailabilityTranslationKeys = AVAILABILITY_TRANSLATION_KEYS[keyof AVAILABILITY_TRANSLATION_KEYS];

const addTodaySuffixWhenRelevant = (
    str: AvailabilityTranslationKeys,
    date: DateLike,
): `${AvailabilityTranslationKeys}_today` | AvailabilityTranslationKeys => `${str}${isToday(date) ? '_today' : ''}`;

const getAvailabilityTranslationKey = (type: AvailabilityType, date: DateLike) => {
    const str = AVAILABILITY_TRANSLATION_KEYS[type];
    return addTodaySuffixWhenRelevant(str, date);
};

export const translateAvailabilityDate = (translate: Translate, timeZone: string) => {
    const dateTranslator = createDateTranslator(translate, timeZone);
    return ({ start, type }: { start: DateLike; type?: AvailabilityType | undefined }): string => {
        if (isAvailabilityType(type)) {
            const translationKey = getAvailabilityTranslationKey(type, start);
            return dateTranslator(translationKey, start);
        }
        return '';
    };
};

const createDateTranslator =
    (translate: Translate, timeZone: string) =>
    (translationKey: TranslateLabels, date: DateLike): string => {
        return translate(translationKey, {
            date: formatInTimeZone(
                date,
                timeZone,
                translate('replay_internal.formaters.teasers.availability.formats.date'),
            ),
            hour: formatInTimeZone(
                date,
                timeZone,
                translate('replay_internal.formaters.teasers.availability.formats.hour'),
            ),
        });
    };

export const translateBetweenDate =
    (translate: Translate, timeZone: string) =>
    ({ start, end }: { start: DateLike; end: DateLike }): string => {
        const startDate = formatInTimeZone(start, timeZone, translate('replay_internal.formaters.program.date_format'));
        // Must subtract 5 hours plus 1 second from the end date to manage the case of tv contents finishing at 5h in the morning the next day
        const fiveHoursInSeconds = 18001;
        const endDate = subSeconds(end, fiveHoursInSeconds);
        const endDateFormat = formatInTimeZone(
            endDate,
            timeZone,
            translate('replay_internal.formaters.program.date_format'),
        );
        return translate('replay_internal.formaters.program.available_between', {
            startDate,
            endDate: endDateFormat,
        });
    };

export const translateBetweenDatesFormatted =
    (translate: Translate, timeZone: string) =>
    ({ start, end }: { start: DateLike; end: DateLike }): string => {
        const today = new Date();
        const tenDaysFromNow = addDays(today, 10);
        const startDate = sanitizeDate(start);
        const endDate = sanitizeDate(end);
        if (isAfter(startDate, today)) {
            return translate('replay_internal.formaters.program.formatted_notavailable', {
                date: formatInTimeZone(start, timeZone, translate('replay_internal.formaters.program.date_format')),
            });
        } else if (isBetween(today, tenDaysFromNow, endDate)) {
            const timeLeft = differenceInCalendarDays(endDate, today);
            return translate('replay_internal.formaters.program.formatted_daysremaining', { count: timeLeft });
        } else {
            return translate('replay_internal.formaters.program.formatted_until', {
                date: formatInTimeZone(end, timeZone, translate('replay_internal.formaters.program.date_format')),
            });
        }
    };

export const translateDate = (translate: Translate, timeZone: string) => (date: DateLike) =>
    formatInTimeZone(date, timeZone, translate('replay_internal.formaters.program.date_format'));

// We need to pass the date locale to be able to let date-fns translate textual date (like the day, month...)
export const translateNextBroadcastDate =
    (translate: Translate, dateLocale: DateLocale, timeZone: string) =>
    (date: DateLike): string => {
        return formatInTimeZone(date, timeZone, translate('replay_internal.formaters.program.broadcast_date_format'), {
            locale: dateLocale,
        });
    };

const WEEK_STARTS_ON_MONDAY = 1;
export const translateWeekInterval =
    (translate: Translate, dateLocale: DateLocale, timeZone: string) => (date: DateLike) => {
        const startOfWeekDate = startOfWeek(date, dateLocale as Locale, WEEK_STARTS_ON_MONDAY);
        const firstDayOfWeek = formatInTimeZone(
            startOfWeekDate,
            timeZone,
            translate('replay_internal.formaters.guide_concerts.weekInterval.firstDateFormat'),
            { locale: dateLocale },
        );
        const lastDayOfWeekDate = addDays(startOfWeekDate, 6);
        const lastDayOfWeek = formatInTimeZone(
            lastDayOfWeekDate,
            timeZone,
            translate('replay_internal.formaters.guide_concerts.weekInterval.lastDateFormat'),
            { locale: dateLocale },
        );
        return translate('replay_internal.formaters.guide_concerts.weekInterval.label', {
            firstDayOfWeek,
            lastDayOfWeek,
        });
    };

export const translateDateWithFormat =
    (dateLocale: DateLocale, timeZone: string) => (date: DateLike, formatPattern: string) =>
        formatInTimeZone(date, timeZone, formatPattern, { locale: dateLocale });

export const untilDateFormated =
    (translate: Translate, timeZone: string) =>
    (date: DateLike): string => {
        return translate('replay_internal.formaters.program.formatted_until', {
            date: formatInTimeZone(date, timeZone, translate('replay_internal.formaters.program.date_format')),
        });
    };
