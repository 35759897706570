// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

var endpoint = {
  NAME: "Static",
  VAL: "send-magic-link"
};

var options = {
  tokenKind: "Sso"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

function decodeApiResponse(param) {
  return {
          TAG: "Ok",
          _0: "EmailSent"
        };
}

var SendConfig = {
  args_encode: args_encode,
  endpoint: endpoint,
  domain: "Sso",
  options: options,
  method: "Post",
  makeBody: makeBody,
  decodeApiResponse: decodeApiResponse
};

var Send = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: decodeApiResponse,
      options: options
    });

var send = Send.call;

export {
  SendConfig ,
  Send ,
  send ,
}
/* Send Not a pure module */
