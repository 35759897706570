// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Logo from "../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as UsePlayer from "../../hook/UsePlayer.res.mjs";
import * as Atom_Image from "../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../type/Type_Teaser.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as Molecule_Sticker from "./Molecule_Sticker.res.mjs";
import * as Type_Progression from "../../type/Type_Progression.res.mjs";
import * as UseClickTracking from "../../hook/UseClickTracking.res.mjs";
import * as UseTrackingPixel from "../../hook/UseTrackingPixel.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_FavAlertButton from "../atom/Atom_FavAlertButton.res.mjs";
import * as Molecule_TopTeaser_Ds from "./Molecule_TopTeaser_Ds.res.mjs";
import * as Particle_Animation_Ds from "../particle/Particle_Animation_Ds.res.mjs";
import * as Molecule_TopTeaser_Css from "./Molecule_TopTeaser_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_Teaser_Hover_Play from "../organism/teaser/Organism_Teaser_Hover_Play.res.mjs";

var forceMountOnDesktop = Particle_Screen_Ds.make(undefined, undefined, true, undefined, undefined, false);

var imageContainerPosition = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: CssJs.px(0)
      }
    }, undefined, undefined, "Relative");

function Molecule_TopTeaser$Image(props) {
  return JsxRuntime.jsx(Atom_Row.make, {
              children: JsxRuntime.jsx(Atom_Cell.make, {
                    width: Caml_option.some(Molecule_TopTeaser_Css.$$Image.width),
                    height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                    children: JsxRuntime.jsx(Atom_Image.make, {
                          alt: Core__Option.getOr(props.alt, ""),
                          animation: props.animation,
                          identifier: Caml_option.some(Identifiers.TopTeaser.imageElement),
                          src: props.srcs,
                          style: "TopTeaser"
                        }),
                    rules: Caml_option.some(Molecule_TopTeaser_Css.$$Image.rulesAspectRatio)
                  }),
              justifyContent: Caml_option.some(Molecule_TopTeaser_Css.$$Image.justifyContent),
              maxHeight: Caml_option.some(Particle_Screen_Constants.oneHundredPctMaxHeight),
              position: Caml_option.some(imageContainerPosition),
              rules: Caml_option.some(Molecule_TopTeaser_Css.$$Image.Container.rules),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var $$Image = {
  imageContainerPosition: imageContainerPosition,
  make: Molecule_TopTeaser$Image
};

function make(param) {
  var trailerUrl = param.trailerUrl;
  var teaser = param.teaser;
  var pageKind = param.pageKind;
  var style = Core__Option.getOr(param.style, Molecule_TopTeaser_Ds.makeDefault);
  var match = Context.I18n.getState();
  var labels = match.labels;
  var minutesLabel = labels.teaser.durationUnit;
  var trailerButton = labels.program.trailer_button;
  var title = teaser.title;
  var subtitle = teaser.subtitle;
  var kind = teaser.kind;
  var images = teaser.imageSrc;
  var duration = teaser.duration;
  var description = teaser.description;
  var watchTrailer = match.formaters.watchTrailer;
  var match$1 = Molecule_TopTeaser_Ds.view(style);
  var clickTracking = UseClickTracking.use();
  var idString = Type_String.Teaser.Id.toString(teaser.id);
  var progression = User_Observable.Progression.Hook.use(teaser.dsId);
  var progressionPct = Type_Progression.getPct(progression.timeProgression);
  var teaserKind = React.useMemo((function () {
          var match = Type_Teaser.Kind.view(kind);
          if (typeof match === "object") {
            if (progressionPct !== undefined) {
              return {
                      NAME: "Progress",
                      VAL: progressionPct
                    };
            } else {
              return "Standard";
            }
          } else if (match === "PrimeTime") {
            return "PrimeTime";
          } else if (match === "Direct") {
            return "Direct";
          } else if (match === "Live") {
            return "Live";
          } else if (progressionPct !== undefined) {
            return {
                    NAME: "Progress",
                    VAL: progressionPct
                  };
          } else {
            return "Standard";
          }
        }), [
        kind,
        progressionPct
      ]);
  var match$2 = labels.label;
  var trackingPixel = UseTrackingPixel.make(Core__Option.flatMap(teaser.trackingPixel, Type_String.TrackingPixel.toTypeUrl));
  var onClick = function (param) {
    if (typeof pageKind === "object" && pageKind.TAG === "Rc") {
      clickTracking({
            eventGroup: "collection_actions",
            eventName: "WATCH_CONTENT"
          });
    }
    trackingPixel();
  };
  var trailerScreenReadersLabel;
  if (title !== undefined) {
    switch (title.TAG) {
      case "AccessibilityOnly" :
          trailerScreenReadersLabel = title._0;
          break;
      case "Displayed" :
      case "HoverOnly" :
          trailerScreenReadersLabel = undefined;
          break;
      
    }
  } else {
    trailerScreenReadersLabel = undefined;
  }
  var alt = Core__Option.map(trailerScreenReadersLabel, Type_String.Teaser.Title.toString);
  var favoriteAvailability = Atom_FavAlertButton.availabilityFromTeaserFavorite(teaser.favoriteStatus);
  var favoriteButton = JsxRuntime.jsx(Atom_FavAlertButton.make, {
        teaser: teaser,
        styleVariation: "TopTeaser",
        availability: favoriteAvailability
      });
  var ariaLabelledby = Particle_Accessibility.AriaLabelledby.make(idString);
  var trailerButton$1 = trailerUrl !== undefined ? JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: Type_String.Href.toString(trailerUrl),
          label: trailerButton,
          onClick: (function (param) {
              clickTracking({
                    eventGroup: "collection_actions",
                    eventName: "WATCH_TRAILER"
                  });
            }),
          screenReadersLabel: Core__Option.map(trailerScreenReadersLabel, (function (title) {
                  return watchTrailer(Type_String.Teaser.Title.toString(title));
                })),
          style: Atom_Cta_Ds.Trailer.enabledTopTeaser
        }) : null;
  var buttons = JsxRuntime.jsxs(Atom_Row.make, {
        alignItems: Caml_option.some(Molecule_TopTeaser_Css.alignItems),
        children: [
          JsxRuntime.jsx(Organism_Teaser_Hover_Play.make, {
                identifier: Caml_option.some(Identifiers.Teaser.hoverPlayButton),
                onClick: onClick,
                teaser: teaser,
                parentComponent: "TopTeaser"
              }),
          trailerButton$1,
          favoriteButton
        ],
        gap: Caml_option.some(Molecule_TopTeaser_Css.ButtonsChildrenRow.gap),
        justifyContent: Caml_option.some(Molecule_TopTeaser_Css.justifyContent)
      });
  var logo;
  logo = typeof pageKind !== "object" && pageKind === "Concert" ? null : JsxRuntime.jsx(Atom_Logo.make, {
          logo: {
            NAME: "WithWrapper",
            VAL: [
              {
                NAME: "ArteVerticalLogo",
                VAL: "HighlightTitle"
              },
              "TopTeaser"
            ]
          }
        });
  var titleComponent;
  if (title !== undefined) {
    switch (title.TAG) {
      case "Displayed" :
          titleComponent = JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(Type_String.Teaser.Title.toString(title._0)),
                id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-title"),
                kind: "h2",
                style: Atom_Text_Ds.makeTopTeaser(undefined, "Title")
              });
          break;
      case "AccessibilityOnly" :
      case "HoverOnly" :
          titleComponent = null;
          break;
      
    }
  } else {
    titleComponent = null;
  }
  var subtitle$1;
  var exit = 0;
  var subtitle$2;
  if (subtitle !== undefined) {
    if (subtitle.TAG === "Displayed") {
      subtitle$2 = subtitle.label;
      exit = 1;
    } else {
      subtitle$2 = subtitle._0;
      exit = 1;
    }
  } else {
    subtitle$1 = null;
  }
  if (exit === 1) {
    subtitle$1 = JsxRuntime.jsx(Atom_Text.make, {
          blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Content.subtitleSpacer),
          children: Caml_option.some(Type_String.Teaser.Subtitle.toString(subtitle$2)),
          id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-subtitle"),
          kind: "h3",
          style: Atom_Text_Ds.makeTopTeaser(undefined, "Subtitle")
        });
  }
  var description$1 = description !== undefined ? JsxRuntime.jsx(Atom_Row.make, {
          children: JsxRuntime.jsx(Atom_Text.make, {
                children: Caml_option.some(Type_String.Teaser.Description.toString(description)),
                kind: "p",
                style: Atom_Text_Ds.makeTopTeaser(undefined, "Desc"),
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
              }),
          display: Caml_option.some(Molecule_TopTeaser_Css.Description.display)
        }) : null;
  var sticker;
  if (teaserKind === "Live") {
    sticker = JsxRuntime.jsx(Atom_Column.make, {
          blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Sticker.liveBlockSpace),
          children: JsxRuntime.jsx(Molecule_Sticker.make, {
                text: match$2.live,
                style: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.direct("Concert")),
                position: "Inherit",
                id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-live"),
                identifier: Caml_option.some(Identifiers.Sticker.direct)
              }),
          display: Caml_option.some(Molecule_TopTeaser_Css.Sticker.display)
        });
  } else if (duration !== undefined) {
    var durationLabel = Type_Teaser.Helper.getDurationLabel(minutesLabel, duration);
    sticker = JsxRuntime.jsx(Atom_Column.make, {
          blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Sticker.blockSpace),
          children: JsxRuntime.jsx(Molecule_Sticker.make, {
                text: durationLabel,
                style: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.topTeaser),
                position: "Inherit",
                id: Particle_Accessibility.AriaLabelledby.newId(ariaLabelledby, "-duration")
              }),
          display: Caml_option.some(Molecule_TopTeaser_Css.Sticker.display)
        });
  } else {
    sticker = null;
  }
  var videoContainerPosition = Particle_Screen_Ds.make(undefined, undefined, {
        TAG: "Absolute",
        _0: {
          right: CssJs.px(0)
        }
      }, {
        TAG: "Absolute",
        _0: {
          top: CssJs.px(0)
        }
      }, undefined, "Relative");
  var video = Belt_Option.map(param.clip, (function (clip) {
          return {
                  TAG: "Clip",
                  _0: clip,
                  _1: "Preview"
                };
        }));
  var player = UsePlayer.make(video, Caml_option.some(forceMountOnDesktop), images, undefined);
  var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeNone());
  var width = Molecule_TopTeaser_Css.Clip.width;
  var animation = Particle_Animation_Ds.Clip.On.element;
  var match$3 = UsePlayer.getPlayerElement(player);
  var match$4 = UsePlayer.getRef(player);
  var videoContainer = match$3 !== undefined ? JsxRuntime.jsx(Atom_Cell.make, {
          blockSpace: Caml_option.some(blockSpace),
          width: Caml_option.some(width),
          children: JsxRuntime.jsx(Atom_Cell.make, {
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                children: Caml_option.valFromOption(match$3),
                position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                identifier: Caml_option.some(Identifiers.Player.videoContainer),
                rules: Caml_option.some(Molecule_TopTeaser_Css.Clip.gradientRules),
                animation: Caml_option.some(animation)
              }),
          position: Caml_option.some(videoContainerPosition),
          rules: Caml_option.some(Molecule_TopTeaser_Css.Clip.rules),
          cbRef: (function (a) {
              match$4(a);
            })
        }) : null;
  var imageAnimation = videoContainer === null ? undefined : Caml_option.some(Particle_Animation_Ds.TopTeaser.image);
  return JsxRuntime.jsxs(Atom_Row.make, {
              blockSpace: match$1.blockSpace,
              children: [
                JsxRuntime.jsx(Molecule_TopTeaser$Image, {
                      srcs: images,
                      alt: alt,
                      animation: imageAnimation
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      justifyContent: Caml_option.some(Molecule_TopTeaser_Css.Content.justifyContent),
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      blockSpace: Caml_option.some(Molecule_TopTeaser_Css.Content.blockSpace),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                      maxWidth: Caml_option.some(Molecule_TopTeaser_Css.Content.maxWidth),
                      height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                      minHeight: match$1.contentMinHeight,
                      children: JsxRuntime.jsxs(Atom_Row.make, {
                            children: [
                              logo,
                              JsxRuntime.jsxs(Atom_Column.make, {
                                    animation: param.elementsAnimation,
                                    children: [
                                      titleComponent,
                                      subtitle$1,
                                      sticker,
                                      JsxRuntime.jsxs(Atom_Column.make, {
                                            alignItems: Caml_option.some(Molecule_TopTeaser_Css.alignItems),
                                            blockSpace: Caml_option.some(Molecule_TopTeaser_Css.DescriptionButtons.blockSpace),
                                            children: [
                                              description$1,
                                              buttons
                                            ],
                                            innerSpace: Caml_option.some(Molecule_TopTeaser_Css.ButtonsChildrenRow.innerSpace)
                                          })
                                    ]
                                  })
                            ],
                            wrap: "OnOneLine"
                          }),
                      rules: Caml_option.some(Molecule_TopTeaser_Css.Content.rules)
                    }),
                videoContainer
              ],
              height: Caml_option.some(match$1.height),
              id: idString,
              justifyContent: Caml_option.some(Molecule_TopTeaser_Css.Container.justifyContent),
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            }, idString);
}

var playButtonId = "next_main";

export {
  playButtonId ,
  forceMountOnDesktop ,
  $$Image ,
  make ,
}
/* forceMountOnDesktop Not a pure module */
