// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as $$Storage from "../Storage.res.mjs";
import * as User_Type from "./User_Type.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Util_Result from "../util/Util_Result.res.mjs";
import * as $$Date from "@artegeie/date";

function userToken_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var token = User_Type.Token.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
  if (token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              token: token._0
            }
          };
  }
  var e = token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function toString(t) {
  return JSON.stringify(User_Type.t_encode(t));
}

function fromString(str) {
  var json = JSON.parse(str);
  var user = User_Type.t_decode(json);
  if (user.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: user._0
          };
  } else {
    var user$1 = userToken_decode(json);
    if (user$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: User_Type.make(undefined, undefined, undefined, $$Date.subWeeks(new Date(), 10), undefined, user$1._0.token)
            };
    } else {
      return {
              TAG: "Ok",
              _0: User_Type.disconnected
            };
    }
  }
}

var Model_version = "v6";

var Model = {
  key: "user",
  version: Model_version,
  defaultValue: User_Type.disconnected,
  fromString: fromString,
  toString: toString
};

var Main = $$Storage.Make(Model, $$Storage.LocalStorage);

var get = Main.get;

function getProgression(dsId) {
  var user = Util_Result.toOption(get());
  return User_Type.getTimeProgressionById(dsId, user);
}

function Internal(funarg) {
  $$Storage.Make(Model, funarg);
  return {};
}

var save = Main.save;

export {
  Internal ,
  getProgression ,
  get ,
  save ,
}
/* Main Not a pure module */
