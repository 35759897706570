// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Toaster from "../../../toast/Toaster.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Icon_Ds from "../../atom/Atom_Icon_Ds.res.mjs";
import * as Molecule_Message from "../../molecule/Molecule_Message.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Type_User_Navigation from "../../../type/Type_User_Navigation.res.mjs";

var id = "welcome";

function Organism_Header_Subheaders$Welcome(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.common;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.subHeader,
              closeLabel: match$1.closeSubheader,
              id: id,
              storage: "Stored",
              icon: Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.megaphone, "Secondary")),
              onClose: props.onClose
            });
}

var Welcome = {
  id: id,
  storage: "Stored",
  make: Organism_Header_Subheaders$Welcome
};

var id$1 = "fsk";

function Organism_Header_Subheaders$Fsk(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.common;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.fskSubHeader,
              closeLabel: match$1.closeSubheader,
              id: id$1,
              storage: "Stored",
              linkLabel: match$2.fskSubHeaderLink,
              linkUrl: Type_User_Navigation.Profile.makeHref(match.locale, "profile"),
              linkPosition: "End",
              icon: Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.megaphone, "Secondary")),
              onClose: props.onClose
            });
}

var Fsk = {
  id: id$1,
  storage: "Stored",
  make: Organism_Header_Subheaders$Fsk
};

var id$2 = "accountcreated";

function Organism_Header_Subheaders$AccountCreated(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.mona.subHeader;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.accountCreatedText,
              closeLabel: match$1.closeSubheader,
              id: id$2,
              storage: "NotStored",
              linkLabel: match$2.accountCreatedLinkLabel,
              linkUrl: match$2.accountCreatedLinkUrl,
              linkPosition: "End",
              onClose: props.onClose
            });
}

var AccountCreated = {
  id: id$2,
  storage: "NotStored",
  make: Organism_Header_Subheaders$AccountCreated
};

var id$3 = "accountloggedin";

function Organism_Header_Subheaders$AccountLoggedIn(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.mona.subHeader;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.accountLoggedInText,
              closeLabel: match$1.closeSubheader,
              id: id$3,
              storage: "NotStored",
              linkLabel: match$2.accountCreatedLinkLabel,
              linkUrl: match$2.accountCreatedLinkUrl,
              linkPosition: "End",
              onClose: props.onClose
            });
}

var AccountLoggedIn = {
  id: id$3,
  storage: "NotStored",
  make: Organism_Header_Subheaders$AccountLoggedIn
};

var id$4 = "expiredmagiclink";

function Organism_Header_Subheaders$ExpiredMagicLink(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.mona.subHeader;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.expiredMagicLinkText,
              closeLabel: match$1.closeSubheader,
              id: id$4,
              storage: "NotStored",
              onClose: props.onClose
            });
}

var ExpiredMagicLink = {
  id: id$4,
  storage: "NotStored",
  make: Organism_Header_Subheaders$ExpiredMagicLink
};

var id$5 = "updatedpasswordsuccess";

function Organism_Header_Subheaders$UpdatedPasswordSuccess(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.mona.subHeader;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.updatedPasswordSuccessText,
              closeLabel: match$1.closeSubheader,
              id: id$5,
              storage: "NotStored",
              icon: Caml_option.some(Atom_Icon_Ds.updateColor(Atom_Icon_Ds.circleCheck, "Success")),
              onClose: props.onClose
            });
}

var UpdatedPasswordSuccess = {
  id: id$5,
  storage: "NotStored",
  make: Organism_Header_Subheaders$UpdatedPasswordSuccess
};

var id$6 = "geoblocked";

function Organism_Header_Subheaders$Geoblocked(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.program;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeader",
              text: match$2.geoblockedLabel,
              closeLabel: match$1.closeSubheader,
              id: id$6,
              storage: "NotStored",
              linkLabel: match$2.geoblockedLinkLabel,
              linkUrl: match$2.geoblockedLinkUrl,
              linkPosition: "End"
            });
}

var Geoblocked = {
  id: id$6,
  storage: "NotStored",
  make: Organism_Header_Subheaders$Geoblocked
};

function Organism_Header_Subheaders$Saf11Warning(props) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.accessibility;
  var match$2 = labels.common;
  return JsxRuntime.jsx(Molecule_Message.make, {
              variant: "subHeaderError",
              text: match$2.safariVersionDeprecated,
              closeLabel: match$1.closeSubheader,
              storage: "NotStored",
              onClose: props.onClose
            });
}

var Saf11Warning = {
  storage: "NotStored",
  make: Organism_Header_Subheaders$Saf11Warning
};

function get(id, onClose) {
  if (id === "Saf11Warning") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$Saf11Warning, {
                onClose: onClose
              }, "safari11warning");
  } else if (id === "Welcome") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$Welcome, {
                onClose: onClose
              }, "welcome");
  } else if (id === "AccountLoggedIn") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$AccountLoggedIn, {
                onClose: onClose
              }, "accountloggedin");
  } else if (id === "Fsk") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$Fsk, {
                onClose: onClose
              }, "fsk");
  } else if (id === "AccountCreated") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$AccountCreated, {
                onClose: onClose
              }, "accountcreated");
  } else if (id === "ExpiredMagicLink") {
    return JsxRuntime.jsx(Organism_Header_Subheaders$ExpiredMagicLink, {
                onClose: onClose
              }, "expiredmagiclink");
  } else {
    return JsxRuntime.jsx(Organism_Header_Subheaders$UpdatedPasswordSuccess, {
                onClose: onClose
              }, "updatedpasswordsuccess");
  }
}

function make(param) {
  var remove = param.remove;
  var value = param.value;
  var onClose = function () {
    var onClose$1 = value.onClose;
    if (onClose$1 !== undefined) {
      onClose$1(value.id);
    }
    remove();
  };
  return get(value.id, onClose);
}

var Toast = {
  make: make
};

var Base = {
  Toast: Toast
};

var Toaster$1 = Toaster.Make(Base);

function use(consentStatus, displayedSubHeaders, requiredConsentSubHeaders, onCloseSubHeader) {
  var match = Toaster$1.Hook.use();
  var add = match.add;
  React.useEffect((function () {
          displayedSubHeaders.map(function (id) {
                return add(undefined, {
                            id: id,
                            onClose: onCloseSubHeader
                          });
              });
        }), [displayedSubHeaders]);
  React.useEffect((function () {
          if (consentStatus === "unset" || consentStatus === "unknown") {
            
          } else {
            requiredConsentSubHeaders.map(function (id) {
                  return add(undefined, {
                              id: id,
                              onClose: onCloseSubHeader
                            });
                });
          }
        }), [
        consentStatus,
        requiredConsentSubHeaders
      ]);
}

var Hook = {
  use: use
};

export {
  Welcome ,
  Fsk ,
  AccountCreated ,
  AccountLoggedIn ,
  ExpiredMagicLink ,
  UpdatedPasswordSuccess ,
  Geoblocked ,
  Saf11Warning ,
  get ,
  Base ,
  Toaster$1 as Toaster,
  Hook ,
}
/* Toaster Not a pure module */
