// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Type_String from "../../type/Type_String.res.mjs";

function args_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Type_String.User.Email.t_encode(v.email)
                  ]]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emailExist = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "emailExist"), null));
  if (emailExist.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              emailExist: emailExist._0
            }
          };
  }
  var e = emailExist._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emailExist" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var endpoint = {
  NAME: "Static",
  VAL: "check-email-exists"
};

function makeBody(args) {
  return Api.Utils.makeJSONBody(args, args_encode);
}

var options = {
  tokenKind: "Sso"
};

var PostConfig = {
  args_encode: args_encode,
  response_decode: response_decode,
  domain: "Sso",
  endpoint: endpoint,
  method: "Post",
  makeBody: makeBody,
  options: options,
  decodeApiResponse: response_decode
};

var Post = Api.User.MakeMutation({
      domain: "Sso",
      method: "Post",
      endpoint: endpoint,
      makeBody: makeBody,
      decodeApiResponse: response_decode,
      options: options
    });

var check = Post.call;

export {
  PostConfig ,
  Post ,
  check ,
}
/* Post Not a pure module */
