// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_FooterLabels from "./Organism_FooterLabels.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var deviceIconBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("XS")), undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var deviceIconJustifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var deviceIconDisplayOrder = Particle_Screen_Ds.make(undefined, undefined, 0, undefined, undefined, 1);

var shopButtonBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeBottom("L"));

var textBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Ds.makeBottom("XS")), undefined, undefined, Particle_Spacer_Ds.make("XS", "XS", "None", "None"));

var textInnerspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS");

var textDisplayOrder = Particle_Screen_Ds.make(undefined, undefined, 1, undefined, undefined, 0);

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Organism_AppLinks(props) {
  var match = Context.I18n.getState();
  var locale = match.locale;
  var match$1 = Organism_FooterLabels.getLabels(locale);
  var appLink = match$1.appLink;
  var badge = appLink.badge;
  var match$2 = Context.Config.getState();
  var playStoreUrl = Core__Option.mapOr(match$2.playStoreUrl, Organism_FooterLabels.defaultPlayStoreUrl, Type_String.PlayStoreUrl.toString);
  var appStoreUrl = Core__Option.mapOr(match$2.appStoreUrl, Organism_FooterLabels.defaultAppStoreUrl, Type_String.AppStoreUrl.toString);
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsxs(Atom_Row.make, {
                              blockSpace: Caml_option.some(deviceIconBlockSpace),
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      kind: "span",
                                      style: Atom_Text_Ds.OverFooter.deviceDesktop
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      kind: "span",
                                      style: Atom_Text_Ds.OverFooter.deviceSmartPhone
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      kind: "span",
                                      style: Atom_Text_Ds.OverFooter.deviceTablet
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      kind: "span",
                                      style: Atom_Text_Ds.OverFooter.deviceTv
                                    })
                              ],
                              displayOrder: Caml_option.some(deviceIconDisplayOrder),
                              justifyContent: Caml_option.some(deviceIconJustifyContent)
                            }),
                        JsxRuntime.jsxs(Atom_Column.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                              blockSpace: Caml_option.some(textBlockSpace),
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(appLink.title),
                                      kind: "h2",
                                      style: Atom_Text_Ds.OverFooter.title
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(appLink.description),
                                      style: Atom_Text_Ds.OverFooter.desc
                                    })
                              ],
                              displayOrder: Caml_option.some(textDisplayOrder),
                              innerSpace: Caml_option.some(textInnerspace),
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      blockSpace: Caml_option.some(shopButtonBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                              children: JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                                    alt: badge.altAppStore,
                                    style: {
                                      TAG: "FromCdn",
                                      _0: {
                                        TAG: "Badge",
                                        _0: "AppStore",
                                        _1: locale
                                      }
                                    }
                                  }),
                              href: appStoreUrl,
                              style: Atom_Cta_Ds.storeButton
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                              children: JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                                    alt: badge.altPlayStore,
                                    style: {
                                      TAG: "FromCdn",
                                      _0: {
                                        TAG: "Badge",
                                        _0: "PlayStore",
                                        _1: locale
                                      }
                                    }
                                  }),
                              href: playStoreUrl,
                              style: Atom_Cta_Ds.storeButton
                            })
                      ],
                      gap: Caml_option.some(gap),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.footer
                    })
              ]
            });
}

var justifyContent = Particle_Screen_Constants.justifyContentCenter;

var make = Organism_AppLinks;

export {
  deviceIconBlockSpace ,
  deviceIconJustifyContent ,
  deviceIconDisplayOrder ,
  shopButtonBlockSpace ,
  justifyContent ,
  textBlockSpace ,
  textInnerspace ,
  textDisplayOrder ,
  gap ,
  make ,
}
/* deviceIconBlockSpace Not a pure module */
