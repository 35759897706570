import { type IncomingMessage } from 'http';
import { getAbGroupsFromRequest } from '../../../apps/replay-app-router/src/shared/abTesting/abGroupParser';
import { type Locale } from './Locale';
import { type Page } from './Page';

type UserAbv = 'A' | 'B' | null;

export type Xiti = {
    page_name: string;
    chapter1: string;
    chapter2: string;
    chapter3: string;
    x1: string;
    x2: string;
    s2: number;
    x4: UserAbv;
    siteId: string;
    env_work: string;
    search_keywords: string;
};

export type ServerSideTracking = {
    page: {
        id: string;
        language: string;
        url: string;
        abv: UserAbv;
        query: string | null;
        category: string | null;
        subcategories: string | null;
    };
    content: {
        category: string;
        id: string;
        kind: string;
        slug: string;
        subcategory: string;
    } | null;
    teaser: {
        clickTrackingUrl: string | null;
    } | null;
};

export type Stats = { xiti: Xiti; serverSideTracking: ServerSideTracking };

export type TagsCommanderVars = {
    env_template?: string;
    env_work?: string;
    env_n2?: number;
    page_cat1_name?: string;
    page_cat2_name?: string;
    page_cat3_name?: string;
    page_name?: string;
    user_abv?: UserAbv;
};

export const ensureAbv = (abv: string): UserAbv => {
    switch (abv) {
        case 'A':
        case 'B':
            return abv;
        default:
            return null;
    }
};

export const defaultStats: (slug: string, locale: Locale, req: IncomingMessage | undefined) => Stats = (
    slug,
    locale,
    req,
) => {
    const abv = getAbGroupsFromRequest(req);
    return {
        xiti: {
            page_name: slug,
            chapter1: '',
            chapter2: '',
            chapter3: '',
            x1: locale,
            x2: 'Home',
            x4: null,
            s2: 1,
            siteId: '582046',
            env_work: 'prod',
            search_keywords: '',
        },
        serverSideTracking: {
            page: {
                id: slug,
                language: locale,
                url: req?.url || '',
                abv: ensureAbv(abv),
                query: null,
                category: null,
                subcategories: null,
            },
            content: null,
            teaser: null,
        },
    };
};

export const getStatsFromEmacPage = (page: Page, locale: Locale) => {
    return page.stats ?? defaultStats(page.slug, locale, undefined);
};
