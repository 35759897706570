// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "./User.res.mjs";
import * as Uuid from "uuid";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as User_Type from "./User_Type.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Progression from "../type/Type_Progression.res.mjs";
import * as User_Api__SsoLogin from "./User_Api/User_Api__SsoLogin.res.mjs";
import * as Belt_MutableMapString from "@rescript/std/lib/es6/belt_MutableMapString.js";
import * as User_Api__Sso__Favorites from "./User_Api/User_Api__Sso__Favorites.res.mjs";
import * as Shim from "use-sync-external-store/shim";

function make(base) {
  var t_encode = function (param) {
    return "";
  };
  var t_decode = function (param) {
    return {
            TAG: "Ok",
            _0: Belt_MutableMapString.make()
          };
  };
  var Handlers = Type_String.$$Map.MakeMutable({
        Str: Type_String.Teaser.DsId,
        t_encode: t_encode,
        t_decode: t_decode
      });
  var handlers = Handlers.make();
  var getHandlers = function () {
    return Curry._3(Handlers.reduce, handlers, [], (function (acc, dsId, stringMap) {
                  return Belt_MutableMapString.reduce(stringMap, acc, (function (acc, listenerId, callback) {
                                var return_0 = [
                                  listenerId,
                                  dsId
                                ];
                                var $$return = [
                                  return_0,
                                  callback
                                ];
                                acc.push($$return);
                                return acc;
                              }));
                }));
  };
  var subscribe = function (dsId, callback) {
    var listenerId = Uuid.v4();
    var currentCallbacks = Core__Option.getOr(Curry._2(Handlers.get, handlers, dsId), Belt_MutableMapString.make());
    Belt_MutableMapString.set(currentCallbacks, listenerId, callback);
    Curry._3(Handlers.set, handlers, dsId, currentCallbacks);
    return [
            listenerId,
            dsId
          ];
  };
  var unsubscribe = function (param) {
    var dsId = param[1];
    var listenerId = param[0];
    Core__Option.forEach(Curry._2(Handlers.get, handlers, dsId), (function (__x) {
            Belt_MutableMapString.remove(__x, listenerId);
          }));
    var match = Core__Option.map(Curry._2(Handlers.get, handlers, dsId), Belt_MutableMapString.isEmpty);
    if (match !== undefined && match) {
      return Curry._2(Handlers.remove, handlers, dsId);
    }
    
  };
  var notify = function (dsId, progression) {
    var callbacks = Curry._2(Handlers.get, handlers, dsId);
    if (callbacks !== undefined) {
      return Belt_MutableMapString.forEach(Caml_option.valFromOption(callbacks), (function (_key, callback) {
                    callback(progression);
                  }));
    }
    
  };
  var getSnapshot = function (dsId) {
    return function () {
      return Core__Option.getOr(base.getInitialValue(undefined, dsId), base.defaultValue);
    };
  };
  var subscribe$1 = function (dsId) {
    return function (listener) {
      var subscribeId = subscribe(dsId, (function (param) {
              listener();
            }));
      return function () {
        unsubscribe(subscribeId);
      };
    };
  };
  var use = function (dsId) {
    return Shim.useSyncExternalStore(subscribe$1(dsId), getSnapshot(dsId), getSnapshot(dsId));
  };
  return {
          getHandlers: getHandlers,
          subscribe: subscribe,
          unsubscribe: unsubscribe,
          notify: notify,
          Hook: {
            use: use
          }
        };
}

var WithDsId = {
  make: make
};

function make$1(base) {
  var handlers = Belt_MutableMapString.make();
  var getHandlers = function () {
    return Belt_MutableMapString.reduce(handlers, [], (function (acc, key, callback) {
                  var $$return = [
                    key,
                    callback
                  ];
                  acc.push($$return);
                  return acc;
                }));
  };
  var subscribe = function (callback) {
    var listenerId = Uuid.v4();
    Belt_MutableMapString.set(handlers, listenerId, callback);
    return listenerId;
  };
  var unsubscribe = function (listenerId) {
    Belt_MutableMapString.remove(handlers, listenerId);
  };
  var notify = function (value) {
    Belt_MutableMapString.forEach(handlers, (function (_key, callback) {
            callback(value);
          }));
  };
  var getSnapshot = function () {
    return Core__Option.getOr(base.getInitialValue(undefined, undefined), base.defaultValue);
  };
  var subscribe$1 = function (callback) {
    var subscribeId = subscribe(function (param) {
          callback();
        });
    return function () {
      Belt_MutableMapString.remove(handlers, subscribeId);
    };
  };
  var use = function () {
    return Shim.useSyncExternalStore(subscribe$1, getSnapshot, getSnapshot);
  };
  return {
          getHandlers: getHandlers,
          subscribe: subscribe,
          unsubscribe: unsubscribe,
          notify: notify,
          getSnapshot: getSnapshot,
          Hook: {
            use: use
          }
        };
}

var WithStringMap = {
  make: make$1
};

function getInitialValue($staropt$star, param) {
  $staropt$star !== undefined;
}

var Base = {
  defaultValue: "Init",
  getInitialValue: getInitialValue
};

var Observable = make$1(Base);

var defaultValue_persistence = {
  TAG: "Local",
  _0: new Date()
};

var defaultValue = {
  timeProgression: Type_Progression.noProgression,
  persistence: defaultValue_persistence
};

function getInitialValue$1(getUserOpt, dsId) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  return User_Type.getProgressionById(getUser(), dsId);
}

var Status = {
  defaultValue: defaultValue,
  getInitialValue: getInitialValue$1
};

async function purgeProgression() {
  var user = User.get();
  return User.set(User_Type.purge(user, "History"));
}

var include = make(Status);

var notify = include.notify;

function setTimeProgression(getUserOpt, setUserOpt, dsId, progression) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  var setUser = setUserOpt !== undefined ? setUserOpt : User.set;
  var user = getUser();
  Belt_Option.map(User_Type.setTimeProgression(dsId, progression, Caml_option.some(user)), setUser);
  notify(dsId, {
        timeProgression: progression,
        persistence: {
          TAG: "Local",
          _0: new Date()
        }
      });
}

async function $$delete(getUserOpt, setUserOpt, dsId) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  var setUser = setUserOpt !== undefined ? setUserOpt : User.set;
  var user = getUser();
  Belt_Option.map(User_Type.setTimeProgression(dsId, Type_Progression.noProgression, Caml_option.some(user)), setUser);
  notify(dsId, {
        timeProgression: Type_Progression.noProgression,
        persistence: {
          TAG: "Local",
          _0: new Date()
        }
      });
  return Observable.notify({
              TAG: "Remove",
              _0: dsId
            });
}

var Progression_getHandlers = include.getHandlers;

var Progression_subscribe = include.subscribe;

var Progression_unsubscribe = include.unsubscribe;

var Progression_Hook = include.Hook;

var Progression = {
  Base: Base,
  Observable: Observable,
  Status: Status,
  purgeProgression: purgeProgression,
  getHandlers: Progression_getHandlers,
  subscribe: Progression_subscribe,
  unsubscribe: Progression_unsubscribe,
  notify: notify,
  Hook: Progression_Hook,
  setTimeProgression: setTimeProgression,
  $$delete: $$delete
};

function getInitialValue$2($staropt$star, param) {
  $staropt$star !== undefined;
}

var Base$1 = {
  defaultValue: "Init",
  getInitialValue: getInitialValue$2
};

var Observable$1 = make$1(Base$1);

function getInitialValue$3(getUserOpt, dsId) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  return (function (__x) {
              return User_Type.getVideoById(dsId, __x);
            })(getUser());
}

var Base$2 = {
  defaultValue: "NotFavorite",
  getInitialValue: getInitialValue$3
};

var Observable$2 = make(Base$2);

var notify$1 = Observable$2.notify;

var FavoriteStatus = {
  Base: Base$2,
  Observable: Observable$2,
  notify: notify$1
};

async function addFavorite(getUserOpt, setUserOpt, addFavoriteOpt, minutesLabel, locale, dsId) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  var setUser = setUserOpt !== undefined ? setUserOpt : User.set;
  var addFavorite$1 = addFavoriteOpt !== undefined ? addFavoriteOpt : User_Api__Sso__Favorites.put;
  var controller = new AbortController();
  var signal = controller.signal;
  var clubTeaser = await addFavorite$1({
        args: {
          programId: dsId
        },
        signal: signal,
        locale: locale
      });
  if (clubTeaser.TAG !== "Ok") {
    return ;
  }
  var clubTeaser$1 = clubTeaser._0;
  if (clubTeaser$1 === undefined) {
    return ;
  }
  var teaser = Type_Club.Teaser.toDsTeaser(minutesLabel, undefined, clubTeaser$1);
  var newUser = User_Type.addFavorite(Type_Club.Teaser.toDsTeaser(minutesLabel, undefined, clubTeaser$1), getUser());
  setUser(newUser);
  Observable$1.notify({
        TAG: "Add",
        _0: teaser
      });
  return notify$1(dsId, "Favorite");
}

async function removeFavorite(getUserOpt, setUserOpt, removeFavoriteOpt, teaserId, locale) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  var setUser = setUserOpt !== undefined ? setUserOpt : User.set;
  var removeFavorite$1 = removeFavoriteOpt !== undefined ? removeFavoriteOpt : User_Api__Sso__Favorites.$$delete;
  var controller = new AbortController();
  var signal = controller.signal;
  var user = getUser();
  var newUser = User_Type.removeFavorite(teaserId, user);
  setUser(newUser);
  notify$1(teaserId, "NotFavorite");
  await removeFavorite$1({
        args: {
          programId: teaserId
        },
        signal: signal,
        locale: locale
      });
  return Observable$1.notify({
              TAG: "Remove",
              _0: teaserId
            });
}

async function purgeFavorites() {
  var user = User.get();
  return User.set(User_Type.purge(user, "Favorites"));
}

function useStatus(dsId, onAdd, onRemove) {
  var status = Observable$2.Hook.use(dsId);
  var match = Context.I18n.getState();
  var locale = match.locale;
  var minutesLabel = match.labels.teaser.durationUnit;
  var add = React.useCallback((function () {
          addFavorite(undefined, undefined, undefined, minutesLabel, locale, dsId);
          onAdd();
        }), [
        dsId,
        locale,
        minutesLabel
      ]);
  var remove = React.useCallback((function () {
          removeFavorite(undefined, undefined, undefined, dsId, locale);
          onRemove();
        }), [dsId]);
  var toggle = function () {
    if (status === "Favorite") {
      return remove();
    } else {
      return add();
    }
  };
  return {
          status: status,
          toggle: toggle
        };
}

var Hook = {
  useStatus: useStatus
};

var Favorite = {
  Base: Base$1,
  Observable: Observable$1,
  FavoriteStatus: FavoriteStatus,
  addFavorite: addFavorite,
  removeFavorite: removeFavorite,
  purgeFavorites: purgeFavorites,
  Hook: Hook
};

var defaultValue$1 = User_Type.getUserStatus(User.get());

function getInitialValue$4(getUserOpt, param) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  return User_Type.getUserStatus(getUser());
}

var Base$3 = {
  defaultValue: defaultValue$1,
  getInitialValue: getInitialValue$4
};

var include$1 = make$1(Base$3);

var UserStatus_getHandlers = include$1.getHandlers;

var UserStatus_subscribe = include$1.subscribe;

var UserStatus_unsubscribe = include$1.unsubscribe;

var UserStatus_notify = include$1.notify;

var UserStatus_getSnapshot = include$1.getSnapshot;

var UserStatus_Hook = include$1.Hook;

var UserStatus = {
  Base: Base$3,
  getHandlers: UserStatus_getHandlers,
  subscribe: UserStatus_subscribe,
  unsubscribe: UserStatus_unsubscribe,
  notify: UserStatus_notify,
  getSnapshot: UserStatus_getSnapshot,
  Hook: UserStatus_Hook
};

var defaultValue$2 = User.Status.get();

function getInitialValue$5(_getUserOpt, param) {
  return User.Status.get();
}

var Base$4 = {
  defaultValue: defaultValue$2,
  getInitialValue: getInitialValue$5
};

var include$2 = make$1(Base$4);

var notify$2 = include$2.notify;

var Status_getHandlers = include$2.getHandlers;

var Status_subscribe = include$2.subscribe;

var Status_unsubscribe = include$2.unsubscribe;

var Status_getSnapshot = include$2.getSnapshot;

var Status_Hook = include$2.Hook;

var Status$1 = {
  Base: Base$4,
  getHandlers: Status_getHandlers,
  subscribe: Status_subscribe,
  unsubscribe: Status_unsubscribe,
  notify: notify$2,
  getSnapshot: Status_getSnapshot,
  Hook: Status_Hook
};

var defaultValue$3 = User_Type.getAnonymousFeatureStatus(User.get());

function getInitialValue$6(getUserOpt, param) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  return User_Type.getAnonymousFeatureStatus(getUser());
}

var Base$5 = {
  defaultValue: defaultValue$3,
  getInitialValue: getInitialValue$6
};

function setAnonymousFeatureStatus(getUserOpt, setUserOpt, enabled, locale) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  var setUser = setUserOpt !== undefined ? setUserOpt : User.set;
  var controller = new AbortController();
  var signal = controller.signal;
  var ensuredAnonymousFeatureStatus = enabled ? "Enabled" : "Disabled";
  var newUser = User_Type.newUserFromAnonymousStatus(getUser(), ensuredAnonymousFeatureStatus);
  var match = User_Type.getUserStatus(newUser);
  var tmp;
  switch (match) {
    case "Anonymous" :
    case "Connected" :
        tmp = Promise.resolve();
        break;
    case "Disconnected" :
        tmp = User_Api__SsoLogin.deleteToken({
                signal: signal,
                locale: locale
              }).then(function (param) {
              
            });
        break;
    
  }
  tmp.then(function () {
        setUser(newUser);
        User.Status.set("ForceReInit");
        notify$2("ForceReInit");
      });
}

function use() {
  var match = Context.I18n.getState();
  var locale = match.locale;
  var setAnonymous = React.useCallback((function (enabled) {
          setAnonymousFeatureStatus(undefined, undefined, enabled, locale);
        }), [locale]);
  return React.useMemo((function () {
                return {
                        setAnonymous: setAnonymous
                      };
              }), [setAnonymous]);
}

var Hook$1 = {
  use: use
};

var AnonymousFeatureStatus = {
  setAnonymousFeatureStatus: setAnonymousFeatureStatus,
  Hook: Hook$1
};

var include$3 = make$1(Base$5);

var AnonymousFeatureStatus_getHandlers = include$3.getHandlers;

var AnonymousFeatureStatus_subscribe = include$3.subscribe;

var AnonymousFeatureStatus_unsubscribe = include$3.unsubscribe;

var AnonymousFeatureStatus_notify = include$3.notify;

var AnonymousFeatureStatus_getSnapshot = include$3.getSnapshot;

var AnonymousFeatureStatus_Hook = include$3.Hook;

var AnonymousFeatureStatus$1 = {
  Base: Base$5,
  AnonymousFeatureStatus: AnonymousFeatureStatus,
  getHandlers: AnonymousFeatureStatus_getHandlers,
  subscribe: AnonymousFeatureStatus_subscribe,
  unsubscribe: AnonymousFeatureStatus_unsubscribe,
  notify: AnonymousFeatureStatus_notify,
  getSnapshot: AnonymousFeatureStatus_getSnapshot,
  Hook: AnonymousFeatureStatus_Hook
};

var defaultValue$4 = User_Type.getUserInfo(User.get());

function getInitialValue$7(getUserOpt, param) {
  var getUser = getUserOpt !== undefined ? getUserOpt : User.get;
  return Caml_option.some(User_Type.getUserInfo(getUser()));
}

var Base$6 = {
  defaultValue: defaultValue$4,
  getInitialValue: getInitialValue$7
};

var All = make$1(Base$6);

function updateAcceptCom(val) {
  var userFromStorage = User.get();
  var userInfo = User_Type.getUserInfo(userFromStorage);
  var newInfos = Core__Option.map(userInfo, (function (__x) {
          return User_Type.Info.setAcceptCom(__x, val);
        }));
  var newUser = Core__Option.map(newInfos, (function (__x) {
          return User_Type.updateUserInfo(userFromStorage, __x);
        }));
  Core__Option.forEach(newUser, (function (newUser) {
          User.set(newUser);
          All.notify(newInfos);
        }));
}

function updateIsSubscribedToNewsletter(val) {
  var userFromStorage = User.get();
  var userInfo = User_Type.getUserInfo(userFromStorage);
  var newInfos = Core__Option.map(userInfo, (function (__x) {
          return User_Type.Info.setIsSubscribedToNewsletter(__x, val);
        }));
  var newUser = Core__Option.map(newInfos, (function (__x) {
          return User_Type.updateUserInfo(userFromStorage, __x);
        }));
  Core__Option.forEach(newUser, (function (newUser) {
          User.set(newUser);
          All.notify(newInfos);
        }));
}

function updateAvatar(avatar) {
  var userFromStorage = User.get();
  var userInfo = User_Type.getUserInfo(userFromStorage);
  var newInfos = Core__Option.map(userInfo, (function (__x) {
          return User_Type.Info.setAvatar(__x, avatar);
        }));
  var newUser = Core__Option.map(newInfos, (function (__x) {
          return User_Type.updateUserInfo(userFromStorage, __x);
        }));
  Core__Option.forEach(newUser, (function (newUser) {
          User.set(newUser);
          All.notify(newInfos);
        }));
}

function addEventParticipation(eventId) {
  var userFromStorage = User.get();
  var userInfo = User_Type.getUserInfo(userFromStorage);
  var newInfos = Core__Option.map(userInfo, (function (__x) {
          return User_Type.Info.addEventParticipation(__x, eventId);
        }));
  var newUser = Core__Option.map(newInfos, (function (__x) {
          return User_Type.updateUserInfo(userFromStorage, __x);
        }));
  Core__Option.forEach(newUser, (function (newUser) {
          User.set(newUser);
          All.notify(newInfos);
        }));
}

function updateUserInfos(userInfo) {
  var userFromStorage = User.get();
  var newUser = User_Type.updateUserInfo(userFromStorage, userInfo);
  User.set(newUser);
  All.notify(Caml_option.some(userInfo));
}

function use$1() {
  var userInfo = All.Hook.use();
  return React.useMemo((function () {
                return Core__Option.flatMap(userInfo, (function (userInfo) {
                              var id = User_Type.Info.id(userInfo);
                              if (id !== undefined) {
                                return {
                                        id: id,
                                        ageVerification: User_Type.Info.ageVerificationToken(userInfo)
                                      };
                              }
                              
                            }));
              }), [userInfo]);
}

var Tracking = {
  use: use$1
};

var UserInfo_getHandlers = All.getHandlers;

var UserInfo_subscribe = All.subscribe;

var UserInfo_unsubscribe = All.unsubscribe;

var UserInfo_notify = All.notify;

var UserInfo_getSnapshot = All.getSnapshot;

var UserInfo_Hook = All.Hook;

var UserInfo = {
  Base: Base$6,
  All: All,
  getHandlers: UserInfo_getHandlers,
  subscribe: UserInfo_subscribe,
  unsubscribe: UserInfo_unsubscribe,
  notify: UserInfo_notify,
  getSnapshot: UserInfo_getSnapshot,
  Hook: UserInfo_Hook,
  updateAcceptCom: updateAcceptCom,
  updateIsSubscribedToNewsletter: updateIsSubscribedToNewsletter,
  updateAvatar: updateAvatar,
  addEventParticipation: addEventParticipation,
  updateUserInfos: updateUserInfos,
  Tracking: Tracking
};

export {
  WithDsId ,
  WithStringMap ,
  Progression ,
  Favorite ,
  UserStatus ,
  Status$1 as Status,
  AnonymousFeatureStatus$1 as AnonymousFeatureStatus,
  UserInfo ,
}
/* Observable Not a pure module */
