// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_List_Ds from "../atom/Atom_List_Ds.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Atom_Image_Ds from "../atom/Atom_Image_Ds.res.mjs";
import * as Atom_Text_Css from "../atom/Atom_Text_Css.res.mjs";
import * as Atom_Image_Css from "../atom/Atom_Image_Css.res.mjs";
import * as Particle_Color from "../particle/Particle_Color.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";
import * as Molecule_DangerousHtml_Ds from "./Molecule_DangerousHtml_Ds.res.mjs";

function makeTextCss(textDs) {
  return Atom_Text_Css.toCss(Atom_Text_Ds.view(textDs));
}

function makeListCss(listDs) {
  return Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Atom_List_Ds.toCss(listDs)));
}

function makeImageCss(imageDs) {
  var match = Atom_Image_Css.toCss(Atom_Image_Ds.make(imageDs));
  return Particle_Screen_Ds.$$Array.concat(Core__Option.getOr(match.gradient, Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [])), match.image);
}

function makeSpacerCss(spacerDs) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Css.toCss(spacerDs));
}

function makeMaxWidthCss(maxWidth) {
  var mapper = function (mw) {
    return [CssJs.maxWidth(mw)];
  };
  return Particle_Screen_Ds.map(maxWidth, mapper);
}

function mapToSelector(rules, selector) {
  return Particle_Screen_Ds.map(rules, (function (rule) {
                return [CssJs.selector(selector, rule)];
              }));
}

function makeTextRules(dsStyle, selector) {
  var baseRules = Caml_option.some(makeTextCss(dsStyle.text));
  var spacerRules = Core__Option.map(dsStyle.spacer, makeSpacerCss);
  return mapToSelector(Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
                      baseRules,
                      spacerRules
                    ])), selector);
}

function makeListRules(dsStyle, selector) {
  var baseRules = makeListCss(dsStyle.list);
  var spacerRules = Core__Option.map(dsStyle.spacer, makeSpacerCss);
  return mapToSelector(Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
                      baseRules,
                      spacerRules
                    ])), selector);
}

var tableRules = mapToSelector(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
          CssJs.borderCollapse("collapse"),
          CssJs.tableLayout("fixed")
        ]), "table");

function makeTableCellRules(table, cellType, selector) {
  var baseRules = Caml_option.some(makeTextCss(cellType.text));
  var spacerRules = Core__Option.map(cellType.spacer, makeSpacerCss);
  var textAlignRules = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
            CssJs.textAlign("center"),
            CssJs.verticalAlign("middle")
          ]));
  var backgroundRules = selector === "th" ? Core__Option.map(table.thBackground, (function (bg) {
            return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.backgroundColor(Particle_Color.Css.color(bg))]);
          })) : (
      selector === "tr" ? Core__Option.map(table.tdBackgroundOdd, (function (bg) {
                return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.nthChild("odd", [CssJs.backgroundColor(Particle_Color.Css.color(bg))])]);
              })) : undefined
    );
  return mapToSelector(Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
                      baseRules,
                      spacerRules,
                      backgroundRules,
                      textAlignRules
                    ])), selector);
}

function makeImageRules(img) {
  var baseRules = Caml_option.some(makeImageCss(img.image));
  var spacerRules = Core__Option.map(img.spacer, makeSpacerCss);
  var maxWidth = Core__Option.map(img.maxWidth, makeMaxWidthCss);
  return mapToSelector(Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
                      baseRules,
                      spacerRules,
                      maxWidth
                    ])), "img");
}

function makeIframeRules(iframe) {
  return Particle_Screen_Ds.map(iframe, (function (rules) {
                return [CssJs.selector("iframe", rules)];
              }));
}

function toCss(t) {
  var match = Molecule_DangerousHtml_Ds.view(t);
  var table = match.table;
  var td = match.td;
  var th = match.th;
  var h1Rules = makeTextRules(match.h1, "h1");
  var h2Rules = makeTextRules(match.h2, "h2");
  var h3Rules = makeTextRules(match.h3, "h3");
  var pRules = makeTextRules(match.p, "p");
  var aRules = makeTextRules(match.a, "a");
  var strongRules = makeTextRules(match.strong, "strong");
  var emRules = makeTextRules(match.em, "em");
  var liRules = makeTextRules(match.li, "li");
  var ulRules = makeListRules(match.ul, "ul");
  var iframe = Core__Option.map(match.iframe, makeIframeRules);
  var olRules = makeListRules(match.ol, "ol");
  var tdRules = Core__Option.map(table, (function (table) {
          return makeTableCellRules(table, td, "td");
        }));
  var thRules = Core__Option.map(table, (function (table) {
          return makeTableCellRules(table, th, "th");
        }));
  var trRules = Core__Option.map(table, (function (table) {
          return makeTableCellRules(table, td, "tr");
        }));
  var imageRules = Core__Option.map(match.img, makeImageRules);
  return Particle_Screen_Ds.$$Array.concatMany(Util_Array.catOption([
                  Caml_option.some(h1Rules),
                  Caml_option.some(h2Rules),
                  Caml_option.some(h3Rules),
                  Caml_option.some(pRules),
                  Caml_option.some(aRules),
                  Caml_option.some(strongRules),
                  Caml_option.some(emRules),
                  Caml_option.some(ulRules),
                  Caml_option.some(olRules),
                  Caml_option.some(liRules),
                  imageRules,
                  Caml_option.some(tableRules),
                  tdRules,
                  thRules,
                  trRules,
                  iframe
                ]));
}

export {
  makeTextCss ,
  makeListCss ,
  makeImageCss ,
  makeSpacerCss ,
  makeMaxWidthCss ,
  mapToSelector ,
  makeTextRules ,
  makeListRules ,
  tableRules ,
  makeTableCellRules ,
  makeImageRules ,
  makeIframeRules ,
  toCss ,
}
/* tableRules Not a pure module */
