'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../user/User.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Divider from "../../../design-system/atom/Atom_Divider.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../../../design-system/atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as User_Api__SsoLogin from "../../../user/User_Api/User_Api__SsoLogin.res.mjs";
import * as User_Api__Sso__Token from "../../../user/User_Api/User_Api__Sso__Token.res.mjs";
import * as User_Api__Sso__Password from "../../../user/User_Api/User_Api__Sso__Password.res.mjs";

function get(values, field) {
  if (field === "Password") {
    return values.password;
  } else {
    return values.passwordConfirmation;
  }
}

function set(values, field, value) {
  if (field === "Password") {
    return {
            password: value,
            passwordConfirmation: values.passwordConfirmation
          };
  } else {
    return {
            password: values.password,
            passwordConfirmation: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var initialState = {
  password: "",
  passwordConfirmation: ""
};

var Form = UseForm.Make({
      set: set,
      get: get
    });

async function loginUser(signal, locale, email, resetToken, password, token, setLoading, setError, onLoginCallback, errorMessage, redirectToLogin) {
  setLoading();
  var passwordResetResponse = await User_Api__Sso__Password.reset({
        args: {
          password: password,
          resetToken: resetToken
        },
        signal: signal,
        locale: locale
      });
  if (passwordResetResponse.TAG === "Ok") {
    if (email === undefined) {
      return redirectToLogin();
    }
    if (token === undefined) {
      return redirectToLogin();
    }
    var tokenResponse = await User_Api__Sso__Token.getTokenWithPassword({
          args: {
            anonymousToken: Caml_option.valFromOption(token),
            email: email,
            password: password
          },
          signal: signal,
          locale: locale
        });
    if (tokenResponse.TAG === "Ok") {
      var ssoTokenResponse = await User_Api__SsoLogin.setToken({
            args: {
              token: tokenResponse._0
            },
            signal: signal,
            locale: locale
          });
      if (ssoTokenResponse.TAG === "Ok") {
        onLoginCallback();
      } else {
        setError(errorMessage);
      }
    } else {
      setError(errorMessage);
    }
    return ;
  }
  return setError(errorMessage);
}

function Page_Password_Reset$ResetForm(props) {
  var onLoginCallback = props.onLoginCallback;
  var email = props.email;
  var resetToken = props.resetToken;
  var redirectToLogin = props.redirectToLogin;
  var controller = new AbortController();
  var signal = controller.signal;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var passwordPlaceholder = match$1.passwordPlaceholder;
  var match$2 = labels.mona.register;
  var match$3 = labels.mona.profile;
  var errorMessage = match$3.errorMessage;
  var makeSchema = function () {
    return Form.Schema.Validation.schema([
                Form.Schema.Validation.nonEmpty("Password"),
                Form.Schema.Validation.string(6, undefined, undefined, undefined, "Password"),
                Form.Schema.Validation.password("Password")
              ]);
  };
  var schema = makeSchema();
  var match$4 = UseUserFetch.make();
  var setError = match$4.setError;
  var setLoading = match$4.setLoading;
  var handleSubmit = function (param) {
    var token = User.getToken();
    var match = param.state.values;
    loginUser(signal, locale, email, resetToken, match.password, token, setLoading, setError, onLoginCallback, errorMessage, redirectToLogin);
  };
  var form = Form.use(initialState, schema, handleSubmit, labels, match.formaters, undefined, undefined);
  var match$5 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                            autoComplete: "new-password",
                            type_: "password",
                            value: match$5.password,
                            label: passwordPlaceholder,
                            onChange: (function (__x) {
                                form.handleChange("Password", __x);
                              }),
                            id: "password",
                            name: passwordPlaceholder,
                            errorMessage: form.getFieldError({
                                  TAG: "Field",
                                  _0: "Password"
                                }),
                            inputRef: form.getFieldRef({
                                  TAG: "Field",
                                  _0: "Password"
                                }),
                            action: {
                              TAG: "Toggle",
                              style: Atom_Cta_Ds.User.textfieldAction,
                              labelOn: match$2.passwordActionLabelOn,
                              labelOff: match$2.passwordActionLabelOff,
                              accessibilityLabelOn: match$2.passwordAccessibilityLabelOn,
                              accessibilityLabelOff: match$2.passwordAccessibilityLabelOff
                            }
                          }),
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                match$4.component,
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: match$1.requestPasswordResetButton,
                            variant: "base",
                            type_: "submit"
                          })
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.user
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              kind: {
                TAG: "Form",
                _0: onSubmit
              },
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var ResetForm = {
  FormFields: FormFields,
  initialState: initialState,
  Form: Form,
  loginUser: loginUser,
  make: Page_Password_Reset$ResetForm
};

function make(param) {
  var resetToken = param.resetToken;
  var redirectToLogin = param.redirectToLogin;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.login;
  React.useEffect((function () {
          if (resetToken !== undefined) {
            
          } else {
            redirectToLogin();
          }
        }), [resetToken]);
  var resetToken$1 = Core__Option.getOr(resetToken, "");
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: match$1.newPasswordTitle,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: JsxRuntime.jsx(Page_Password_Reset$ResetForm, {
                    redirectToLogin: redirectToLogin,
                    resetToken: resetToken$1,
                    email: param.email,
                    onLoginCallback: param.onLoginCallback
                  })
            });
}

export {
  ResetForm ,
  make ,
}
/* Form Not a pure module */
