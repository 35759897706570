'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "./Context.res.mjs";
import * as Util_I18n from "../util/Util_I18n.res.mjs";
import * as Context_I18n from "./Context_I18n.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";

function Provider_I18n(props) {
  var __profileUrl = props.profileUrl;
  var __loginUrl = props.loginUrl;
  var loginUrl = __loginUrl !== undefined ? __loginUrl : "https://www.arte.tv/fr/profile/auth/landing";
  var profileUrl = __profileUrl !== undefined ? __profileUrl : "https://www.arte.tv/fr/profile";
  var ensuredLocale = Util_I18n.localeFromLanguage(props.locale);
  var ensuredFormaters = Context_I18n.ensureFormaters(props.formaters);
  var value_labels = props.labels;
  var value = {
    formaters: ensuredFormaters,
    labels: value_labels,
    locale: ensuredLocale,
    loginUrl: loginUrl,
    profileUrl: profileUrl
  };
  var state = Context.I18n.make("Initialized", value);
  return JsxRuntime.jsx(Context.I18n.Provider.make, {
              value: state,
              children: props.children
            });
}

var make = Provider_I18n;

export {
  make ,
}
/* Context Not a pure module */
