// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Cta from "./Atom_Cta.res.mjs";
import * as Atom_Cta_Ds from "./Atom_Cta_Ds.res.mjs";
import * as Type_String from "../../type/Type_String.res.mjs";
import * as Type_Teaser from "../../type/Type_Teaser.res.mjs";
import * as Atom_Icon_Ds from "./Atom_Icon_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as UseClickTracking from "../../hook/UseClickTracking.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Atom_FavAlertToaster from "./Atom_FavAlertToaster.res.mjs";

function availabilityFromTeaserFavorite(favoriteStatus) {
  if (typeof favoriteStatus !== "object") {
    return "NotAvailable";
  } else if (favoriteStatus.TAG === "Available") {
    return "Available";
  } else {
    return "Upcoming";
  }
}

function Atom_FavAlertButton(props) {
  var __availability = props.availability;
  var styleVariation = props.styleVariation;
  var teaser = props.teaser;
  var availability = __availability !== undefined ? __availability : "NotAvailable";
  var clickTracking = UseClickTracking.use();
  var ffAlertButton = Context.Config.getFeatureFlippingStatus("AlertButton");
  var userStatus = User_Observable.UserStatus.Hook.use();
  var match = Atom_FavAlertToaster.Toaster.Hook.use();
  var toastify = match.add;
  var onRemove = function (param) {
    switch (styleVariation) {
      case "Program" :
          return clickTracking({
                      eventGroup: "program_actions",
                      eventName: "REMOVE_FROM_FAVORITES"
                    });
      case "ContentCollection" :
          return clickTracking({
                      eventGroup: "collection_actions",
                      eventName: "REMOVE_FROM_FAVORITES"
                    });
      case "TopTeaser" :
      case "TeaserHover" :
          return ;
      
    }
  };
  var onAdd = function (param) {
    switch (styleVariation) {
      case "Program" :
          clickTracking({
                eventGroup: "program_actions",
                eventName: "ADD_TO_FAVORITES"
              });
          break;
      case "ContentCollection" :
          clickTracking({
                eventGroup: "collection_actions",
                eventName: "ADD_TO_FAVORITES"
              });
          break;
      case "TopTeaser" :
      case "TeaserHover" :
          break;
      
    }
    if (ffAlertButton !== "On") {
      return ;
    }
    if (availability !== "Upcoming") {
      return ;
    }
    switch (userStatus) {
      case "Connected" :
          toastify(9000, {
                icon: Atom_Icon_Ds.updateColor(Atom_Icon_Ds.checked, "Success")
              });
          return ;
      case "Anonymous" :
      case "Disconnected" :
          return ;
      
    }
  };
  var match$1 = User_Observable.Favorite.Hook.useStatus(teaser.dsId, onAdd, onRemove);
  var toggle = match$1.toggle;
  var match$2 = Context.I18n.getState();
  var formaters = match$2.formaters;
  var labels = match$2.labels;
  var match$3 = labels.common;
  var match$4 = labels.reminder.subscribe;
  var handleClick = function (ev) {
    ev.preventDefault();
    ev.stopPropagation();
    toggle();
  };
  var title = Core__Option.getOr(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.empty);
  var removeFavoriteTitle = formaters.removeFavorite(Type_String.Teaser.Title.toString(title));
  var addFavoriteTitle = formaters.addFavorite(Type_String.Teaser.Title.toString(title));
  var removeReminderTitle = formaters.removeReminder(Type_String.Teaser.Title.toString(title));
  var addReminderTitle = formaters.addReminder(Type_String.Teaser.Title.toString(title));
  var availability$1;
  switch (userStatus) {
    case "Anonymous" :
        availability$1 = availability === "Upcoming" ? "Available" : availability;
        break;
    case "Connected" :
    case "Disconnected" :
        availability$1 = availability;
        break;
    
  }
  var match$5;
  var exit = 0;
  if (match$1.status === "Favorite") {
    if (availability$1 === "Upcoming") {
      if (ffAlertButton === "On") {
        match$5 = [
          removeReminderTitle,
          match$4.remove,
          Atom_Cta_Ds.Favorite.make("Remove", styleVariation, "Upcoming")
        ];
      } else {
        exit = 2;
      }
    } else {
      exit = availability$1 === "Available" ? 2 : 1;
    }
  } else if (availability$1 === "Upcoming") {
    if (ffAlertButton === "On") {
      match$5 = [
        addReminderTitle,
        match$4.add,
        Atom_Cta_Ds.Favorite.make("Add", styleVariation, "Upcoming")
      ];
    } else {
      exit = 3;
    }
  } else {
    exit = availability$1 === "Available" ? 3 : 1;
  }
  switch (exit) {
    case 1 :
        match$5 = [
          undefined,
          "",
          Atom_Cta_Ds.Favorite.make("Add", styleVariation, "Available")
        ];
        break;
    case 2 :
        match$5 = [
          removeFavoriteTitle,
          match$3.remove,
          Atom_Cta_Ds.Favorite.make("Remove", styleVariation, "Available")
        ];
        break;
    case 3 :
        match$5 = [
          addFavoriteTitle,
          match$3.add,
          Atom_Cta_Ds.Favorite.make("Add", styleVariation, "Available")
        ];
        break;
    
  }
  switch (userStatus) {
    case "Anonymous" :
        if (availability$1 !== "Available") {
          return null;
        }
        break;
    case "Connected" :
        if (!(availability$1 === "Available" || availability$1 === "Upcoming")) {
          return null;
        }
        break;
    case "Disconnected" :
        return null;
    
  }
  return JsxRuntime.jsx(Atom_Cta.Button.make, {
              label: match$5[1],
              onClick: handleClick,
              screenReadersLabel: match$5[0],
              style: match$5[2]
            });
}

var make = Atom_FavAlertButton;

export {
  availabilityFromTeaserFavorite ,
  make ,
}
/* Context Not a pure module */
