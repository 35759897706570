// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../User.res.mjs";
import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_exn from "@rescript/std/lib/es6/js_exn.js";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as User_Type from "../User_Type.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";
import * as Belt_Array from "@rescript/std/lib/es6/belt_Array.js";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Promise from "@rescript/core/src/Core__Promise.res.mjs";
import * as User_Api__Env from "./User_Api__Env.res.mjs";
import * as Webapi__Fetch from "rescript-webapi/src/Webapi/Webapi__Fetch.res.mjs";

function error_t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "error",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.stringToJson, extra);
                        })(v.error)
                  ],
                  [
                    "success",
                    true,
                    (function (extra) {
                          return Spice.optionToJson(Spice.boolToJson, extra);
                        })(v.success)
                  ]
                ]));
}

function error_t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var error = (function (extra) {
        return Spice.optionFromJson(Spice.stringFromJson, extra);
      })(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
  if (error.TAG === "Ok") {
    var success = (function (extra) {
          return Spice.optionFromJson(Spice.boolFromJson, extra);
        })(Belt_Option.getWithDefault(Js_dict.get(v, "success"), null));
    if (success.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                error: error._0,
                success: success._0
              }
            };
    }
    var e = success._0;
    return {
            TAG: "Error",
            _0: {
              path: ".success" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = error._0;
  return {
          TAG: "Error",
          _0: {
            path: ".error" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function reason_encode(v) {
  switch (v) {
    case "EmailNotVerified" :
        return ["EmailNotVerified"];
    case "InvalidEmail" :
        return ["InvalidEmail"];
    case "Other" :
        return ["Other"];
    case "EventInvalid" :
        return ["EventInvalid"];
    case "EventAlreadyParticipated" :
        return ["EventAlreadyParticipated"];
    case "EventParticipationEnded" :
        return ["EventParticipationEnded"];
    
  }
}

function reason_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "EmailNotVerified" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EmailNotVerified"
                  };
          }
      case "EventAlreadyParticipated" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventAlreadyParticipated"
                  };
          }
      case "EventInvalid" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventInvalid"
                  };
          }
      case "EventParticipationEnded" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "EventParticipationEnded"
                  };
          }
      case "InvalidEmail" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "InvalidEmail"
                  };
          }
      case "Other" :
          if (v.length !== 1) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          } else {
            return {
                    TAG: "Ok",
                    _0: "Other"
                  };
          }
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function parseJsonError(response) {
  return response.json().then(error_t_decode);
}

function exnToError(exn) {
  var tmp;
  if (exn.RE_EXN_ID === Js_exn.$$Error) {
    var name = exn._1.name;
    tmp = name === "AbortError" ? "RequestAborted" : "Unknown";
  } else {
    tmp = "Unknown";
  }
  return Promise.resolve({
              TAG: "Error",
              _0: tmp
            });
}

var $$Error = {
  error_t_encode: error_t_encode,
  error_t_decode: error_t_decode,
  reason_encode: reason_encode,
  reason_decode: reason_decode,
  parseJsonError: parseJsonError,
  exnToError: exnToError
};

function makeJSONBody(args, encoder) {
  return Caml_option.some(JSON.stringify(encoder(args)));
}

function parseResponse(decoder) {
  return async function (response) {
    var status = response.status;
    var parsed;
    var exit = 0;
    if (status >= 200 && status < 300) {
      var value = await response.json().then(decoder);
      parsed = value.TAG === "Ok" ? ({
            TAG: "Ok",
            _0: value._0
          }) : ({
            TAG: "Error",
            _0: "CantParseResponse"
          });
    } else if (status < 430) {
      if (status > 403 || status < 400) {
        parsed = status >= 429 ? ({
              TAG: "Error",
              _0: "TooManyRequest"
            }) : ({
              TAG: "Error",
              _0: {
                TAG: "FailedWithStatus",
                _0: status
              }
            });
      } else if (status >= 403) {
        exit = 1;
      } else {
        switch (status) {
          case 400 :
              var match = await response.json().then(error_t_decode);
              if (match.TAG === "Ok") {
                var match$1 = match._0;
                var error = match$1.error;
                var exit$1 = 0;
                if (error === "email_not_verified") {
                  parsed = {
                    TAG: "Error",
                    _0: {
                      TAG: "BadRequest",
                      _0: "EmailNotVerified"
                    }
                  };
                } else {
                  exit$1 = 2;
                }
                if (exit$1 === 2) {
                  var success = match$1.success;
                  parsed = success !== undefined && success === false ? ({
                        TAG: "Error",
                        _0: {
                          TAG: "BadRequest",
                          _0: "InvalidEmail"
                        }
                      }) : ({
                        TAG: "Error",
                        _0: {
                          TAG: "BadRequest",
                          _0: "Other"
                        }
                      });
                }
                
              } else {
                parsed = {
                  TAG: "Error",
                  _0: {
                    TAG: "BadRequest",
                    _0: "Other"
                  }
                };
              }
              break;
          case 401 :
              exit = 1;
              break;
          case 402 :
              parsed = {
                TAG: "Error",
                _0: {
                  TAG: "FailedWithStatus",
                  _0: status
                }
              };
              break;
          
        }
      }
    } else {
      parsed = status !== 500 && status !== 503 ? ({
            TAG: "Error",
            _0: {
              TAG: "FailedWithStatus",
              _0: status
            }
          }) : ({
            TAG: "Error",
            _0: "ServerError"
          });
    }
    if (exit === 1) {
      var match$2 = await response.json().then(error_t_decode);
      if (match$2.TAG === "Ok") {
        var match$3 = match$2._0;
        var error$1 = match$3.error;
        var exit$2 = 0;
        if (error$1 !== undefined) {
          if (error$1 === "email_not_verified") {
            parsed = {
              TAG: "Error",
              _0: {
                TAG: "BadRequest",
                _0: "EmailNotVerified"
              }
            };
          } else if (error$1 === "-2") {
            parsed = {
              TAG: "Error",
              _0: {
                TAG: "BadRequest",
                _0: "EventInvalid"
              }
            };
          } else if (error$1 === "-5") {
            parsed = {
              TAG: "Error",
              _0: {
                TAG: "BadRequest",
                _0: "EventParticipationEnded"
              }
            };
          } else if (error$1 === "-6") {
            parsed = {
              TAG: "Error",
              _0: {
                TAG: "BadRequest",
                _0: "EventAlreadyParticipated"
              }
            };
          } else {
            exit$2 = 2;
          }
        } else {
          exit$2 = 2;
        }
        if (exit$2 === 2) {
          var success$1 = match$3.success;
          parsed = success$1 !== undefined && success$1 === false ? ({
                TAG: "Error",
                _0: {
                  TAG: "BadRequest",
                  _0: "InvalidEmail"
                }
              }) : ({
                TAG: "Error",
                _0: "InvalidAuthent"
              });
        }
        
      } else {
        parsed = {
          TAG: "Error",
          _0: "InvalidAuthent"
        };
      }
    }
    var exit$3 = 0;
    if (parsed.TAG !== "Ok") {
      var tmp = parsed._0;
      if (typeof tmp !== "object") {
        switch (tmp) {
          case "ServerError" :
          case "TooManyRequest" :
          case "CantParseResponse" :
              exit$3 = 1;
              break;
          default:
            
        }
      } else if (tmp.TAG === "FailedWithStatus") {
        exit$3 = 1;
      }
      
    }
    if (exit$3 === 1) {
      User.set(User_Type.restrictUserFromApi(User.get()));
    }
    return parsed;
  };
}

var Utils = {
  makeJSONBody: makeJSONBody,
  parseResponse: parseResponse
};

function fetchWithDecoder(decoder) {
  return function (param) {
    return Core__Promise.$$catch(fetch(param.url, Webapi__Fetch.RequestInit.make(param.method, Caml_option.some(param.headers), param.body, undefined, undefined, undefined, param.credentials, undefined, undefined, undefined, undefined, Caml_option.some(param.signal), undefined)).then(parseResponse(decoder)), exnToError);
  };
}

var Token = {};

var trailingSlashRegex = new RegExp("/$");

function createUrl(env, domain, path) {
  if (domain === "Sso") {
    return "https://api" + User_Api__Env.envToString(env) + ".arte.tv/api/sso/v3/" + path.replace(trailingSlashRegex, "");
  } else {
    return "https://auth" + User_Api__Env.envToString(env) + ".arte.tv/ssologin/" + path.replace(trailingSlashRegex, "");
  }
}

function getToken(overrideToken) {
  if (overrideToken !== undefined) {
    return Caml_option.some(Caml_option.valFromOption(overrideToken));
  } else {
    return User.getToken();
  }
}

function getBearerToken(tokenKindOpt, userToken) {
  var tokenKind = tokenKindOpt !== undefined ? tokenKindOpt : "User";
  switch (tokenKind) {
    case "User" :
        return Core__Option.flatMap(userToken, User_Type.Token.createBearerToken);
    case "Sso" :
        return User.Api.ssoApiKey();
    case "None" :
        return ;
    
  }
}

function defaultHeaders(locale) {
  return [
          [
            "accept-language",
            Util_I18n.localeToString(locale)
          ],
          [
            "access-control-request-headers",
            "content-type"
          ],
          [
            "client",
            "web"
          ],
          [
            "content-type",
            "application/json"
          ]
        ];
}

function makeHeaders(locale, bearerToken) {
  var headers = new Headers(defaultHeaders(locale));
  Core__Option.forEach(bearerToken, (function (bearerToken) {
          headers.append("Authorization", bearerToken);
        }));
  return headers;
}

function userGuard(userRestriction, fn) {
  return function (userToken, fetchParams) {
    var userStatus = Core__Option.getOr(Core__Option.map(userToken, User_Type.Status.fromToken), "Disconnected");
    if (userRestriction === undefined) {
      return fn(fetchParams);
    }
    switch (userRestriction) {
      case "Anonymous" :
          switch (userStatus) {
            case "Anonymous" :
            case "Connected" :
                return fn(fetchParams);
            case "Disconnected" :
                return Promise.resolve({
                            TAG: "Error",
                            _0: "InvalidAuthent"
                          });
            
          }
      case "Connected" :
          switch (userStatus) {
            case "Connected" :
                return fn(fetchParams);
            case "Anonymous" :
            case "Disconnected" :
                return Promise.resolve({
                            TAG: "Error",
                            _0: "InvalidAuthent"
                          });
            
          }
      case "Disconnected" :
          return fn(fetchParams);
      
    }
  };
}

function MakeCustomRequest(Config) {
  var $$fetch = userGuard(Config.options.userRestriction, Config.$$fetch);
  var call = function (param) {
    var userToken = getToken(param.overrideToken);
    var headers = makeHeaders(param.locale, getBearerToken(Config.options.tokenKind, userToken));
    var match = Config.endpoint;
    var url = match.NAME === "Static" ? match.VAL : match.VAL(param.args);
    return $$fetch(userToken, {
                url: createUrl(User.Env.get(), Config.domain, url),
                headers: headers,
                signal: param.signal,
                method: "Get",
                credentials: Config.options.withCredentials
              });
  };
  return {
          call: call
        };
}

function MakeRequest(Config) {
  var domain = Config.domain;
  var endpoint = Config.endpoint;
  var options = Config.options;
  var $$fetch = fetchWithDecoder(Config.decodeApiResponse);
  var $$fetch$1 = userGuard(options.userRestriction, $$fetch);
  var call = function (param) {
    var userToken = getToken(param.overrideToken);
    var headers = makeHeaders(param.locale, getBearerToken(options.tokenKind, userToken));
    var url = endpoint.NAME === "Static" ? endpoint.VAL : endpoint.VAL(param.args);
    return $$fetch$1(userToken, {
                url: createUrl(User.Env.get(), domain, url),
                headers: headers,
                signal: param.signal,
                method: "Get",
                credentials: options.withCredentials
              });
  };
  return {
          call: call
        };
}

function MakeCustomMutation(Config) {
  var $$fetch = userGuard(Config.options.userRestriction, Config.$$fetch);
  var call = function (param) {
    var args = param.args;
    var body = Core__Option.getOr(Config.makeBody(args), JSON.stringify({}));
    var userToken = getToken(param.overrideToken);
    var headers = makeHeaders(param.locale, getBearerToken(Config.options.tokenKind, userToken));
    var match = Config.endpoint;
    var url = match.NAME === "Static" ? match.VAL : match.VAL(args);
    return $$fetch(userToken, {
                url: createUrl(User.Env.get(), Config.domain, url),
                headers: headers,
                body: Caml_option.some(body),
                signal: param.signal,
                method: Config.method,
                credentials: Config.options.withCredentials
              });
  };
  return {
          call: call
        };
}

function MakeMutation(Config) {
  var domain = Config.domain;
  var method = Config.method;
  var endpoint = Config.endpoint;
  var makeBody = Config.makeBody;
  var options = Config.options;
  var $$fetch = fetchWithDecoder(Config.decodeApiResponse);
  var $$fetch$1 = userGuard(options.userRestriction, $$fetch);
  var call = function (param) {
    var args = param.args;
    var body = Core__Option.getOr(makeBody(args), JSON.stringify({}));
    var userToken = getToken(param.overrideToken);
    var headers = makeHeaders(param.locale, getBearerToken(options.tokenKind, userToken));
    var url = endpoint.NAME === "Static" ? endpoint.VAL : endpoint.VAL(args);
    return $$fetch$1(userToken, {
                url: createUrl(User.Env.get(), domain, url),
                headers: headers,
                body: Caml_option.some(body),
                signal: param.signal,
                method: method,
                credentials: options.withCredentials
              });
  };
  return {
          call: call
        };
}

var User$1 = {
  Token: Token,
  trailingSlashRegex: trailingSlashRegex,
  createUrl: createUrl,
  getToken: getToken,
  getBearerToken: getBearerToken,
  defaultHeaders: defaultHeaders,
  makeHeaders: makeHeaders,
  userGuard: userGuard,
  MakeCustomRequest: MakeCustomRequest,
  MakeRequest: MakeRequest,
  MakeCustomMutation: MakeCustomMutation,
  MakeMutation: MakeMutation
};

export {
  $$Error ,
  Utils ,
  fetchWithDecoder ,
  User$1 as User,
}
/* trailingSlashRegex Not a pure module */
