// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";

function withBackoff(maxRetryAttempts, baseBackoffTime, backoffFactor, asyncFn, validateResult, onSuccess, onTimeout, onError, onCancel) {
  var timeoutId = {
    contents: undefined
  };
  var run = async function (retryCount) {
    var retry = function () {
      if (retryCount >= maxRetryAttempts) {
        return onTimeout();
      }
      var timerID = setTimeout((function () {
              run(retryCount + 1);
            }), baseBackoffTime * Math.pow(backoffFactor, retryCount) | 0);
      timeoutId.contents = Caml_option.some(timerID);
    };
    var response = await asyncFn();
    var validatedResult = validateResult(response);
    var timeout = timeoutId.contents;
    if (timeout !== undefined) {
      clearTimeout(Caml_option.valFromOption(timeout));
      timeoutId.contents = undefined;
    }
    switch (validatedResult) {
      case "Success" :
          return onSuccess();
      case "Retry" :
          return retry();
      case "Error" :
          return onError();
      case "Aborted" :
          return ;
      
    }
  };
  var cancel = function () {
    onCancel();
    var id = timeoutId.contents;
    if (id !== undefined) {
      clearTimeout(Caml_option.valFromOption(id));
      return ;
    }
    
  };
  return {
          cancel: cancel,
          run: run
        };
}

export {
  withBackoff ,
}
/* No side effect */
