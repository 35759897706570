// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Divider from "../atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Atom_Backlink from "../atom/Atom_Backlink.res.mjs";
import * as User_Provider from "../../user/User_Provider.res.mjs";
import * as User_Observable from "../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Organism_Setting_Item from "../organism/Organism_Setting_Item.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var height = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.vh(60));

function Page_Settings$Loader(props) {
  return JsxRuntime.jsx(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              children: JsxRuntime.jsx(Atom_Loader.make, {}),
              height: Caml_option.some(height),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
            });
}

var Loader = {
  height: height,
  make: Page_Settings$Loader
};

var blockSpaceContainer = Particle_Screen_Ds.make(undefined, undefined, Caml_option.some(Particle_Spacer_Constants.Zone.desktop), undefined, undefined, Particle_Spacer_Constants.Zone.mobile);

function make(param) {
  var match = Context.I18n.getState();
  var labels = match.labels;
  var setting = labels.common.setting;
  var match$1 = labels.setting;
  var userConsentStatus = User_Provider.Hook.useAnonymousFeatureStatus();
  var match$2 = User_Observable.AnonymousFeatureStatus.AnonymousFeatureStatus.Hook.use();
  var userStatus = User_Observable.UserStatus.Hook.use();
  var status = User_Observable.Status.Hook.use();
  var tmp;
  var exit = 0;
  switch (userStatus) {
    case "Connected" :
        tmp = Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.TargetedCommunication.make, {
                  title: match$1.userTargetedCommunicationTitle,
                  description: match$1.userTargetedCommunicationDescription
                }));
        break;
    case "Anonymous" :
    case "Disconnected" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    tmp = Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.UserInterface.make, {
              title: match$1.userTitle,
              description: match$1.userDescription,
              onChange: match$2.setAnonymous,
              userConsentStatus: userConsentStatus
            }));
  }
  var personnalisationItems = [tmp];
  var interfaceItems = [Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.Clip.make, {
              title: match$1.clipAutoplayTitle,
              description: match$1.clipAutoplayDescription,
              onChange: param.onClipAutoplayChange,
              isClipAutoplayActivated: param.isClipAutoplayActivated
            }))];
  var videoItems = [
    Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.Player.make, {
              title: match$1.playerAutoplayTitle,
              description: match$1.playerAutoplayDescription,
              onChange: param.onPlayerAutoplayChange,
              isPlayerAutoplayActivated: param.isPlayerAutoplayActivated
            })),
    Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.Autoplay.make, {
              title: match$1.autoplayTitle,
              description: match$1.autoplayDescription,
              onChange: param.onPlayNextChange,
              isPlayNextActivated: param.isPlayNextActivated
            })),
    Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.VideoQuality.make, {
              onChange: param.onChangeVideoQuality,
              value: param.selectedVideoQuality
            }))
  ];
  var cookiesItem = [Caml_option.some(JsxRuntime.jsx(Organism_Setting_Item.CookieManagement.make, {
              title: match$1.cookieTitle,
              description: match$1.cookieDescription,
              ctaLabel: match$1.cookieLabel
            }))];
  var renderItems = function (items) {
    var filteredItems = Util_Array.catOption(items);
    var formattedItems = filteredItems.map(function (item, index) {
          var isLast = index === (filteredItems.length - 1 | 0);
          return JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        item,
                        isLast ? JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.settingsMenu("LastItemFromCategory")
                              }) : JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.settingsMenu("Normal")
                              })
                      ]
                    }, index.toString());
        });
    return JsxRuntime.jsx(Atom_Column.make, {
                children: formattedItems
              });
  };
  var tmp$1;
  var exit$1 = 0;
  switch (status) {
    case "NotInitialized" :
        tmp$1 = JsxRuntime.jsx(Page_Settings$Loader, {});
        break;
    case "Initialized" :
    case "ForceReInit" :
        exit$1 = 1;
        break;
    
  }
  if (exit$1 === 1) {
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            renderItems(personnalisationItems),
            renderItems(interfaceItems),
            renderItems(videoItems),
            renderItems(cookiesItem),
            JsxRuntime.jsx(Organism_Setting_Item.SobrietyMessage.make, {
                  message: match$1.sobrietyMessageText,
                  linkLabel: match$1.sobrietyMessageLinkLabel,
                  linkUrl: match$1.sobrietyMessageLinkUrl,
                  title: match$1.sobrietyMessageTitle
                })
          ]
        });
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              blockSpace: Caml_option.some(blockSpaceContainer),
              children: [
                JsxRuntime.jsx(Atom_Backlink.User.make, {}),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(setting),
                      kind: "h1",
                      style: Atom_Text_Ds.Setting.title
                    }),
                tmp$1
              ],
              kind: "Main"
            });
}

export {
  Loader ,
  blockSpaceContainer ,
  make ,
}
/* height Not a pure module */
