// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Css_Js_Core from "bs-css/src/Css_Js_Core.res.mjs";
import * as Type_Header from "../../../type/Type_Header.res.mjs";
import * as Particle_Css from "../../particle/Particle_Css.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";

var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
      TAG: "Exact",
      _0: "Header"
    });

var scrollableHeightCalculation = CssJs.height(Css_Js_Core.Calc.$neg(CssJs.vh(100), Particle_Css.pxToRem(Type_Header.Constants.height.default)));

var rules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.height(CssJs.auto)], undefined, undefined, [
      zIndexRule,
      scrollableHeightCalculation,
      CssJs.overflow(CssJs.auto)
    ]);

var MenuNavigation = {
  rules: rules
};

var rules$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      zIndexRule,
      CssJs.overflow(CssJs.auto),
      CssJs.minWidth(CssJs.pct(100)),
      CssJs.unsafe("width", "max-content")
    ]);

function display(searchState) {
  var tmp;
  tmp = searchState === "On" ? "none" : "flex";
  return Particle_Screen_Ds.make(undefined, undefined, "flex", undefined, undefined, tmp);
}

var LanguageSwitcher = {
  rules: rules$1,
  display: display
};

var makeRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.minWidth(CssJs.pct(50)),
      CssJs.height(CssJs.auto)
    ], undefined, undefined, [
      zIndexRule,
      CssJs.overflow(CssJs.auto),
      CssJs.minWidth(CssJs.pct(100)),
      scrollableHeightCalculation
    ]);

var ConcertMenuNavigation = {
  makeRules: makeRules
};

export {
  zIndexRule ,
  scrollableHeightCalculation ,
  MenuNavigation ,
  LanguageSwitcher ,
  ConcertMenuNavigation ,
}
/* zIndexRule Not a pure module */
