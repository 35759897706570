import {
    ConfigProvider as DsConfigProvider,
    globalStyle as dsGlobalStyle,
    I18nProvider as DsI18nProvider,
    UserProvider,
} from '@artegeie/design-system/replay';
import { ErrorInfo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { type ConfigProviderApi, type FrontendContext, type NextPage } from '@artegeie/design-system/type';
import { CacheProvider } from '@emotion/react';
import App, { type AppContext } from 'next/app';
import { useRouter } from 'next/router';
import { Player } from '../domains/player/Player';
import { controlledPlayer } from '../domains/program/createPlayerEvent';
import getTrailerUrl from '../domains/program/getTrailerUrl';
import { getDateLocale, translateFactory, loadPhrases } from '@replay/i18n';
import { type Translate } from '@replay/i18n/types/translations';
import { env } from '@replay/env-generator';
import { translations } from '@replay/i18n/generated';
import { cache } from '../shared/emotion-server';
import { ErrorBoundary } from '../shared/ErrorBoundary';
import {
    acceptDataLabel,
    acceptTosLabel,
    checkFaqLabel,
    forgottenPasswordSuccess,
    formValidationStringMax,
    formValidationStringMin,
    magicLink,
    registerConfirmSuccess,
    translateAddToFavorite,
    translateAddToReminder,
    translateInfoSelectedDay,
    translateNextEpisode,
    translateProgress,
    translateRemoveFromFavorite,
    translateRemoveFromReminder,
    translateSeasonCount,
    translateWatchTrailer,
} from '@replay/i18n/formaters';
import getGeoblockingFromRequest from '../shared/getGeoblockingFromRequest';
import { Layout } from '../shared/Layout';
import { SearchProvider } from '../shared/SearchContext';
import { subscribeToNewsletter } from '../shared/subscribeToNewsletter';
import { buildChapterUrl, buildNextPageUrl, buildPageUrl } from '../shared/urlBuilder';
import { useCheckLocalStorage } from '../shared/useLocalStorage';
import { GetInitialProps, ReplayAppProps } from '@replay/types/App';
import { isLocale, Locale } from '@replay/i18n/types/locale';

import { useSearchParams } from 'next/navigation';
import { fetchFooterData } from '../domains/footer/fetchFooterData';
import {
    translateAvailabilityDate,
    translateBetweenDate,
    translateBetweenDatesFormatted,
    translateDate,
    translateDateWithFormat,
    translateNextBroadcastDate,
    translateWeekInterval,
    untilDateFormated,
} from '@replay/i18n/dateFormaters';
import { fetchJson } from '@replay/fetch';
import getReleaseInfos from '../shared/getReleaseInfos';
import { isTeaserAvailable } from '../shared/isTeaserAvailable';
import { makeGeoblockingFromString } from '../shared/makeGeoblocking';
import replayizeEmacUrl from '../shared/replayizeEmacUrl';
import {
    type EmacPageResult,
    getAlternativeLanguages,
    getHighestParentUrlFromEmacPageProps,
    getStats,
    type ServerSidePageProps,
} from '@replay/types/MyGetServerSidePropsResult';
import { isError } from '@replay/types/Result';
import { getErrorUrl } from './500';
import { type WithNewRelic } from '@replay/types/Window';
import { useSendPageView } from '@replay/tracking/pageview';
import { getServerSideTracking, userStore } from '@replay/tracking/userStore';
import { eventStore } from '@replay/tracking/eventStore';
import { addConsentUpdateCallback, showPrivacyCenter } from '@replay/tracking/tagCommander';
import type { ConsentStatus, UserFrontContext } from '@replay/types/Tracking';
import useSetUserInfos from '@replay/tracking/useSetUserInfos';
import { validateUtmQueryParam } from '@replay/tracking/utils';
const loadNextPage = async (nextPageUrl: string): Promise<NextPage | null | undefined> => {
    const res = await fetchJson<NextPage>(replayizeEmacUrl(nextPageUrl));
    if (isError(res)) {
        return null;
    }
    return res.value;
};

type DsFeatureFlippings = Parameters<typeof DsConfigProvider>[0]['featureFlippings'];
const featureFlippings: DsFeatureFlippings = {
    themeSwitch: false,
    magicLinkConnection: env.NEXT_PUBLIC_FEATURE_FLAGS_MAGIC_LINK_CONNECTION,
    improveComWithUser: env.NEXT_PUBLIC_FEATURE_FLAGS_IMPROVE_COM_WITH_USER,
    alertButton: env.NEXT_PUBLIC_FEATURE_FLAGS_ALERT_BUTTON,
    comingSoonEuropeEntry: env.NEXT_PUBLIC_FEATURE_FLAGS_COMINGSOON_EUROPE_ENTRY,
};

const configProviderApi: (locale: Locale, translate: Translate) => ConfigProviderApi = (locale, translate) => ({
    loadNextPage,
    getTrailerUrl: getTrailerUrl(locale),
    getNextPageUrl: buildNextPageUrl,
    getPageUrl: buildPageUrl,
    getChapterUrl: buildChapterUrl,
    showPrivacyCenter,
    subscribeToNewsletter: subscribeToNewsletter(translate),
    // TODO: implement this function
    startAgeVerification: () => {},
    // redefine in the component because with need a prop to create the function
    isTeaserAvailable: undefined,
    onLogoutCallback: undefined,
    onPurgeCallback: undefined,
});

const renderError = (): React.ReactElement => {
    if (global.window !== undefined && ['production', 'test'].includes(env.NODE_ENV)) {
        global.window.location.replace(getErrorUrl());
    }

    return <></>;
};

declare let window: WithNewRelic;
const handleError = (error: Error, data: ErrorInfo | undefined): void => {
    const { componentStack } = data || {};
    /* eslint-disable no-console */
    console.group('A rendering error occured');
    console.error(error);
    if (componentStack) {
        console.log('Component Stack Trace', componentStack);
    }
    console.groupEnd();
    /* eslint-enable no-console */
    // send client error to new relic
    if (window.newrelic) {
        window.newrelic.noticeError(error);
    }
};

const disableScrollRestoration = (): void => {
    if (global.history?.scrollRestoration === 'auto') {
        global.history.scrollRestoration = 'manual';
    }
};

type CreateVideoElement = React.ComponentProps<typeof DsConfigProvider>['createVideoElement'];

const createTrailerElement = (
    translate: Translate,
    locale: Locale,
    page: EmacPageResult<Record<string, unknown>>,
): CreateVideoElement => {
    const DynamicPlayer: CreateVideoElement = ({
        onPlayerReady,
        playSetter,
        pauseSetter,
        videoKind,
        videoUrl,
        previewImage,
        isUserPaused,
    }) => {
        const handlePlayerEvent = controlledPlayer({ pauseSetter, playSetter, onPlayerReady, isUserPaused });

        return (
            <Player
                forcedMute={videoKind === 'clip' || videoKind === 'preview'}
                loop={videoKind === 'preview'}
                previewData={{
                    title: '',
                    subtitle: '',
                    image: previewImage,
                    stickers: [],
                }}
                autoPlayNext={false}
                config={videoUrl}
                fromDomain="other"
                locale={locale}
                videoKind={videoKind}
                getExternalInterface={handlePlayerEvent}
                translate={translate}
                tcStartFrom={null}
                serverSideTracking={getServerSideTracking(page)}
                hasIllico={false}
                playlistItems={[]}
            />
        );
    };
    return DynamicPlayer;
};

const ssoEnv = env.NEXT_PUBLIC_SSO_ENV === 'preprod' ? 'preprod' : 'prod';

type WithUserProps = {
    children: React.ReactNode;
    ssoEnv: 'prod' | 'preprod';
};
const WithUser = ({ children, ssoEnv }: WithUserProps) => {
    if (env.NEXT_PUBLIC_IS_USER_ACTIVATED) {
        return (
            <UserProvider
                env={ssoEnv}
                ttl={env.NEXT_PUBLIC_USER_DATA_TTL}
                progressionTTl={env.NEXT_PUBLIC_LASTVIEWED_TTL}
            >
                {children}
            </UserProvider>
        );
    }
    return children;
};

dsGlobalStyle(true, 'Dark');

const ReplayApp = (props: ReplayAppProps): ReactElement => {
    const {
        pageProps,
        footerProps,
        geoblocking,
        serverTime,
        serverTimezone,
        phrases,
        releaseInfos,
        locale,
        isStandalone,
    } = props;
    const { push, back } = useRouter();
    const searchParams = useSearchParams();
    const {
        setAudienceMeasurementConsent,
        setTechnicalMeasurementConsent,
        setReleaseInfos,
        setTrackingId,
        setLoginStatus,
        setLocale,
        setUtm,
        setGeoBlocking,
    } = userStore.getState();
    const { sendPageview, sendTeaserClick } = eventStore.getState();

    useSendPageView();

    const { translate, i18n } = useMemo(() => translateFactory(locale, phrases), [locale, phrases]);
    const { sendUserClickedEvent } = eventStore();
    const getServerSideTrackingGroupControl = (value: FrontendContext) => {
        sendUserClickedEvent(getServerSideTracking(pageProps), { action: 'ACTION', ...value } as UserFrontContext);
    };

    const labels = useMemo(() => translations(translate), [translate]);
    const dateLocale = getDateLocale(locale as Locale);

    disableScrollRestoration();

    const createPlayerElement = createTrailerElement(translate, locale, pageProps);

    useCheckLocalStorage();
    useSetUserInfos();

    const [consentStatus, setConsentStatus] = useState<ConsentStatus>('unknown');
    const onLogoutCallback = (): void => {
        setLoginStatus('NotReady');
        try {
            localStorage.removeItem('LRTokenKey');
        } catch (e) {
            console.error(e);
        }
        push(`/${locale}/profile/auth/landing/`);
    };

    const onPurgeCallback = () => {
        push(`/${locale}/profile/myvideos/`);
    };
    //
    const api = {
        ...configProviderApi(locale, translate),
        // isTeaserAvailable: publicRuntimeConfig.FEATURE_FLAGS_GEOBLOCKING ? isTeaserAvailable(geoblocking) : undefined,
        isTeaserAvailable: isTeaserAvailable(geoblocking),
        onLogoutCallback,
        onPurgeCallback,
        consentStatus,
        goBack: () => {
            back();
        },
    };

    useEffect(() => {
        const utm = {
            utm_source: validateUtmQueryParam(searchParams && searchParams.get('utm_source')),
            utm_medium: validateUtmQueryParam(searchParams && searchParams.get('utm_medium')),
            utm_campaign: validateUtmQueryParam(searchParams && searchParams.get('utm_campaign')),
        };
        setTrackingId();
        addConsentUpdateCallback({
            onReady: (consentStatus, audienceMeasurementConsent, technicalMeasurementConsent) => {
                setConsentStatus(consentStatus);
                setAudienceMeasurementConsent(audienceMeasurementConsent);
                setTechnicalMeasurementConsent(technicalMeasurementConsent);
            },
        });
        setReleaseInfos(releaseInfos);
        setLocale(locale);
        setUtm(utm);
        setGeoBlocking(geoblocking);
    }, [
        setAudienceMeasurementConsent,
        setTechnicalMeasurementConsent,
        setReleaseInfos,
        setTrackingId,
        releaseInfos,
        locale,
        setLocale,
        searchParams,
        setUtm,
        setGeoBlocking,
        geoblocking,
    ]);

    useEffect(() => {
        sendPageview(getServerSideTracking(pageProps));
    }, [pageProps, sendPageview]);

    const teaserClick = useCallback(
        (trackingPixel: string) => {
            sendTeaserClick(getServerSideTracking(pageProps), trackingPixel);
        },
        [pageProps, sendTeaserClick],
    );

    return (
        <>
            <ErrorBoundary renderError={renderError} handleError={handleError}>
                <I18nextProvider i18n={i18n}>
                    <SearchProvider>
                        <CacheProvider value={cache}>
                            <DsConfigProvider
                                trackUserClick={getServerSideTrackingGroupControl}
                                featureFlippings={featureFlippings}
                                api={api}
                                baseUrl=""
                                cdnUrl={env.NEXT_PUBLIC_DS_CDN}
                                vodStoreHomepageUrl={env.NEXT_PUBLIC_SHOP_URL}
                                trackPixel={teaserClick}
                                createVideoElement={createPlayerElement}
                                playStoreUrl={
                                    'https://play.google.com/store/apps/details?id=tv.arte.plus7&utm_campaign=install-app-btn'
                                }
                                appStoreUrl={
                                    'https://apps.apple.com/app/apple-store/id405028510?pt=398281&ct=install-app-btn&mt=8'
                                }
                                serverTime={serverTime}
                            >
                                <DsI18nProvider
                                    locale={locale}
                                    labels={labels}
                                    loginUrl={`/${locale}/profile/auth/landing/`}
                                    profileUrl={
                                        env.NEXT_PUBLIC_FEATURE_FLAGS_INTERNAL_MYARTE
                                            ? `/${locale}/profile/myvideos/`
                                            : `https://my.arte.tv/index.php?lang=${locale}`
                                    }
                                    formaters={{
                                        betweenDatesFormatted: translateBetweenDatesFormatted(
                                            translate,
                                            serverTimezone,
                                        ),
                                        seasonCount: translateSeasonCount(translate),
                                        date: translateDate(translate, serverTimezone),
                                        dateWithFormat: translateDateWithFormat(dateLocale, serverTimezone),
                                        addFavorite: translateAddToFavorite(translate),
                                        addReminder: translateAddToReminder(translate),
                                        removeFavorite: translateRemoveFromFavorite(translate),
                                        removeReminder: translateRemoveFromReminder(translate),
                                        progress: translateProgress(translate),
                                        betweenDates: translateBetweenDate(translate, serverTimezone),
                                        nextBroadcastDate: translateNextBroadcastDate(
                                            translate,
                                            dateLocale,
                                            serverTimezone,
                                        ),
                                        nextEpisode: translateNextEpisode(translate),
                                        availabilityDate: translateAvailabilityDate(translate, serverTimezone),
                                        watchTrailer: translateWatchTrailer(translate),
                                        weekInterval: translateWeekInterval(translate, dateLocale, serverTimezone),
                                        infoSelectedDay: translateInfoSelectedDay(translate),
                                        formValidationStringMax: formValidationStringMax(translate),
                                        formValidationStringMin: formValidationStringMin(translate),
                                        forgottenPasswordSuccess: forgottenPasswordSuccess(translate),
                                        registerConfirmSuccess: registerConfirmSuccess(translate),
                                        magicLink: magicLink(translate),
                                        acceptTosLabel: acceptTosLabel(translate),
                                        acceptDataLabel: acceptDataLabel(translate),
                                        checkFaqLabel: checkFaqLabel(translate),
                                        untilDateFormated: untilDateFormated(translate, serverTimezone),
                                    }}
                                >
                                    <WithUser ssoEnv={ssoEnv}>
                                        <Layout
                                            isStandalone={isStandalone}
                                            parentUrl={getHighestParentUrlFromEmacPageProps(pageProps)}
                                            footerItems={footerProps.main}
                                            alternativeLanguages={getAlternativeLanguages(pageProps)}
                                            stats={getStats(pageProps)}
                                            locale={locale}
                                        >
                                            <App {...props} />
                                        </Layout>
                                    </WithUser>
                                </DsI18nProvider>
                            </DsConfigProvider>
                        </CacheProvider>
                    </SearchProvider>
                </I18nextProvider>
            </ErrorBoundary>
        </>
    );
};

type ExtendedAppContext = AppContext & { Component: AppContext['Component'] & { standalone?: boolean } };

ReplayApp.getInitialProps = async (appContext: ExtendedAppContext): Promise<GetInitialProps> => {
    const { Component, ctx, router } = appContext;

    const { locale: localeFromRouter = 'fr' } = router;
    const locale = isLocale(localeFromRouter) ? localeFromRouter : 'fr';

    if (Component.getInitialProps) {
        (await Component.getInitialProps(ctx)) as ServerSidePageProps;
    }

    const footerProps = await fetchFooterData(locale as Locale);
    const serverTime = new Date().toISOString();
    const serverTimezone = env.NEXT_PUBLIC_TIMEZONE;

    const geoblockingString = getGeoblockingFromRequest(ctx.req);
    const geoblocking = makeGeoblockingFromString(geoblockingString);

    const phrases = await loadPhrases(locale);

    const releaseInfos = getReleaseInfos() || { release: 'unknown', commit_id: 'unknown' };

    const isCI = process.env.CI === 'true';

    return {
        footerProps,
        geoblocking,
        serverTime,
        serverTimezone,
        phrases,
        releaseInfos,
        locale,
        isCI,
        isStandalone: !!Component.standalone,
    };
};

export default ReplayApp;
