// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "./Api.res.mjs";
import * as Type_Club from "../../club/Type_Club.res.mjs";
import * as Util_I18n from "../../util/Util_I18n.res.mjs";

function endpoint_1(args) {
  return "personalzone/" + Util_I18n.localeToString(args.language) + "?page=" + args.page.toString();
}

var endpoint = {
  NAME: "Dynamic",
  VAL: endpoint_1
};

var options = {
  userRestriction: "Anonymous"
};

var GetConfig = {
  domain: "Sso",
  endpoint: endpoint,
  options: options,
  decodeApiResponse: Type_Club.teasersResponse_decode
};

var Get = Api.User.MakeRequest({
      domain: "Sso",
      endpoint: endpoint,
      decodeApiResponse: Type_Club.teasersResponse_decode,
      options: options
    });

var get = Get.call;

export {
  GetConfig ,
  Get ,
  get ,
}
/* Get Not a pure module */
