// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Util_Css from "../../util/Util_Css.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_Css from "../particle/Particle_Css.res.mjs";
import * as Atom_Textfield_Ds from "./Atom_Textfield_Ds.res.mjs";
import * as Particle_Screen_Css from "../particle/Particle_Screen_Css.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";

function toCss(dsStyle) {
  var match = Atom_Textfield_Ds.view(dsStyle);
  var svgRules = Core__Option.map(match.icon, (function (param) {
          return [
                  CssJs.pointerEvents("none"),
                  CssJs.lineHeight(CssJs.zero),
                  CssJs.right(Particle_Css.spacerSizeOutside("M")),
                  CssJs.top(CssJs.pct(50)),
                  CssJs.transform({
                        NAME: "translateY",
                        VAL: CssJs.pct(-50)
                      })
                ];
        }));
  var placeholderSizeRules = [CssJs.selector("::placeholder", Particle_Screen_Css.toRules(undefined, undefined, Util_Css.toRules(Particle_Css.font_size), match.textStyle.size))];
  var blockSpace = Particle_Screen_Css.toRules(undefined, undefined, Particle_Spacer_Css.toCss, match.blockSpace);
  return {
          blockSpace: blockSpace,
          border: match.border,
          iconWrapperRules: svgRules,
          placeholderSizeRules: placeholderSizeRules
        };
}

export {
  toCss ,
}
/* CssJs Not a pure module */
